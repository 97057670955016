//src/services/profile-template.service.ts
import store from "../store/store";
import { ProfileData, ProfileAiData } from "@/models/profile.model";

export const buildProfileTemplate = (
  aiData?: ProfileAiData,
  data?: ProfileData,
  mandantUuid?: string,
  candidateAvailability?: string,
  hideSummary?: boolean
) => {
  let profileAiData = {} as ProfileAiData;
  let profileData = {} as ProfileData;
  let mandantName = "";
  let mandantAddress = "";

  if (!aiData) {
    profileAiData = {
      languageSkills: [
        { language: "Deutsch", level: "█ █ █ █ █ █ █ █" },
        { language: "Englisch", level: "█ █ █ █ " },
        { language: "Spanisch", level: "█ █ " },
      ],
      headline: "Überschrift des Kandidaten",
      overTheCandidate:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum sed quas vel, quis dolores tenetur velit harum eaque quasi, officiis molestiae animi voluptatibus explicabo tempora, incidunt eligendi voluptatum quia aut!",
      skills: `<li>Skill1</li>` + `<li>Skill2</li>` + `<li>Skill3</li>`,
      softSkills:
        `<li>SoftSkill1</li>` + `<li>SoftSkill2</li>` + `<li>SoftSkill3</li>`,
      education:
        `  <li>01.09.2000 bis 30.06.2009 <strong>Beispielschulabschluss</strong><br>bei Bespielschule in 54321 Musterstadt</li>` +
        `  <li>01.09.2009 bis 30.06.2011 <strong>Berufsausbildung zum Beispielberuf</strong><br>bei Ausbildungsbetrieb in 54321 Musterstadt` +
        `    <ul style="padding-left:1rem">` +
        `     <li>Detail</li>` +
        `     <li>Detail</li>` +
        `    </ul>` +
        `  </li>`,
      curiculumVitae:
        `  <li>01.07.2011 bis 31.12.2019 <strong>Beispielberuf</strong><br>bei Betrieb in 54321 Musterstadt` +
        `    <ul style="padding-left:1rem">` +
        `     <li>Detail</li>` +
        `     <li>Detail</li>` +
        `    </ul>` +
        `  </li>` +
        `  <li>01.07.2011 bis 31.12.2019 <strong>Beispielberuf</strong><br>bei Betrieb in 54321 Musterstadt<ul>` +
        `    <ul style="padding-left:1rem">` +
        `     <li>Detail</li>` +
        `     <li>Detail<br>&nbsp;</li>` +
        `    </ul>` +
        `  </li>`,
      summary:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum sed quas vel, quis dolores tenetur velit harum eaque quasi, officiis molestiae animi voluptatibus explicabo tempora, incidunt eligendi voluptatum quia aut!",
    };
  } else {
    profileAiData = aiData;
  }
  if (!data) {
    profileData = {
      candidateId: "1",
      profileNumber: "#0815",
      firstname: "Horst",
      lastname: "Lokal",
      age: "57",
      city: "Entenhausen",
      mobility: "eigener PKW<br>Mobil bis 15km",
      shiftReadiness: "Tagschicht<br>Wochenende",
      licences: "Führerschein Klasse B<br>Gabelstaplerschein",
    };
  } else {
    profileData = data;
  }
  if (mandantUuid) {
    const mandant = store.getters.getMandantByUuid(mandantUuid);
    if (mandant) {
      mandantName = mandant.name;
      mandantAddress = mandant.contact;
    } else {
      mandantName = "Mandant nicht gefunden";
      mandantAddress = "Keine Adresse verfügbar";
    }
  } else {
    mandantName = "Testniederlassung";
    mandantAddress =
      "Zum Geldspeicher 4<br>54321 Entenhausen<br><br>Karriere@superfirma.de<br>+49 1234 53216-0<br>+49 12345 321152 (WhatsApp)";
  }
  let availability = "Sofort";
  if (candidateAvailability) availability = candidateAvailability;

  const root = document.querySelector(":root");
  let standartFontType = "Arial, sans-serif";
  if (root) {
    const rootStyles = getComputedStyle(root);
    standartFontType = rootStyles
      .getPropertyValue("--font-profile-editor")
      .trim();
  }
  const tmpl = store.state.company.profileTemplate;

  let fontType = "";
  if (!fontType) fontType = standartFontType;

  const midWidth = parseFloat(tmpl.tblMid);
  const innerMidTableLeftFactor = 0.7;
  const midLeftWidth =
    (midWidth * (1 - innerMidTableLeftFactor)).toFixed(2) + "%";
  const midRightWidth = (midWidth * innerMidTableLeftFactor).toFixed(2) + "%";

  const profileTemplate = `
<head>
  <style>
    body {
      font-family: ${fontType};
    }
    table {
      border-collapse: collapse;
      width: 210mm;
    }
    td {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      padding-top: 1rem;
      border: 0;
    }
    figure.table, table {
      margin: 0;
      padding: 0;
      width: 210mm;
    }
  </style>
</head>
<body>
<figure class="table">
  <table class="ck-table-resized">
    <colgroup>
      <col style="width: ${tmpl.tblLeft};" >
      <col style="width: ${midLeftWidth};" >
      <col style="width: ${midRightWidth};" >
      <col style="width: ${tmpl.tblRight};" >
    </colgroup>
    <tbody>
      <!-- Erste Zeile -->
      <tr>
        <td style="vertical-align:top;">&nbsp;</td>
        <td style="background-color:${tmpl.background};" colspan="2">
          Profil ID: [<strong>${profileData.profileNumber}</strong>]
        </td>
        <td style="vertical-align:top;">&nbsp;</td>
      </tr>
      <!-- Zweite Zeile -->
      <tr>
        <td style="background-color:${
          tmpl.colorPrimary
        };vertical-align:top;">&nbsp;</td>
        <td style="background-color:${tmpl.background}; " colspan="2">
    <p style="text-align:center;">
    <img src="${
      tmpl.logo
    }" alt="logo" style="height:auto;width:90%;max-height:90%;"></p></td>
        <td style="background-color:${
          tmpl.colorPrimary
        };padding:0.5rem;vertical-align:top;">
          <h1><span style="color:${tmpl.fontLight};margin-right:4rem;">
            ${profileAiData.headline}</span></h1>
          <p><span style="color:${tmpl.fontLight}; font-size:${tmpl.fontM};">
            ${profileAiData.overTheCandidate}</span></p>
        </td>
      </tr>
      <!-- Zusammengeführte erste Spalte -->
      <tr>
        <td rowspan="21">&nbsp;</td>
        <td style="background-color:${tmpl.background};" colspan="2">
          <span style="color:${tmpl.colorPrimary};"><strong>${
    tmpl.titles.background
  }</strong></span>
        </td>
        <td style="padding:0.5rem;vertical-align:top;" rowspan="21">
          <!-- Inhalt der rechten Spalte -->
          <p><span style="color:${tmpl.colorPrimary};"><strong>${
    tmpl.titles.skills
  }</strong></span></p>
          <ul style="padding-left:1rem; font-size:${tmpl.fontM};">
            ${profileAiData.skills}
          </ul>
          <p><br>&nbsp;</p>
          <p><span style="color:${tmpl.colorPrimary};"><strong>${
    tmpl.titles.softSkills
  }</strong></span></p>
          <ul style="padding-left:1rem; font-size:${tmpl.fontM};">
            ${profileAiData.softSkills}
          </ul>
          <p><br>&nbsp;</p>
          <p><span style="color:${tmpl.colorPrimary};"><strong>${
    tmpl.titles.education
  }</strong></span></p>
          <ul style="padding-left:1rem; font-size:${tmpl.fontM};">
            ${profileAiData.education}
          </ul>
          <p><br>&nbsp;</p>
          <p><span style="color:${tmpl.colorPrimary};"><strong>${
    tmpl.titles.cv
  }</strong></span></p>
          <ul style="padding-left:1rem; font-size:${tmpl.fontM};">
            ${profileAiData.curiculumVitae}
          </ul>
          ${
            !hideSummary
              ? `
          <hr>
          <p><span style="font-size:1rem;"><strong>Zusammenfassung früherer oder irrelevanter Beschäftigungen</strong></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:${tmpl.fontM};">${profileAiData.summary}</span></p>
          `
              : ""
          }
        </td>
      </tr>
      <!-- Weitere Zeilen -->
      <tr>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl.fontXS
          };"><strong>Name:</strong></span></p>
        </td>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <span style="font-size:${tmpl.fontM};">${profileData.firstname} ${
    profileData.lastname
  }</span>
        </td>
      </tr>
      <tr>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl.fontXS
          };"><strong>Alter:</strong></span></p>
        </td>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <span style="font-size:${tmpl.fontM};">${profileData.age}</span>
        </td>
      </tr>
      <tr>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl.fontXS
          };"><strong>Wohnort:</strong></span></p>
        </td>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <span style="font-size:${tmpl.fontM};">${profileData.city}</span>
        </td>
      </tr>
      <tr>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl.fontXS
          };"><strong>${
    profileData.mobility ? "Mobilität:" : ""
  }</strong></span></p>
        </td>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <span style="font-size:${tmpl.fontM};">${
    profileData.mobility ?? ""
  }</span>
        </td>
      </tr>
      <tr>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <p style="text-align:right;"><span style="font-size:${
            tmpl.fontXS
          };"><strong>Verfügbar:</strong></span></p>
        </td>
        <td style="background-color:${tmpl.background};height:1.5rem;">
          <span style="font-size:${tmpl.fontM};">${availability ?? ""}</span>
        </td>
      </tr>
      <!-- Schichtbereitschaft -->
      ${
        profileData.shiftReadiness
          ? `
        <tr>
          <td style="background-color:${tmpl.background};" colspan="2">
            <span style="color:${tmpl.colorPrimary};"><strong>${tmpl.titles.shiftReadiness}</strong></span>
          </td>
        </tr>
        <tr>
          <td style="background-color:${tmpl.background};" colspan="2">
            <span style="font-size:${tmpl.fontM};">${profileData.shiftReadiness}</span>
          </td>
        </tr>
      `
          : ""
      }
            <!-- Führerscheine -->
      ${
        profileData.licences
          ? `
        <tr>
          <td style="background-color:${tmpl.background};" colspan="2">
            <span style="color:${tmpl.colorPrimary};"><strong>${tmpl.titles.licences}</strong></span>
          </td>
        </tr>
        <tr>
          <td style="background-color:${tmpl.background};" colspan="2">
            <span style="font-size:${tmpl.fontM};">${profileData.licences}</span>
          </td>
        </tr>
      `
          : ""
      }

      <!-- Sprachen -->
      <tr>
        <td style="background-color:${tmpl.background};" colspan="2">
          <span style="color:${tmpl.colorPrimary};"><strong>${
    tmpl.titles.languages
  }</strong></span>
        </td>
      </tr>
      ${profileAiData.languageSkills
        .map(
          (langSkill) => `
      <tr>
        <td style="background-color:${tmpl.background};">
          <span style="font-size:${tmpl.fontXS};"><strong>${langSkill.language}</strong></span>
        </td>
        <td style="background-color:${tmpl.background};">
          <span style="color:${tmpl.colorSecondary};"><strong>${langSkill.level}</strong></span>
        </td>
      </tr>`
        )
        .join("")}
      <!-- Ansprechpartner -->
      <tr>
        <td style="background-color:${tmpl.background};" colspan="2">
          <span style="color:${
            tmpl.colorPrimary
          };"><strong>Ansprechpartner</strong></span>
        </td>
      </tr>
      <tr>
        <td style="background-color:${tmpl.background};" colspan="2">
          <p><span style="font-size:${tmpl.fontM};">${
    store.state.company.loggedInUser.forename
  } ${store.state.company.loggedInUser.lastname}</span></p>
          <p><strong>${mandantName}</strong></p>
          <p>${mandantAddress}</p>
        </td>
      </tr>
      <!-- Letzte Zeile mit Button -->
      <tr>
        <td style="background-color:${tmpl.background};" colspan="2">
          <a
            href="${tmpl.candidatesButtonLink}?source=${
    profileData.profileNumber
  }"
            target="_blank"
            style="
              display: inline-block;
              background-color: ${tmpl.colorPrimary};
              color: ${tmpl.fontLight};
              padding: 5px 20px;
              margin-top: 20px;
              margin-bottom: 20px;
              border-radius: 5px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              text-decoration: none;
            ">
            ${tmpl.candidatesButtonText}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</figure>
</body>
`;
  return profileTemplate;
};
