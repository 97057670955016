<template>
  <!-- Modal for changing pwd -->
  <v-dialog :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'">
    <v-card>
      <v-card-title class="ma-3"> Passwort ändern </v-card-title>
      <v-card-text>
        <v-form v-model="passwordValid">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="password.oldPassword"
            label="Altes Passwort"
            type="password"
            :rules="[rules.required]"
            @update:model-value="$emit('dirty')"
          ></v-text-field>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="password.newPassword"
            label="Neues Passwort"
            type="password"
            :rules="[rules.required, rules.min(8)]"
            @update:model-value="$emit('dirty')"
          ></v-text-field>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="password.confirmPassword"
            label="Passwort bestätigen"
            type="password"
            :rules="[
              rules.required,
              () =>
                password.newPassword === password.confirmPassword ||
                'Passwörter stimmen nicht überein',
            ]"
            @update:model-value="$emit('dirty')"
          ></v-text-field>
          <ErrorChip :label="requestError"></ErrorChip>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="abort" @click="$emit('cancel')">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-4" @click="save">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formRules } from "../../../../helper/form-rules.helper";
import { PasswordChange } from "@/models/password-change.model";
import { useDisplay } from "vuetify/lib/framework.mjs";
import ErrorChip from "@/helper/ErrorChip.vue";

export default defineComponent({
  name: "PasswordDialog",
  components: {
    ErrorChip,
  },
  emits: ["cancel", "changePassword", "dirty"],
  props: {
    requestError: String,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      } as PasswordChange,
      passwordValid: false,
      rules: formRules,
      smAndDown,
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    save() {
      if (this.passwordValid) {
        this.$emit("changePassword", this.password);
      }
    },
  },
});
</script>
