// src/services/spinner.service.ts
import store from "@/store/store";
import { App } from "vue";
import { MutationType } from "../enums/vuex-types.enum";
import { SpinnerEvent } from "../enums/spinner-events.enum";

export class SpinnerService {
  private static app: App<Element> | null = null;

  static setHeaderSpinnerElement(size: number, gif: string) {
    store.commit(MutationType.setHeaderSpinnerElement, { size, gif });
  }

  static clearHeaderSpinnerElement() {
    store.commit(MutationType.clearHeaderSpinnerElement);
  }

  static showSpinner() {
    document.dispatchEvent(new CustomEvent(SpinnerEvent.show));
  }

  static removeSpinner() {
    document.dispatchEvent(new CustomEvent(SpinnerEvent.hide));
  }
}
