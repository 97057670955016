<!--src/components/config/software-integration/ConfigZorst.vue-->
<template>
  <v-container>
    <h2 class="mb-5 d-flex">
      <v-img class="mr-3" :src="zorstIcon" alt="Zorst Icon" max-width="36">
      </v-img
      >Chrome Browsererweiterung ZORST
    </h2>
    <v-col cols="12" md="8">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title class="d-flex justify-space-between"
          >Download
          <v-switch
            v-if="user.role === UserRole.UberAdmin"
            v-model="isDebugMode"
            label="ZORST debug mode"
            color="tertiary"
            @update:modelValue="toggleZorstDebugging()"
        /></v-card-title>
        <v-card-text>
          <h3>Schritt 1: Download der Datei</h3>
          Klicke auf diesen Button, um die Datei herunterzuladen:<br />
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            v-if="$store.state.company.softwareIntegration.zvooveOne"
            class="my-3"
            color="primary"
            href="https://zorst.dispositioner.de/download/zorst_full.zip"
            target="_blank"
          >
            ZORST {{ $store.getters.getEnv.VUE_APP_ZORST }} FULL
          </v-btn>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            v-if="!$store.state.company.softwareIntegration.zvooveOne"
            color="primary"
            href="https://zorst.dispositioner.de/download/zorst_ats.zip"
            target="_blank"
          >
            ZORST {{ $store.getters.getEnv.VUE_APP_ZORST }} ATS
          </v-btn>
          <br /><br />
          <h3>Schritt 2: Entpacken der Datei</h3>
          1. Gehe zu dem Ordner, in dem du <code>zorst.zip</code> gespeichert
          hast.<br />
          2. Rechtsklicke auf die Datei und wähle "Alle extrahieren..." oder
          eine ähnliche Option.<br />
          3. Entpacke die Datei in einen Zielordner.<br /><br />
          <h3>Schritt 3: Installation der Erweiterung in Chrome</h3>
          1. Öffne Google Chrome.<br />
          2. Klicke auf das Dreipunkt-Menü (oben rechts) und wähle
          "Erweiterungen" > "Erweiterungen verwalten".<br />
          3. Aktiviere den Entwicklermodus (Schalter oben rechts).<br />
          4.Klicke auf "Entpackte Erweiterung laden".<br />
          5. Wähle den Ordner aus, in den du die Dateien entpackt hast.<br />
          6. Klicke auf "Ordner auswählen".<br /><br /><br />
          <p>Fertig! Die Erweiterung ist jetzt installiert.</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { UserRole } from "../../../enums/user-role.enum";
import { MutationType } from "../../../enums/vuex-types.enum";

export default defineComponent({
  name: "ConfigZorst",
  computed: {
    ...mapGetters(["getEnv", "user"]),
    ...mapState({
      zorstDebugging: (state: any) => state.zorstDebugging,
    }),
  },
  data() {
    return {
      isDebugMode: this.zorstDebugging,
      UserRole,
      vStyle: this.$store.state.vStyle,
      zorstIcon: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/zorst_icon48.png`,
    };
  },
  methods: {
    toggleZorstDebugging() {
      if (this.isDebugMode !== this.zorstDebugging) {
        this.$store.commit(MutationType.toggleZorstDebugging);
      }
    },
  },
});
</script>
