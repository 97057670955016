<template>
  <div></div>
</template>

<script lang="ts">
import { HeartbeatService } from "@/services/api/heartbeat.service";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { EventBusEvent, EventBusService } from "../services/event-bus.service";

export default defineComponent({
  name: "HeartbeatService",
  data() {
    return {
      heartbeatService: new HeartbeatService(),
      userActivity: false,
      heartbeatInterval: null as ReturnType<typeof setInterval> | null,
      activityTimeout: null as ReturnType<typeof setTimeout> | null,
      router: useRouter(),
    };
  },
  mounted() {
    this.addEventListeners();
    this.startHeartbeat();
  },
  beforeUnmount() {
    this.removeEventListeners();
    this.stopHeartbeat();
  },
  methods: {
    addEventListeners() {
      window.addEventListener("mousemove", this.userActive);
      window.addEventListener("keydown", this.userActive);
      window.addEventListener("mousedown", this.userActive);
    },
    removeEventListeners() {
      window.removeEventListener("mousemove", this.userActive);
      window.removeEventListener("keydown", this.userActive);
      window.removeEventListener("mousedown", this.userActive);
    },
    userActive() {
      this.userActivity = true;
      if (this.activityTimeout) {
        clearTimeout(this.activityTimeout);
      }
      this.activityTimeout = setTimeout(() => {
        this.userActivity = false;
      }, 10000); // Reset activity flag if no activity for 10 seconds
    },
    startHeartbeat() {
      this.heartbeatInterval = setInterval(() => {
        if (localStorage.getItem("accessToken") && this.userActivity) {
          this.sendHeartbeat();
          this.userActivity = false; // Reset activity flag after sending heartbeat
        }
      }, 10000); // Send heartbeat every 10 seconds if user is active
    },
    stopHeartbeat() {
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }
      if (this.activityTimeout) {
        clearTimeout(this.activityTimeout);
        this.activityTimeout = null;
      }
    },
    async sendHeartbeat() {
      try {
        await this.heartbeatService
          .sendHeartbeat()
          .then((data) =>
            localStorage.setItem("accessToken", data.access_token)
          );
      } catch (error: any) {
        if (
          error?.response?.status === 401 &&
          localStorage.getItem("accessToken")
        ) {
          EventBusService.getInstance().publish(EventBusEvent.userLoggedOut);
        }
      }
    },
  },
});
</script>
