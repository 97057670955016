// src/services/timeline.service.ts
import { Customer, Participant, Timeline } from "@/models/timeline.model";
import { CustomerService } from "@/services/api/customer.service";
import store from "../store/store";
import { TimelineService } from "./api/timeline.service";
import { EmployeeService } from "./api/employee.service";
import { AllAtsAdapter } from "../adapter/all-ats.adapter";
import { AllErpAdapter } from "../adapter/all-erp.adapter";
import { TimelineEntryType } from "../enums/timeline-entry-types.enum";
import { LinkingStatus } from "../enums/dependency.enum";
import { PerfScore } from "../enums/perf-scoring.enum";
import { MutationType } from "../enums/vuex-types.enum";

interface TimeLineObject {
  note: string;
  entryType?: string;
  mandant?: string;
  customer?: Partial<Customer>;
  participant?: Partial<Participant>;
}

export class TimelineHelperService {
  private timelineService = new TimelineService();
  // we need that service to add every poop in the timeline
  async systemAutoDocu(
    entryType: TimelineEntryType | LinkingStatus | string,
    mandant: string,
    note: string,
    customer?: { name: string; contact: string; customerId: string },
    participant?: {
      name: string;
      uuid: string;
      employeeId: string;
      candidateId?: string;
    }
  ): Promise<void> {
    const entryTypeString =
      store.getters.getLinkingDescriptionOrEventNameFromType(entryType);
    if (entryTypeString) {
      this.timelineAutoDocu({
        note,
        entryType: entryTypeString,
        mandant,
        customer,
        participant,
      });
    }
  }

  async timelineAutoDocu({
    note,
    entryType = "SYSTEM",
    mandant = "",
    customer = { name: "", contact: "", customerId: "" },
    participant = { name: "", uuid: "", employeeId: "" },
  }: TimeLineObject): Promise<void> {
    const newEntryTimeline: Timeline = {
      user: "",
      userId: "",
      mandants: [mandant],
      entryDate: "",
      entryType,
      customer: {
        name: customer.name ?? "",
        contact: customer.contact ?? "",
        customerId: customer.customerId ?? "",
      },
      participant: {
        name: participant.name ?? "",
        uuid: participant.uuid ?? "",
        employeeId: participant.employeeId ?? "",
        candidateId: participant.candidateId ?? "",
      },
      note,
    };

    try {
      await this.timelineService.postTimeline(newEntryTimeline);
      // add PERF score:
      const linkingStatusOrTimelineEntryType =
        store.getters.getLinkingstatusOrTimelineEntryTypeFromEventName(
          entryType
        );
      const points = PerfScore[linkingStatusOrTimelineEntryType];
      const lastPhoneCallFrom = store.state.lastPhoneCallFrom;
      let hasPhoneCallBefore = false;
      if (
        newEntryTimeline.participant.candidateId === lastPhoneCallFrom?.id ||
        newEntryTimeline.customer.customerId === lastPhoneCallFrom?.id ||
        newEntryTimeline.participant.employeeId === lastPhoneCallFrom?.id
      ) {
        hasPhoneCallBefore = true;
      }

      store.commit(MutationType.addPerfRecentEntries, {
        timeline: newEntryTimeline,
        score: points,
        entryType: {
          entryType: linkingStatusOrTimelineEntryType,
          hasPhoneCallBefore: hasPhoneCallBefore,
        },
        description: note,
      });
    } catch (error) {
      console.error("Fehler beim Speichern des Timeline-Eintrags:", error);
    }

    //AutoDocu zvoove One: Company
    if (customer.customerId) {
      const customerService = new CustomerService();
      const response = await customerService.getById(customer.customerId);
      const customerNumber = response.customerNumber;
      await AllErpAdapter.createCustomerTimelineEntry(
        customerNumber,
        entryType,
        note,
        mandant
      );
    }
    //employee
    if (participant.employeeId) {
      const employeeService = new EmployeeService();
      const employee = await employeeService.getEmployee(
        participant.employeeId
      );
      const employeeNumber = employee.employeeNumber;
      await AllErpAdapter.createEmployeeTimelineEntry(
        employeeNumber,
        entryType,
        note,
        mandant
      );
    }

    //candidate
    if (
      participant.uuid &&
      store.state.company.softwareIntegration.atsAutoDocu
    ) {
      await AllAtsAdapter.addTimelineEntry(participant.uuid, entryType, note);
    }
  }
}
