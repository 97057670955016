import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "registration" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h2", null, [
      _createTextVNode(" Die Registrierung Ihrer Firma wurde erfolgreich abgeschlossen. Sie können sich jetzt "),
      _createElementVNode("a", { href: "/" }, "hier"),
      _createTextVNode(" einloggen. ")
    ], -1)
  ])))
}