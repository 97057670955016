import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-row-reverse mt-4 mb-4" }
const _hoisted_2 = { class: "text-overline mb-1" }
const _hoisted_3 = { class: "text-h6 mb-1" }
const _hoisted_4 = { class: "text-caption" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 1,
  class: "no-entries"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidateDeletionDialog = _resolveComponent("ValidateDeletionDialog")!
  const _component_MandantDialog = _resolveComponent("MandantDialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ValidateDeletionDialog, {
      show: _ctx.showValidateDeletionDialog,
      email: _ctx.deleteUserEmail,
      onValidate: _ctx.deleteMandantByUuid,
      "onUpdate:show": _ctx.updateValidateDeletionDialogVisibility
    }, null, 8, ["show", "email", "onValidate", "onUpdate:show"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showMandantDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showMandantDialog) = $event)),
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xl)',
      class: "xs12 md6"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MandantDialog, {
          editMode: _ctx.editMode,
          editMandantModel: _ctx.mandantModel,
          allMandants: _ctx.mandants,
          onCloseDialog: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateDialogVisibility(false)))
        }, null, 8, ["editMode", "editMandantModel", "allMandants"])
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_AdminMenu),
    (_ctx.layout !== _ctx.AppLayout.classic)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { fluid: true }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.getUserRole() <= _ctx.UserRole.Admin)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                variant: _ctx.vStyle.btn.variant || undefined,
                rounded: _ctx.vStyle.btn.rounded || undefined,
                border: _ctx.vStyle.btn.border || undefined,
                density: "comfortable",
                size: "large",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addMandant()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, { icon: "fa-solid fa-plus" })
                ]),
                _: 1
              }, 8, ["variant", "rounded", "border"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.mandants.length > 0)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    key: mandant.uuid,
                    name: mandant.name,
                    cols: "12",
                    md: "3"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.getUserRole() <= _ctx.UserRole.BranchManager)
                        ? (_openBlock(), _createBlock(_component_v_card, {
                            key: 0,
                            variant: _ctx.vStyle.card.variant || undefined,
                            rounded: _ctx.vStyle.card.rounded || undefined,
                            border: _ctx.vStyle.card.border || undefined
                          }, {
                            default: _withCtx(() => [
                              (_ctx.getUserRole() <= _ctx.UserRole.Admin)
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 0,
                                    variant: _ctx.vStyle.btn.variant || undefined,
                                    rounded: _ctx.vStyle.btn.rounded || undefined,
                                    border: _ctx.vStyle.btn.border || undefined,
                                    size: "large",
                                    style: {"position":"absolute","right":"0","padding":"0","min-width":"3rem"},
                                    onClick: ($event: any) => (_ctx.showDeleteMandantDialog(mandant))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, { icon: "fa-solid fa-trash-can" })
                                    ]),
                                    _: 2
                                  }, 1032, ["variant", "rounded", "border", "onClick"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_card_item, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createElementVNode("div", _hoisted_2, " Nr. " + _toDisplayString(mandant.branchNumber) + " | " + _toDisplayString(mandant.uuid), 1),
                                    _createElementVNode("div", _hoisted_3, _toDisplayString(mandant.name) + " (" + _toDisplayString(mandant.branchInitials) + ") ", 1),
                                    _createElementVNode("div", _hoisted_4, [
                                      _createElementVNode("div", {
                                        innerHTML: mandant.contact
                                      }, null, 8, _hoisted_5),
                                      _createElementVNode("div", null, [
                                        _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-brands fa-whatsapp" }, null, -1)),
                                        _createTextVNode(" " + _toDisplayString(mandant.whatsApp), 1)
                                      ]),
                                      (_ctx.$store.state.company.softwareIntegration.zvooveRecruit)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                            _createVNode(_component_v_divider, { class: "my-3" }),
                                            _createTextVNode(" " + _toDisplayString(mandant.zvoovename), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (_ctx.$store.state.company.softwareIntegration.zvooveRecruit)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(mandant.EmailEingangskontoId), 1))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_card_actions, null, {
                                default: _withCtx(() => [
                                  (_ctx.getUserRole() < 11)
                                    ? (_openBlock(), _createBlock(_component_v_btn, {
                                        key: 0,
                                        variant: _ctx.vStyle.btn.variant || undefined,
                                        rounded: _ctx.vStyle.btn.rounded || undefined,
                                        border: _ctx.vStyle.btn.border || undefined,
                                        color: "success",
                                        onClick: ($event: any) => (_ctx.openEditDialog(mandant)),
                                        class: "edit-btn"
                                      }, {
                                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                                          _createTextVNode(" Bearbeiten ")
                                        ])),
                                        _: 2
                                      }, 1032, ["variant", "rounded", "border", "onClick"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["variant", "rounded", "border"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["name"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.mandants.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Keine Einträge vorhanden "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}