import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "gameContainer" }
const _hoisted_2 = {
  ref: "gameCanvas",
  width: "1000",
  height: "400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512)
  ]))
}