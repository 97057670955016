import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { bottom: "" }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_card, _mergeProps({
        flat: "",
        class: ["price-selection", { selected: _ctx.subscription.selected }],
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
      }, props), {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa-solid fa-box" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.subscription.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(" Ø " + _toDisplayString(_ctx.monthly
              ? _ctx.subscription.price.monthly
              : _ctx.calculateMonthlyPrice(_ctx.subscription.price)) + "€ im Monat ", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createElementVNode("strong", null, _toDisplayString(_ctx.monthly ? _ctx.subscription.price.monthly : _ctx.subscription.price.yearly) + "€ " + _toDisplayString(_ctx.monthly ? "monatlich" : "jährlich"), 1),
              _cache[2] || (_cache[2] = _createTextVNode(" pro Nutzer "))
            ]),
            _: 1
          })
        ]),
        _: 2
      }, 1040, ["class"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", {
        innerHTML: _ctx.subscription.tooltip
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}