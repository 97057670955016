import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_PasswordForgottenDialog = _resolveComponent("PasswordForgottenDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, { class: "fill-height d-flex justify-center align-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          align: "center",
          justify: "center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "8",
              lg: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  color: "var(--color-item)",
                  class: "my-10",
                  border: "sm",
                  rounded: "lg",
                  elevation: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_item, {
                      align: "center",
                      justify: "center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_img, {
                          width: "100%",
                          "aspect-ratio": "1/1",
                          cover: "",
                          src: _ctx.loginLogo
                        }, null, 8, ["src"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_form, {
                      class: "pa-5",
                      onSubmit: _withModifiers(_ctx.authUser, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_divider),
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("Login")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          modelValue: _ctx.username,
                          "onUpdate:modelValue": [
                            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                            _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetError()))
                          ],
                          label: "Email",
                          type: "email",
                          required: "",
                          error: _ctx.loginError
                        }, null, 8, ["modelValue", "error"]),
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          modelValue: _ctx.password,
                          "onUpdate:modelValue": [
                            _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                            _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetError()))
                          ],
                          label: "Passwort",
                          type: "password",
                          required: "",
                          error: _ctx.loginError,
                          "error-messages": 
                _ctx.loginError ? 'Ungültiger Benutzername oder Passwort' : ''
              
                        }, null, 8, ["modelValue", "error", "error-messages"]),
                        _createVNode(_component_v_row, { class: "d-flex justify-space-between" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  class: "password-forgot",
                                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showPasswordForgottenModal && _ctx.showPasswordForgottenModal(...args)))
                                }, "Passwort vergessen?")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { class: "text-right" }, {
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  href: _ctx.Routes.registration,
                                  class: "register"
                                }, "Registrieren", 8, _hoisted_1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          loading: _ctx.isLoggingIn,
                          type: "submit",
                          color: "primary",
                          block: "",
                          class: "mt-4 mb-6"
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode(" Einloggen ")
                          ])),
                          _: 1
                        }, 8, ["loading"])
                      ]),
                      _: 1
                    }, 8, ["onSubmit"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.passwordForgottenModal)
      ? (_openBlock(), _createBlock(_component_PasswordForgottenDialog, {
          key: 0,
          "model-value": "",
          requestError: _ctx.passwordRequestError,
          onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.passwordForgottenModal = false)),
          onSent: _cache[6] || (_cache[6] = ($event: any) => (_ctx.passwordForgottenModal = false)),
          "onClick:outside": _ctx.handlePasswordBackgroundClick
        }, null, 8, ["requestError", "onClick:outside"]))
      : _createCommentVNode("", true)
  ], 64))
}