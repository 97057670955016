<template>
  <div>
    <v-badge
      :color="zorstStatus().color"
      :content="zorstStatus().content"
      overlap
      offset-x="3"
      offset-y="9"
      class="mr-5"
    >
      <v-img
        class="mr-2 zorst-icon"
        :src="zorstIcon"
        alt="Zorst Icon"
        width="24"
        @click="openSoftwareIntegration()"
      ></v-img>
    </v-badge>
    <v-tooltip activator="parent" location="end">
      {{ zorstStatus().tooltip }}
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ToastService from "@/services/toast.service";
import { Routes } from "../../enums/routes.enum";

export default defineComponent({
  name: "ZorstListener",
  computed: {
    ...mapGetters(["getEnv"]),
  },
  data() {
    return {
      zorstIcon: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/zorst_icon16.png`,
      zorstWarn: false,
      checkInterval: null as ReturnType<typeof setInterval> | null,
      retryCount: 0,
      maxRetries: 3,
    };
  },
  mounted() {
    window.addEventListener("message", this.handleZorstMessage);
    if (!this.$store.state.zorstAlive) {
      this.startCheckingZorst();
    }
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleZorstMessage);
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
  methods: {
    handleZorstMessage(event: any) {
      if (event.data && event.data.type === "ZORST_LOAD") {
        this.$store.state.zorstAlive = true;

        if (event.data.version) {
          const neededZorstVersion = this.$store.getters.getEnv.VUE_APP_ZORST;
          const installedZorstVersion = event.data.version;

          if (neededZorstVersion !== installedZorstVersion) {
            this.zorstWarn = true;
          } else {
            this.zorstWarn = false;
          }
        }

        this.retryCount = 0;

        if (this.checkInterval) {
          clearInterval(this.checkInterval);
          this.checkInterval = null;
        }
      }
    },
    openSoftwareIntegration() {
      this.$router.push(Routes.configApi);
    },
    startCheckingZorst() {
      if (this.$store.state.zorstAlive) {
        return;
      }

      this.checkInterval = setInterval(() => {
        if (!this.$store.state.zorstAlive) {
          this.retryCount++;
          window.postMessage(
            { type: "ZORST_CHECK", message: "Checking ZORST" },
            "*"
          );

          if (this.retryCount >= this.maxRetries && this.checkInterval) {
            ToastService.showReminder("Kein ZORST im Browser aktiviert");
            clearInterval(this.checkInterval);
            this.checkInterval = null;
          }
        }
      }, 500);
    },
    zorstStatus() {
      if (this.zorstWarn) {
        return {
          color: "warning",
          content: "!",
          tooltip: "Veraltete ZORST-Version installiert",
        };
      } else if (this.$store.state.zorstAlive) {
        return {
          color: "success",
          content: "✔",
          tooltip: "Verbindung zu ZORST hergestellt",
        };
      } else {
        return {
          color: "abort",
          content: "✘",
          tooltip: "Keine Verbindung zu ZORST",
        };
      }
    },
  },
});
</script>

<style scoped>
.zorst-icon {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.zorst-icon:hover {
  transform: scale(1.1);
}
</style>
