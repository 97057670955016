<!--src/components/dispostion/WhatsAppClient.vue-->
<template>
  <div>
    <v-icon class="whatsapp-icon" @click="showDialog = true"
      >fa-brands fa-whatsapp</v-icon
    >
    <v-tooltip activator="parent" location="bottom"
      >WhatsApp versenden</v-tooltip
    >
    <v-dialog
      v-model="showDialog"
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'"
    >
      <v-form ref="form" v-autoscroll="'.v-card'">
        <v-card>
          <v-card-title>
            <v-icon class="mr-1">fa-brands fa-whatsapp</v-icon>WhatsApp
            senden</v-card-title
          >
          <v-card-text class="pb-0">
            <v-container fluid style="padding: 0">
              <v-row dense>
                <v-col cols="6">
                  <v-combobox
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="wabNumber"
                    :items="reversedPhoneNumbers"
                    label="Wähle eine Empfänger-Nummer"
                    item-text="label"
                    item-value="value"
                    return-object
                    class="w-100"
                    :rules="[requiredRule]"
                  ></v-combobox>
                </v-col>
                <v-col cols="6" v-if="isWabIntegration">
                  <v-btn @click="toggleTemplates()" height="56" class="w-100">
                    {{ templateToggleBtnTitle }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div class="d-flex">
              <v-select
                v-if="isWabIntegration"
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="selectedMandant"
                :items="getLoggedInMandantsItems()"
                item-title="description"
                item-value="id"
                label="Mandant Absendernummer"
                density="compact"
                class="mb-2"
                :rules="[requiredRule]"
              ></v-select>
            </div>
            <div
              class="font-weight-bold mb-6"
              style="color: var(--color-primary)"
            >
              <v-icon class="fa-regular fa-circle-user"></v-icon>
              {{ receiverName }}
            </div>
            <div
              v-collapse="!showTemplates"
              :style="{ height: !showTemplates ? '0' : 'auto' }"
              class="collapsible-box"
            >
              <WhatsAppMessenger
                ref="whatsAppMessengerComponent"
                @checkTimeline="checkChatHistoryAndAddMissingTimelineEntries"
                @closeDialog="(msg) => close(msg)"
                @chatsEmpty="(value) => setShowTemplates(value)"
                :whatsAppConfig="isWabIntegration"
                :wabNumber="wabNumber"
                :message="fullMessage"
                :selectedMandant="selectedMandant"
                :sendMessageEvent="sendMessageEvent"
                :showChatMessages="isWabIntegration"
                :username="receiverName"
              ></WhatsAppMessenger>
            </div>
            <WabTemplates
              v-if="isWabIntegration"
              v-collapse="showTemplates"
              @closeDialog="(msg) => close(msg)"
              message="fullMessage"
              :receiverName="receiverName"
              :sendTemplateEvent="sendTemplateEvent"
              :wabNumber="wabNumber"
              :wabSenderNumber="wabSenderNumber"
              :selectedMandant="selectedMandant"
              :style="{ height: !showTemplates ? '0' : 'auto' }"
              class="collapsible-box"
            ></WabTemplates>
            <v-card-actions class="ma-0 pa-0">
              <v-btn color="abort" @click="closeDialog()">Abbrechen</v-btn>
              <v-spacer></v-spacer>
              <v-btn icon @click="showAiModelMenu($event)">
                <v-icon>fa-solid fa-rocket</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >Nachricht mit AI umformulieren</v-tooltip
                >
                <v-menu
                  v-model="menuAiModelVisible"
                  class="ai-menu"
                  :style="{
                    left: `${menuPosition.x}px`,
                    top: `${menuPosition.y}px`,
                  }"
                  ref="aiMenu"
                >
                  <v-list>
                    <v-list-item
                      v-for="aiOption in aiOptions"
                      :key="aiOption.value"
                      @click="handleAiOptions(aiOption)"
                    >
                      <v-list-item-title>{{
                        aiOption.label
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn>
              <v-btn
                class="longpress"
                icon
                @click="showLanguageMenu($event)"
                @contextmenu.prevent="showAiModelMenu($event, true)"
                @longpress="showAiModelMenu($event, true)"
              >
                <v-icon>fa-solid fa-comments</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >mit AI in eine andere Sprache übersetzen</v-tooltip
                >
              </v-btn>
              <!--language selection for Ai Translator-->
              <v-menu
                v-model="menuTranslationVisible"
                class="ai-menu"
                :style="{
                  left: `${menuPosition.x}px`,
                  top: `${menuPosition.y}px`,
                }"
                ref="aiMenu"
              >
                <v-list>
                  <v-list-item
                    v-for="language in languages"
                    :key="language"
                    @click="selectLanguageAndTranslate(language)"
                  >
                    <v-list-item-title>{{ language }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn @click="send" color="success"
                >Senden<v-icon class="ml-1"
                  >fa-regular fa-paper-plane</v-icon
                ></v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AiService } from "@/services/ai.service";
import { Mandant } from "@/models/mandant.model";
import { TimelineHelperService } from "@/services/timeline-helper.service";
import { useDisplay } from "vuetify";
import ToastService from "@/services/toast.service";
import { CandidateList } from "@/models/candidate-list.model";
import { Employee } from "@/models/employee.model";
import DialogService from "@/services/dialog.service";
import { SpinnerService } from "@/services/spinner.service";
import moment from "moment";
import WhatsAppMessenger from "@/components/whatsapp/WhatsAppMessenger.vue";
import { TimelineService } from "@/services/api/timeline.service";
import { Timeline } from "@/models/timeline.model";
import { AiMessageType, AiOptions } from "@/enums/ai-options.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import { MessageContent } from "@/enums/empty-message.enum";
import { LinkingEvent } from "@/models/linking.model";
import { TemplateToggleBtnTitle } from "../../../../enums/whatsapp/template-button.enum";
import WabTemplates from "./WabTemplates.vue";

export default defineComponent({
  name: "WhatsAppClient",
  components: {
    WabTemplates,
    WhatsAppMessenger,
  },
  props: {
    candidate: {
      type: Object as () => CandidateList | null,
      default: null,
    },
    receiverName: {
      type: String,
      default: "",
    },
    employee: {
      type: Object as () => Employee | null,
      default: null,
    },
    phoneNumbers: {
      type: Array,
      required: true,
    } as any,
    salutation: {
      type: String,
      default: "",
    },
    linkingStatuses: {
      type: Array as PropType<LinkingEvent[]>,
      default: () => [],
    },
    message: {
      type: String,
      default: "",
    },
    signature: {
      type: String,
      default: "",
    },
    AiMessageType: {
      type: String as PropType<AiMessageType>,
      default: AiMessageType.whatsAppCandidate,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    const isWabIntegration =
      this.$store.state.company.softwareIntegration.whatsApp;

    return {
      awaitSelection: false,
      form: null as any,
      fullMessage: "",
      isWabIntegration,
      menuAiModelVisible: false,
      menuPosition: { x: 0, y: 0 },
      menuTranslationVisible: false,
      selectedAiModel: "",
      selectedMandant: "",
      sendMessageEvent: false,
      sendTemplateEvent: false,
      showDialog: false,
      showMessenger: isWabIntegration,
      showTemplates: false,
      smAndDown,
      targetLanguage: "Englisch",
      templateToggleBtnTitle: "Unterhaltung starten",
      timelineService: new TimelineService(),
      vStyle: this.$store.state.vStyle,
      wabNumber: "",
      wabSenderNumber: "",
    };
  },
  computed: {
    languages() {
      return this.$store.state.company.aiData.languages;
    },
    reversedPhoneNumbers() {
      return this.phoneNumbers.map((phone: any) => phone.value).reverse();
    },
    aiOptions() {
      return getEnumOptions(AiOptions);
    },
  },
  mounted() {
    const items = this.getLoggedInMandantsItems();
    if (items.length > 0) {
      this.selectedMandant = items[0].id;
    }
  },
  watch: {
    message() {
      this.updateFullMessage();
    },
    salutation() {
      this.updateFullMessage();
    },
    signature() {
      this.updateFullMessage();
    },
  },
  created() {
    this.updateFullMessage();
  },
  methods: {
    async checkChatHistoryAndAddMissingTimelineEntries(chathistory: any) {
      const timeline = await this.getTimeline();

      const messageExistsInTimeline = (
        message: any,
        timeline: any[]
      ): boolean => {
        const phoneNumber = message.fromMe
          ? formatJidToPhoneNumber(message.to)
          : formatJidToPhoneNumber(message.from);
        const contentSnippet = message.body.substring(0, 20);
        return (
          Array.isArray(timeline) &&
          timeline.some(
            (entry: any) =>
              entry.note &&
              entry.note.includes(phoneNumber) &&
              entry.note.includes(contentSnippet)
          )
        );
      };

      const formatTimestamp = (timestamp: number): string => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
      };

      const formatJidToPhoneNumber = (jid: string): string => {
        return `+${jid.split("@")[0]}`;
      };

      let combinedNote = "";
      let newMessagesAdded = false;
      let recipientPhoneNumber = "";

      chathistory.forEach((message: any) => {
        const formattedTimestamp = formatTimestamp(message.t);
        const phoneNumber = formatJidToPhoneNumber(
          message.fromMe ? message.to : message.from
        );
        if (!recipientPhoneNumber) {
          recipientPhoneNumber = phoneNumber;
        }
        const directionArrow = message.fromMe ? "➜" : "⬅";
        const messageText = `${directionArrow} ${formattedTimestamp} ${message.body}`;

        if (
          !messageExistsInTimeline(message, timeline) &&
          !combinedNote.includes(messageText)
        ) {
          combinedNote += `${messageText}\n\n`;
          newMessagesAdded = true;
        }
      });

      if (newMessagesAdded) {
        const finalNote = `automatische Dokumentation Chatverlauf WhatsApp (${recipientPhoneNumber}):\n${combinedNote}`;
        await this.submitEntry(finalNote);
      }
    },
    async checkGDPR(reminderType: string) {
      const reminderDSGVO = this.$store.state.reminderDSGVO[reminderType];

      const lastReminderTime = moment(reminderDSGVO.lastReminder);
      const currentTime = moment();
      const intervalTime = lastReminderTime
        .add(reminderDSGVO.intervalHours, "hours")
        .add(reminderDSGVO.intervalDays, "days");

      const hasToCheck =
        !reminderDSGVO.lastReminder || currentTime.isAfter(intervalTime);

      if (hasToCheck) {
        const confirmed = await DialogService.confirm(
          reminderDSGVO.reminderText,
          "Abbrechen",
          "mit ChatGPT verarbeiten"
        );

        if (!confirmed) {
          return Promise.reject("DSGVO check not confirmed");
        }

        this.$store.state.reminderDSGVO[reminderType].lastReminder =
          moment().format("YYYY-MM-DD HH:mm:ss");
      }

      return Promise.resolve();
    },
    cleanMessage() {
      const salutation = this.salutation;
      const signature = this.signature;

      let message = this.fullMessage;

      const escapedSalutation = salutation.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const escapedSignature = signature.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const salutationRegex = new RegExp(
        `(<[^>]*>)*\\s*${escapedSalutation}\\s*(<[^>]*>)*`,
        "gi"
      );
      const signatureRegex = new RegExp(
        `(<[^>]*>)*\\s*${escapedSignature
          .split("\n")
          .map((line: any) => line.trim())
          .join("\\s*")}\\s*(<[^>]*>)*`,
        "gi"
      );

      // remove all occurrences of salutation, lastSalutation and signature including surrounding HTML tags and spaces
      message = message
        .replace(salutationRegex, "")
        .replace(signatureRegex, "")
        .trim();

      return message;
    },
    close(message?: string) {
      if (this.isWabIntegration) {
        this.resetMessageBox(message);
      } else {
        this.closeDialog(message);
      }
    },
    closeDialog(message?: string) {
      this.sendMessageEvent = false;
      this.sendTemplateEvent = false;
      this.showDialog = false;
      this.$emit("collapseParentItem");
      if (message) {
        this.submitEntry(
          `➜ WhatsApp gesendet an ${this.wabNumber}:\n${message}`
        );
      }
    },
    getLoggedInMandantsItems() {
      const result: any = [];
      const mandants = this.$store.state.company.mandants;
      const loggedInMandants =
        this.$store.state.company.loggedInUser.config.loggedInMandants;
      const filteredMandants = mandants.filter((mandant: Mandant) =>
        loggedInMandants.includes(mandant.uuid)
      );
      filteredMandants.forEach((mandant: Mandant) => {
        result.push({
          description: mandant.name,
          id: mandant.uuid,
        });
      });
      return result;
    },
    getStatusWithCompany(linkingStatuses: LinkingEvent[]): string {
      const companyEvents = linkingStatuses.filter(
        (event) => !event.description
      );

      let statusWithCompany = "aktueller Status des Kandidaten mit uns:\n";

      if (companyEvents.length > 0) {
        const lastCompanyEvent = companyEvents[companyEvents.length - 1];
        const eventDates = lastCompanyEvent.eventDate.join(", ");
        statusWithCompany += `${lastCompanyEvent.eventType} am ${eventDates}\n`;
      } else {
        statusWithCompany += "Es gibt noch keine Events mit uns.\n";
      }

      return statusWithCompany;
    },
    getStatusWithCustomers(linkingStatuses: LinkingEvent[]): string {
      const customerEvents = linkingStatuses.filter(
        (event) => event.description
      );

      let statusWithCustomers = "aktueller Status der Bewerbungsverfahren:\n";

      customerEvents.forEach((event) => {
        const eventDates = event.eventDate.join(", ");
        statusWithCustomers += `Kunde: ${event.description} - Letztes Event: ${event.eventType} am ${eventDates}\n`;
      });

      return statusWithCustomers;
    },
    async getTimeline() {
      let timeline = [] as Timeline[];
      if (this.candidate && this.candidate.candidateUuid) {
        const uuid = this.candidate?.candidateUuid as string;
        timeline = await this.timelineService.getTimelineByUuid(uuid);
      } else if (this.employee && this.employee._id) {
        const id = this.employee?._id as string;
        timeline = await this.timelineService.getTimelineByCustomerId(id);
      }
      return timeline;
    },
    handleAiOptions(aiModel: { value: string; label: string }) {
      if (this.awaitSelection) {
        ToastService.showSuccess(
          `"${aiModel.label}" wurde als AI für die Übersetzung ausgewählt`
        );
        this.selectedAiModel = aiModel.label;
        this.awaitSelection = false;
      } else {
        this.handleGenerateText(aiModel.label);
      }
    },
    async handleGenerateText(aiModel?: string) {
      let additionalPrompt = "";
      if (
        this.candidate &&
        this.linkingStatuses.length > 0 &&
        this.message === MessageContent.empty
      ) {
        const today = `Heutiges Datum: ${moment().format("DD-MM-YYYY HH:mm")}`;
        const statusWithCompany = this.getStatusWithCompany(
          this.linkingStatuses
        );
        const statusWithCustomers = this.getStatusWithCustomers(
          this.linkingStatuses
        );
        additionalPrompt = `Generiere Deine Nachricht basierend auf diesen Informationen:\n${today}\n${statusWithCompany}\n${statusWithCustomers}`;
      }
      if (aiModel === AiOptions.chatGPT) {
        try {
          await this.checkGDPR("whatsApp");
          SpinnerService.showSpinner();
          const aiService = new AiService();
          const text = await aiService.generateMessage(
            `${this.salutation}\n${this.cleanMessage()}`,
            additionalPrompt,
            this.AiMessageType,
            false
          );
          this.fullMessage = text;
          SpinnerService.removeSpinner();
        } catch (error) {
          if (error === "DSGVO check not confirmed") {
            return this.fullMessage;
          } else {
            ToastService.showError("Fehler bei der Textgenerierung");
            return this.fullMessage;
          }
        } finally {
          SpinnerService.removeSpinner();
        }
      } else if (aiModel === AiOptions.recurion) {
        SpinnerService.showSpinner();
        try {
          const aiService = new AiService();
          this.fullMessage = await aiService.generateMessage(
            this.fullMessage,
            additionalPrompt,
            this.AiMessageType,
            true
          );
          return this.fullMessage;
        } catch (error) {
          ToastService.showError("Fehler bei der Textgenerierung!");
          return this.fullMessage;
        } finally {
          SpinnerService.removeSpinner();
        }
      }
    },
    async handleTranslateText(targetLanguage: string) {
      if (this.selectedAiModel === AiOptions.chatGPT) {
        try {
          await this.checkGDPR("translation");
          SpinnerService.showSpinner();
          const originalText = `${this.salutation} ${this.cleanMessage()}`;
          const aiService = new AiService();
          const response = await aiService.translateMessage(
            originalText,
            targetLanguage,
            false
          );
          const translatedText = response.text;
          this.fullMessage = `${translatedText}\n${this.signature}\n\n--------------------\nOriginal:\n${originalText}\n${this.signature}`;
        } catch (error) {
          if (error === "DSGVO check not confirmed") {
            return this.fullMessage;
          } else {
            ToastService.showError("Fehler bei der Übersetzung!");
            return this.fullMessage;
          }
        } finally {
          SpinnerService.removeSpinner();
        }
      } else {
        SpinnerService.showSpinner();
        try {
          const originalText = this.fullMessage;
          const aiService = new AiService();
          const response = await aiService.translateMessage(
            this.fullMessage,
            targetLanguage,
            true
          );
          const translatedText = response.text;
          this.fullMessage = `${translatedText}\n\n--------------------\nOriginal:\n${originalText}`;
          return this.fullMessage;
        } catch (error) {
          ToastService.showError("Fehler bei der Übersetzung!");
          return this.fullMessage;
        } finally {
          SpinnerService.removeSpinner();
        }
      }
    },
    requiredRule(value: any) {
      return !!value || "Dieses Feld ist erforderlich";
    },
    resetMessageBox(message?: string) {
      this.sendMessageEvent = false;
      this.sendTemplateEvent = false;
      this.fullMessage = "";
      if (message) {
        this.submitEntry(
          `➜ WhatsApp gesendet an ${this.wabNumber}:\n${message}`
        );
      }
    },
    showLanguageMenu(event: MouseEvent) {
      this.menuTranslationVisible = true;
      this.menuPosition.x = event.clientX;
      this.menuPosition.y = 100;
    },
    showAiModelMenu(event: MouseEvent, awaitSelection?: boolean) {
      if (awaitSelection) this.awaitSelection = true;
      this.menuAiModelVisible = true;
      const menuHeight = 150;
      const windowHeight = window.innerHeight;

      let menuY = event.clientY;

      if (menuY + menuHeight > windowHeight) {
        menuY = windowHeight - menuHeight;
      }

      this.menuPosition.x = event.clientX;
      this.menuPosition.y = menuY;
    },
    toggleTemplates() {
      switch (this.templateToggleBtnTitle) {
        case TemplateToggleBtnTitle.start:
          this.templateToggleBtnTitle = TemplateToggleBtnTitle.send;
          this.showMessenger = false;
          break;
        case TemplateToggleBtnTitle.send:
          this.templateToggleBtnTitle = TemplateToggleBtnTitle.start;
          this.showMessenger = true;
      }
      this.showTemplates = !this.showTemplates;
    },
    async send() {
      const isValid = await this.validateForm();
      if (isValid.valid) {
        if (this.showTemplates) {
          this.sendTemplate();
        } else {
          this.sendMessage();
        }
      }
    },
    async sendMessage() {
      this.sendMessageEvent = true;
    },
    sendTemplate() {
      this.sendTemplateEvent = true;
    },
    async selectLanguageAndTranslate(language: string) {
      this.targetLanguage = language;
      await this.handleTranslateText(language);
      this.menuTranslationVisible = false;
    },
    setShowTemplates(value: boolean) {
      this.showTemplates = value;
    },
    submitEntry(noteText: string) {
      const mandants = this.$store.state.company.mandants;
      let timelineData = {
        note: noteText,
        entryType: "",
        mandant: "",
        customer: undefined as
          | undefined
          | { name: string; contact: string; customerId: string | undefined },
        participant: undefined as
          | undefined
          | { name: string; uuid: string; employeeId: string },
      };

      if (this.employee) {
        const participantName = `${this.employee.firstName} ${this.employee.lastName}`;
        const matchedMandant = mandants.find(
          (m: Mandant) => m.branchNumber === this.employee?.branchOfficeId
        );
        const mandantUuid = matchedMandant
          ? matchedMandant.uuid
          : "StandardwertWennNichtGefunden";

        timelineData.entryType =
          this.$store.state.company.timelineEntryTypes.whatsAppEmployee.description;
        timelineData.mandant = mandantUuid;
        timelineData.participant = {
          name: participantName,
          uuid: "", // TODO: Add when added in Employee model (employee was a candidate ...)
          employeeId: this.employee._id ?? "",
        };
      } else if (this.candidate) {
        const mandantUuid = this.candidate?.mandants[0]
          ? this.candidate?.mandants[0]
          : "StandardwertWennNichtGefunden";

        timelineData = {
          note: noteText,
          entryType:
            this.$store.state.company.timelineEntryTypes.whatsAppCandidate
              .description,
          mandant: mandantUuid,
          customer: undefined, // No customer data specific for WhatsApp message scenario
          participant: this.candidate
            ? {
                name: this.receiverName,
                uuid: this.candidate.candidateUuid,
                employeeId: "", // Placeholder if needed
              }
            : undefined,
        };
      }
      const timelineHelperService = new TimelineHelperService();
      timelineHelperService
        .timelineAutoDocu(timelineData)
        .then(() => {
          ToastService.show(
            "WhatsApp-Nachricht Eintrag erfolgreich hinzugefügt."
          );
        })
        .catch((error: any) => {
          console.error(
            "Fehler beim Hinzufügen des WhatsApp Timeline-Eintrags:",
            error
          );
          ToastService.showError(
            "Fehler beim Dokumentieren der WhatsApp-Nachricht."
          );
        });
    },
    updateFullMessage() {
      this.fullMessage = `${this.salutation}\n${this.message}\n\n${this.signature}`;
    },
    async validateForm() {
      return this.$refs.form && (await (this.$refs.form as any).validate());
    },
  },
});
</script>

<style scoped>
.whatsapp-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.whatsapp-icon:hover {
  color: var(--color-tertiary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.ai-menu {
  position: fixed;
  z-index: 200;
}
.wabTemplate {
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid black;
  cursor: pointer;
  padding: 1rem;
}
.templateImg {
  width: 100%;
}
</style>
