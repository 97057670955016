import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatMessages = _resolveComponent("ChatMessages")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    class: "mt-2",
    ref: "form",
    onSubmit: _withModifiers(_ctx.sendMessage, ["prevent"])
  }, {
    default: _withCtx(() => [
      (_ctx.showChatMessages)
        ? (_openBlock(), _createBlock(_component_ChatMessages, {
            key: 0,
            messages: _ctx.chatMessages,
            wabNumber: _ctx.wabNumber
          }, null, 8, ["messages", "wabNumber"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_textarea, {
        variant: _ctx.vStyle.input.variant || undefined,
        rounded: _ctx.vStyle.input.rounded || undefined,
        "base-color": _ctx.vStyle.input.baseColor || undefined,
        color: _ctx.vStyle.input.color || undefined,
        modelValue: _ctx.localMessage,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localMessage) = $event)),
        label: "Nachricht",
        density: "compact",
        rows: _ctx.softwareIntegration.whatsApp ? 6 : 8,
        rules: [_ctx.requiredRule]
      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rows", "rules"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}