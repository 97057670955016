import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "status-icon fa-solid fa-circle-pause",
  "data-tid": "job-poster-status-icon"
}
const _hoisted_2 = {
  key: 1,
  class: "status-icon fa-solid fa-hand",
  "data-tid": "job-poster-status-icon"
}
const _hoisted_3 = {
  key: 2,
  class: "status-icon fa-solid fa-hourglass-half",
  "data-tid": "job-poster-status-icon"
}
const _hoisted_4 = {
  key: 3,
  class: "status-icon fa-solid fa-square-check",
  "data-tid": "job-poster-status-icon"
}
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }
const _hoisted_7 = { class: "progress-bar-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (!_ctx.isOffline)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        class: _normalizeClass(["job-poster-container ma-0 pa-0", { 'is-hidden': !_ctx.isClosed }])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_sheet, {
            onClick: _ctx.toggleDrawer,
            color: "primary-darken-1",
            class: "pt-3 pl-3 job-poster-open-button"
          }, {
            default: _withCtx(() => [
              (_ctx.jobPosterState.status === _ctx.JobposterState.paused)
                ? (_openBlock(), _createElementBlock("i", _hoisted_1))
                : (_ctx.jobPosterState.status === _ctx.JobposterState.break)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                  : (_ctx.jobPosterState.status === _ctx.JobposterState.posting)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                    : (_ctx.jobPosterState.status === _ctx.JobposterState.completed)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                      : _createCommentVNode("", true),
              (_ctx.isClosed)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _cache[2] || (_cache[2] = [
                    _createElementVNode("i", { class: "open-close-arrow fa-solid fa-arrow-left" }, null, -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "open-close-arrow fa-solid fa-arrow-right" }, null, -1)
                  ])))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_card, {
            color: "primary-darken-1",
            class: "ma-0 pr-4 rounded-s-lg",
            elevation: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "mb-2" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Jobposter ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_checkbox, {
                    modelValue: _ctx.publishOnHomepage,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.publishOnHomepage) = $event)),
                    label: "auf allen eigenen Stellenbörsen veröffentlichen",
                    class: "text-caption",
                    density: "compact"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_checkbox, {
                    modelValue: _ctx.publishOnBfA,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.publishOnBfA) = $event)),
                    label: "auf BA veröffentlichen",
                    class: "text-caption",
                    density: "compact"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_divider),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", {
                      class: "progress-bar",
                      style: _normalizeStyle({ width: _ctx.progressBarWidth + '%' }),
                      "data-tid": "job-poster-progress-bar"
                    }, null, 4)
                  ]),
                  _createVNode(_component_v_divider, { class: "mb-2" }),
                  (
            _ctx.jobPosterState.status === _ctx.JobposterState.posting ||
            _ctx.jobPosterState.status === _ctx.JobposterState.paused
          )
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        variant: _ctx.vStyle.btn.variant || undefined,
                        rounded: _ctx.vStyle.btn.rounded || undefined,
                        border: _ctx.vStyle.btn.border || undefined,
                        onClick: _ctx.pausePosting,
                        color: "success",
                        style: {"color":"var(--color-primary)"},
                        class: "ma-2"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.jobPosterState.status === _ctx.JobposterState.posting
              ? "Pause"
              : "Weiter"), 1)
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border", "onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.jobPosterState.status === _ctx.JobposterState.posting)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        variant: _ctx.vStyle.btn.variant || undefined,
                        rounded: _ctx.vStyle.btn.rounded || undefined,
                        border: _ctx.vStyle.btn.border || undefined,
                        onClick: _ctx.cancelPosting,
                        color: "abort",
                        class: "ma-2"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Abbrechen ")
                        ])),
                        _: 1
                      }, 8, ["variant", "rounded", "border", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  (
            _ctx.jobPosterState.status === _ctx.JobposterState.break ||
            _ctx.jobPosterState.status === _ctx.JobposterState.completed
          )
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        variant: _ctx.vStyle.btn.variant || undefined,
                        rounded: _ctx.vStyle.btn.rounded || undefined,
                        border: _ctx.vStyle.btn.border || undefined,
                        onClick: _ctx.turnOffJobPoster,
                        color: "white",
                        style: {"color":"var(--color-primary)"},
                        "data-tid": "close-jobposter",
                        class: "ma-2"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" ausschalten ")
                        ])),
                        _: 1
                      }, 8, ["variant", "rounded", "border", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}