export enum AppLayout {
  classic = "classic",
  dispositioner = "dispositioner",
  pdmaster = "pdmaster",
}

export enum AppTitle {
  classic = "Disposition:er Classic",
  dispositioner = "Disposition:er",
  pdmaster = "PD Master",
}

export enum AppPageAndMenuTitle {
  ai = "Recurion.ai Einstellungen",
  backendLogs = "Backend Logs",
  company = "Firmenkunden",
  dataCleaning = "Initialisierung & Datenbereinigung",
  dashboard = "Dashboard",
  logout = "Ausloggen",
  mandants = "Niederlassungen",
  softwareIntegration = "Software & Schnittstellen",
  version = "Version",
  user = "Benutzer",
}

export enum HeaderSpinner {
  pdLoading = "pdhub-loading.gif",
  recurion = "recurion.gif",
}

export enum HeaderSpinnerSize {
  small,
  normal,
  classic,
}

export enum DispatcherBoardColumnHeight {
  headerHeight = 280,
  standard = 500,
  iLoaderSubtract = 20,
  iLoaderDistance = 200,
}
