import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showReminderIcon)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 0,
          icon: "",
          color: "white",
          density: "compact",
          variant: "tonal",
          class: "reminder-icon-btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPrompt = true)),
          onMouseenter: _ctx.showTooltip,
          onMouseleave: _ctx.hideTooltip
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              class: "reminder-icon",
              size: "xs"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("fa-solid fa-bell")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onMouseenter", "onMouseleave"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showPrompt,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showPrompt) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.promptTitle) + " ", 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  class: "longpress",
                  icon: "",
                  variant: "text",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSnooze(2))),
                  onContextmenu: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.openMenu($event)), ["prevent"])),
                  onLongpress: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openMenu($event)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { color: "primary" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("fa-solid fa-bell-slash")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "bottom"
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Still für 2min / Rechtsklick = Snooze-Zeit wählen")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_menu, {
              modelValue: _ctx.selectSnooze,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectSnooze) = $event)),
              style: _normalizeStyle({ position: 'absolute', top: _ctx.menuY + 'px', left: _ctx.menuX + 'px' }),
              absolute: "",
              "offset-y": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.snoozeOptions, (item, index) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: index,
                        onClick: ($event: any) => (_ctx.setSnooze(item.value))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "style"]),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.promptText), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, { class: "d-flex justify-space-between" }, {
              default: _withCtx(() => [
                (_ctx.buttonSuccess)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      color: "success",
                      onClick: _ctx.clickSuccess
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.buttonSuccess), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.buttonResult)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      color: "primary",
                      onClick: _ctx.clickResult
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.buttonResult), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.buttonFailure)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 2,
                      color: "abort",
                      onClick: _ctx.clickFailure
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.buttonFailure), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ], 64))
}