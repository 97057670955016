import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"width":"5rem"}
}
const _hoisted_2 = { style: {"max-height":"2rem"} }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_ZorstStatus = _resolveComponent("ZorstStatus")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_ctx.isLoggedIn)
    ? (_openBlock(), _createBlock(_component_v_sheet, {
        key: 0,
        class: "d-flex justify-space-between pa-1",
        color: "primary-darken-1"
      }, {
        default: _withCtx(() => [
          (_ctx.recurionChatIsActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              class: "menu-icon",
              src: _ctx.chipIcon,
              style: {
          width: '1.5rem',
          height: '1.5rem',
          objectFit: 'contain',
        }
            }, null, 8, _hoisted_3),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "left"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Recurion")
              ])),
              _: 1
            }),
            _createVNode(_component_v_menu, { activator: "parent" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openKnowledgeBase()))
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("fa-brands fa-youtube")
                          ])),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("Grundkurs: Videos")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_list_item, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openRecurionChat()))
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("fa-brands fa-rocketchat")
                          ])),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("Recurion Chat")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          (_ctx.getUserRole() === 0 && !_ctx.mdAndDown)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 1,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.gimmeStore())),
                variant: "text",
                density: "compact"
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" GIMME STORE DATA ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_spacer),
          (_ctx.$store?.state?.company?.softwareIntegration?.zorst)
            ? (_openBlock(), _createBlock(_component_ZorstStatus, { key: 2 }))
            : _createCommentVNode("", true),
          _cache[10] || (_cache[10] = _createTextVNode("   ")),
          _createVNode(_component_v_btn, {
            icon: "",
            density: "compact",
            color: "primary-darken-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" fa-solid fa-bars ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_menu, {
                activator: "parent",
                "offset-y": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list, { "bg-color": "primary-darken-1" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, {
                          color: "error",
                          key: item.text,
                          class: "d-flex",
                          onClick: ($event: any) => (_ctx.toggleMenu(item.route))
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_v_icon, { size: "xs" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.icon), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.text), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}