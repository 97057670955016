import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"position":"relative"} }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 0,
  class: "calendar"
}
const _hoisted_5 = {
  key: 0,
  class: "d-flex pt-15"
}
const _hoisted_6 = { style: {"position":"relative"} }
const _hoisted_7 = ["title"]
const _hoisted_8 = {
  key: 0,
  class: "calendar"
}
const _hoisted_9 = {
  key: 1,
  class: "dependencies-container"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "modal-dependency-name" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 0,
  class: "modal-dependency-name"
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  key: 2,
  class: "dependencies-flexbox"
}
const _hoisted_17 = { key: 2 }
const _hoisted_18 = {
  key: 1,
  class: "dependencies-container"
}
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "dependencies-flexbox follow-up" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "dependency-name" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "dependencies-flexbox" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "dependencies-flexbox" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "dependencies-flexbox" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { key: 2 }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { class: "dependencies-flexbox" }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = ["onClick"]
const _hoisted_39 = { key: 1 }
const _hoisted_40 = { class: "dependencies-flexbox" }
const _hoisted_41 = ["onClick"]
const _hoisted_42 = { key: 1 }
const _hoisted_43 = {
  key: 0,
  class: "d-flex justify-space-between"
}
const _hoisted_44 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_Reminder = _resolveComponent("Reminder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (
        _ctx.modalFollowUpOpen ||
        _ctx.modalCandidateToCustomerOpen ||
        _ctx.modalCandidateToMandantOpen
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.isModalOpen,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isModalOpen) = $event)),
              persistent: "",
              "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.modalTitle) + " ", 1),
                        _createVNode(_component_v_spacer)
                      ]),
                      _: 1
                    }),
                    (_ctx.candidate_id)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "close-icon",
                          title: "Terminfenster schließen",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                        }, _cache[16] || (_cache[16] = [
                          _createElementVNode("i", { class: "fa-solid fa-xmark" }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          items: _ctx.optionsForSelect(),
                          "item-title": "eventName",
                          "item-value": "eventName",
                          label: "Wählen Sie eine Option",
                          modelValue: _ctx.selectedOption,
                          "onUpdate:modelValue": [
                            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOption) = $event)),
                            _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleOptionChange(_ctx.selectedOption)))
                          ]
                        }, null, 8, ["variant", "rounded", "base-color", "color", "items", "modelValue"]),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("i", {
                              class: _normalizeClass(["fa-solid", {
                    'fa-calendar-xmark close-calendar-icon': _ctx.showCalendar,
                    'fa-calendar-plus open-calendar-icon': !_ctx.showCalendar,
                  }]),
                              title: 
                    _ctx.showCalendar ? 'Kalender verbergen' : 'Kalender anzeigen'
                  ,
                              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
                            }, [
                              _createVNode(_component_v_tooltip, {
                                activator: "parent",
                                location: "bottom"
                              }, {
                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                  _createTextVNode("Kalender aus- bzw. einblenden...")
                                ])),
                                _: 1
                              })
                            ], 10, _hoisted_3)
                          ]),
                          _createVNode(_Transition, { name: "v-expand-transition" }, {
                            default: _withCtx(() => [
                              (_ctx.showCalendar)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createVNode(_component_Calendar, { selectedDatesTimes: _ctx.selectedDatesTimes }, null, 8, ["selectedDatesTimes"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        (_ctx.showDatePicker)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _withDirectives(_createElementVNode("input", {
                                class: "date-picker",
                                type: "date",
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedDate) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.selectedDate]
                              ]),
                              _withDirectives(_createElementVNode("input", {
                                class: "time-picker",
                                type: "time",
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedTime) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.selectedTime]
                              ]),
                              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "horizontal-spacer" }, null, -1)),
                              _createVNode(_component_v_btn, {
                                variant: _ctx.vStyle.btn.variant || undefined,
                                rounded: _ctx.vStyle.btn.rounded || undefined,
                                border: _ctx.vStyle.btn.border || undefined,
                                onClick: _ctx.addDateTime
                              }, {
                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                  _createTextVNode("Datum/Zeit hinzufügen")
                                ])),
                                _: 1
                              }, 8, ["variant", "rounded", "border", "onClick"])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedDatesTimes, (selection, index) => {
                            return (_openBlock(), _createBlock(_component_v_chip, {
                              key: index,
                              class: "ma-2",
                              color: "primary",
                              "text-color": "var(--color-on-primary)"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formatDateTime(selection)) + " ", 1),
                                _createVNode(_component_v_icon, {
                                  small: "",
                                  onClick: _withModifiers(($event: any) => (_ctx.removeDateTime(index)), ["stop"]),
                                  class: "fa fa-times"
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          color: "abort",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closeModal()))
                        }, {
                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                            _createTextVNode("Abbrechen")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          variant: _ctx.vStyle.btn.variant || undefined,
                          rounded: _ctx.vStyle.btn.rounded || undefined,
                          border: _ctx.vStyle.btn.border || undefined,
                          color: "primary",
                          onClick: _ctx.addLinking
                        }, {
                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                            _createTextVNode("Hinzufügen")
                          ])),
                          _: 1
                        }, 8, ["variant", "rounded", "border", "onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "max-width"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showAppointmentModal,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showAppointmentModal) = $event)),
        persistent: "",
        "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => _cache[22] || (_cache[22] = [
                  _createTextVNode("Termin")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("i", {
                        class: _normalizeClass(["fa-solid", {
                  'fa-calendar-xmark close-calendar-icon': _ctx.showCalendar,
                  'fa-calendar-plus open-calendar-icon': !_ctx.showCalendar,
                }]),
                        title: 
                  _ctx.showCalendar ? 'Kalender verbergen' : 'Kalender anzeigen'
                ,
                        onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
                      }, [
                        _createVNode(_component_v_tooltip, {
                          activator: "parent",
                          location: "bottom"
                        }, {
                          default: _withCtx(() => _cache[23] || (_cache[23] = [
                            _createTextVNode("Kalender aus- bzw. einblenden...")
                          ])),
                          _: 1
                        })
                      ], 10, _hoisted_7)
                    ]),
                    _createVNode(_Transition, { name: "v-expand-transition" }, {
                      default: _withCtx(() => [
                        (_ctx.showCalendar)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(_component_Calendar, { selectedDates: _ctx.selectedDatesTimes }, null, 8, ["selectedDates"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.candidate_id)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "close-icon",
                        title: "Terminfenster schließen",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showAppointmentModal = false))
                      }, _cache[24] || (_cache[24] = [
                        _createElementVNode("i", { class: "fa-solid fa-xmark" }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  (_ctx.linkingObjects[0])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkingObjects, (linkingObject) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "modal-dependencies-list",
                            key: linkingObject._id
                          }, [
                            (_ctx.customer_id && _ctx.isDependencyVisible(linkingObject))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                  _createVNode(_component_v_spacer),
                                  _createElementVNode("div", _hoisted_11, _toDisplayString(linkingObject.description?.firstName) + " " + _toDisplayString(linkingObject.description?.lastName) + " aus " + _toDisplayString(linkingObject.description?.postCode) + " " + _toDisplayString(linkingObject.description?.city), 1),
                                  _createElementVNode("div", {
                                    class: "modal-dependency-event-type",
                                    onClick: ($event: any) => (
                    linkingObject.linkingCore?.candidate
                      ? _ctx.linkCandidateToCustomer(
                          linkingObject.linkingCore.candidate
                        )
                      : void 0
                  )
                                  }, _toDisplayString(_ctx.getLastEventType(linkingObject)) + ": ", 9, _hoisted_12)
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.candidate_id && _ctx.isDependencyVisible(linkingObject))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                  _createVNode(_component_v_spacer),
                                  (linkingObject.description?.customerName)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(linkingObject.description?.customerName) + " in " + _toDisplayString(linkingObject.description?.customerPostCode) + " " + _toDisplayString(linkingObject.description?.customerCity), 1))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", {
                                    class: "modal-dependency-event-type",
                                    onClick: ($event: any) => (
                    linkingObject.linkingCore?.candidate &&
                    linkingObject.linkingCore?.mandant
                      ? _ctx.linkCandidateToMandant(
                          linkingObject.linkingCore.candidate,
                          linkingObject.linkingCore.mandant
                        )
                      : linkingObject.linkingCore?.candidate &&
                        linkingObject.linkingCore?.customer
                      ? _ctx.linkCandidateToCustomer(
                          linkingObject.linkingCore.candidate,
                          linkingObject.linkingCore.customer
                        )
                      : linkingObject.linkingCore?.candidate &&
                        _ctx.firstEventIsFollowUp(linkingObject)
                      ? _ctx.linkFollowUp(linkingObject.linkingCore.candidate, null)
                      : linkingObject.linkingCore?.customer &&
                        _ctx.firstEventIsFollowUp(linkingObject)
                      ? _ctx.linkFollowUp(null, linkingObject.linkingCore.customer)
                      : void 0
                  )
                                  }, _toDisplayString(_ctx.getLastEventType(linkingObject)), 9, _hoisted_15)
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.isDependencyVisible(linkingObject))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLastEventDates(linkingObject), (date, dateIndex) => {
                                    return (_openBlock(), _createBlock(_component_v_chip, {
                                      key: dateIndex,
                                      class: "ma-2 modal-date",
                                      color: "primary",
                                      "text-color": "var(--color-font-primary)"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatDateTime(date)), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128)),
                                  _cache[25] || (_cache[25] = _createElementVNode("div", { class: "spacer" }, null, -1)),
                                  _createVNode(_component_v_spacer)
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _cache[27] || (_cache[27] = _createElementVNode("p", null, " Keine bestehenden Verknüpfungen gefunden. Neuen Termin erstellen? ", -1)),
                        _createVNode(_component_v_btn, {
                          variant: _ctx.vStyle.btn.variant || undefined,
                          rounded: _ctx.vStyle.btn.rounded || undefined,
                          border: _ctx.vStyle.btn.border || undefined,
                          color: "primary",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.createNewAppointment()))
                        }, {
                          default: _withCtx(() => _cache[26] || (_cache[26] = [
                            _createTextVNode("Neuen Termin erstellen")
                          ])),
                          _: 1
                        }, 8, ["variant", "rounded", "border"])
                      ]))
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    variant: _ctx.vStyle.btn.variant || undefined,
                    rounded: _ctx.vStyle.btn.rounded || undefined,
                    border: _ctx.vStyle.btn.border || undefined,
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showAppointmentModal = false))
                  }, {
                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                      _createTextVNode("Schließen")
                    ])),
                    _: 1
                  }, 8, ["variant", "rounded", "border"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "max-width"]),
      (_ctx.linkingObjects[0])
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkingObjects, (linkingObject) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "dependencies-list",
                key: linkingObject._id
              }, [
                (
            linkingObject.linkingCore.mandant &&
            _ctx.isFollowUpEvent(linkingObject) &&
            _ctx.isDependencyVisible(linkingObject)
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", {
                          class: "dependency-event-type",
                          onClick: ($event: any) => (_ctx.openLinking(linkingObject, false))
                        }, [
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[29] || (_cache[29] = [
                              _createTextVNode("Wiedervorlage öffen und neuen Status eintragen")
                            ])),
                            _: 1
                          }),
                          _createTextVNode(" " + _toDisplayString(_ctx.getLastFollowUpEventType(linkingObject)), 1)
                        ], 8, _hoisted_21),
                        _createElementVNode("div", _hoisted_22, _toDisplayString(linkingObject.description?.firstName) + " " + _toDisplayString(linkingObject.description?.lastName), 1),
                        (linkingObject.description?.city)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_23, " aus " + _toDisplayString(linkingObject.description?.postCode) + " " + _toDisplayString(linkingObject.description?.city), 1))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_icon, {
                          small: "",
                          class: "fa fa-phone-slash remove-dependency",
                          onClick: _withModifiers(($event: any) => (_ctx.removeFollowUp(linkingObject)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[30] || (_cache[30] = [
                                _createTextVNode("Wiedervorlage entfernen")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _cache[32] || (_cache[32] = _createElementVNode("i", { class: "fa-solid fa-calendar-days calendar-icon" }, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLastFollowUpEventDates(
                linkingObject
              ), (date, dateIndex) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "date-item",
                            key: dateIndex,
                            onClick: ($event: any) => (_ctx.openLinking(linkingObject, true))
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.formatDateTime(date)) + " ", 1),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[31] || (_cache[31] = [
                                _createTextVNode("Kalender anzeigen und neuen Status eintragen")
                              ])),
                              _: 1
                            })
                          ], 8, _hoisted_25))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.customer_id && _ctx.isDependencyVisible(linkingObject))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      (_ctx.getLastNonFollowUpEventType(linkingObject))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("div", {
                                class: "dependency-event-type",
                                onClick: ($event: any) => (_ctx.openLinking(linkingObject, false))
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.getLastNonFollowUpEventType(linkingObject)) + ": ", 1),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                                    _createTextVNode("Status bzw. Termin öffnen und ergänzen!")
                                  ])),
                                  _: 1
                                })
                              ], 8, _hoisted_29),
                              _createElementVNode("div", {
                                class: "dependency-name",
                                onClick: ($event: any) => (
                  _ctx.openItem(
                    linkingObject.description?.firstName,
                    linkingObject.description?.lastName,
                    linkingObject.linkingCore?.candidate
                  )
                )
                              }, [
                                _cache[35] || (_cache[35] = _createElementVNode("i", { class: "fa-solid fa-person-circle-question" }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(linkingObject.description?.firstName) + " " + _toDisplayString(linkingObject.description?.lastName) + " ", 1),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                                    _createTextVNode("Kandidat in der Spalte \"Kandidaten\" anzeigen")
                                  ])),
                                  _: 1
                                })
                              ], 8, _hoisted_30),
                              (linkingObject.description?.city)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                    _createTextVNode(" aus " + _toDisplayString(linkingObject.description?.postCode) + " " + _toDisplayString(linkingObject.description?.city) + " ", 1),
                                    _cache[36] || (_cache[36] = _createElementVNode("div", { class: "horizontal-spacer" }, null, -1))
                                  ]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_icon, {
                                small: "",
                                class: "fa fa-trash-can remove-dependency",
                                onClick: _withModifiers(($event: any) => (_ctx.removeDependency(linkingObject)), ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_tooltip, {
                                    activator: "parent",
                                    location: "bottom"
                                  }, {
                                    default: _withCtx(() => _cache[37] || (_cache[37] = [
                                      _createTextVNode("alle Verlinkungen komplett löschen!")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_v_icon, {
                                small: "",
                                class: "fa fa-rotate-left remove-dependency",
                                onClick: _withModifiers(($event: any) => (_ctx.removeLastEvent(linkingObject)), ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_tooltip, {
                                    activator: "parent",
                                    location: "bottom"
                                  }, {
                                    default: _withCtx(() => _cache[38] || (_cache[38] = [
                                      _createTextVNode("letzter Status Rückgängig machen!")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _createElementVNode("div", _hoisted_32, [
                              _cache[40] || (_cache[40] = _createElementVNode("i", { class: "fa-solid fa-calendar-days calendar-icon" }, null, -1)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLastNonFollowUpEventDates(
                  linkingObject
                ), (date, dateIndex) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "date-item",
                                  key: dateIndex,
                                  onClick: ($event: any) => (_ctx.openLinking(linkingObject, true))
                                }, [
                                  _createTextVNode(_toDisplayString(_ctx.formatDateTime(date)) + " ", 1),
                                  _createVNode(_component_v_tooltip, {
                                    activator: "parent",
                                    location: "bottom"
                                  }, {
                                    default: _withCtx(() => _cache[39] || (_cache[39] = [
                                      _createTextVNode("Kalender öffnen und Status bzw. Termin ergänzen!")
                                    ])),
                                    _: 1
                                  })
                                ], 8, _hoisted_33))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.candidate_id && _ctx.isDependencyVisible(linkingObject))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                      (_ctx.getLastNonFollowUpEventType(linkingObject))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("div", {
                                class: "dependency-event-type",
                                onClick: ($event: any) => (_ctx.openLinking(linkingObject, false))
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.getLastNonFollowUpEventType(linkingObject)) + ": ", 1),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[41] || (_cache[41] = [
                                    _createTextVNode("Status bzw. Termin öffnen und ergänzen")
                                  ])),
                                  _: 1
                                })
                              ], 8, _hoisted_37),
                              (linkingObject.description?.customerName)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: "dependency-name",
                                    onClick: ($event: any) => (
                  _ctx.openItem(
                    linkingObject.description?.customerName,
                    undefined,
                    linkingObject.linkingCore?.customer
                  )
                )
                                  }, [
                                    _cache[43] || (_cache[43] = _createElementVNode("i", { class: "fa-solid fa-industry" }, null, -1)),
                                    _createTextVNode(" " + _toDisplayString(linkingObject.description?.customerName) + " ", 1),
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => _cache[42] || (_cache[42] = [
                                        _createTextVNode("Unternehmen in der Spalte \"Unternehmen\" suchen!")
                                      ])),
                                      _: 1
                                    })
                                  ], 8, _hoisted_38))
                                : _createCommentVNode("", true),
                              (
                  linkingObject.description?.customerPostCode ||
                  linkingObject.description?.customerCity
                )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_39, " in " + _toDisplayString(linkingObject.description?.customerPostCode) + " " + _toDisplayString(linkingObject.description?.customerCity), 1))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_icon, {
                                small: "",
                                class: "fa fa-trash-can remove-dependency",
                                onClick: _withModifiers(($event: any) => (_ctx.removeDependency(linkingObject)), ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_tooltip, {
                                    activator: "parent",
                                    location: "bottom"
                                  }, {
                                    default: _withCtx(() => _cache[44] || (_cache[44] = [
                                      _createTextVNode("Komplette Verlinkung entfernen!")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_v_icon, {
                                small: "",
                                class: "fa fa-rotate-left remove-dependency",
                                onClick: _withModifiers(($event: any) => (_ctx.removeLastEvent(linkingObject)), ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_tooltip, {
                                    activator: "parent",
                                    location: "bottom"
                                  }, {
                                    default: _withCtx(() => _cache[45] || (_cache[45] = [
                                      _createTextVNode("Letzte Verlinkung Rückgängig machen!")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _createElementVNode("div", _hoisted_40, [
                              _cache[47] || (_cache[47] = _createElementVNode("i", { class: "fa-solid fa-calendar-days calendar-icon" }, null, -1)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLastNonFollowUpEventDates(
                  linkingObject
                ), (date, dateIndex) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "date-item",
                                  key: dateIndex,
                                  onClick: ($event: any) => (_ctx.openLinking(linkingObject, true))
                                }, [
                                  _createTextVNode(_toDisplayString(_ctx.formatDateTime(date)) + " ", 1),
                                  _createVNode(_component_v_tooltip, {
                                    activator: "parent",
                                    location: "bottom"
                                  }, {
                                    default: _withCtx(() => _cache[46] || (_cache[46] = [
                                      _createTextVNode("Kalender anzeigen und Status bzw. Termin öffnen und ergänzen... ")
                                    ])),
                                    _: 1
                                  })
                                ], 8, _hoisted_41))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_ctx.hasLinkingsExceptDemandLinkings() && _ctx.hasDemandLinkings())
              ? (_openBlock(), _createBlock(_component_v_divider, {
                  key: 0,
                  class: "mt-3"
                }))
              : _createCommentVNode("", true),
            (_ctx.hasDemandLinkings())
              ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        size: "xsmall",
                        class: "text-medium-emphasis mr-1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.customer_id ? "fa-solid fa-file-contract" : "fa-solid fa-building"), 1)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(_ctx.customer_id ? "Anfragen" : "Kunde"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.customer_id ? "Verlinkte Anfragen:" : "Verbundener Kunde:"), 1)
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkingObjects, (linkingObject) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "dependencies-list",
                      key: linkingObject._id
                    }, [
                      (_ctx.isDemandLinking(linkingObject))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                            _createElementVNode("div", null, [
                              _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.customer_id
                    ? linkingObject.description?.position
                    : linkingObject.description?.customerName), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.customer_id
                  ? `${linkingObject.description?.postCode} ${linkingObject.description?.city}`
                  : `${linkingObject.description?.customerPostCode} ${linkingObject.description?.customerCity}`), 1)
                            ]),
                            _createVNode(_component_v_icon, {
                              small: "",
                              class: "fa fa-trash-can remove-dependency",
                              onClick: _withModifiers(($event: any) => (_ctx.removeDependency(linkingObject)), ["stop"])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[48] || (_cache[48] = [
                                    _createTextVNode("alle Verlinkungen komplett löschen!")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showReminder)
      ? (_openBlock(), _createBlock(_component_Reminder, {
          key: 0,
          ref: "reminderComponent",
          openReminder: _ctx.reminderEvents.foundedEvents,
          promptTitle: _ctx.reminderTitle,
          promptText: _ctx.reminderText,
          buttonSuccess: _ctx.reminderButtons.success,
          buttonResult: _ctx.reminderButtons.result,
          buttonFailure: _ctx.reminderButtons.failure,
          onClickSuccess: _cache[13] || (_cache[13] = ($event: any) => (_ctx.handleReminderResponse(_ctx.DialogResponse.success))),
          onClickResult: _cache[14] || (_cache[14] = ($event: any) => (_ctx.handleReminderResponse(_ctx.DialogResponse.result))),
          onClickFailure: _cache[15] || (_cache[15] = ($event: any) => (_ctx.handleReminderResponse(_ctx.DialogResponse.failure)))
        }, null, 8, ["openReminder", "promptTitle", "promptText", "buttonSuccess", "buttonResult", "buttonFailure"]))
      : _createCommentVNode("", true)
  ], 64))
}