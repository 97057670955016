export enum GitLabLabels {
  bug = "BUG",
  criticalBug = "CRITICAL BUG",
  urgentCustomerRequest = "URGENT CUSTOMER REQUEST",
  refactoring = "Refactoring",
  knowledgeRequest = "Request: add Recurion knowledge",
  idea = "Idea",
  featureRequest = "Feature Request",
  recurionTicket = "RECURION TICKET",
}
