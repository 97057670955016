<!--src/components/disposition/CandidateItem.vue-->
<template>
  <v-container v-if="!isHided" class="ma-0 pa-0">
    <v-hover>
      <template #default="{ isHovering, props }">
        <v-container
          @contextmenu.prevent="openContextMenu($event)"
          @dragstart="handleDragStart"
          @longpress="openContextMenu($event)"
          draggable="true"
          class="ma-0 pa-0 item longpress"
          v-if="isFullyCollapsed"
          :color="isHovering ? 'card' : 'cardHover'"
        >
          <v-card-title class="d-flex justify-space-between pa-0">
            <v-btn
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              :loading="isLoadingFromAts"
              @click="loadCandidate(ExternalSoftware.atsRecruit)"
              variant="tonal"
              density="compact"
              size="small"
              v-if="
                !candidate.firstName &&
                !candidate.lastName &&
                !candidateData?.firstName &&
                !candidateData?.lastName
              "
            >
              <v-icon class="mr-2" size="small"
                >fa-solid fa-cloud-arrow-down</v-icon
              >
              #{{ candidate.applicationId }}
              <v-tooltip activator="parent" location="top left"
                >Kandidatendaten vollständig aus dem ATS laden ...</v-tooltip
              >
            </v-btn>
            <span class="pa-0 text-body-2">
              {{
                candidateData?.firstName
                  ? candidateData.firstName
                  : candidate.firstName
              }}
              {{
                candidateData?.lastName
                  ? candidateData.lastName
                  : candidate.lastName
              }}
              <span v-if="candidateAge > 0"> ({{ candidateAge }})</span>
              {{
                candidateData?.shiftIcon
                  ? candidateData.shiftIcon
                  : candidate.shiftIcon
              }}
              <span
                style="color: black"
                v-if="
                  (candidateData && candidateData.mobilityRadius > 0) ||
                  (candidate &&
                    candidate?.mobilityRadius &&
                    candidate?.mobilityRadius > 0)
                "
              >
                ({{
                  candidateData?.mobilityRadius
                    ? candidateData.mobilityRadius
                    : candidate.mobilityRadius
                }}
                km
                <v-icon size="xsmall">{{
                  candidateData?.mobilityIcon
                    ? candidateData.mobilityIcon
                    : candidate.mobilityIcon
                }}</v-icon>
                )
              </span>
            </span>
            <span
              class="mx-1 text-caption font-weight-light text-medium-emphasis"
            >
              {{
                candidateData?.addressCity
                  ? candidateData.addressCity
                  : candidate.addressCity
              }}
            </span>
            <div
              class="status-list"
              :style="{ backgroundColor: statusColor }"
              :class="{ blinking: needToContact || prioNeedToContact }"
            ></div>
          </v-card-title>
          <v-divider class="my-1"></v-divider>
        </v-container>
        <v-card
          v-if="!isFullyCollapsed"
          :variant="vStyle.boardItem.variant || undefined"
          :rounded="vStyle.boardItem.rounded || undefined"
          :border="vStyle.boardItem.border || undefined"
          :elevation="vStyle.boardItem.elevation || undefined"
          :color="isHovering ? item.hoverColor : item.color"
          v-bind="props"
          :class="{
            expanded: isExpanded,
            'fully-expanded': isFullyExpanded,
            'item pl-5 pr-2': isOpenedAsDialog(),
            'item pa-2 mb-2': !isOpenedAsDialog(),
          }"
          :max-height="getItemHeight()"
          @dblclick="handleDoubleClickOnItem"
          @dragstart="handleDragStart"
          @contextmenu.prevent="openContextMenu($event)"
          @longpress="openContextMenu($event)"
          draggable="true"
        >
          <div v-if="isOpenedAsDialog()" class="d-flex ma-0">
            <p class="mt-2 mb-5 text-h6 text-medium-emphasis">
              Kandidatenkarte
            </p>
            <v-spacer></v-spacer>
            <v-btn
              @click="$emit(CandidateItemEmits.closeDialog)"
              icon
              size="s"
              variant="text"
              density="compact"
            >
              <v-icon> fa-solid fa-xmark close-icon </v-icon>
            </v-btn>
          </div>
          <div
            class="status"
            :style="{ backgroundColor: statusColor }"
            :class="{ blinking: needToContact || prioNeedToContact }"
          ></div>
          <div class="status-title">
            <div class="item-collapsed">
              <div class="d-flex">
                <v-avatar v-if="avatar" size="2.5rem" class="mr-1">
                  <v-img alt="Avatar" :src="avatar"></v-img>
                </v-avatar>
                <div>
                  <v-card-title class="d-flex pa-0 text-body-2">
                    <v-btn
                      :rounded="vStyle.btn.rounded || undefined"
                      :border="vStyle.btn.border || undefined"
                      :loading="isLoadingFromAts"
                      @click="loadCandidate(ExternalSoftware.atsRecruit)"
                      variant="tonal"
                      v-if="
                        !candidate.firstName &&
                        !candidate.lastName &&
                        !candidateData?.firstName &&
                        !candidateData?.lastName
                      "
                    >
                      <v-icon class="mr-2" size="small"
                        >fa-solid fa-cloud-arrow-down</v-icon
                      >
                      #{{ candidate.applicationId }}
                      <v-tooltip activator="parent" location="top left"
                        >Kandidatendaten vollständig aus dem ATS laden
                        ...</v-tooltip
                      >
                    </v-btn>
                    <v-btn
                      icon
                      variant="text"
                      density="compact"
                      v-if="
                        isAtsStatusUnknown && !isAtsStatusChangedFromUnknown
                      "
                      @click="updateComponent"
                    >
                      <v-icon size="small"
                        >fa-regular fa-question-circle</v-icon
                      >
                      <v-tooltip activator="parent" location="bottom"
                        >ATS Status ist unbekannt! Kandidat aktualisieren und
                        neuen Status abrufen?</v-tooltip
                      >
                    </v-btn>
                    {{
                      candidateData?.firstName
                        ? candidateData.firstName
                        : candidate.firstName
                    }}
                    {{
                      candidateData?.lastName
                        ? candidateData.lastName
                        : candidate.lastName
                    }}
                    <span class="mx-1" v-if="candidateAge > 0">
                      ({{ candidateAge }})</span
                    >
                    {{
                      candidateData?.shiftIcon
                        ? candidateData.shiftIcon
                        : candidate.shiftIcon
                    }}
                    <span
                      class="mx-1 text-caption text-medium-emphasis"
                      v-if="isAtsStatusChangedFromUnknown"
                    >
                      (verschoben nach {{ matchedApplication.ats?.status }})
                    </span>
                  </v-card-title>
                  <v-card-subtitle class="pl-0">
                    {{
                      candidateData?.addressPostalCode
                        ? candidateData.addressPostalCode
                        : candidate.addressPostalCode
                    }}
                    {{
                      candidateData?.addressCity
                        ? candidateData.addressCity
                        : candidate.addressCity
                    }}
                    <span
                      style="color: black"
                      v-if="
                        (candidateData && candidateData.mobilityRadius > 0) ||
                        (candidate &&
                          candidate?.mobilityRadius &&
                          candidate?.mobilityRadius > 0)
                      "
                    >
                      ({{
                        candidateData?.mobilityRadius
                          ? candidateData.mobilityRadius
                          : candidate.mobilityRadius
                      }}
                      km
                      <v-icon size="xsmall">{{
                        candidateData?.mobilityIcon
                          ? candidateData.mobilityIcon
                          : candidate.mobilityIcon
                      }}</v-icon>
                      )
                    </span>
                  </v-card-subtitle>
                </div>
              </div>
            </div>
            <TopRightButton
              v-if="!isOpenedAsDialog()"
              :isExpanded="isExpanded"
              :isFullyExpanded="isFullyExpanded"
              :parentRole="Role.candidate"
              @sendCandidate="handleSendCandidateToGenerateProfile"
              @toggleExpansion="toggleExpansion"
              @toggleFullExpansion="toggleFullExpansion"
              @openContextMenu="openContextMenu($event)"
            />
          </div>
          <div class="hide-on-inactive-column" v-if="isActive">
            <span style="font-size: 0.7em"
              ><b
                >#{{ candidate?.applicationId }}
                {{
                  matchedApplication?.appliedAs
                    ? matchedApplication.appliedAs
                    : candidate.appliedAs
                }}</b
              ></span
            ><br />
            <span style="font-size: 0.8em">{{ matchedMandantName }}</span>
          </div>
          <v-divider></v-divider>
          <div v-if="isExpanded" class="status-communication-container">
            <div class="communication-icons-container">
              <div
                v-if="candidateData && candidateData._id"
                class="appointment-icon"
                @click="createNewMandantAppointmentOnDependencies()"
              >
                <i class="fa-solid fa-building">
                  <v-tooltip activator="parent" location="bottom"
                    >Termine mit Niederlassung</v-tooltip
                  ></i
                >
              </div>
              <v-select
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                :items="statusOptionsRecruit"
                label="Status (manuell)"
                item-title="text"
                item-value="value"
                density="compact"
                v-model="selectedStatus"
                @update:model-value="changeStatus(selectedStatus)"
              >
              </v-select>
            </div>
            <div class="communication-icons-container">
              <zvooveRecruitLinkMenu
                :zvooveRecruitMitarbeiterUuid="candidate?.candidateUuid"
                :zvooveRecruitCurrentApplicationUuid="
                  candidate?.applicationUuid
                "
                @getRecruitData="loadCandidate(ExternalSoftware.atsRecruit)"
              ></zvooveRecruitLinkMenu>
              <v-btn
                v-if="!isStatusInterview()"
                icon
                class="application-form-icon"
                variant="text"
                density="compact"
                @click="openApplicationFormDispatcherMode()"
              >
                <v-icon size="xsmall">fa-solid fa-id-card</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >Zum Bewerberbogen</v-tooltip
                >
              </v-btn>
              <PhoneClient
                ref="phoneClientComponent"
                :candidate="candidate"
                :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
                :phoneNumbers="filteredPhoneNumbers"
                @addFollowUpEvent="addFollowUpOnDependencies"
                @collapseParentItem="collapseItem"
                @isDialingNumber="phoneClientIsDialing"
                @setAppointmentEvent="setAppointmentOnDependencies"
              ></PhoneClient>
              <WhatsAppClient
                :AiMessageType="AiMessageType.whatsAppCandidate"
                :candidate="candidate"
                :linkingStatuses="linkingStatuses"
                :message="message.body"
                :phoneNumbers="filteredPhoneNumbers"
                :receiverName="`${candidateData?.firstName} ${candidateData?.lastName}`"
                :salutation="salutationText"
                :signature="signatureWhatsApp"
                @collapseParentItem="collapseItem"
              ></WhatsAppClient>
              <MailClient
                ref="mailClientComponent"
                :AiMessageType="AiMessageType.mailCandidate"
                :candidate="candidate"
                :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
                :emailAddresses="filteredEmail"
                :emailObject="message"
                :linkingStatuses="linkingStatuses"
                :signature="signatureMail"
                @collapseParentItem="collapseItem"
              ></MailClient>
            </div>
          </div>
          <DialogApplicationForm
            v-if="isExpanded"
            ref="dialogApplicationFormComponent"
            :candidate="candidateData"
            :isStatusInterview="isStatusInterview()"
            @submit="loadCandidate()"
          ></DialogApplicationForm>
          <v-divider></v-divider>
          <Checklist
            :checklist="candidateData?.checklist || []"
            :candidate="candidate"
            @updateChecklist="updateChecklist"
          ></Checklist>
          <v-divider></v-divider>
          <div v-if="isExpanded" class="tags-container">
            <v-chip
              v-for="(tag, index) in candidateData?.tags"
              :key="index"
              class="dispatcher-board-icon-tag"
              color="primary"
              @click:close="removeTag(index)"
            >
              {{ tag }}
              <v-icon
                small
                @click.stop="removeTag(index)"
                class="fa fa-times"
              ></v-icon>
            </v-chip>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              class="mt-2"
              label="Neuer Tag"
              style="max-width: 10rem"
              density="compact"
              v-model="newTag"
              @keyup.enter="addTag"
            >
              <v-tooltip activator="parent" location="bottom"
                >mit "Enter" neuen Tag hinzufügen</v-tooltip
              >
            </v-text-field>
          </div>
          <v-divider></v-divider>
          <Dependencies
            v-if="
              candidate &&
              (hasLinking(candidate.parentObjectid) || isDependenciesActive)
            "
            ref="dependenciesComponent"
            :mandant="[matchedMandantUuid || '']"
            :candidate_id="candidate.parentObjectid"
            :linkingDescription="linkingDescription"
            @updateCandidateStatus="handleCandidateStatusUpdate"
            @candidateToMandantLinked="setCandidateToMandantFromDependencies"
            @needToContact="prioNeedToContact = true"
            @notNeedToContact="prioNeedToContact = false"
            @openPhoneClient="openPhoneClient()"
          />
          <v-divider></v-divider>
          <div v-if="isExpanded">
            <v-textarea
              class="mt-2 pr-1"
              v-model="interviewResultEdit"
              label="Zusammenfassung Vorstellungsgespräch:"
              variant="solo"
              rows="4"
            ></v-textarea>
            <v-container class="ma-0 pa-0 d-flex">
              <v-checkbox
                v-if="
                  candidateData &&
                  softwareIntegration.wordPressPlugin &&
                  isCandidateActiveForPlacement()
                "
                density="compact"
                v-model="candidateData.isPublishedOnWebsite"
                :label="`Kandidat auf die Webseite stellen (WordPress Plugin)`"
                @change="handlePublishedOnWebseiteChange"
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-icon
                class="save-interview-result"
                @click="saveInterviewResult(interviewResultEdit)"
                >fa-solid fa-cloud-arrow-up</v-icon
              >
              <v-tooltip activator="parent" location="top left"
                >Zusammenfassung in den Kandidatendaten abspeichern</v-tooltip
              >
            </v-container>
            <p v-if="getCareerLevelDescription()">
              {{ getCareerLevelDescription() }}
            </p>
            <v-divider></v-divider>
            <SendCandidateToWp
              v-if="softwareIntegration.wordPressPlugin"
              ref="sendCandidateToWpComponent"
              @aborted="sendCandidateToWpAborted()"
            ></SendCandidateToWp>

            <Timeline
              :candidate="candidate"
              :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
              :mandant="matchedMandantUuid || ''"
            ></Timeline>
          </div>
        </v-card>
        <v-menu
          v-model="showContextMenu"
          :style="{
            top: contextMenuPosition.y + 'px',
            left: contextMenuPosition.x + 'px',
          }"
        >
          <v-list dense>
            <v-list-item @click="openEditCandidateModal()">
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-pen-to-square </v-icon
              >bearbeiten</v-list-item
            >
            <v-list-item
              v-if="!isOpenedAsDialog()"
              @click="askCustomerItemsForMatch()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-heart </v-icon
              >Matching</v-list-item
            >
            <v-list-item
              v-if="$store.state.isActiveCandidateMatching"
              @click="clearMatchingOject()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-heart-circle-xmark </v-icon
              >Matches entfernen</v-list-item
            >
            <v-list-item @click="deleteCandidate()">
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-trash-can </v-icon
              >Kandidat vom Dispoboard löschen</v-list-item
            >
            <v-list-item
              v-if="isAdmin"
              @click="deleteCandidate(candidate.candidateUuid)"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-user-xmark </v-icon
              >ADMIN: Kandidat anhand uuid löschen</v-list-item
            >
            <v-list-item
              v-if="!isOpenedAsDialog()"
              @click="emitLoadCandidatesFull()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-regular fa-id-card </v-icon
              >alle vollständig laden</v-list-item
            >
            <v-list-item
              v-if="!isOpenedAsDialog()"
              @click="emitLoadCandidatesFromAts()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-cloud-arrow-down </v-icon
              >alle aus ATS überschreiben</v-list-item
            >
            <v-list-item
              v-if="!isOpenedAsDialog()"
              @click="emitToggleItemsMinimized()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-1" size="xs">
                {{
                  isFullyCollapsed
                    ? "fa-solid fa-chevron-up"
                    : "fa-solid fa-chevron-down"
                }}
              </v-icon>
              {{ isFullyCollapsed ? "Karten normal" : "Karten minimieren" }}
            </v-list-item>
          </v-list>
        </v-menu>
        <Teleport to="body">
          <JobAdLeadsCandidate
            v-if="
              isExpanded &&
              candidateData?.jobAdLeads &&
              isCandidateActiveForPlacement() &&
              !isOpenedAsDialog()
            "
            ref="jobAdListComponent"
            :jobAds="candidateData?.jobAdLeads as JobAdLead[] || []"
            :indexCareerLevel="candidateData?.careerLevel"
            :mandant="matchedMandantUuid || ''"
            @generateAiSearchterm="fetchJobAdLeads(true)"
            @manualAnzeigendatenSearch="openAnzeigendatenManualSearch()"
            @updateCareerLevels="updateCareerLevels"
          />
          <DialogInterviewCompleted
            v-if="isDialogInterviewCompletedActive"
            ref="dialogInterviewCompletedComponent"
            :zvooveRecruitMitarbeiterUuid="candidate.candidateUuid"
            @saveResumeInterview="handleSaveResumeInterview"
            @fetchRecruitData="loadCandidate(ExternalSoftware.atsRecruit)"
            @openEditCandidateDialog="openEditCandidateModal"
          ></DialogInterviewCompleted>
          <DialogManualAnzeigendatenSearch
            v-if="isDialogManualAnzeigendatenSearchActive"
            :dataForQuery="prepareDataForJobAdLeadsQuery()"
            ref="dialogManualAnzeigendatenSearchComponent"
            @searchAndReplace="searchAnzeigendatenAndReplace"
            @searchAndAdd="searchAnzeigendatenAndAdd"
          ></DialogManualAnzeigendatenSearch>
          <DialogEditCandidate
            v-if="isDialogEditCandidateActive"
            ref="dialogEditCandidateComponent"
            :candidate="candidateData"
            @submit="loadCandidate()"
            @fetchJobAdLeads="fetchJobAdLeads()"
            @generateAiSearchterm="fetchJobAdLeads(true)"
            @manualAnzeigendatenSearch="openAnzeigendatenManualSearch()"
          ></DialogEditCandidate>
        </Teleport>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import { AiMessageType } from "../../enums/ai-options.enum";
import { AiService } from "@/services/ai.service";
import { AllAtsAdapter } from "@/adapter/all-ats.adapter";
import {
  AllLinkingsStatusObject,
  CandidateToCustomerData,
  LinkingDescription,
  LinkingEvent,
} from "@/models/linking.model";
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import {
  Candidate,
  CareerStep,
  CommunicationMeans,
  Skill,
} from "@/models/candidate.model";
import { CandidateList } from "@/models/candidate-list.model";
import { CandidateService } from "@/services/api/candidate.service";
import { CareerLevel, IaCareerLevel } from "@/enums/anzeigendaten.enum";
import { CheckList } from "@/models/checklist.model";
import { CommunicationType } from "@/enums/communication-types.enum";
import {
  DataForJobAdLeadsQuery,
  JodAdLeadsQuery,
} from "@/models/external/index-anzeigendaten.model";
import { defineComponent, PropType } from "vue";
import { ExternalSoftware } from "@/enums/external/external-software.enum";
import { Gender } from "@/enums/gender.enum";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { JobAdLead } from "@/models/job-ad-lead.model";
import { LinkingService } from "@/services/api/linking.service";
import { LinkingStatus, Role } from "@/enums/dependency.enum";
import { Mandant } from "@/models/mandant.model";
import { mapGetters, mapMutations } from "vuex";
import { MessageContent } from "@/enums/empty-message.enum";
import { MutationType } from "@/enums/vuex-types.enum";
import { RecruitStatusOption } from "@/models/status-candidate.model";
import { Salutation, getSalutationText } from "@/enums/salutation.enum";
import { SoftwareIntegration } from "@/models/company-config.model";
import { SpinnerService } from "@/services/spinner.service";
import { StatusResponse } from "@/enums/dialog-action.enum";
import { User } from "@/models/user.model";
import { UserRoleHelper } from "@/helper/user-role.helper";
import { ZipCodeDistance } from "@/models/zipcode.model";
import { ZipCodeService } from "@/services/api/zip-code.service";
import Checklist from "./elements/Checklist.vue";
import Dependencies from "@/components/disposition/elements/Dependencies.vue";
import DialogApplicationForm from "./elements/DialogApplicationForm.vue";
import DialogEditCandidate from "@/components/disposition/elements/DialogEditCandidate.vue";
import DialogInterviewCompleted from "@/components/disposition/elements/DialogInterviewCompleted.vue";
import DialogManualAnzeigendatenSearch from "./elements/DialogManualAnzeigendatenSearch.vue";
import DialogService from "@/services/dialog.service";
import JobAdLeadsCandidate from "@/components/disposition/elements/JobAdLeadsCandidate.vue";
import MailClient from "./elements/MailClient.vue";
import moment from "moment";
import PhoneClient from "./elements/PhoneClient.vue";
import SendCandidateToWp from "./elements/SendCandidateToWp.vue";
import Timeline from "./elements/Timeline.vue";
import ToastService from "@/services/toast.service";
import TopRightButton from "./elements/TopRightButton.vue";
import WhatsAppClient from "./elements/wab/WhatsAppClient.vue";
import zvooveRecruitLinkMenu from "./elements/ExternalSoftwareLinksMenu.vue";
import { AllItemUpdate } from "../../enums/board-actions.enum";

enum CandidateItemEmits {
  closeDialog = "closeDialog",
  updatePostcode = "updatePostcode",
  updateCandidate = "updateCandidate",
  loadCandidates = "loadCandidates",
  loadCandidatesFull = "loadCandidatesFull",
  loadCandidatesFromAts = "loadCandidatesFromAts",
  toggleItemsMinimized = "toggleItemsMinimized",
}

export default defineComponent({
  name: "CandidatItem",
  emits: [
    CandidateItemEmits.closeDialog,
    CandidateItemEmits.updatePostcode,
    CandidateItemEmits.updateCandidate,
    CandidateItemEmits.loadCandidates,
    CandidateItemEmits.loadCandidatesFull,
    CandidateItemEmits.loadCandidatesFromAts,
    CandidateItemEmits.toggleItemsMinimized,
  ],
  components: {
    Checklist,
    Dependencies,
    DialogApplicationForm,
    DialogEditCandidate,
    DialogInterviewCompleted,
    JobAdLeadsCandidate,
    MailClient,
    PhoneClient,
    DialogManualAnzeigendatenSearch,
    SendCandidateToWp,
    Timeline,
    TopRightButton,
    WhatsAppClient,
    zvooveRecruitLinkMenu,
  },
  props: {
    candidate: {
      type: Object as PropType<CandidateList>,
      required: true,
    },
    candidateDataInput: {
      type: Object as PropType<Candidate>,
      required: false,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      hasLinking: "hasLinkingForCandidate",
      mandants: "mandants",
      mailTemplates: "mailTemplates",
      statusOptionsRecruit: "statusOptionsRecruit",
    }),
    isAdmin(): boolean {
      return UserRoleHelper.isAdmin(this.user);
    },
    isAtsStatusUnknown() {
      if (this.softwareIntegration.atsStatusIgnored) return false;
      return this.candidate.atsStatus === StatusResponse.unknown;
    },
    matchedApplication() {
      if (!this.candidateData || Object.keys(this.candidateData).length === 0) {
        return {
          applicationDate: "",
          appliedAs: "",
          ats: { status: "" },
          mandantUuid: "",
          operationalRadius: null,
          status: "",
        };
      }
      if (
        this.candidateData.applications &&
        this.candidateData.applications.length > 0
      ) {
        const matchedApplication = this.candidateData.applications.find(
          (application) => application.uuid === this.candidate.applicationUuid
        );

        if (matchedApplication) {
          const extractedData = {
            applicationDate: matchedApplication.applicationDate,
            appliedAs: matchedApplication.appliedAs,
            ats: { status: matchedApplication.ats.status },
            mandantUuid: matchedApplication.mandantUuid,
            operationalRadius: matchedApplication.operationalRadius,
            status: matchedApplication.status
              ? matchedApplication.status
              : null,
          };

          return extractedData;
        } else {
          return {
            applicationDate: "",
            appliedAs: "",
            ats: { status: "" },
            mandantUuid: "",
            operationalRadius: null,
            status: "",
          };
        }
      } else {
        return {
          applicationDate: "",
          appliedAs: "",
          mandantUuid: "",
          operationalRadius: null,
          status: "",
        };
      }
    },
    matchedMandant() {
      const matchedMandant = this.mandants.find(
        (mandant: Mandant) => mandant.uuid === this.candidate?.mandants[0]
      );
      return matchedMandant || { name: "", uuid: "" };
    },
    matchedMandantName() {
      return this.matchedMandant.name;
    },
    matchedMandantUuid() {
      return this.matchedMandant.uuid;
    },
    salutationText() {
      if (
        this.candidateData &&
        this.candidateData.salutationCatalogId !== undefined
      ) {
        const salutation = getSalutationText(
          this.candidateData.salutationCatalogId
        );
        return `Guten Tag ${salutation} ${this.candidateData.firstName} ${this.candidateData.lastName},`;
      }
      return "";
    },
    signatureMail() {
      const user = this.user;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.$store.state.company.name}`;
    },
    signatureWhatsApp() {
      const user = this.user;
      return `Viele Grüße\n${Salutation[user.salutation]} ${user.forename} ${
        user.lastname
      }\n${this.$store.state.company.name}`;
    },
  },
  data() {
    return {
      anzeigedatenService: AnzeigedatenService.getInstance(),
      avatar: "",
      AiMessageType,
      candidateAge: 0,
      candidateData: {} as Candidate | undefined,
      CandidateItemEmits,
      candidateService: new CandidateService(),
      careerLevel: [] as Array<keyof typeof IaCareerLevel>,
      contextMenuPosition: { x: 0, y: 0 },
      ExternalSoftware: ExternalSoftware,
      filteredEmail: [] as any,
      filteredPhoneNumbers: [] as any,
      interviewResultEdit: "",
      isAtsStatusChangedFromUnknown: false,
      isDependenciesActive: false,
      isDialogEditCandidateActive: false,
      isDialogInterviewCompletedActive: false,
      isDialogManualAnzeigendatenSearchActive: false,
      isExpanded: false,
      isFullyExpanded: false,
      isHided: false,
      isLoadingFromAts: false,
      item: {
        border: this.$store.state.vStyle.boardItem.border || undefined,
        color: this.isOpenedAsDialog() ? undefined : "card",
        elevation: 2,
        hoverColor: "cardHover",
        colorList: undefined as undefined | string,
      },
      licences: "",
      linkingDescription: {} as LinkingDescription,
      linkingStatuses: [] as LinkingEvent[],
      message: {
        subject: MessageContent.emptySubject as string,
        body: MessageContent.empty as string,
      },
      needToContact: false,
      newTag: "",
      prioNeedToContact: false,
      Role,
      selectedStatus: this.candidate?.status,
      showContextMenu: false,
      skillsForJobAdLeads: [] as string[],
      statusColor: "",
      vStyle: this.$store.state.vStyle,
      zipCodeService: new ZipCodeService(),
    };
  },
  watch: {
    lastUpdateTimeline() {
      if (this.$refs.dependenciesComponent) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).loadLinkingsFromStore();
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).checkForReminders();
      }
    },
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterCandidate(newVal.payload.searchTerm);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initializeComponent().then(() => {
      this.loadAvatarFromAtsRecruit();
    });
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    actionsFromStatusChange(statusValue: string) {
      const candidateStatus = this.$store.getters.getStatusByValue(
        statusValue
      ) as string[];
      candidateStatus.forEach((status) => {
        switch (status) {
          case LinkingStatus.rejectedCandidate:
            this.deleteCandidateFromWpPlugin();
            break;
          case LinkingStatus.rejected:
            this.deleteCandidateFromWpPlugin();
            break;
          case LinkingStatus.hired:
            this.deleteCandidateFromWpPlugin();
            break;
          default:
            break;
        }
      });
    },
    addFollowUpOnDependencies() {
      this.isDependenciesActive = true;
      this.$nextTick(() => {
        if (this.candidateData && this.$refs.dependenciesComponent) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).linkFollowUp(this.candidateData._id || null, null);
        }
      });
    },
    addTag() {
      const trimmedTag = this.newTag.trim();
      if (this.candidateData && trimmedTag !== "") {
        if (!this.candidateData.tags) {
          this.candidateData.tags = [];
        }

        this.candidateData.tags.push(trimmedTag);
        this.newTag = "";

        this.updateCandidateData().then(() => {
          ToastService.show("Tag hinzugefügt!");
        });
      }
    },
    async askCustomerItemsForMatch() {
      if (!this.candidateData || !this.candidateData.tags) {
        return;
      }

      const customersInRange = await this.getCustomerPostcodesInMobilityRange();
      this.setICM({
        message: InterComponentMessage.matchMeFromCandidate,
        payload: {
          postcodes: customersInRange,
          tags: this.candidateData.tags ?? "",
        },
      });
      this.$store.state.isActiveCandidateMatching = true;
      this.closeContextMenu();
    },
    async autoCheckForJobAdLeads() {
      if (!this.candidateData || Object.keys(this.candidateData).length === 0) {
        return;
      }
      if (
        this.candidateData.tags &&
        this.candidateData.tags.length > 0 &&
        this.candidateData.careerSteps &&
        this.candidateData.careerSteps.length > 0 &&
        this.softwareIntegration.indexAnzeigendaten
      ) {
        let isFetchAds = false;
        if (this.candidateData.jobAdLeads?.length === 0) {
          isFetchAds = true;
        } else {
          const firstAdLead: JobAdLead | undefined =
            this.candidateData.jobAdLeads?.[0];
          if (firstAdLead && firstAdLead.retrievalDate) {
            const adRetrievalDate = moment(
              firstAdLead.retrievalDate,
              "DD.MM.YYYY"
            );
            const currentDate = moment();
            const daysSinceLastRetrieval = currentDate.diff(
              adRetrievalDate,
              "days"
            );
            isFetchAds =
              daysSinceLastRetrieval >
              this.softwareIntegration.indexJobAdRefreshInterval;
          } else {
            isFetchAds = true;
          }
        }

        if (isFetchAds) {
          try {
            const needsAiFetching = await this.fetchJobAdLeads();

            if (needsAiFetching) {
              this.fetchJobAdLeads(true).then(() =>
                ToastService.show(
                  `Job Leads von ${this.candidateData?.firstName} ${this.candidateData?.lastName}abgerufen!`
                )
              );
            }
          } catch (error) {
            console.error("Fehler beim Abrufen neuer Job-Leads:", error);
          }
        }
      }
    },
    async allItemUpdate(methode: AllItemUpdate): Promise<void> {
      try {
        switch (methode) {
          case AllItemUpdate.checkForFullLoad:
            await this.checkForFullLoad();
            break;
          case AllItemUpdate.loadCandidateFromAts:
            await this.loadCandidate(ExternalSoftware.atsRecruit);
            break;
          default:
            console.warn(`Methode ${methode} ist nicht implementiert.`);
            break;
        }
        return Promise.resolve();
      } catch (error) {
        console.error(
          `Fehler beim Ausführen der Methode ${methode} für Kandidat ${this.candidate.lastName}:`,
          error
        );
        return Promise.reject(error);
      }
    },
    calculateAge(birthdayISO: string) {
      const birthday = moment(birthdayISO);
      const today = moment();
      const age = today.diff(birthday, "years");

      this.candidateAge = age;
    },
    async changeAtsStatus(targetStatus: string): Promise<string | null> {
      let result = await AllAtsAdapter.changeAtsStatus(
        this.candidate.candidateUuid,
        this.candidate.applicationUuid,
        targetStatus
      );

      if (result === StatusResponse.manual) {
        result = await this.changeAtsStatusManualWithLink();
      }

      return result;
    },
    async changeAtsStatusManualWithLink() {
      const candidateLink = AllAtsAdapter.atsCandidateLink(
        this.candidate.candidateUuid,
        this.candidate.applicationUuid
      );
      const confirmed = await DialogService.confirm(
        `Statuswechsel für #${this.candidate.applicationId}: ${this.candidateData?.firstName} ${this.candidateData?.lastName} manuell durchführen?`,
        "Nein",
        "Kandidat in ATS öffnen"
      );
      if (confirmed) {
        window.open(candidateLink, "_blank", "width=950,height=900"); //Width 950 supress zvoove Recruits Side Menu and TODO: use responsive dimensions from service
        return StatusResponse.atsSuccess;
      } else {
        return null;
      }
    },
    changeMatchedApplicationStatus(
      newStatus: string,
      atsStatusUuid?: string,
      atsNewStatus?: string,
      atsInternalStatusSystemName?: string
    ) {
      const application = this.candidateData?.applications.find(
        (application) => application.uuid === this.candidate.applicationUuid
      );
      if (application) {
        application.status = newStatus;
        if (atsNewStatus) application.ats.status = atsNewStatus;
        if (atsStatusUuid) application.ats.statusUuid = atsStatusUuid;
        if (atsInternalStatusSystemName)
          application.ats.internalStatusSystemName =
            atsInternalStatusSystemName;
        this.updateCandidateData();
      }
    },
    changeStatus(targetStatus: string) {
      if (this.candidate.status === targetStatus) {
        ToastService.show("Status ist bereits: " + targetStatus);
        return;
      }
      if (
        this.softwareIntegration.atsDeterminesStatus &&
        AllAtsAdapter.hasAtsSystem()
      ) {
        this.changeAtsStatus(targetStatus).then((result) => {
          if (result === StatusResponse.atsSuccess) {
            this.changeMatchedApplicationStatus(
              targetStatus,
              undefined,
              targetStatus
            );
            this.actionsFromStatusChange(targetStatus);
          } else {
            ToastService.showError(
              "Statuswechsel in ATS System hat nicht geklappt..."
            );
          }
        });
        return;
      } else if (
        AllAtsAdapter.hasAtsSystem() &&
        !this.softwareIntegration.atsStatusIgnored
      ) {
        this.changeAtsStatus(targetStatus);
      }
      this.changeMatchedApplicationStatus(targetStatus);
      this.actionsFromStatusChange(targetStatus);
    },
    async checkAtsStatus() {
      if (
        !AllAtsAdapter.hasAtsSystem() ||
        this.softwareIntegration.atsStatusIgnored
      )
        return;
      const atsStatus = this.matchedApplication.ats?.status;
      const status = this.candidate.status;
      let externalAtsStatus = {
        statusUuid: "",
        status: "",
        internalStatusSystemName: "",
      };
      // Get new ATS status if atsStatus is unknown
      if (atsStatus === "unknown" && AllAtsAdapter.hasAtsSystem()) {
        ToastService.show(
          `ATS Status von ${this.candidate.firstName} ${this.candidate.lastName} passt nicht zum gespeicherten Status - rufe neue Daten ab!`
        );

        const { error, externalAtsStatus } =
          await AllAtsAdapter.getAtsStatusForCandidate(
            this.candidate,
            this.candidate.applicationUuid
          );

        if (externalAtsStatus) {
          ToastService.showReminder(
            `Ändere Status '${this.candidate.status}' von ${this.candidate.firstName} ${this.candidate.lastName} auf den ATS Recruit Status '${externalAtsStatus.status}'`
          );
          this.isAtsStatusChangedFromUnknown = true;
          this.changeMatchedApplicationStatus(
            externalAtsStatus.status,
            externalAtsStatus.statusUuid,
            externalAtsStatus.status,
            externalAtsStatus.internalStatusSystemName
          );
          return;
        } else if (error) {
          return;
        }
      }

      if (
        this.softwareIntegration.atsDeterminesStatus &&
        externalAtsStatus.status
      ) {
        this.changeMatchedApplicationStatus(
          externalAtsStatus.status,
          externalAtsStatus.statusUuid,
          externalAtsStatus.status,
          externalAtsStatus.internalStatusSystemName
        );
        return;
      }

      if (
        this.softwareIntegration.atsDeterminesStatus &&
        status !== atsStatus &&
        atsStatus
      ) {
        this.changeMatchedApplicationStatus(atsStatus);
      } else if (status !== atsStatus && atsStatus) {
        const confirmed = await DialogService.confirm(
          `Der Kandidat #${this.candidate.applicationId}: ${this.candidateData?.firstName} ${this.candidateData?.lastName} hat im ATS System den Status ${atsStatus} statt ${status}.`,
          "Beibehalten",
          "Status in ATS ändern!"
        );
        if (confirmed) this.changeAtsStatus(status);
      }
    },
    checkForCandidateDataInput() {
      if (this.candidateDataInput) {
        this.candidateData = this.candidateDataInput;
        this.isExpanded = true;
        this.isFullyExpanded = true;
        this.prepareFilteredPhoneNumbers(this.candidateDataInput);
        this.prepareFilteredEmail(this.candidateDataInput);
      }
    },
    async checkForFullLoad() {
      if (!this.candidate.lastName && !this.candidateData?.lastName) {
        await this.loadCandidate(ExternalSoftware.atsRecruit);
      } else if (!this.candidateData?.lastName) {
        await this.loadCandidate();
      }
    },
    async clearMatchingOject() {
      this.setICM({
        message: InterComponentMessage.matchMeFromCandidate,
        payload: {
          postcodes: "",
          tags: "",
        },
      });
      this.$store.state.isActiveCandidateMatching = false;
      this.closeContextMenu();
    },
    collapseItem() {
      this.isFullyExpanded = false;
      this.isExpanded = false;
    },
    createNewMandantAppointmentOnDependencies() {
      this.isDependenciesActive = true;
      const mandantName = this.$store.getters.getMandantNameByUuid(
        this.matchedApplication.mandantUuid
      );
      this.linkingDescription = {
        firstName: this.candidateData?.firstName,
        lastName: this.candidateData?.lastName,
        postCode: this.candidateData?.addressPostalCode,
        city: this.candidateData?.addressCity,
        customerName: mandantName,
        uuid: this.candidateData?.uuid,
      } as LinkingDescription;
      this.$nextTick(() => {
        if (this.$refs.dependenciesComponent) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).createNewAppointment();
        }
      });
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    deactivateSubcomponents() {
      this.isDialogEditCandidateActive = false;
      this.isDialogInterviewCompletedActive = false;
      this.isDialogManualAnzeigendatenSearchActive = false;
    },
    async deleteCandidate(uuid?: string) {
      let id = this.candidate.parentObjectid as string;
      if (this.candidateData && this.candidateData._id)
        id = this.candidateData._id;
      const confirmed = await DialogService.confirm(
        `Den Kandidat #${this.candidate.applicationId}: ${this.candidateData?.firstName} ${this.candidateData?.lastName} wirklich löschen?`,
        "Abbrechen",
        "Löschen"
      );
      if (confirmed && this.candidateData && this.candidateData._id) {
        if (this.$store.getters.hasLinkingForCandidate(id)) {
          const linkingService = new LinkingService();
          const responseLinking =
            await linkingService.deleteLinkingsByTypeAndId(Role.candidate, id);
          if (responseLinking.error) {
            ToastService.showError(
              "Fehler beim Löschen des Kunden: Verlinkungen konnten nicht entfernt werden!" +
                responseLinking.error
            );
            return;
          }
        }
        if (uuid) {
          this.candidateService.deleteCandidateByUuid(uuid).then(() => {
            this.$emit(CandidateItemEmits.loadCandidates);
          });
          return;
        }
        this.candidateService
          .removeCandidate(this.candidateData._id)
          .then(() => {
            this.$emit(CandidateItemEmits.loadCandidates);
          });
      }
    },
    deleteCandidateFromWpPlugin() {
      if (!this.softwareIntegration.wordPressPlugin) {
        return;
      }
      if (this.$refs.sendCandidateToWpComponent) {
        (
          this.$refs.sendCandidateToWpComponent as InstanceType<
            typeof SendCandidateToWp
          >
        ).deleteCandidateFromWP(this.candidate.applicationId);
      }
      if (this.candidateData && this.candidateData.isPublishedOnWebsite) {
        this.candidateData.isPublishedOnWebsite = false;
      }
      this.updateCandidateData;
    },
    emitToggleItemsMinimized() {
      this.$emit(CandidateItemEmits.toggleItemsMinimized);
      this.closeContextMenu();
    },
    emitLoadCandidatesFull() {
      this.$emit(CandidateItemEmits.loadCandidatesFull);
      this.closeContextMenu();
    },
    async emitLoadCandidatesFromAts() {
      const confirmed = await DialogService.confirm(
        `Wirklich alle Kandidaten aus dem ATS neu Laden?`,
        "Nein",
        "Ja, alle überschreiben"
      );
      if (confirmed) {
        this.$emit(CandidateItemEmits.loadCandidatesFromAts);
      }
      this.closeContextMenu();
    },
    async fetchJobAdLeads(ai?: boolean) {
      if (!this.candidateData || !this.softwareIntegration.indexAnzeigendaten) {
        return;
      }
      let mobilityRadius = this.candidate.mobilityRadius ?? 0;
      if (this.candidateData && this.candidateData.mobilityRadius)
        mobilityRadius = this.candidateData.mobilityRadius;
      const query = {
        POSTAL_CODE: this.candidateData.addressPostalCode,
      } as JodAdLeadsQuery;
      if (mobilityRadius > 0) {
        query.SURROUNDING_REGION = mobilityRadius.toString();
      }

      if (this.careerLevel && this.careerLevel.length) {
        query.CAREER_LEVEL = this.careerLevel.join(",");
      }

      if (this.candidateData && ai) {
        SpinnerService.showSpinner();
        const anonymizedResume = this.prepareAnonymizedResume(
          this.candidateData
        );
        const aiService = new AiService();
        const response = await aiService.generateJobAdLeadsQuery(
          anonymizedResume
        );
        query.JOB_TITLE = response.jobTitle;
        query.JOB_POSTING = response.searchstrings;
      } else {
        if (this.skillsForJobAdLeads.length > 5) {
          query.JOB_POSTING = this.skillsForJobAdLeads.join(" OR ");
        }

        if (
          this.candidateData &&
          this.candidateData.tags &&
          this.candidateData.tags.length
        ) {
          query.JOB_TITLE = this.candidateData.tags.join(" OR ");
        }
      }

      try {
        const mandant = this.matchedApplication.mandantUuid;
        const leads = await this.anzeigedatenService.getLeads(query, mandant);
        if (this.candidateData && leads) {
          this.candidateData.jobAdLeads = leads;
        } else if (this.candidateData) {
          const retrievalDate = moment().format("DD.MM.YYYY");
          this.candidateData.jobAdLeads = [
            {
              adId: "notFound",
              retrievalDate: retrievalDate,
              jobTitle: "keine Anzeigen gefunden",
              jobAdText: "",
            },
          ];
        }
        this.updateCandidateData();
        SpinnerService.removeSpinner();
        if (
          this.candidateData &&
          this.candidateData.jobAdLeads &&
          this.candidateData.jobAdLeads[0].adId === "notFound" &&
          !ai
        ) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error fetching job leads:", error);
        SpinnerService.removeSpinner();
      }
    },
    async fetchJobAdLeadsManual(query: DataForJobAdLeadsQuery) {
      SpinnerService.showSpinner();
      const anzeigendatenQuery = {
        JOB_TITLE: query.tags.join(" OR "),
        POSTAL_CODE: query.postcode,
        SURROUNDING_REGION: query.radius,
        JOB_POSTING: query.skills.join(" OR "),
        CAREER_LEVEL: query.careerLevel ? query.careerLevel.join(",") : "",
      } as unknown as JodAdLeadsQuery;
      const differentPeriod = query.period;

      try {
        const mandant = this.matchedApplication.mandantUuid;
        const response = await this.anzeigedatenService.getLeads(
          anzeigendatenQuery,
          mandant,
          differentPeriod
        );
        return response;
      } catch (error) {
        ToastService.showError("Fehler beim abrufen der Anzeigendaten");
        console.error("Error fetching Index anzeigendaten:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    filterCandidate(searchTerm: string) {
      if (searchTerm === "") {
        this.showCandidate();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ");
      const candidate = this.candidateData;
      if (candidate) {
        const matches = terms.every(
          (term) =>
            this.searchCandidateObject(candidate, term) ||
            this.searchCandidateAllFields(this.candidate, term)
        );

        if (!matches) {
          this.hideCandidate();
        }
      }
    },
    //helping methods
    searchCandidateAllFields(candidate: CandidateList, term: string) {
      return Object.values(candidate).some((value) => {
        return (
          typeof value === "string" &&
          value !== null &&
          value.toLowerCase().includes(term)
        );
      });
    },
    searchCandidateObject(candidate: Candidate, term: string) {
      return (
        this.searchField(candidate.addressHouseNumber, term) ||
        (candidate.additionalAddresses &&
          candidate.additionalAddresses.some(
            (address) =>
              this.searchField(address.houseNumber, term) ||
              this.searchField(address.city, term) ||
              this.searchField(address.postalCode, term) ||
              this.searchField(address.street, term)
          )) ||
        this.searchField(candidate.addressCity, term) ||
        this.searchField(candidate.addressPostalCode, term) ||
        this.searchField(candidate.addressStreet, term) ||
        (candidate.communicationMeans &&
          candidate.communicationMeans.some(
            (communicationMean) =>
              this.searchField(communicationMean.description, term) ||
              this.searchField(communicationMean.value, term)
          )) ||
        this.searchField(candidate.lastName, term) ||
        (candidate.skills &&
          candidate.skills.some((skill) =>
            this.searchField(skill.description, term)
          )) ||
        this.searchField(candidate.firstName, term) ||
        (candidate.careerSteps &&
          candidate.careerSteps.some(
            (careerStep) =>
              this.searchField(careerStep.at, term) ||
              this.searchField(careerStep.details, term) ||
              this.searchField(careerStep.title, term)
          )) ||
        (candidate.tags &&
          candidate.tags.some((tag) => this.searchField(tag, term)))
      );
    },
    searchField(field: any, term: string) {
      return field && field.toLowerCase().includes(term);
    },
    findStatusOptionByStatus(
      statusToFind: string
    ): RecruitStatusOption | undefined {
      return this.statusOptionsRecruit.find((option: RecruitStatusOption) =>
        Array.isArray(option.status)
          ? option.status.includes(statusToFind)
          : option.status === statusToFind
      );
    },
    async generateAndSetCandidateTags() {
      if (this.candidateData) {
        const anonymizedResume = this.prepareAnonymizedResume(
          this.candidateData
        );
        try {
          const aiService = new AiService();
          const tags = await aiService.generateCandidateTags(anonymizedResume);
          this.candidateData.tags = tags;
          await this.updateCandidateData();
        } catch (error) {
          console.error("Error generating candidate tags:", error);
        }
      }
    },
    getCareerLevelDescription() {
      if (!this.candidateData || Object.keys(this.candidateData).length === 0) {
        return;
      }
      if (
        !this.candidateData.careerLevel ||
        this.candidateData.careerLevel.length === 0
      ) {
        return null;
      }

      const firstLevelIndex = this.candidateData.careerLevel[0];
      const lastLevelIndex =
        this.candidateData.careerLevel[
          this.candidateData.careerLevel.length - 1
        ];

      const firstLevel = CareerLevel[firstLevelIndex];
      const lastLevel = CareerLevel[lastLevelIndex];

      if (firstLevelIndex === lastLevelIndex) {
        return `${firstLevel}`;
      } else {
        return `Karrierestufen von ${firstLevel} bis ${lastLevel}`;
      }
    },
    async getCustomerPostcodesInMobilityRange() {
      if (!this.candidateData || !this.candidateData.addressPostalCode) {
        console.error("AdressePlz ist nicht definiert.");
        return [];
      }

      const baseZipcode = this.candidateData.addressPostalCode;
      const targetZipcodes = this.$store.state.postcodesCustomers;

      try {
        const distances: ZipCodeDistance[] =
          await this.zipCodeService.getDistances(baseZipcode, targetZipcodes);
        let mobilityRadius = this.candidate.mobilityRadius ?? 0;
        if (this.candidateData && this.candidateData.mobilityRadius)
          mobilityRadius = this.candidateData.mobilityRadius;
        const filteredZipcodes = distances
          .filter(
            (distance: ZipCodeDistance) => distance.distance <= mobilityRadius
          )
          .map((distance: ZipCodeDistance) => distance.targetZipcode);

        const uniqueZipcodes = [...new Set(filteredZipcodes)];
        return uniqueZipcodes;
      } catch (error) {
        console.error("Fehler beim Abrufen der Distanzen: ", error);
        return [];
      }
    },
    handleDoubleClickOnItem() {
      if (this.isOpenedAsDialog()) return;
      if (!this.isExpanded) {
        this.toggleExpansion();
      } else if (this.isExpanded && !this.isFullyExpanded) {
        this.toggleFullExpansion();
      } else if (this.isFullyExpanded) {
        this.toggleExpansion();
      }
    },
    handleDragStart(event: DragEvent) {
      this.checkForFullLoad().then(() => {
        if (event.dataTransfer && this.candidateData) {
          event.dataTransfer.setData(
            "application/originComponent",
            Role.candidate as string
          );
          this.sendCandidateToGenerateProfile();
        }
      });
    },
    handleCandidateStatusUpdate(statusObject: AllLinkingsStatusObject) {
      this.linkingStatuses = statusObject.lastEvents;
      this.updateStatusAndColor(statusObject.highestPriorityStatus);
    },
    handlePublishedOnWebseiteChange() {
      if (!this.softwareIntegration.wordPressPlugin) {
        return;
      }
      if (
        this.candidateData &&
        this.candidateData.salutationCatalogId !== undefined
      ) {
        const gender = Gender[this.candidateData.salutationCatalogId] || "";
        let qualification = "";
        if (
          this.candidateData.careerLevel &&
          Object.keys(this.candidateData.careerLevel).length > 0
        ) {
          qualification = this.getCareerLevelDescription() ?? "";
        }
        if (this.candidateData.isPublishedOnWebsite) {
          let mobilityRadius = this.candidate.mobilityRadius ?? 0;
          if (this.candidateData && this.candidateData.mobilityRadius)
            mobilityRadius = this.candidateData.mobilityRadius;
          const wpCandidateData = {
            title: this.matchedApplication.appliedAs,
            profileId: this.candidate.applicationId,
            gender: gender,
            age: this.candidateAge.toString(),
            residence: this.candidateData.addressCity,
            mobility: `${this.candidateData.mobility}: ${mobilityRadius} km`,
            shift: this.candidateData.shiftPreference,
            qualification: qualification,
            licenses: this.licences.replace(/<br>/g, ", "),
            experience: this.candidateData.tags.join(", "),
            anonymizedResume: this.prepareAnonymizedResume(this.candidateData),
          };

          if (this.$refs.sendCandidateToWpComponent) {
            (
              this.$refs.sendCandidateToWpComponent as InstanceType<
                typeof SendCandidateToWp
              >
            ).openModal(wpCandidateData);
          }
          this.candidateData.isPublishedOnWebsite = true;
          this.updateCandidateData();
        } else if (this.candidateData.isPublishedOnWebsite === false) {
          this.deleteCandidateFromWpPlugin();
        }
      }
    },
    async handleSaveResumeInterview(resumeText: string) {
      if (!this.candidateData) return;
      await this.saveInterviewResult(resumeText);
      await this.loadCandidate();
      this.candidateData.isPublishedOnWebsite = true;
      await this.updateCandidateData();
      this.handlePublishedOnWebseiteChange();
    },
    handleSendCandidateToGenerateProfile() {
      if (!this.candidateData?._id) {
        this.loadCandidate().then(() => {
          this.sendCandidateToGenerateProfile();
        });
      } else {
        this.sendCandidateToGenerateProfile();
      }
    },
    hideCandidate() {
      this.isHided = true;
    },
    async initializeComponent() {
      this.checkForCandidateDataInput();
      this.setDefaultStatus();
      this.autoCheckForJobAdLeads();
      if (
        this.candidateData &&
        this.candidateData.notes &&
        this.candidateData.notes.interviewResult
      ) {
        this.interviewResultEdit = this.candidateData.notes.interviewResult;
      } else {
        this.interviewResultEdit = "";
      }
      if (this.candidate.birthDate) this.calculateAge(this.candidate.birthDate);
      this.$nextTick().then(() => {
        if (this.$refs.dependenciesComponent) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).loadLinkingsFromStore();
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).checkForReminders();
        }
      });
    },
    isCandidateActiveForPlacement(): boolean {
      if (!this.candidateData) {
        return false;
      }

      if (this.candidateData.isPublishedOnWebsite === undefined) {
        this.candidateData.isPublishedOnWebsite = false;
      }

      if (
        this.candidate.status === this.statusOptionsRecruit[0].value ||
        this.candidate.status === this.statusOptionsRecruit[1].value
      ) {
        return false;
      }

      return true;
    },
    isOpenedAsDialog(): boolean {
      return !!this.candidateDataInput;
    },
    async getCandidateDataFromATS() {
      try {
        const atsCandidate = await AllAtsAdapter.getCandidateData(
          this.candidate
        );

        if (atsCandidate) {
          const mergedCandidate = this.candidateService.mergeCandidateData(
            this.candidateData,
            atsCandidate
          );

          const response = await this.candidateService.postCandidate(
            mergedCandidate
          );
          return response;
        } else {
          ToastService.showError(
            "Kandidatendaten vom ATS konnten nicht abgerufen werden."
          );
        }
      } catch (error) {
        ToastService.showError(
          "Fehler beim Abrufen der Kandidaten-Daten vom ATS."
        );
        throw error;
      }
    },
    async getCandidateData() {
      try {
        const response = await this.candidateService.getCandidateByUuid(
          this.candidate.candidateUuid
        );
        if (response?._id && !this.candidate.parentObjectid) {
          const candidate = this.candidate;
          candidate.parentObjectid = response._id;
          this.$emit(CandidateItemEmits.updateCandidate, candidate);
        }
        return response as Candidate;
      } catch (error) {
        console.error(
          `failed loading candidate #${this.candidate?.applicationId}`,
          error
        );
        throw error;
      }
    },
    getItemHeight(): string {
      const { fullyExpanded, expanded, collapsed } = this.vStyle.itemHeight;

      if (this.isFullyExpanded) return fullyExpanded;
      if (this.isExpanded) return expanded;

      return collapsed;
    },
    isStatusInterview() {
      const statusArray = this.$store.getters.getStatusByValue(
        this.selectedStatus
      );
      return statusArray.includes(LinkingStatus.interview);
    },
    loadAvatarFromAtsRecruit() {
      if (
        this.candidate &&
        this.$store.state.company.apiKeys.zvooveNextLevel &&
        this.candidate.avatarUuid
      ) {
        AllAtsAdapter.getCandidateAvatar(this.candidate.avatarUuid).then(
          (response) => {
            if (response) this.avatar = URL.createObjectURL(response);
          }
        );
      }
    },
    async loadCandidate(sourceCandidate?: string) {
      try {
        if (sourceCandidate) {
          this.isLoadingFromAts = true;
        } else {
          SpinnerService.showSpinner();
        }
        let data = {} as Candidate | undefined;
        if (sourceCandidate === ExternalSoftware.atsRecruit) {
          data = await this.getCandidateDataFromATS();
          ToastService.show(
            `Recruit Daten von ${data?.firstName} ${data?.lastName} aktualisiert!`
          );
        } else {
          data = await this.getCandidateData();
          if (!data?._id) {
            data = await this.getCandidateDataFromATS();
          }
        }
        if (data) this.prepareFilteredPhoneNumbers(data);
        if (data) this.prepareFilteredEmail(data);
        if (data) this.prepareLicensesAndSkillForJobLeads(data);
        if (data && data.birthDate) {
          this.calculateAge(data.birthDate);
        }
        if (data)
          this.$emit(CandidateItemEmits.updatePostcode, data.addressPostalCode);
        this.candidateData = data;
        if (data && data.notes?.interviewResult)
          this.interviewResultEdit = data.notes.interviewResult;
      } catch (error: any) {
        console.error("Fehler beim Abrufen der Bewerberdaten:", error);
      } finally {
        if (sourceCandidate) {
          this.isLoadingFromAts = false;
        } else {
          SpinnerService.removeSpinner();
        }
      }
    },
    openAnzeigendatenManualSearch() {
      this.isDialogManualAnzeigendatenSearchActive = true;
      this.$nextTick().then(() => {
        if (this.$refs.dialogManualAnzeigendatenSearchComponent) {
          (
            this.$refs.dialogManualAnzeigendatenSearchComponent as InstanceType<
              typeof DialogManualAnzeigendatenSearch
            >
          ).openModal();
        }
      });
    },
    openApplicationFormDispatcherMode() {
      if (this.$refs.dialogApplicationFormComponent) {
        (
          this.$refs.dialogApplicationFormComponent as InstanceType<
            typeof DialogApplicationForm
          >
        ).openModal(true);
      }
    },
    openContextMenu(event: MouseEvent) {
      if (!this.candidateData?._id) this.loadCandidate();
      event.preventDefault();

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const menuWidth = 250;
      const menuHeight = 280;

      let xPos = event.clientX;
      let yPos = event.clientY;

      if (xPos + menuWidth > windowWidth) {
        xPos = windowWidth - menuWidth;
      }

      if (yPos + menuHeight > windowHeight) {
        yPos = windowHeight - menuHeight;
      }

      if (xPos < 0) {
        xPos = 0;
      }
      this.contextMenuPosition = { x: xPos, y: yPos };
      this.showContextMenu = true;
    },
    openEditCandidateModal() {
      this.isDialogEditCandidateActive = true;
      this.$nextTick().then(() => {
        if (this.$refs.dialogEditCandidateComponent) {
          (
            this.$refs.dialogEditCandidateComponent as InstanceType<
              typeof DialogEditCandidate
            >
          ).openModal();
        }
      });
      this.closeContextMenu();
    },
    openPhoneClient() {
      this.isExpanded = true;
      this.$nextTick().then(() => {
        this.updateComponent().then(() => {
          if (this.$refs.phoneClientComponent) {
            (
              this.$refs.phoneClientComponent as InstanceType<
                typeof PhoneClient
              >
            ).handleOutgoingCall();
          }
        });
      });
    },
    phoneClientIsDialing() {
      if (this.$refs.dependenciesComponent) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).waitForPhoneClientDialing();
      }
    },
    prepareAnonymizedResume(data: Candidate) {
      const salutation = Salutation[data.salutationCatalogId];

      const anonymizedResume = {
        tags: data.tags || [],
        addressCity: data.addressCity,
        addressPostalCode: data.addressPostalCode,
        addressStreet: data.addressStreet,
        maritalStatus: data.maritalStatus ? data.maritalStatus : "",
        birthDate: data.birthDate,
        birthPlace: data.birthPlace,
        nationality: data.nationality ? data.nationality : "",
        salutation: salutation,
        skills: data.skills.map((skill: Skill) => skill.description),
        careerSteps: data.careerSteps.map((careerStep: CareerStep) => ({
          title: careerStep.title,
          from: careerStep.from,
          until: careerStep.until,
          at: careerStep.at,
          degree: careerStep.degree,
          type: careerStep.type ? careerStep.type.description : "",
          details: careerStep.details,
        })),
      };
      return JSON.stringify(anonymizedResume);
    },
    prepareDataForJobAdLeadsQuery(): DataForJobAdLeadsQuery {
      if (!this.candidateData || !this.candidateData.skills) {
        return {
          tags: [],
          postcode: "",
          radius: 0,
          period: this.softwareIntegration.indexJobLeadsAgeLimitDays ?? 30,
          skills: [],
          careerLevel: [],
        };
      }
      const skills = this.candidateData.skills
        .filter(
          (skill: any) =>
            skill.groupDescription !== "Schichtbereitschaft" &&
            skill.groupDescription !== "Mobilitäten"
        )
        .map((skill: any) => skill.description);

      let mobilityRadius = this.candidate.mobilityRadius ?? 0;
      if (this.candidateData && this.candidateData.mobilityRadius)
        mobilityRadius = this.candidateData.mobilityRadius;

      const dataForJobAdLeadsQuery = {
        tags: this.candidateData.tags ?? [],
        postcode: this.candidateData.addressPostalCode ?? "",
        radius: mobilityRadius,
        period:
          this.$store.state.company.softwareIntegration
            .indexJobLeadsAgeLimitDays ?? 30,
        skills: skills,
        careerLevel: this.candidateData.careerLevel ?? [],
      } as DataForJobAdLeadsQuery;

      return dataForJobAdLeadsQuery;
    },
    prepareFilteredEmail(data: Candidate) {
      this.filteredEmail =
        data?.communicationMeans
          ?.filter(
            (c: CommunicationMeans) => c.type === CommunicationType.Email
          )
          .map((c: CommunicationMeans) => {
            const salutationText = getSalutationText(data.salutationCatalogId);
            return {
              mailaddress: c.value,
              salutation: `Guten Tag ${salutationText} ${data.firstName} ${data.lastName},`,
            };
          }) || [];
    },
    prepareFilteredPhoneNumbers(data: Candidate) {
      this.filteredPhoneNumbers =
        data?.communicationMeans
          ?.filter(
            (c: CommunicationMeans) =>
              c.type === CommunicationType.Phone ||
              c.type === CommunicationType.Mobile ||
              c.type === CommunicationType.WorkMobile ||
              c.type === CommunicationType.WhatsApp
          )
          .map((c: CommunicationMeans) => ({
            label: c.description,
            value: c.value.replace(/ /g, ""), // delete "space" between the numbers
          })) || [];
    },
    prepareLicensesAndSkillForJobLeads(data: Candidate) {
      this.licences = "";
      if (data?.skills) {
        for (let skill of data.skills) {
          if (skill.groupDescription === "Führerscheine") {
            if (this.licences.length > 0) {
              this.licences += "<br>";
            }
            this.licences += skill.description;
          } else {
            this.skillsForJobAdLeads.push(skill.description);
          }
        }
      }
    },
    async removeTag(index: number) {
      if (!this.candidateData) return;
      this.candidateData.tags.splice(index, 1);
      await this.updateCandidateData();
    },
    async saveInterviewResult(resumeText: string) {
      if (!this.candidateData) return;
      if (!this.candidateData.notes) {
        this.candidateData.notes = {
          interviewResult: "",
          information: "",
        };
      }
      this.candidateData.notes.interviewResult = resumeText;
      await this.updateCandidateData();
      ToastService.show("Ergebnis Vorstellungstermin gespeichert!");
    },
    async searchAnzeigendatenAndAdd(query: DataForJobAdLeadsQuery) {
      if (!this.candidateData) return;

      try {
        const response = await this.fetchJobAdLeadsManual(query);

        if (!this.candidateData.jobAdLeads) {
          this.candidateData.jobAdLeads = [];
        }

        const existingAdIds = new Set(
          this.candidateData.jobAdLeads.map((ad: JobAdLead) => ad.adId)
        );

        const newJobAds = (response || []).filter((ad: JobAdLead) => {
          return !existingAdIds.has(ad.adId);
        });

        this.candidateData.jobAdLeads = [
          ...this.candidateData.jobAdLeads,
          ...newJobAds,
        ];

        await this.updateCandidateData();

        ToastService.showSuccess("Neue Job Leads hinzugefügt!");
      } catch (error) {
        console.error("Error adding job ads:", error);
      }
    },
    async searchAnzeigendatenAndReplace(query: DataForJobAdLeadsQuery) {
      if (this.candidateData) {
        const response = await this.fetchJobAdLeadsManual(query);
        this.candidateData.jobAdLeads = response;
        await this.updateCandidateData();
      }
    },
    sendCandidateToGenerateProfile() {
      if (!this.candidateData) return;
      this.$store.commit(MutationType.clearDraggingItem);
      let resultInterview =
        "Keine Zusammenfassung vom Vorstellungsgespräch vorhanden";
      if (
        this.candidateData.notes &&
        this.candidateData.notes.interviewResult
      ) {
        resultInterview = this.candidateData.notes.interviewResult;
      }
      const salutation = Salutation[this.candidateData.salutationCatalogId];

      const draggingData = {
        age: this.candidateAge.toString(),
        anonymizedResume: this.prepareAnonymizedResume(this.candidateData),
        appliedAs: this.matchedApplication.appliedAs,
        candidateData: this.candidateData as Candidate,
        licences: this.licences,
        notes: resultInterview,
        profileNumber: this.candidate.applicationId,
        salutation: salutation,
        candidateMandantUuid: this.matchedApplication.mandantUuid,
      } as CandidateToCustomerData;

      this.$store.commit(MutationType.setDraggingItem, {
        type: Role.candidate,
        data: draggingData,
      });
    },
    sendCandidateToWpAborted() {
      if (this.candidateData) {
        this.candidateData.isPublishedOnWebsite = false;
      }
    },
    setAppointmentOnDependencies() {
      if (!this.hasLinking(this.candidate.parentObjectid)) {
        this.isDependenciesActive = true;
        this.$nextTick(() => {
          if (this.$refs.dependenciesComponent && this.candidateData?._id) {
            (
              this.$refs.dependenciesComponent as InstanceType<
                typeof Dependencies
              >
            ).linkCandidateToMandant(
              this.candidateData?._id,
              this.matchedMandantUuid
            );
          }
        });
      } else {
        if (this.$refs.dependenciesComponent && this.candidateData?._id) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).openAppointmentModal();
        }
      }
    },
    setCandidateToMandantFromDependencies(data: {
      status: string;
      dates?: string[];
    }): void {
      const statusMatch = this.findStatusOptionByStatus(data.status);
      let statusDates = "";
      if (data.dates && data.dates.length > 0) {
        statusDates = data.dates.join(", ");
      }
      this.message.body = `${status} ${statusDates}`;

      if (statusMatch) {
        this.changeStatus(statusMatch.value);
        const formattedAppointments = data.dates
          ? data.dates
              .map((date) => moment(date).format("DD.MM.YYYY [um] HH:mm [Uhr]"))
              .join(", ")
          : "";
        this.setMessageTemplate(data.status, formattedAppointments);
        if (statusMatch.status[0] === LinkingStatus.interviewCompleted) {
          this.loadCandidate();
          (this.isDialogInterviewCompletedActive = true),
            this.$nextTick().then(() => {
              // We do that explicit NOT in this.actionsFromStatusChange() it has to start ONLY in this case
              if (this.$refs.dialogInterviewCompletedComponent) {
                (
                  this.$refs.dialogInterviewCompletedComponent as InstanceType<
                    typeof DialogInterviewCompleted
                  >
                ).openSkillsModal();
              }
            });
        }
      } else {
        ToastService.showError("Kein entsprechender Status gefunden.");
      }
    },
    setDefaultStatus() {
      const defaultStatus = LinkingStatus.noneProfile;
      this.updateStatusAndColor(defaultStatus);
    },
    setMessageTemplate(candidateStatus: string, appointments?: string) {
      let subjectTemplate = "";
      let messageTemplate = "";

      const templates = this.mailTemplates;
      const matchingTemplate = templates.find(
        (template: {
          candidateStatus: string;
          subject: string;
          text1: string;
          text2: string;
        }) => template.candidateStatus === candidateStatus
      );

      if (matchingTemplate) {
        const mandant = this.$store.getters.getMandantByUuid(
          this.matchedMandantUuid
        );
        const mandantAddress = mandant
          ? mandant.contact.replace(/<br>/g, "\n")
          : "Adresse nicht verfügbar";
        if (
          matchingTemplate.candidateStatus === LinkingStatus.interview ||
          matchingTemplate.candidateStatus === LinkingStatus.contractDate
        ) {
          subjectTemplate = `${matchingTemplate.subject} am ${appointments}`;
          messageTemplate = `\n${matchingTemplate.text1}\n\nam ${appointments}\n\n${mandant.name}\n${mandantAddress}\n\n${matchingTemplate.text2}`;
        } else {
          subjectTemplate = matchingTemplate.subject;
          messageTemplate = `\n${matchingTemplate.text1}\n${matchingTemplate.text2}`;
        }
      } else {
        console.error(
          "Keine passende E-Mail Vorlage gefunden für den Status: ",
          candidateStatus
        );
      }
      this.message = {
        subject: subjectTemplate,
        body: messageTemplate,
      };

      if (this.$refs.mailClientComponent) {
        (
          this.$refs.mailClientComponent as InstanceType<typeof MailClient>
        ).openModal();
      }

      ToastService.show(
        `Eine Nachricht ist im Messenger für Versand vorbereitet: Betreff: ${subjectTemplate}`
      );
    },
    showCandidate() {
      this.isHided = false;
    },
    async toggleExpansion() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.isFullyExpanded = false;
      }
      if (this.isExpanded) {
        this.updateComponent();
      } else {
        this.deactivateSubcomponents();
      }
    },
    async toggleFullExpansion() {
      if (this.isExpanded) {
        this.isFullyExpanded = !this.isFullyExpanded;
      }
    },
    updateCareerLevels(data: IaCareerLevel[]) {
      if (this.candidateData) {
        this.candidateData.careerLevel = data;
        this.updateCandidateData();
      }
    },
    async updateComponent() {
      SpinnerService.showSpinner();
      try {
        this.loadCandidate().then(() => {
          this.checkAtsStatus().then(() => {
            if (
              this.candidateData &&
              (!this.candidateData.tags ||
                this.candidateData.tags.length === 0 ||
                this.candidateData.careerSteps.length === 0)
            ) {
              if (
                this.candidateData.careerSteps &&
                this.candidateData.careerSteps.length > 0
              ) {
                this.generateAndSetCandidateTags();
              }
            }
            if (this.$refs.dependenciesComponent) {
              (
                this.$refs.dependenciesComponent as InstanceType<
                  typeof Dependencies
                >
              ).loadLinkingsFromStore();
            }
            this.autoCheckForJobAdLeads();
          });
        });
      } catch (error) {
        console.error("Error updating CandidateItem:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    updateStatusAndColor(status: LinkingStatus) {
      const statusColors = this.$store.state.company.candidateStatusColor;
      let color = "";

      switch (status) {
        case LinkingStatus.interviewSuggestionExternal:
          this.needToContact = true;
          color = statusColors[LinkingStatus.interviewExternal];
          break;
        case LinkingStatus.trailWorkSuggestionExternal:
          this.needToContact = true;
          color = statusColors[LinkingStatus.trailWorkExternal];
          break;
        case LinkingStatus.jobofferExternal:
          this.needToContact = true;
          color = statusColors[status];
          break;
        default:
          this.needToContact = false;
          color = statusColors[status] || "#244578";
      }

      this.statusColor = color;
      // for sort option in column
      const candidate = this.candidate;
      candidate.linkingStatus = status;
      this.$emit(CandidateItemEmits.updateCandidate, candidate);
    },
    async updateCandidateData() {
      if (this.candidateData) {
        const candidateData = this.candidateData;
        this.candidateService
          .updateCandidateByUuid(this.candidate.candidateUuid, candidateData)
          .then((candidateDataFromBackend) => {
            this.candidateData = candidateDataFromBackend.response;
            this.prepareFilteredPhoneNumbers(candidateData);
            this.prepareFilteredEmail(candidateData);
            this.prepareLicensesAndSkillForJobLeads(candidateData);
          });
      }
    },
    updateChecklist(checklist: CheckList[]) {
      if (!this.candidateData) return;
      this.candidateData.checklist = checklist;
      this.updateCandidateData();
    },
  },
});
</script>

<style scoped>
.status {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: -webkit-fill-available;
  z-index: 10;
}
.status-list {
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 60%;
  z-index: 10;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    background-color: var(--color-primary);
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1.5s infinite;
}
.appointment-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.appointment-icon:hover {
  color: var(--color-tertiary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.application-form-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.application-form-icon:hover {
  color: var(--color-tertiary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.save-interview-result {
  font-size: 3rem;
  cursor: pointer;
  top: -2.4rem;
  right: 1rem;
  color: var(--color-primary);
  transition: all 0.3s ease;
}

.save-interview-result:hover {
  color: var(--color-tertiary);
}

.zvoove-recuit-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.8rem;
  height: 1.8rem;
}
.zvoove-recuit-icon:hover {
  scale: var(--medium-scale-up);
}
.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
