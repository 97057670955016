import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "ml-1" }
const _hoisted_2 = { class: "progress-bar-container mt-0" }
const _hoisted_3 = { class: "score-display" }
const _hoisted_4 = {
  key: 0,
  class: "floating-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.softwareIntegration.gameBar)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onMouseover: _cache[0] || (_cache[0] = ($event: any) => {
      _ctx.isClosed = false;
      _ctx.updateUserScore();
    }),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isClosed = true)),
          class: "open-gamebar-container-hover"
        }, null, 32))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, {
      class: _normalizeClass(["gamebar-container ma-0 pa-0", { 'is-hidden': !_ctx.isClosed }])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          color: "primary-darken-1",
          class: "ma-0 pr-4 rounded-b-lg",
          elevation: 1
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_TransitionGroup, {
                  name: "score-float",
                  tag: "div"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.floatingScores, (score, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "floating-score"
                      }, " +" + _toDisplayString(score), 1))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_1, "Level " + _toDisplayString(_ctx.todayLevel), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", null, "Score: " + _toDisplayString(_ctx.userScore), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["progress-bar", { growing: _ctx.isGrowing }]),
                    style: _normalizeStyle({
              width: _ctx.progressBarWidth + '%',
              backgroundColor: _ctx.getProgressBarColor,
            }),
                    "data-tid": "gamebar-progress-bar"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bubbles, (bubble) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: bubble.id,
                        style: _normalizeStyle(bubble.style),
                        class: "bubble"
                      }, null, 4))
                    }), 128))
                  ], 6)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_TransitionGroup, {
          name: "message-float",
          tag: "div"
        }, {
          default: _withCtx(() => [
            (_ctx.floatingMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.floatingMessage), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}