<template>
  <v-sheet
    class="d-flex justify-space-between pa-1"
    color="primary-darken-1"
    v-if="isLoggedIn"
  >
    <div v-if="recurionChatIsActive" style="width: 5rem"></div>
    <div style="max-height: 2rem">
      <img
        class="menu-icon"
        :src="chipIcon"
        :style="{
          width: '1.5rem',
          height: '1.5rem',
          objectFit: 'contain',
        }"
      /><v-tooltip activator="parent" location="left">Recurion</v-tooltip>
      <v-menu activator="parent">
        <v-list>
          <v-list-item @click="openKnowledgeBase()">
            <template v-slot:prepend>
              <v-icon>fa-brands fa-youtube</v-icon>
            </template>
            <v-list-item-title>Grundkurs: Videos</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openRecurionChat()">
            <template v-slot:prepend>
              <v-icon>fa-brands fa-rocketchat</v-icon>
            </template>
            <v-list-item-title>Recurion Chat</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-btn
      v-if="getUserRole() === 0 && !mdAndDown"
      @click="gimmeStore()"
      variant="text"
      density="compact"
    >
      GIMME STORE DATA
    </v-btn>
    <v-spacer></v-spacer>
    <ZorstStatus
      v-if="$store?.state?.company?.softwareIntegration?.zorst"
    ></ZorstStatus>
    &nbsp;

    <!-- New v-menu implementation -->
    <v-btn icon density="compact" color="primary-darken-1">
      <v-icon> fa-solid fa-bars </v-icon>

      <v-menu activator="parent" offset-y>
        <v-list bg-color="primary-darken-1">
          <v-list-item
            color="error"
            v-for="item in filteredItems"
            :key="item.text"
            class="d-flex"
            @click="toggleMenu(item.route)"
          >
            <template v-slot:prepend>
              <v-icon size="xs">{{ item.icon }}</v-icon>
            </template>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { UserRole } from "@/enums/user-role.enum";
import { UserHelperService } from "@/services/user-helper.service";
import { mapActions, mapGetters } from "vuex";
import ToastService from "@/services/toast.service";
import ZorstStatus from "./ZorstStatus.vue";
import { Routes } from "@/enums/routes.enum";
import { AppPageAndMenuTitle } from "../../enums/app-layout.enum";
import { ActionType, MutationType } from "../../enums/vuex-types.enum";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "TopHeader",
  components: {
    ZorstStatus,
  },
  props: {
    isLoggedIn: Boolean,
    showVersion: Boolean,
  },
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
      chipIcon: `${this.$store?.getters?.getEnv?.VUE_APP_ASSETS_URL}/app-layout/${this.$store?.getters?.appLayout}/chip-icon.svg`,
      menuItems: [
        {
          text: AppPageAndMenuTitle.dashboard,
          route: Routes.dashboard,
          icon: "fa-solid fa-tachometer-alt",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: AppPageAndMenuTitle.company,
          route: Routes.configCompany,
          icon: "fa-solid fa-building",
        },
        {
          text: AppPageAndMenuTitle.user,
          route: Routes.configUser,
          icon: "fa-solid fa-user",
        },
        {
          text: AppPageAndMenuTitle.mandants,
          route: Routes.configMandant,
          icon: "fa-solid fa-map-marker-alt",
        },
        {
          text: AppPageAndMenuTitle.softwareIntegration,
          route: Routes.configApi,
          icon: "fa-solid fa-plug",
        },
        {
          text: AppPageAndMenuTitle.ai,
          route: Routes.configAi,
          icon: "fa-solid fa-rocket",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: AppPageAndMenuTitle.backendLogs,
          route: Routes.backendLogs,
          icon: "fa-solid fa-boxes-packing",
        },
        {
          accessRoles: [
            UserRole.UberAdmin,
            UserRole.Admin,
            UserRole.CompanyAdmin,
          ],
          text: AppPageAndMenuTitle.dataCleaning,
          route: Routes.dataCleaning,
          icon: "fa-solid fa-broom",
        },
        {
          text: AppPageAndMenuTitle.version,
          route: Routes.version,
          icon: "fa-solid fa-info-circle",
          condition: "showVersion",
        },
        {
          text: AppPageAndMenuTitle.logout,
          route: Routes.logout,
          icon: "fa-solid fa-sign-out-alt",
        },
      ],
      userRole: UserHelperService.getInstance().getUserRole(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["storeHasToInit", "recurionChatIsActive"]),
    filteredItems() {
      return this.menuItems.filter((item) => {
        if (!item.accessRoles) {
          return true;
        }
        return item.accessRoles.includes(this.userRole);
      });
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  created() {
    this.checkIfStoreHasToInit();
  },
  methods: {
    ...mapActions([ActionType.loadStoreInitialData]),
    async checkIfStoreHasToInit() {
      if (this.storeHasToInit) {
        await this.loadStoreInitialData().then(() => {
          ToastService.show(
            `Sichere Verbindung zu ${this.$store.state.company.name} hergestellt ...`
          );
        });
      }
    },
    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },
    gimmeStore() {
      console.log(this.$store.state);
    },
    openRecurionChat() {
      this.$store.commit(MutationType.setRecurionChatActive);

      if (this.$store.state.recurionState.isClosed) {
        this.$store.commit(MutationType.toggleRecurionMenu);
      }
    },
    openKnowledgeBase() {
      const externalLink =
        "https://www.youtube.com/playlist?list=PLjAx46HKPM5u2xrQVQaybbqDGw4vSoam8";
      window.open(externalLink, "_blank", "width=950,height=900");
    },
    toggleMenu(route: string) {
      if (route) {
        this.router.push(route);
      }
    },
  },
});
</script>

<style scoped>
.menu-icon {
  cursor: pointer;
  transition: all 0.3s ease;
}
.menu-icon:hover {
  scale: 1.1;
}
</style>
