import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 3,
  class: "spinner-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeartbeatService = _resolveComponent("HeartbeatService")!
  const _component_TopHeader = _resolveComponent("TopHeader")!
  const _component_JobPoster = _resolveComponent("JobPoster")!
  const _component_RecurionMenu = _resolveComponent("RecurionMenu")!
  const _component_GameBar = _resolveComponent("GameBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SpinnerGame = _resolveComponent("SpinnerGame")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeartbeatService),
    (_ctx.showTopHeader)
      ? (_openBlock(), _createBlock(_component_TopHeader, {
          key: 0,
          isLoggedIn: _ctx.isLoggedIn,
          showVersion: _ctx.showVersion()
        }, null, 8, ["isLoggedIn", "showVersion"]))
      : _createCommentVNode("", true),
    _createVNode(_component_JobPoster),
    (_ctx.isRecurionChatActive)
      ? (_openBlock(), _createBlock(_component_RecurionMenu, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.showGameBar)
      ? (_openBlock(), _createBlock(_component_GameBar, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_ctx.isSpinnerVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SpinnerGame),
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-spinner fa-spin fa-3x" }, null, -1))
        ]))
      : _createCommentVNode("", true)
  ]))
}