import {
  Candidate,
  CareerStep,
  CommunicationMeans,
} from "@/models/candidate.model";
import {
  Customer,
  CustomerContact,
  CustomerList,
} from "@/models/customer.model";
import { CommunicationType } from "@/enums/communication-types.enum";
import { EmploymentType } from "@/enums/employment-types.enum";
import {
  getCustomerStates,
  CustomerStates,
  getCustomerStateValue,
} from "@/enums/customer-states.enum";
import moment from "moment";
import { Salutation } from "@/enums/salutation.enum";
import store from "@/store/store";
import { Address, Employee, EmployeeList } from "@/models/employee.model";
import { MaritalStatus } from "@/enums/marital-status.enum";
import { Assignment } from "@/models/assignment.model";
import {
  PdHubCustomerList,
  PdHubEmployeeList,
} from "../models/external/pd-hub.model";

function extractCandidatePhoneNumbers(data: CommunicationMeans[]) {
  const filteredPhoneNumbers =
    data
      ?.filter(
        (c: CommunicationMeans) =>
          c.type === CommunicationType.Phone ||
          c.type === CommunicationType.Mobile ||
          c.type === CommunicationType.WorkMobile ||
          c.type === CommunicationType.WhatsApp
      )
      .map((c: CommunicationMeans) => c.value.replace(/ /g, "")) || [];

  const phone1 = filteredPhoneNumbers[0] || "";
  const phone2 = filteredPhoneNumbers[1] || "";

  return {
    phone1,
    phone2,
  };
}

function extractCandidateEmail(data: CommunicationMeans[]) {
  const filteredEmails =
    data
      ?.filter((c: CommunicationMeans) => c.type === CommunicationType.Email)
      .map((c: CommunicationMeans) => c.value) || [];
  return filteredEmails[0] || "";
}

function employmentTypeToString(type: EmploymentType): string {
  return EmploymentType[type] as unknown as string;
}

function mapCareerSteps(steps: CareerStep[], type: EmploymentType) {
  const typeString = employmentTypeToString(type);
  return steps
    .filter((step) => step.type.systemName === typeString)
    .map((step) => {
      const fromDate = moment(step.from).isValid()
        ? moment(step.from).format("DD.MM.YYYY")
        : null;
      const untilDate = moment(step.until).isValid()
        ? moment(step.until).format("DD.MM.YYYY")
        : null;

      return {
        Von: fromDate,
        Bis: untilDate,
        Bei: step.at,
        Abschluss: step.degree,
        Bemerkung: step.details,
      };
    });
}

export class PdHubAdapter {
  static candidateToPdHubCandidate(
    candidate: Candidate,
    mandantUuid: string
  ): any {
    const { phone1, phone2 } = extractCandidatePhoneNumbers(
      candidate.communicationMeans
    );
    const email = extractCandidateEmail(candidate.communicationMeans);
    const branchNumber = parseInt(
      store.getters.getMandantBranchNumberByUuid(mandantUuid)
    );

    const salutation = Salutation[candidate.salutationCatalogId];
    let gender = "maennlich";
    if (candidate.salutationCatalogId === Salutation.Frau) {
      gender = "weiblich";
    } else if (candidate.salutationCatalogId === Salutation.Divers) {
      gender = "divers";
    }

    let maritalStatus = "verheiratet";
    if (candidate.maritalStatus !== MaritalStatus.verheiratet)
      maritalStatus = "nicht verheiratet";

    let letterSalutation = `Guten Tag ${candidate.firstName} ${candidate.lastName}`;
    if (gender === "maennlich") {
      letterSalutation = `Sehr geehrter Herr ${candidate.lastName}`;
    } else if (gender === "weiblich") {
      letterSalutation = `Sehr geehrte Frau ${candidate.lastName}`;
    }

    return {
      Nachname: candidate.lastName,
      Vorname: candidate.firstName,
      Titel: candidate.title,
      Anrede: salutation,
      Geburtsdatum: candidate.birthDate,
      Kostenstelle: branchNumber,
      Geschlecht: gender,
      Familienstand: maritalStatus,
      Adresse: {
        Briefanrede: letterSalutation,
        Strasse: `${candidate.addressStreet} ${candidate.addressHouseNumber}`,
        Plz: candidate.addressPostalCode,
        Ort: candidate.addressCity,
        Telefon1: phone1,
        Telefon2: phone2,
        Email: email,
      },
      Geburtsdaten: {
        Geburtsort: candidate.birthPlace,
        Geburtsname: candidate.birthName,
      },
      Bewerbung: {
        BewerbungAls: candidate.applications[0]?.appliedAs,
        Datum: moment(candidate.applications[0]?.applicationDate).format(),
        Gehaltswunsch: candidate.applications[0]?.salary,
        VerfuegbarAbDatum: moment(
          candidate.applications[0]?.dataPrivacyDeadline
        ).format(),
        WunschbeschaeftigungUmkreis:
          candidate.applications[0]?.operationalRadius,
      },
      Ausbildungen: mapCareerSteps(
        candidate.careerSteps,
        EmploymentType.professional_training
      ),
      Schulbildung: mapCareerSteps(
        candidate.careerSteps,
        EmploymentType.schooling
      ),
      Weiterbildung: mapCareerSteps(
        candidate.careerSteps,
        EmploymentType.further_training
      ),
      Berufserfahrung: mapCareerSteps(
        candidate.careerSteps,
        EmploymentType.employed
      ),
    };
  }

  static transformToAssignmentModel = (
    apiResponse: any[],
    isCustomer?: boolean,
    isNoTimeFilter?: boolean
  ): Assignment[] => {
    const filteredResponse = apiResponse.filter((item) => {
      if (isCustomer && !isNoTimeFilter) {
        const maxMonthsAgo = moment().subtract(3, "months");
        const isValid =
          !item.DatumBis || moment(item.DatumBis).isSameOrAfter(maxMonthsAgo);
        return isValid;
      }
      return true;
    });

    const assignments = filteredResponse.map((item) => {
      const employeeNumber = item.PersonalNr
        ? item.PersonalNr.toString()
        : null;
      const customerNumber = item.KundenNr ? item.KundenNr.toString() : null;

      let employeeName = { firstName: "", lastName: "" };
      let clientName = "";

      if (employeeNumber) {
        try {
          employeeName = store.getters.getEmployeeNameByNumberFromEmployeeList(
            employeeNumber
          ) || { firstName: "", lastName: "" };
        } catch (error) {
          console.warn(
            `Fehler beim Abrufen des Mitarbeiters für PersonalNr ${employeeNumber}:`,
            error
          );
        }
      }

      if (customerNumber) {
        try {
          clientName =
            store.getters.getCustomerNameByNumberFromCustomerList(
              customerNumber
            ) || "";
        } catch (error) {
          console.warn(
            `Fehler beim Abrufen des Kunden für KundenNr ${customerNumber}:`,
            error
          );
        }
      }

      const assignment = {
        assignmentId: item.AuftragNr.toString(),
        positionId: "",
        leaseId: "",
        clientName: clientName,
        workLocation: item.EinsatzortBezeichnung || "",
        from: item.DatumVon || "",
        to: item.DatumBis || "",
        jobTitle: item.BerufBezeichnung || "",
        billingRate: 0,
        tariffWage: 0,
        aboveTariffAllowance: 0,
        firstName: employeeName.firstName,
        lastName: employeeName.lastName,
      } as Assignment;

      return assignment;
    });

    return assignments;
  };
  static transformToEmployeeModel(
    apiResponse: any[],
    mandantL1?: string
  ): any[] {
    return apiResponse.map((item) => {
      const address: Address = {
        stateId: item.Adresse?.BundeslandId?.toString() || "",
        email: item.Adresse?.Email || "",
        homepage: item.Adresse?.Homepage || "",
        city: item.Adresse?.Ort || "",
        postalCode: item.Adresse?.Plz || "",
        countryId: item.Adresse?.Land || "",
        street: item.Adresse?.Strasse || "",
        phone1: item.Adresse?.Telefon1 || "",
        phone2: item.Adresse?.Telefon2 || "",
      };

      return {
        employeeNumber: item.PersonalNr?.toString() || "",
        candidateUuid: item.kandidatGuid,
        address: address,
        creationDate: item.Anlagedatum || "",
        salutation: this.getSalutationText(item.Anrede) || "",
        appActivation: false,
        letterSalutation: item.Adresse?.Briefanrede || "",
        dataPrivacyConsents: [], // Fill as necessary
        formerEmployeeNumbers: [], // Fill as necessary
        employedAsJobId: item.ErlernterBeruf || "",
        email: item.Adresse?.Email || "",
        maritalStatus: this.mapMaritalStatus(item.Familienstand || ""),
        foreignPayrollNumber: item.FremdLohnPersonalNr || 0,
        birthDate: item.Geburtsdatum || "",
        birthName: item.Geburtsdaten?.Geburtsname || "",
        birthPlace: item.Geburtsdaten?.Geburtsort || "",
        branchOfficeId: item.Geschaeftsstelle?.toString() || "",
        mandants: [
          mandantL1
            ? store.getters.getMandantByBranchNumberAndMandantL1(
                item.Geschaeftsstelle?.toString(),
                mandantL1.toString()
              )
            : store.getters.getMandantByBranchNumber(
                item.Geschaeftsstelle?.toString()
              ),
        ],
        mandantL1: mandantL1,
        gender: this.mapGender(item.Geschlecht || ""),
        costCenter: item.Kostenstelle || 0,
        salary: 0,
        lastName: item.Nachname || "",
        employeeStatus: parseInt(item.PersonalStatus),
        probationEnd: "",
        nationalityId: item.Staatsangehoerigkeit?.StaatId || "",
        title: item.Titel || "",
        unlimitedResidencePermit: false,
        firstName: item.Vorname || "",
        exitDate: item.Austritt || "",
        entryDate: item.Eintritt || "",
        assignments: [], // Fill as necessary
        zvooveCandidateObjectId: "", // To link with ZvooveCandidate from zvoove-candidate.model.ts
      };
    });
  }

  static mapMaritalStatus(familienstand: string): number {
    switch (familienstand) {
      case "nicht verheiratet":
        return 1;
      case "verheiratet":
        return 2;
      default:
        return 0;
    }
  }

  static mapGender(geschlecht: string): number {
    const genderMap: { [key: string]: number } = {
      männlich: 1,
      maennlich: 1,
      weiblich: 2,
      divers: 3,
      unbekannt: 4,
      firma: 5,
    };

    return genderMap[geschlecht.toLowerCase()] || 4; // Default to 'unbekannt'
  }

  static transformToPdHubEmployeeModel(employee: Employee): any {
    return {
      Staatsangehoerigkeit: {
        StaatId: employee.nationalityId,
      },
      Adresse: {
        Nummer: employee.employeeNumber,
        Briefanrede: employee.letterSalutation,
        Strasse: employee.address.street,
        Land: "DE",
        Plz: employee.address.postalCode,
        Ort: employee.address.city,
        Telefon1: employee.address.phone1,
        Telefon2: employee.address.phone2,
        Email: employee.email,
      },
      Geburtsdaten: {
        Geburtsort: employee.birthPlace,
        Geburtsname: employee.birthName,
      },
      PersonalNr: employee.employeeNumber,
      Nachname: employee.lastName,
      Vorname: employee.firstName,
      Titel: employee.title,
      Anrede: employee.salutation,
      Geburtsdatum: employee.birthDate,
      PersonalStatus: employee.employeeStatus.toString(),
      PersonalStatusText: this.getPersonalStatusText(employee.employeeStatus),
      FremdLohnPersonalNr: employee.foreignPayrollNumber,
      Geschaeftsstelle: employee.branchOfficeId,
      Geschlecht: this.mapGenderToPdHub(employee.gender),
      Familienstand: this.mapMaritalStatusToPdHub(employee.maritalStatus),
      Disponent: "SYS",
      Lohnsachbearbeiter: "SYS",
      Eintritt: employee.entryDate,
      Austritt: employee.exitDate,
      ErlernterBeruf: employee.employedAsJobId,
      Anlagedatum: employee.creationDate,
    };
  }

  private static mapGenderToPdHub(geschlecht: number): string {
    const genderMap: { [key: number]: string } = {
      1: "männlich",
      2: "weiblich",
      3: "divers",
      4: "unbekannt",
      5: "firma",
    };

    return genderMap[geschlecht] || "unbekannt";
  }

  private static mapMaritalStatusToPdHub(familienstand: number): string {
    const familienstandMap: { [key: number]: string } = {
      1: "nicht verheiratet",
      2: "verheiratet",
      3: "geschieden",
      4: "verwitwet",
    };

    return familienstandMap[familienstand] || "unbekannt";
  }

  private static getPersonalStatusText(personalstatus: number): string {
    const statusTextMap: { [key: number]: string } = {
      1: "Bewerber",
      2: "Mitarbeiter",
      3: "Ehemaliger Mitarbeiter",
    };

    return statusTextMap[personalstatus] || "Unbekannt";
  }

  private static getSalutationText(anrede: string): string {
    // You need to implement the logic for translating or mapping salutation texts here
    return anrede;
  }

  static transformToCustomerModel(
    apiResponse: any[],
    mandantL1?: string
  ): Customer[] {
    return apiResponse.map((item) => {
      const primaryAddress =
        item.Adressen.find((address: any) => address.Typ === "Postanschrift") ||
        item.Adressen[0] ||
        {};

      const customerStates = getCustomerStates(CustomerStates);
      const statusFromEnum =
        customerStates[item.KundenStatus] ||
        customerStates[CustomerStates.unknown];

      const geschaeftsstelleId = item.Geschaeftsstelle.Nummer.toString() || "";
      let mandant = "";
      if (mandantL1) {
        mandant = store.getters.getMandantByBranchNumberAndMandantL1(
          geschaeftsstelleId,
          mandantL1.toString()
        );
      } else if (store.getters.getMandantByBranchNumber(geschaeftsstelleId)) {
        mandant = store.getters.getMandantByBranchNumber(geschaeftsstelleId);
      }
      let customerContacts = [] as CustomerContact[];

      if (item.Ansprechpartner && item.Ansprechpartner.length > 0) {
        customerContacts = item.Ansprechpartner.map((contact: any) => ({
          zvooveId: contact.AnsprechpartnerNr?.toString() || "",
          salutation: contact.Anrede || "",
          title: contact.Titel || "",
          role: contact.Position || "",
          firstName: contact.Vorname || "",
          lastName: contact.Nachname || "",
          phone: contact.Telefon1 || "",
          email: contact.Email || "",
          status: contact.Kontaktsperre ? "inaktiv" : "aktiv",
        }));
      }

      return {
        customerNumber: item.KundenNr?.toString() || "",
        mandants: mandant ? [mandant] : [""], // Ensure uuid is a string
        mandantL1: mandantL1,
        tags: [],
        generalData: {
          name: item.KundenName || "",
          name2: "",
          name3: "",
          searchTerm: item.KundenName || "",
          costCenter: item.Kostenstelle?.toString() || "",
          customerSince: item.Anlagedatum || "",
          accountsReceivable: item.Debitorenkonto?.toString() || "",
          createdOn: item.Anlagedatum || "",
          status: statusFromEnum || "",
        },
        addressAndCommunication: {
          street: primaryAddress.Strasse || "",
          postalCode: primaryAddress.Plz || "",
          city: primaryAddress.Ort || "",
          country: primaryAddress.Land || "",
          state: primaryAddress.Staat?.Bezeichnung || "",
          phone1: primaryAddress.Telefon1 || "",
          phone2: primaryAddress.Telefon2 || "",
          email: primaryAddress.Email || "",
          website: primaryAddress.Homepage || "",
        },
        furtherInformation: {
          dispatcher: item.Disponent || "",
          industries: [],
          WZ08: "",
          Siret: "",
        },
        additionalInfo: {
          info1: item.Chefanweisung || "",
          info2: "",
          info3: "",
          indexLink: "",
          indexCompanyId: "",
          careerSiteLink: "",
          dataProtectionConsent: false,
        },
        reporting: [],
        contacts: customerContacts,
        jobAds: [],
      };
    });
  }

  static transformToPdHubModel(customer: Customer): any {
    const {
      generalData,
      furtherInformation,
      addressAndCommunication,
      additionalInfo,
    } = customer;

    const postAnschrift = {
      Anrede: "Firma",
      Briefanrede: "Sehr geehrte Damen und Herren",
      Name1: generalData.name.substring(0, 25),
      Name2: generalData.name2?.substring(0, 25) ?? "",
      Name3: generalData.name3?.substring(0, 25) ?? "",
      Strasse: addressAndCommunication.street,
      Land: "D",
      Plz: addressAndCommunication.postalCode,
      Ort: addressAndCommunication.city,
      Postfach: "",
      PostfachPlz: "",
      PostfachOrt: "",
      Telefon1: addressAndCommunication.phone1 || "",
      Telefon2: addressAndCommunication.phone2 || "",
      Telefax: "",
      Email: addressAndCommunication.email || "",
      Homepage: (addressAndCommunication.website || "").substring(0, 60),
      Info1: (additionalInfo.info1 || "").substring(0, 80),
      Info2: (additionalInfo.info2 || "").substring(0, 80),
      Info3: (additionalInfo.info3 || "").substring(0, 80),
    };

    const rechnungAnschrift = {
      Anrede: "Firma",
      Briefanrede: "Sehr geehrte Damen und Herren",
      Name1: generalData.name.substring(0, 25),
      Name2: generalData.name2 || "",
      Name3: generalData.name3 || "",
      Strasse: addressAndCommunication.street,
      Land: "D",
      Plz: addressAndCommunication.postalCode,
      Ort: addressAndCommunication.city,
      Postfach: "",
      PostfachPlz: "",
      PostfachOrt: "",
      Telefon1: addressAndCommunication.phone1 || "",
      Telefon2: addressAndCommunication.phone2 || "",
      Telefax: "",
      Email: addressAndCommunication.email || "",
      Homepage: (addressAndCommunication.website || "").substring(0, 60),
      Info1: (additionalInfo.info1 || "").substring(0, 80),
      Info2: (additionalInfo.info2 || "").substring(0, 80),
      Info3: (additionalInfo.info3 || "").substring(0, 80),
    };

    const pdHubCustomer = {
      Kundname: generalData.name.substring(0, 25),
      Kostenstelle: generalData.costCenter
        ? parseInt(generalData.costCenter)
        : 0,
      Kostentraeger:
        store.getters.getLoggedInMandantBranchNumbers &&
        store.getters.getLoggedInMandantBranchNumbers.length > 0
          ? parseInt(
              store.getters.getLoggedInMandantBranchNumbers[0].toString()
            )
          : 0,
      Disponent: furtherInformation.dispatcher || "SYS",
      Staat: "D",
      Gebiet: "",
      StatusABC: "",
      BetriebsNr: "",
      UStId: "",
      Steuernummer: "",
      BundeslandId: parseInt(addressAndCommunication.state) || null,
      ZahlungsBedingungen: {
        SkontoTage1: 5,
        SkontoProzent1: 3,
        SkontoTage2: 10,
        SkontoProzent2: 2,
        NettoTage: 30,
        SkontokarenzTage: 2,
        SperrKontoProzent: 1.5,
      },
      PostAnschrift: [postAnschrift],
      RechnungAnschrift: [rechnungAnschrift],
      Einsatzorte: [],
    };

    return pdHubCustomer;
  }

  static transformToPdHubCustomerModel(customer: Customer): any {
    const { generalData, furtherInformation, additionalInfo } = customer;
    let branchId = store.getters.getLoggedInMandantBranchNumbers[0];
    if (customer.mandants[0])
      branchId = store.getters.getBranchNumberByUuid(customer.mandants[0]);
    const customerState = generalData.status
      ? (getCustomerStateValue(generalData.status) ?? 1).toString()
      : "1";

    return {
      KundenNr: parseInt(customer.customerNumber),
      KundenName: generalData.name.substring(0, 25),
      KundenStatus: customerState,
      Kostenstelle: parseInt(branchId),
      Disponent: furtherInformation.dispatcher || "SYS",
      Chefanweisung: additionalInfo.info1,
    };
  }

  static transformToCustomerList(
    data: PdHubCustomerList,
    mandantL1: string
  ): CustomerList {
    const address =
      data.Adressen && data.Adressen.length > 0 ? data.Adressen[0] : null;

    return {
      customerNumber: data.KundenNr.toString(),
      name: data.KundenName,
      address: address
        ? `${address.Strasse} ${address.Plz} ${address.Ort}`.trim()
        : "",
      mandantL1: mandantL1,
    };
  }

  static transformToEmployeeList(
    data: PdHubEmployeeList,
    mandantL1: string
  ): EmployeeList {
    return {
      employeeNumber: data.PersonalNr.toString(),
      name: `${data.Vorname} ${data.Nachname}`,
      address: `${data.Adresse.Strasse} ${data.Adresse.Plz} ${data.Adresse.Ort}`,
      mandantL1: mandantL1,
      status: parseInt(data.PersonalStatus),
    };
  }
}
