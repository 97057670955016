import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_badge, {
      color: _ctx.zorstStatus().color,
      content: _ctx.zorstStatus().content,
      overlap: "",
      "offset-x": "3",
      "offset-y": "9",
      class: "mr-5"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_img, {
          class: "mr-2 zorst-icon",
          src: _ctx.zorstIcon,
          alt: "Zorst Icon",
          width: "24",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSoftwareIntegration()))
        }, null, 8, ["src"])
      ]),
      _: 1
    }, 8, ["color", "content"]),
    _createVNode(_component_v_tooltip, {
      activator: "parent",
      location: "end"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.zorstStatus().tooltip), 1)
      ]),
      _: 1
    })
  ]))
}