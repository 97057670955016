import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "registration-form" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[32] || (_cache[32] = _createElementVNode("h2", null, "Firmenregistrierung", -1)),
    _createElementVNode("form", {
      onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", null, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { for: "companyName" }, "Firmenname:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "companyName",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyName) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.companyName]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[17] || (_cache[17] = _createElementVNode("label", { for: "adminEmail" }, "Admin Emailadresse:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "email",
          id: "adminEmail",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.adminEmail) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.adminEmail]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[18] || (_cache[18] = _createElementVNode("label", { for: "name" }, "Admin Name:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "name",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.name) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.name]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[19] || (_cache[19] = _createElementVNode("label", { for: "lastname" }, "Admin Nachname:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "lastname",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastname) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.lastname]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { for: "password" }, "Passwort:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "password",
          id: "password",
          minlength: "6",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.password]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[21] || (_cache[21] = _createElementVNode("label", { for: "confirmPassword" }, "Passwortbestätigung:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "password",
          id: "confirmPassword",
          minlength: "6",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.confirmPassword) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.confirmPassword]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[22] || (_cache[22] = _createElementVNode("label", { for: "street" }, "Straße:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "street",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.companyAddress.street) = $event))
        }, null, 512), [
          [_vModelText, _ctx.companyAddress.street]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[23] || (_cache[23] = _createElementVNode("label", { for: "street-number" }, "Hausnummer:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "street-number",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.companyAddress.street_number) = $event))
        }, null, 512), [
          [_vModelText, _ctx.companyAddress.street_number]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[24] || (_cache[24] = _createElementVNode("label", { for: "postcode" }, "Postleitzahl:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "postcode",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.companyAddress.postcode) = $event))
        }, null, 512), [
          [_vModelText, _ctx.companyAddress.postcode]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[25] || (_cache[25] = _createElementVNode("label", { for: "city" }, "Stadt", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "city",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.companyAddress.city) = $event))
        }, null, 512), [
          [_vModelText, _ctx.companyAddress.city]
        ])
      ]),
      _cache[30] || (_cache[30] = _createElementVNode("label", { for: "country" }, "Land", -1)),
      _withDirectives(_createElementVNode("select", {
        id: "country",
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.companyAddress.country) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: country
          }, _toDisplayString(country), 9, _hoisted_2))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.companyAddress.country]
      ]),
      _createElementVNode("div", null, [
        _cache[26] || (_cache[26] = _createElementVNode("label", { for: "companyWebsite" }, "Firmen Website:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "url",
          id: "companyWebsite",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.companyWebsite) = $event))
        }, null, 512), [
          [_vModelText, _ctx.companyWebsite]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[27] || (_cache[27] = _createElementVNode("label", { for: "facebook" }, "Facbeook:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "url",
          id: "facebook",
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.social_media_links.facebook) = $event))
        }, null, 512), [
          [_vModelText, _ctx.social_media_links.facebook]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[28] || (_cache[28] = _createElementVNode("label", { for: "instagram" }, "Instagram:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "url",
          id: "instagram",
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.social_media_links.instagram) = $event))
        }, null, 512), [
          [_vModelText, _ctx.social_media_links.instagram]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[29] || (_cache[29] = _createElementVNode("label", { for: "tiktok" }, "TikTok:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "url",
          id: "tiktok",
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.social_media_links.tiktok) = $event))
        }, null, 512), [
          [_vModelText, _ctx.social_media_links.tiktok]
        ])
      ]),
      _cache[31] || (_cache[31] = _createElementVNode("button", { type: "submit" }, "Registrieren", -1))
    ], 32)
  ]))
}