<!--src/components/spinner/SpinnerGame.vue-->
<template>
  <div id="gameContainer">
    <canvas ref="gameCanvas" width="1000" height="400"></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import store from "../../store/store";
import { Timeline } from "../../models/timeline.model";
import { MutationType } from "../../enums/vuex-types.enum";
import moment from "moment";
import { LinkingStatus } from "../../enums/dependency.enum";

export default defineComponent({
  name: "SpinnerGame",
  setup() {
    let animationFrameId: number;
    let gameStarted = false;
    let score = 0;

    const keysPressed = {
      w: false,
      s: false,
      space: false,
    };

    const resetKeys = () => {
      keysPressed.w = false;
      keysPressed.s = false;
      keysPressed.space = false;
    };

    const initGame = () => {
      const canvas = document.querySelector(
        "#gameContainer canvas"
      ) as HTMLCanvasElement;
      const context = canvas.getContext("2d") as CanvasRenderingContext2D;

      const monkeyImg = new Image();
      monkeyImg.src = `${store.getters.getEnv.VUE_APP_ASSETS_URL}/spinner-game/monkey.png`;
      const peanutImg = new Image();
      peanutImg.src = `${store.getters.getEnv.VUE_APP_ASSETS_URL}/spinner-game/peanut.png`;
      const cannonImg = new Image();
      cannonImg.src = `${store.getters.getEnv.VUE_APP_ASSETS_URL}/spinner-game/cannon.png`;
      const explodeImg = new Image();
      explodeImg.src = `${store.getters.getEnv.VUE_APP_ASSETS_URL}/spinner-game/explode-spritesheet.png`;

      const explosionFrames = 16;
      const explosionFrameSize = 187.5; // 750px / 4

      let monkeys: {
        x: number;
        y: number;
        hit: boolean;
        hitTime: number;
        explode: boolean;
        frame: number;
        frameTime: number;
      }[] = [];
      let peanuts: { x: number; y: number; angle: number }[] = [];
      let cannonY = canvas.height / 2;
      let lastTime = 0;

      const shootPeanut = () => {
        peanuts.push({ x: 70, y: cannonY + 12, angle: 0 });
      };

      const update = (deltaTime: number) => {
        // Monkeys
        if (Math.random() < 0.02) {
          const monkeyHeight = 75;
          monkeys.push({
            x: canvas.width,
            y: Math.random() * (canvas.height - monkeyHeight),
            hit: false,
            hitTime: 0,
            explode: false,
            frame: 0,
            frameTime: 0,
          });
        }
        monkeys.forEach((monkey, index) => {
          if (monkey.explode) {
            monkey.frameTime += deltaTime;
            if (monkey.frameTime > 0.1) {
              monkey.frame++;
              monkey.frameTime = 0;
            }
            if (monkey.frame >= explosionFrames) {
              monkeys.splice(index, 1);
            }
          } else {
            monkey.x -= 100 * deltaTime;
            if (monkey.x < 0) {
              monkeys.splice(index, 1);
              score -= 15;
            }
          }
        });

        peanuts.forEach((peanut, index) => {
          peanut.x += 200 * deltaTime;
          peanut.angle += 0.1;
          if (peanut.x > canvas.width) {
            peanuts.splice(index, 1);
            score -= 5;
          }
        });

        // Collision detection
        peanuts.forEach((peanut, pIndex) => {
          monkeys.forEach((monkey) => {
            if (
              peanut.x < monkey.x + 75 &&
              peanut.x + 15 > monkey.x &&
              peanut.y < monkey.y + 75 &&
              peanut.y + 15 > monkey.y &&
              !monkey.hit
            ) {
              peanut.x = -10; // Move peanut out of the canvas
              monkey.hit = true;
              monkey.hitTime = performance.now();
              monkey.explode = true;
              peanuts.splice(pIndex, 1);
              score += 1;
            }
          });
        });

        monkeys = monkeys.filter(
          (monkey) => !(monkey.hit && performance.now() - monkey.hitTime > 1000)
        );

        // Handle cannon movement
        const cannonHeight = 60;
        if (keysPressed.w && cannonY > 0) {
          cannonY -= 200 * deltaTime;
        }
        if (keysPressed.s && cannonY < canvas.height - cannonHeight) {
          cannonY += 200 * deltaTime;
        }

        // Shoot peanuts
        if (keysPressed.space) {
          shootPeanut();
          keysPressed.space = false; // Ensure single shot per key press
        }
      };

      const draw = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Draw monkeys
        monkeys.forEach((monkey) => {
          if (monkey.explode) {
            const frameX = (monkey.frame % 4) * explosionFrameSize;
            const frameY = Math.floor(monkey.frame / 4) * explosionFrameSize;
            context.drawImage(
              explodeImg,
              frameX,
              frameY,
              explosionFrameSize,
              explosionFrameSize,
              monkey.x,
              monkey.y,
              75,
              75
            );
          } else {
            context.drawImage(monkeyImg, monkey.x, monkey.y, 75, 75);
          }
        });

        // Draw peanuts
        peanuts.forEach((peanut) => {
          context.save();
          context.translate(peanut.x + 7.5, peanut.y + 7.5);
          context.rotate(peanut.angle);
          context.drawImage(peanutImg, -7.5, -7.5, 15, 15);
          context.restore();
        });

        // Draw cannon
        if (gameStarted) {
          context.drawImage(cannonImg, 30, cannonY, 60, 60);
        }

        if (score !== 0) {
          context.fillStyle = "white";
          context.font = "20px Arial";
          context.fillText(`Score: ${score}`, 10, 30);
        }
      };

      const gameLoop = (timestamp: number) => {
        const deltaTime = (timestamp - lastTime) / 1000;
        lastTime = timestamp;

        if (gameStarted) {
          update(deltaTime);
        }
        draw();

        animationFrameId = requestAnimationFrame(gameLoop);
      };

      requestAnimationFrame(gameLoop);

      const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === "w") {
          keysPressed.w = true;
        } else if (e.key === "s") {
          keysPressed.s = true;
        }
      };

      const handleKeyup = (e: KeyboardEvent) => {
        if (e.key === "w") {
          keysPressed.w = false;
        } else if (e.key === "s") {
          keysPressed.s = false;
        }
      };

      const handleSpaceKeydown = (e: KeyboardEvent) => {
        if (e.key === " ") {
          e.preventDefault();
          keysPressed.space = true;
          // Start the game on space key press
          if (!gameStarted) {
            gameStarted = true;
          }
        }
      };

      const handleSpaceKeyup = (e: KeyboardEvent) => {
        if (e.key === " ") {
          e.preventDefault();
          keysPressed.space = false;
        }
      };

      document.addEventListener("keydown", handleKeydown);
      document.addEventListener("keyup", handleKeyup);
      document.addEventListener("keydown", handleSpaceKeydown);
      document.addEventListener("keyup", handleSpaceKeyup);

      onBeforeUnmount(() => {
        if (score !== 0) {
          const newEntryTimeline: Timeline = {
            user: "",
            userId: "",
            mandants: [],
            entryDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            entryType: "Affe gefüttert",
            customer: {
              name: "",
              contact: "",
              customerId: "",
            },
            participant: {
              name: "",
              uuid: "",
              employeeId: "",
              candidateId: "",
            },
            note: `Erdnuss gespielt: ${score}`,
          };
          store.commit(MutationType.addPerfRecentEntries, {
            timeline: newEntryTimeline,
            score: score,
            entryType: {
              entryType: LinkingStatus.spinnerGamePlayed,
              hasPhoneCallBefore: false,
            },
            description: `Erdnuss gespielt: ${score}`,
          });
        }

        document.removeEventListener("keydown", handleKeydown);
        document.removeEventListener("keyup", handleKeyup);
        document.removeEventListener("keydown", handleSpaceKeydown);
        document.removeEventListener("keyup", handleSpaceKeyup);
        cancelAnimationFrame(animationFrameId);
        resetKeys();
      });
    };

    onMounted(initGame);

    return {};
  },
});
</script>

<style scoped>
#gameContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9001;
}
canvas {
  border: none;
}
</style>
