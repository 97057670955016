export enum RulesMessage {
  required = "Erforderlich",
  invalidMail = "Ungültige E-Mail",
  postcode = "PLZ muss 4 oder 5 Stellen haben",
  numberField = "Dieses Feld muss eine Zahl sein",
  phoneNumber = "muss eine Telefonummer sein",
  city = "Stadt darf nur Buchstaben enthalten",
  noRadius = "Radius muss eingeben sein",
  radiusToShort = "mindestens 1 km",
}
