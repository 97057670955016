// fuse-match-tags.helper.ts

import Fuse from "fuse.js";

// If you only handle plain strings, you can simply use { tag: string }.
// For more advanced typing, adjust this interface as needed.
interface TagItem {
  tag: string;
}

/**
 * Merges two arrays of Fuse results using OR-logic:
 * - If an item appears in both arrays, keep the one with the lower (better) score.
 */
export function unionResults(
  arrA: import("fuse.js").FuseResult<TagItem>[],
  arrB: import("fuse.js").FuseResult<TagItem>[]
): import("fuse.js").FuseResult<TagItem>[] {
  const map = new Map<string, import("fuse.js").FuseResult<TagItem>>();

  // Insert arrA into the map
  for (const r of arrA) {
    // For plain strings, use r.item.tag as the key.
    // If you have real candidate data with an ID, use that here instead.
    const key = r.item.tag;
    map.set(key, r);
  }

  // Add arrB or update if a better (lower) score is found
  for (const r of arrB) {
    const key = r.item.tag;
    const existing = map.get(key);
    if (!existing || (r.score ?? 1) < (existing.score ?? 1)) {
      map.set(key, r);
    }
  }

  return Array.from(map.values());
}

/**
 * Performs fuzzy matching similar to the backend:
 * 1) Create a Fuse instance using candidateTags
 * 2) For each tag in payloadTags -> perform a search, then union the results
 * 3) Filter the final set by `score <= threshold`
 * 4) Returns a boolean indicating whether at least one match was found
 *    (you could also return the final result array instead if desired)
 */
export function checkTagsMatching(
  payloadTags: string[],
  candidateTags: string[],
  threshold = 0.3 // default threshold can be adjusted
): boolean {
  // Convert candidateTags into objects { tag } so that Fuse can search using keys: ['tag']
  const fuseData = candidateTags.map((tag) => ({ tag }));
  const fuse = new Fuse(fuseData, {
    keys: ["tag"],
    includeScore: true,
    threshold,
    ignoreLocation: true,
    shouldSort: true,
  });

  // Collect all results (OR-logic)
  let allResults: import("fuse.js").FuseResult<TagItem>[] = [];

  // For each payloadTag -> Fuse search -> union results
  for (const userTag of payloadTags) {
    const searchResults = fuse.search(userTag);
    allResults = unionResults(allResults, searchResults);
  }

  // Only keep results with score <= threshold
  const finalResults = allResults.filter((r) => (r.score ?? 1) <= threshold);

  // Example: return true if at least one tag matched
  return finalResults.length > 0;
}
