<!--src/components/disposition/DemandItem.vue-->
<template>
  <v-container class="ma-0 pa-0">
    <v-hover>
      <template v-slot:default="{ isHovering, props }">
        <div
          @dragstart="handleDragStart"
          @drop="handleDrop"
          draggable="true"
          class="ma-0 pa-0"
          v-if="isFullyCollapsed"
          noGutters
        >
          <v-sheet class="ma-0 pt-1 px-1" :color="item.colorList">
            <v-card-title class="d-flex pa-0">
              <span
                class="ml-x font-weight-light text-caption text-medium-emphasis"
              >
                <v-icon class="mx-1 match-icon" v-if="candidatesTagsMatching"
                  >fa-solid fa-heart</v-icon
                >
                <v-tooltip activator="parent" location="bottom">{{
                  getDemandStateInfo(parseInt(demand.demandState)).label
                }}</v-tooltip>
                <i
                  :class="`mr-1 fa-solid ${
                    getDemandStateInfo(parseInt(demand.demandState)).icon
                  }`"
                ></i>
              </span>
              <span class="pa-0 text-body-2">
                {{ demand.demands[0].quantity }}x
                {{ demand.demands[0].position }}
                <span
                  class="ml-1 font-weight-light text-caption text-medium-emphasis"
                >
                  {{ demand.customer }}</span
                >
              </span>
              <span
                class="mx-1 font-weight-light text-caption text-medium-emphasis"
              >
                {{ demand.demands[0].location.city }}</span
              ></v-card-title
            >
            <v-divider class="mt-1"></v-divider>
          </v-sheet>
        </div>
        <v-card
          v-if="!isFullyCollapsed"
          :variant="vStyle.boardItem.variant || undefined"
          :rounded="vStyle.boardItem.rounded || undefined"
          :border="item.border"
          :elevation="vStyle.boardItem.elevation || undefined"
          class="item pa-2 mb-2"
          v-bind="props"
          :color="isHovering ? item.hoverColor : item.color"
          :class="{
            expanded: isExpanded,
            'fully-expanded': isFullyExpanded,
          }"
          :max-height="getItemHeight()"
          @dblclick="handleDoubleClickOnItem()"
          @dragstart="handleDragStart"
          @drop="handleDrop"
          draggable="true"
          @dragover.prevent
        >
          <v-card-title class="pa-0 text-body-2">
            <v-icon class="ml-1 match-icon" v-if="candidatesTagsMatching"
              >fa-solid fa-heart</v-icon
            >
            {{ demand.demands[0].quantity }}x
            <strong>{{ demand.demands[0].position }}</strong>
            <span style="font-size: 0.7rem">
              ({{ demand.demands[0].location.postcode }}
              {{ demand.demands[0].location.city }})</span
            >
          </v-card-title>
          <v-card-subtitle class="pl-0"> {{ demand.customer }}</v-card-subtitle>
          <TopRightButton
            :isExpanded="isExpanded"
            :isFullyExpanded="isFullyExpanded"
            @toggleExpansion="toggleExpansion"
            @toggleFullExpansion="toggleFullExpansion"
            @openContextMenu="$emit('openContextMenu', $event)"
          />
          <div v-if="!isExpanded" class="demand-state-hover">
            <v-tooltip activator="parent" location="bottom">{{
              getDemandStateInfo(parseInt(demand.demandState)).label
            }}</v-tooltip>
            <i
              :class="`fa-solid ${
                getDemandStateInfo(parseInt(demand.demandState)).icon
              }`"
            ></i>
          </div>
          <v-divider class="mt-2"></v-divider>
          <v-container class="ma-0 px-0 d-flex">
            <v-chip
              v-if="
                demand.demandContactPerson.firstName ||
                demand.demandContactPerson.lastName
              "
              prepend-icon="fa-solid fa-address-book"
              class="text-body-2"
            >
              {{ demand.demandContactPerson.firstName }}
              {{ demand.demandContactPerson.lastName }}
              <v-tooltip activator="parent" location="bottom">{{
                demand.demandContactPerson.position
              }}</v-tooltip>
            </v-chip>
            <v-spacer></v-spacer>
            <PhoneClient
              v-if="
                isExpanded &&
                demand.demandContactPerson.contactDetails.phoneNumber
              "
              :phoneNumbers="filteredPhoneNumbers"
            ></PhoneClient>
            <MailClient
              v-if="
                isExpanded && demand.demandContactPerson.contactDetails.email
              "
              :emailAddresses="filteredEmail"
              :AiMessageType="AiMessageType.mailCustomer"
              :emailObject="email"
              :signature="signatureText()"
            ></MailClient>
          </v-container>
          <Dependencies
            ref="dependenciesComponent"
            v-if="hasLinking(demand._id)"
            :demand_id="demand._id"
          ></Dependencies>
          <v-divider></v-divider>
          <div v-if="isExpanded" class="tags-container">
            <v-chip
              v-for="(tag, index) in demand.tags"
              :key="index"
              class="dispatcher-board-icon-tag"
              color="primary"
              @click:close="removeTag(index)"
            >
              {{ tag }}
              <v-icon
                small
                @click.stop="removeTag(index)"
                class="fa fa-times"
              ></v-icon>
            </v-chip>
            <v-text-field
              variant="outlined"
              class="mt-2"
              label="Neuer Tag"
              style="max-width: 10rem"
              rounded="lg"
              density="compact"
              v-model="newTag"
              @keyup.enter="addTag"
            >
              <v-tooltip activator="parent" location="bottom"
                >mit "Enter" neuen Tag hinzufügen</v-tooltip
              >
            </v-text-field>
            <v-btn
              icon
              class="mt-3"
              variant="text"
              density="compact"
              @click="generateAndSetDemandTags()"
            >
              <v-icon size="small">fas fa-arrows-rotate</v-icon>
              <v-tooltip activator="parent" location="bottom"
                >Tags anhand der Anfragen neu generieren</v-tooltip
              >
            </v-btn>
          </div>
          <div v-if="isExpanded">
            <v-card
              :variant="vStyle.card.variant || undefined"
              :rounded="vStyle.card.rounded || undefined"
              :border="vStyle.card.border || undefined"
              class="mb-4"
              v-for="(detail, index) in demand.demands"
              :key="index"
            >
              <v-card-title
                >{{ detail.quantity }}x {{ detail.position }}</v-card-title
              >
              <v-chip
                color="primary"
                density="compact"
                class="ml-4 mt-2"
                prepend-icon="fa-solid fa-location-dot"
              >
                {{ detail.location.postcode }}
                {{ detail.location.city }}
              </v-chip>
              <v-chip
                color="var(--color-tertiary)"
                density="compact"
                class="ml-4 mt-2"
                prepend-icon="fa-solid fa-calendar-xmark"
              >
                {{ formatApplicationDeadline(detail.applicationDeadline) }}
              </v-chip>
              <v-chip
                color="var(--color-success)"
                density="compact"
                class="ml-4 mt-2"
                prepend-icon="fa-solid fa-coins"
              >
                {{ detail.budget.amount }}
                {{ detail.budget.currency }}
              </v-chip>
              <div class="mt-5" v-if="detail.description">
                <v-card-subtitle
                  >Beschreibung
                  <v-divider></v-divider>
                </v-card-subtitle>
                <v-card-text>
                  {{ detail.description }}
                </v-card-text>
              </div>
              <div v-if="detail.additionalInformation">
                <v-card-subtitle
                  >Weitere Informationen
                  <v-divider></v-divider>
                </v-card-subtitle>
                <v-card-text>
                  {{ detail.additionalInformation }}
                </v-card-text>
              </div>
              <v-card-subtitle v-if="detail.requirements.length > 0"
                >Vorraussetzungen
                <v-divider></v-divider>
              </v-card-subtitle>
              <v-container class="mx-1">
                <v-chip
                  v-for="(requirement, rIndex) in detail.requirements"
                  :key="`detail-${index}-requirement-${rIndex}`"
                  density="compact"
                  class="ma-1"
                >
                  {{ requirement }}
                </v-chip>
              </v-container>
            </v-card>
          </div>
        </v-card>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import { AiMessageType } from "../../enums/ai-options.enum";
import { defineComponent, PropType } from "vue";
import { Demand } from "@/models/demand.model";
import { DemandService } from "@/services/api/demand.service";
import { DemandState, getDemandStates } from "@/enums/demand-states.enum";
import Dependencies from "./elements/Dependencies.vue";
import { MessageContent } from "@/enums/empty-message.enum";
import { Salutation } from "@/enums/salutation.enum";
import DialogService from "@/services/dialog.service";
import MailClient from "./elements/MailClient.vue";
import moment from "moment";
import PhoneClient from "./elements/PhoneClient.vue";
import TopRightButton from "./elements/TopRightButton.vue";
import { mapGetters } from "vuex";
import { Role } from "../../enums/dependency.enum";
import { CandidateToCustomerData } from "../../models/linking.model";
import ToastService from "../../services/toast.service";
import { InterComponentMessage } from "../../enums/inter-component-messagin.enum";
import { AiService } from "../../services/ai.service";
import { MutationType } from "../../enums/vuex-types.enum";
import { checkTagsMatching } from "../../helper/fuse-match-tags.helper";

const demandStateIcons: { [key in DemandState]: string } = {
  [DemandState.Passive]: "fa-rectangle-ad",
  [DemandState.Active]: "fa-circle-user",
  [DemandState.Urgent]: "fa-exclamation-circle",
  [DemandState.Priority]: "fa-star",
  [DemandState.ExclusiveConfidential]: "fa-user-secret",
};

export default defineComponent({
  name: "DemandItem",
  emits: ["insertUpdatedDemand", "delete", "openContextMenu"],
  components: {
    Dependencies,
    MailClient,
    PhoneClient,
    TopRightButton,
  },
  props: {
    demand: {
      type: Object as PropType<Demand>,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      AiMessageType,
      candidatesTagsMatching: false,
      email: {
        subject: MessageContent.emptySubject as string,
        body: MessageContent.empty as string,
      },
      filteredEmail: [
        {
          mailaddress: this.demand.demandContactPerson.contactDetails.email,
          salutation: `Guten Tag ${this.demand.demandContactPerson?.firstName} ${this.demand.demandContactPerson?.lastName},`,
        },
      ] as any,
      filteredPhoneNumbers: [
        {
          label: "Telefon",
          value: this.demand.demandContactPerson.contactDetails.phoneNumber,
        },
      ] as any,
      isExpanded: false,
      isFullyExpanded: false,
      item: {
        border: this.$store.state.vStyle.boardItem.border || undefined,
        color: "card",
        elevation: 2,
        hoverColor: "cardHover",
        colorList: undefined as undefined | string,
      },
      demandService: new DemandService(),
      newTag: "",
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      hasLinking: "hasLinkingForDemand",
    }),
  },
  mounted() {
    this.checkDemandDates();
  },
  watch: {
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromCandidate:
            this.handleCheckMatch(newVal.payload);
            break;
          default:
            this.item.color = "card";
            this.item.hoverColor = "card-hover";
            break;
        }
      },
      deep: true,
    },
    lastUpdateTimeline() {
      if (this.$refs.dependenciesComponent) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).loadLinkingsFromStore();
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).checkForReminders();
      }
    },
  },
  methods: {
    async addTag() {
      const trimmedTag = this.newTag.trim();
      if (!this.demand && trimmedTag !== "") return;
      const demand = Object.assign(this.demand as any);
      if (!Array.isArray(demand.tags)) {
        demand.tags = [];
      }
      if (demand && Array.isArray(demand.tags)) {
        demand.tags.push(trimmedTag);
        this.updateDemand(demand);
        this.newTag = "";
      }
    },
    async checkDemandDates() {
      const askForDeletionAfterDays = 14;
      const today = moment();
      const demandCopy = JSON.parse(JSON.stringify(this.demand));

      for (let i = 0; i < demandCopy.demands.length; i++) {
        const detail = demandCopy.demands[i];
        const deadline = moment(detail.applicationDeadline);
        const diffDays = today.diff(deadline, "days");

        if (deadline.isBefore(today)) {
          this.item.color = "tertiary-darken-1";
          this.item.colorList = "tertiary-darken-1";
          this.item.hoverColor = "tertiary";

          if (diffDays > askForDeletionAfterDays) {
            const confirmed = await DialogService.confirm(
              `Die Anfrage ${detail.position} von ${this.demand.customer} ist seit mehr als ${askForDeletionAfterDays} Tagen abgelaufen!`,
              "Abbrechen",
              "Löschen"
            );

            if (confirmed) {
              if (demandCopy.demands.length < 2) {
                this.$emit("delete", demandCopy._id);
              } else {
                demandCopy.demands.splice(i, 1);
                i--;
                this.updateDemand(demandCopy);
              }
            }
          }
        }
      }
    },
    checkIfShouldGenerateTags() {
      if (!this.demand.tags || this.demand.tags?.length === 0) {
        this.generateAndSetDemandTags();
      }
    },
    formatApplicationDeadline(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
    async generateAndSetDemandTags() {
      const demand = this.demand;
      if (!Array.isArray(demand.tags)) {
        demand.tags = [];
      }

      const demandsSummary = demand.demands?.length
        ? demand.demands
            .map(
              (item) =>
                `Position: ${item.quantity}x ${item.position}, Ort: ${
                  item.location.city
                } (${item.location.postcode}), Beschreibung: ${
                  item.description
                }, Anforderungen: ${item.requirements.join(
                  ", "
                )}, Zusatzinformationen: ${item.additionalInformation}`
            )
            .join("\n")
        : undefined;

      if (demand.tags && demand.demands.length > 0 && demandsSummary) {
        const aiService = new AiService();
        demand.tags = await aiService.generateCustomerTags(demandsSummary);
        this.updateDemand(demand);
      }
    },
    getDemandStateInfo(state: DemandState) {
      return {
        icon: demandStateIcons[state],
        label: getDemandStates()[state],
      };
    },
    getItemHeight(): string {
      const { fullyExpanded, expanded, collapsed } = this.vStyle.itemHeight;

      if (this.isFullyExpanded) return fullyExpanded;
      if (this.isExpanded) return expanded;

      return collapsed;
    },
    handleCheckMatch(payload: any) {
      const demandPostalCode = this.demand.demands[0].location.postcode;

      if (demandPostalCode && payload.postcodes.includes(demandPostalCode)) {
        const perfectmatch = (this.candidatesTagsMatching = checkTagsMatching(
          payload.tags || [],
          this.demand?.tags || [],
          this.$store.state.company.aiData.prompt.company
            .tagsMatchingThreshold ?? 0.3
        ));
        if (perfectmatch) {
          this.item.border = "secondary lg";
          this.item.color = "cardPerfectMatch";
          this.item.colorList = "cardPerfectMatch";
          this.item.hoverColor = "perfectMatchHover";
        } else {
          this.item.border = "primary lg";
          this.item.color = "cardMatch";
          this.item.colorList = "cardMatch";
          this.item.hoverColor = "matchHover";
        }
      } else {
        this.item.elevation = 2;
        this.item.border = this.vStyle.boardItem.border;
        this.item.color = "card";
        this.item.colorList = undefined;
        this.item.hoverColor = "cardHover";
        this.candidatesTagsMatching = false;
      }
    },
    handleDoubleClickOnItem() {
      if (!this.isExpanded) {
        this.toggleExpansion();
      } else if (this.isExpanded && !this.isFullyExpanded) {
        this.toggleFullExpansion();
      } else if (this.isFullyExpanded) {
        this.toggleExpansion();
      }
    },
    handleDragStart(event: DragEvent) {
      this.$store.commit(MutationType.clearDraggingItem);
      if (event.dataTransfer) {
        event.dataTransfer.setData(
          "application/haslinking",
          this.hasLinking(this.demand._id) ? "true" : "false"
        );
        event.dataTransfer.setData(
          "application/originComponent",
          Role.demand as string
        );
      }
      this.$store.commit(MutationType.setDraggingItem, {
        type: Role.demand,
        data: this.demand,
      });
    },
    handleDrop(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer) {
        const originComponent = event.dataTransfer.getData(
          "application/originComponent"
        );

        if (originComponent === Role.candidate) {
          const demand = this.demand;
          if (this.hasLinking(demand._id)) {
            const draggingData = this.$store.state.isDraggingItem
              .candidate as CandidateToCustomerData;
            if (this.$refs.dependenciesComponent) {
              (
                this.$refs.dependenciesComponent as InstanceType<
                  typeof Dependencies
                >
              ).checkDemandLinkingAndGenerateProfil(draggingData, demand);
            }
          } else {
            ToastService.showReminder(
              "Profilgenerierung ist nur bei Anfragen möglich, die mit Unternehmen verlinkt sind!"
            );
          }
        }
        if (originComponent !== Role.customer) {
          this.$nextTick().then(() => {
            this.$store.commit(MutationType.clearDraggingItem);
          });
        }
      }
    },
    removeTag(index: number) {
      if (!this.demand) return;
      const demand = this.demand;
      if (demand && Array.isArray(demand.tags)) {
        demand.tags.splice(index, 1);
      }
      this.updateDemand(demand);
    },
    signatureText() {
      const user = this.$store.state.company.loggedInUser;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.$store.state.company.name}`;
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
      this.checkIfShouldGenerateTags();
      if (!this.isExpanded) {
        this.isFullyExpanded = false;
      }
    },
    toggleFullExpansion() {
      if (this.isExpanded) {
        this.isFullyExpanded = !this.isFullyExpanded;
      }
    },
    updateDemand(demand: Demand) {
      this.demandService.updateDemand(demand).then(() => {
        this.$emit("insertUpdatedDemand", demand);
      });
    },
  },
});
</script>
<style scoped>
.demand-state-hover {
  background: none;
  border: none;
  color: grey;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0.75rem;
  text-shadow: var(--text-shadow);
  bottom: 0;
  z-index: 10;
}
</style>
