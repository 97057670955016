import { RulesMessage } from "../enums/app-messages.enum";
//import store from "../store/store";

//const radiusMax = parseInt(store.getters.getEnv.VUE_APP_RADIUS_MAX);

const radiusMax = 100;
export const formRules = {
  city: (value: string) =>
    /^[a-zA-ZäöüÄÖÜß\s]+$/.test(value) || RulesMessage.city,
  email: (value: string) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
    RulesMessage.invalidMail,
  number: (value: number | null) =>
    (value !== null && !isNaN(value)) || RulesMessage.numberField,
  min: (v: number) => (value: string) =>
    value.length >= v || `Mindestens ${v} Zeichen`,
  minLength: (length: number) => (value: string) =>
    (value && value.length >= length) ||
    `Mindestens ${length} Zeichen erforderlich.`,
  postalCode: (value: string) =>
    /^[0-9]{4,5}$/.test(value) || RulesMessage.postcode,
  phoneNumber: (value: string) =>
    /^[0-9+\-/\s]*$/.test(value) || RulesMessage.phoneNumber,
  radiusRules: [
    (v: number) => !!v || RulesMessage.noRadius,
    (v: number) => (v && v >= 1) || RulesMessage.radiusToShort,
    (v: number) => (v && v <= radiusMax) || `maximal ${radiusMax} km`,
  ],
  required: (value: string | number | null | undefined) => {
    if (value === 0 || value) {
      return true;
    }
    return RulesMessage.required;
  },
  validSlider: (minChar: number, maxChar: number) => (value: number | null) => {
    if (value === null || value === undefined) {
      return true;
    }

    const sliderRangeValid = value >= minChar && value <= maxChar;

    if (!sliderRangeValid) {
      return `Der Slider-Wert muss zwischen ${minChar} und ${maxChar} liegen.`;
    }

    return true;
  },
};
