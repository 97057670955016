export enum Routes {
  administration = "/data",
  backendLogs = "/admin/backend-logs",
  configAi = "/config/ai",
  configApi = "/config/software-integration",
  configCompany = "/config/company",
  configMandant = "/config/mandant",
  configUser = "/config/user",
  dashboard = "/dashboard",
  dataCleaning = "/config/data-cleaning",
  dispostion = "/disposition",
  logout = "/logout",
  passwordRestore = "/password-restore",
  privacyPolicy = "/privacy-policy",
  publicFunnel = "/jobs",
  recruiting = "/recruiting",
  registration = "/registration",
  registrationSuccess = "/registration-success",
  subscription = "/subscription",
  version = "/version",
  worktime = "/worktime",
}
