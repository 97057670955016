import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mb-5 d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_img, {
          class: "mr-3",
          src: _ctx.zorstIcon,
          alt: "Zorst Icon",
          "max-width": "36"
        }, null, 8, ["src"]),
        _cache[2] || (_cache[2] = _createTextVNode("Chrome Browsererweiterung ZORST "))
      ]),
      _createVNode(_component_v_col, {
        cols: "12",
        md: "8"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            variant: _ctx.vStyle.card.variant || undefined,
            rounded: _ctx.vStyle.card.rounded || undefined,
            border: _ctx.vStyle.card.border || undefined
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "d-flex justify-space-between" }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createTextVNode("Download ")),
                  (_ctx.user.role === _ctx.UserRole.UberAdmin)
                    ? (_openBlock(), _createBlock(_component_v_switch, {
                        key: 0,
                        modelValue: _ctx.isDebugMode,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDebugMode) = $event)),
                          _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleZorstDebugging()))
                        ],
                        label: "ZORST debug mode",
                        color: "tertiary"
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Schritt 1: Download der Datei", -1)),
                  _cache[5] || (_cache[5] = _createTextVNode(" Klicke auf diesen Button, um die Datei herunterzuladen:")),
                  _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                  (_ctx.$store.state.company.softwareIntegration.zvooveOne)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        variant: _ctx.vStyle.btn.variant || undefined,
                        rounded: _ctx.vStyle.btn.rounded || undefined,
                        border: _ctx.vStyle.btn.border || undefined,
                        class: "my-3",
                        color: "primary",
                        href: "https://zorst.dispositioner.de/download/zorst_full.zip",
                        target: "_blank"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" ZORST " + _toDisplayString(_ctx.$store.getters.getEnv.VUE_APP_ZORST) + " FULL ", 1)
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"]))
                    : _createCommentVNode("", true),
                  (!_ctx.$store.state.company.softwareIntegration.zvooveOne)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        variant: _ctx.vStyle.btn.variant || undefined,
                        rounded: _ctx.vStyle.btn.rounded || undefined,
                        border: _ctx.vStyle.btn.border || undefined,
                        color: "primary",
                        href: "https://zorst.dispositioner.de/download/zorst_ats.zip",
                        target: "_blank"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" ZORST " + _toDisplayString(_ctx.$store.getters.getEnv.VUE_APP_ZORST) + " ATS ", 1)
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"]))
                    : _createCommentVNode("", true),
                  _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                  _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                  _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Schritt 2: Entpacken der Datei", -1)),
                  _cache[10] || (_cache[10] = _createTextVNode(" 1. Gehe zu dem Ordner, in dem du ")),
                  _cache[11] || (_cache[11] = _createElementVNode("code", null, "zorst.zip", -1)),
                  _cache[12] || (_cache[12] = _createTextVNode(" gespeichert hast.")),
                  _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                  _cache[14] || (_cache[14] = _createTextVNode(" 2. Rechtsklicke auf die Datei und wähle \"Alle extrahieren...\" oder eine ähnliche Option.")),
                  _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                  _cache[16] || (_cache[16] = _createTextVNode(" 3. Entpacke die Datei in einen Zielordner.")),
                  _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                  _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                  _cache[19] || (_cache[19] = _createElementVNode("h3", null, "Schritt 3: Installation der Erweiterung in Chrome", -1)),
                  _cache[20] || (_cache[20] = _createTextVNode(" 1. Öffne Google Chrome.")),
                  _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                  _cache[22] || (_cache[22] = _createTextVNode(" 2. Klicke auf das Dreipunkt-Menü (oben rechts) und wähle \"Erweiterungen\" > \"Erweiterungen verwalten\".")),
                  _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                  _cache[24] || (_cache[24] = _createTextVNode(" 3. Aktiviere den Entwicklermodus (Schalter oben rechts).")),
                  _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                  _cache[26] || (_cache[26] = _createTextVNode(" 4.Klicke auf \"Entpackte Erweiterung laden\".")),
                  _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1)),
                  _cache[28] || (_cache[28] = _createTextVNode(" 5. Wähle den Ordner aus, in den du die Dateien entpackt hast.")),
                  _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                  _cache[30] || (_cache[30] = _createTextVNode(" 6. Klicke auf \"Ordner auswählen\".")),
                  _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
                  _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1)),
                  _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1)),
                  _cache[34] || (_cache[34] = _createElementVNode("p", null, "Fertig! Die Erweiterung ist jetzt installiert.", -1))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["variant", "rounded", "border"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}