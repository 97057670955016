import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "d-flex justify-center" }
const _hoisted_2 = { class: "d-flex justify-space-between" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_EmailServerConfigForm = _resolveComponent("EmailServerConfigForm")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "d-flex" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.editMode ? "Benutzer bearbeiten" : "Neuen Benutzer anlegen") + " ", 1),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDialog())),
            icon: "",
            size: "s",
            variant: "text",
            density: "compact"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode(" fa-solid fa-xmark close-icon ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "form",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.valid) = $event)),
            onSubmit: _withModifiers(_ctx.saveUser, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.newUser.salutation,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newUser.salutation) = $event)),
                        items: _ctx.salutationOptions,
                        density: "compact",
                        label: "Anrede",
                        required: ""
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.newUser.forename,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUser.forename) = $event)),
                        rules: [_ctx.rules.required],
                        density: "compact",
                        label: "Vorname",
                        required: ""
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.newUser.lastname,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newUser.lastname) = $event)),
                        rules: [_ctx.rules.required],
                        density: "compact",
                        label: "Nachname",
                        required: ""
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.newUser.role,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newUser.role) = $event)),
                        items: _ctx.roleOptions,
                        rules: [_ctx.rules.required],
                        density: "compact",
                        label: "Rolle",
                        required: ""
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.newUser.tel,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newUser.tel) = $event)),
                        rules: [_ctx.rules.phoneNumber],
                        density: "compact",
                        label: "Telefon",
                        required: ""
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.newUser.mobilePhone,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newUser.mobilePhone) = $event)),
                        rules: [_ctx.rules.phoneNumber],
                        density: "compact",
                        label: "Handy",
                        required: ""
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.$store.state.company.softwareIntegration.zvooveOne)
                ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newUser.config.zvooveOneUuid,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newUser.config.zvooveOneUuid) = $event)),
                            label: "zvoove One Uuid",
                            density: "compact"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newUser.config.zvooveNextLevelApi,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newUser.config.zvooveNextLevelApi) = $event)),
                            label: "nextLevel API",
                            density: "compact"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_divider, { class: "mb-5" }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.newUser.email,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newUser.email) = $event)),
                        rules: [_ctx.rules.email],
                        label: "E-Mail",
                        density: "compact",
                        required: "",
                        disabled: _ctx.editMode
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules", "disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "8"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        (!_ctx.newUser.config.mailServer)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 0,
                              block: "",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.addMailServerConfig()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                                    _createTextVNode("fas fa-plus")
                                  ])),
                                  _: 1
                                }),
                                _cache[17] || (_cache[17] = _createTextVNode("Mailserver Hinzufügen"))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      (_ctx.newUser.config.mailServer)
                        ? (_openBlock(), _createBlock(_component_v_card, {
                            key: 0,
                            variant: "outlined",
                            class: "mb-5"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_subtitle, { class: "d-flex justify-space-between mt-2" }, {
                                default: _withCtx(() => [
                                  _cache[19] || (_cache[19] = _createTextVNode("Mailserver ")),
                                  _createVNode(_component_v_btn, {
                                    icon: "",
                                    variant: "text",
                                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.removeMailserverConfig()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                                          _createTextVNode("fa-solid fa-trash-can")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_card_text, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_EmailServerConfigForm, {
                                    modelValue: _ctx.newUser.config.mailServer
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider, { class: "mb-5" }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_btn, {
                  variant: _ctx.vStyle.btn.variant || undefined,
                  rounded: _ctx.vStyle.btn.rounded || undefined,
                  border: _ctx.vStyle.btn.border || undefined,
                  onClick: _ctx.toggleMandants,
                  color: "primary",
                  class: "mb-5"
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("Niederlassungen")
                  ])),
                  _: 1
                }, 8, ["variant", "rounded", "border", "onClick"]),
                _createVNode(_component_v_switch, {
                  modelValue: _ctx.newUser.config.hasAiCoaching,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newUser.config.hasAiCoaching) = $event)),
                  label: "Recurion Coaching für diesen Benutzer aktivieren",
                  color: "primary"
                }, null, 8, ["modelValue"])
              ]),
              (_ctx.newUser && _ctx.mandants && _ctx.showMandants)
                ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant) => {
                        return (_openBlock(), _createBlock(_component_v_col, {
                          key: mandant.uuid,
                          cols: "12",
                          md: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_checkbox, {
                              label: mandant.name,
                              value: mandant.uuid,
                              modelValue: _ctx.newUser.config.mandants,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newUser.config.mandants) = $event)),
                              color: "primary"
                            }, null, 8, ["label", "value", "modelValue"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  (_ctx.saveError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.saveError), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_btn, {
                    onClick: _ctx.closeDialog,
                    color: "abort"
                  }, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode("Abbrechen")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    type: "submit",
                    color: "success",
                    disabled: !_ctx.valid
                  }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode("Speichern")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onSubmit"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}