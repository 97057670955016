<template>
  <v-container class="fill-height d-flex justify-center align-center">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="3">
        <v-card
          color="var(--color-item)"
          class="my-10"
          border="sm"
          rounded="lg"
          elevation="4"
        >
          <v-card-item align="center" justify="center">
            <v-img
              width="100%"
              aspect-ratio="1/1"
              cover
              :src="loginLogo"
            ></v-img>
            <!--TODO if we need more templates change this to a templateObject-->
          </v-card-item>
          <v-form class="pa-5" @submit.prevent="authUser">
            <v-divider></v-divider>
            <v-card-title>Login</v-card-title>
            <v-text-field
              variant="outlined"
              v-model="username"
              label="Email"
              type="email"
              required
              :error="loginError"
              @update:model-value="resetError()"
            ></v-text-field>
            <v-text-field
              variant="outlined"
              v-model="password"
              label="Passwort"
              type="password"
              required
              :error="loginError"
              :error-messages="
                loginError ? 'Ungültiger Benutzername oder Passwort' : ''
              "
              @update:model-value="resetError()"
            ></v-text-field>
            <v-row class="d-flex justify-space-between">
              <v-col>
                <span
                  class="password-forgot"
                  @click="showPasswordForgottenModal"
                  >Passwort vergessen?</span
                >
              </v-col>
              <v-col class="text-right">
                <a :href="Routes.registration" class="register">Registrieren</a>
              </v-col>
            </v-row>
            <v-btn
              :loading="isLoggingIn"
              type="submit"
              color="primary"
              block
              class="mt-4 mb-6"
            >
              Einloggen
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <PasswordForgottenDialog
    v-if="passwordForgottenModal"
    model-value=""
    :requestError="passwordRequestError"
    @cancel="passwordForgottenModal = false"
    @sent="passwordForgottenModal = false"
    @click:outside="handlePasswordBackgroundClick"
  ></PasswordForgottenDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LoginService } from "@/services/api/login.service";
import ToastService from "@/services/toast.service";
import { AppLayout } from "@/enums/app-layout.enum";
import { Routes } from "@/enums/routes.enum";
import PasswordForgottenDialog from "../components/config/user/dialogs/PasswordForgottenDialog.vue";
import { ActionType } from "../enums/vuex-types.enum";

export default defineComponent({
  name: "LoginView",
  components: {
    PasswordForgottenDialog,
  },
  data() {
    return {
      AppLayout: AppLayout,
      appLayout: this.$store.getters.appLayout,
      currentRoute: "",
      isLoggingIn: false,
      loginError: false,
      loginLogo: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/app-layout/${this.$store.getters.appLayout}/login-logo.svg`,
      loginService: new LoginService(),
      password: "",
      passwordForgottenModal: false,
      passwordRequestError: "",
      Routes: Routes,
      username: "",
    };
  },
  created() {
    localStorage.removeItem("accessToken");
    this.currentRoute = this.$router.currentRoute.value.path;
  },
  mounted() {
    sessionStorage.removeItem("vuexState");
    this.loginService.logout();
  },
  methods: {
    async authUser() {
      let loginOk = false;
      this.isLoggingIn = true;
      this.loginError = false; // Reset error state before attempting login
      try {
        await this.loginService
          .login({ username: this.username, password: this.password })
          .then(() => {
            loginOk = true;
            this.$store.dispatch(ActionType.loadStoreInitialData).then(() => {
              this.navigateToDashboard();
              this.$store.dispatch(
                ActionType.loadCustomerAndEmployeeBackgroundData
              );
            });
          });
      } catch (error: any) {
        switch (error.code) {
          case "ERR_BAD_REQUEST":
            ToastService.show("Name oder Passwort fehlerhaft");
            this.loginError = true;
            break;
          default:
        }
      } finally {
        if (!loginOk) this.isLoggingIn = false;
      }
    },
    handlePasswordBackgroundClick() {
      this.passwordForgottenModal = false;
    },
    navigateToDashboard() {
      this.$router.push(Routes.dashboard);
    },
    showPasswordForgottenModal() {
      this.passwordForgottenModal = true;
    },
    resetError() {
      this.loginError = false;
    },
  },
});
</script>

<style scoped lang="scss">
@import "./scss/styles.scss";

.fill-height {
  height: 100vh;
}
.password-forgot,
.register {
  color: var(--color-primary);
  cursor: pointer;
}

.password-forgot:hover,
.register:hover {
  color: var(--color-tertiary);
}
</style>
