import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "mr-2"
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("fa-solid fa-building")
          ])),
          _: 1
        }),
        _cache[13] || (_cache[13] = _createTextVNode(" Prompts: Unternehmensebene "))
      ]),
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.weAre,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localAiData.prompt.company.weAre) = $event)),
                    label: "Unternehmensbeschreibung",
                    rows: "6",
                    class: "mb-4"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.weAreShort,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localAiData.prompt.company.weAreShort) = $event)),
                    label: "Wer wir sind (kurz)",
                    rows: "6",
                    class: "mb-4"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.profileCandidate,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localAiData.prompt.company.profileCandidate) = $event)),
                    label: "Unternehmensvorgaben Kandidatenprofil",
                    rows: "6",
                    class: "mb-4"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.kpiAnalysis,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localAiData.prompt.company.kpiAnalysis) = $event)),
                    label: "Vorgaben zur AI Analyse auf dem Dashboard",
                    rows: "6",
                    class: "mb-4"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider, { class: "mb-5" }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.whatsAppCandidate,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localAiData.prompt.company.whatsAppCandidate) = $event)),
                    label: "WhatsApp an einen Kandidaten",
                    rows: "6"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.mailCandidate,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localAiData.prompt.company.mailCandidate) = $event)),
                    label: "Email an einen Kandidaten",
                    rows: "6"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider, { class: "mb-5" }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.tagsCandidate,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localAiData.prompt.company.tagsCandidate) = $event)),
                    label: "Tags erstellen für Kandidaten",
                    rows: "6",
                    class: "mb-4"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.tagsCompany,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localAiData.prompt.company.tagsCompany) = $event)),
                    label: "Tags erstellen für Kunden",
                    rows: "6"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "2",
                class: "text-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    color: "primary",
                    small: ""
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" fas fa-crosshairs ")
                    ])),
                    _: 1
                  }),
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-caption" }, "Exakte Übereinstimmung", -1))
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "8" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_subtitle, { class: "d-flex justify-center" }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode("Genauigkeit des Tags-Matching-Algorithmus festlegen")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_slider, {
                    modelValue: _ctx.localAiData.prompt.company.tagsMatchingThreshold,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localAiData.prompt.company.tagsMatchingThreshold) = $event)),
                    min: "0",
                    max: "1",
                    step: "0.01",
                    "tick-size": "4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "2",
                class: "text-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    color: "primary",
                    small: ""
                  }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode(" fas fa-globe ")
                    ])),
                    _: 1
                  }),
                  _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-caption" }, "Mehr Treffer", -1))
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider, { class: "mb-5" }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.mailCustomer,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localAiData.prompt.company.mailCustomer) = $event)),
                    label: "Email an einen Kunden",
                    rows: "6"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider, { class: "mb-5" }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.mailEmployee,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localAiData.prompt.company.mailEmployee) = $event)),
                    label: "Email an einen Mitarbeiter",
                    rows: "6",
                    class: "mb-4"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localAiData.prompt.company.whatsAppEmployee,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localAiData.prompt.company.whatsAppEmployee) = $event)),
                    label: "WhatsApp an einen Mitarbeiter",
                    rows: "6",
                    class: "mb-4"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            variant: _ctx.vStyle.btn.variant || undefined,
            rounded: _ctx.vStyle.btn.rounded || undefined,
            border: _ctx.vStyle.btn.border || undefined,
            class: "mt-10",
            type: "submit",
            color: "success"
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createTextVNode("Speichern")
            ])),
            _: 1
          }, 8, ["variant", "rounded", "border"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}