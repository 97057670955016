import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_vue_cal = _resolveComponent("vue-cal")!
  const _component_CandidateItem = _resolveComponent("CandidateItem")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_select, {
      variant: _ctx.vStyle.input.variant || undefined,
      rounded: _ctx.vStyle.input.rounded || undefined,
      "base-color": _ctx.vStyle.input.baseColor || undefined,
      color: _ctx.vStyle.input.color || undefined,
      density: "compact",
      modelValue: _ctx.selectedStatuses,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatuses) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchAppointments()))
      ],
      width: "32rem",
      items: _ctx.statusOptions,
      "item-title": "label",
      "item-value": "value",
      multiple: "",
      label: "Terminarten filtern",
      outlined: "",
      dense: "",
      clearable: "",
      class: "mt-5"
    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"]),
    _createVNode(_component_vue_cal, {
      locale: "de",
      "time-from": 6 * 60,
      "time-to": 20 * 60,
      "time-step": 60,
      events: _ctx.filteredAppointments,
      view: "week",
      "hide-view-selector": "",
      "hide-weekends": "",
      onEventClick: _ctx.eventClicked
    }, null, 8, ["events", "onEventClick"]),
    _createVNode(_component_v_dialog, {
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)',
      modelValue: _ctx.showCandidateItem,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showCandidateItem) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CandidateItem, {
          candidate: _ctx.clickedCandidate,
          candidateDataInput: _ctx.clickedCandidateData,
          interComponentMessage: _ctx.interComponentMessage,
          isActive: false,
          isFullyCollapsed: false,
          softwareIntegration: _ctx.softwareIntegration,
          lastUpdateTimeline: _ctx.lastUpdateTimeline,
          user: _ctx.user,
          onCloseDialog: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCandidateItem = false))
        }, null, 8, ["candidate", "candidateDataInput", "interComponentMessage", "softwareIntegration", "lastUpdateTimeline", "user"])
      ]),
      _: 1
    }, 8, ["max-width", "modelValue"])
  ], 64))
}