import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "ml-4 mr-2 text-body-2 text-medium-emphasis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ProfileEditor = _resolveComponent("ProfileEditor")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_textarea = _resolveComponent("v-textarea")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.existingProfilesModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.existingProfilesModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _cache[24] || (_cache[24] = _createTextVNode(" Vorhandene Profile ")),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModals())),
                  icon: "",
                  size: "s",
                  variant: "text",
                  density: "compact"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode(" fa-solid fa-xmark close-icon ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  variant: _ctx.vStyle.btn.variant || undefined,
                  rounded: _ctx.vStyle.btn.rounded || undefined,
                  border: _ctx.vStyle.btn.border || undefined,
                  block: "",
                  color: "primary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSelectMatchingDataModal()))
                }, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createTextVNode("Neues Profil generieren")
                  ])),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileSourceData.profiles, (profile) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: profile._id,
                        onClick: ($event: any) => (_ctx.getExistingProfile(profile))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            (profile.uuid)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createElementVNode("img", {
                                    src: `${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-ats-recruit.svg`,
                                    class: "zvoove-recruit-icon",
                                    alt: "zvoove Recruit Icon"
                                  }, null, 8, _hoisted_3)
                                ]))
                              : _createCommentVNode("", true),
                            (profile.html)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                  _createElementVNode("img", {
                                    src: `${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/app-layout/${_ctx.$store.getters.appLayout}/favicon/favicon.ico`,
                                    class: "zvoove-recruit-icon",
                                    alt: "Dispositioner:er Icon"
                                  }, null, 8, _hoisted_5)
                                ]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(profile.description), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.selectMatchingDataModal,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectMatchingDataModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _cache[27] || (_cache[27] = _createTextVNode(" individualisiertes Profil generieren ")),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeModals())),
                  icon: "",
                  size: "s",
                  variant: "text",
                  density: "compact"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                        _createTextVNode(" fa-solid fa-xmark close-icon ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  variant: _ctx.vStyle.btn.variant || undefined,
                  rounded: _ctx.vStyle.btn.rounded || undefined,
                  border: _ctx.vStyle.btn.border || undefined,
                  block: "",
                  color: "primary",
                  class: "mb-5",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.generateProfile()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.profileSourceData.tags.length > 0
                ? "Profil passend auf Firmentags generieren"
                : "AI Profil generieren"), 1)
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                (_ctx.profileSourceData?.demands?.length > 0)
                  ? (_openBlock(), _createBlock(_component_v_list, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_subtitle, null, {
                          default: _withCtx(() => _cache[28] || (_cache[28] = [
                            _createTextVNode("passend auf Anfrage generieren:")
                          ])),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileSourceData?.demands, (demand) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: demand.jobTitle,
                            onClick: ($event: any) => (_ctx.generateProfile(demand))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(demand.jobTitle), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.profileSourceData?.jobAds?.length > 0)
                  ? (_openBlock(), _createBlock(_component_v_list, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_subtitle, null, {
                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                            _createTextVNode("passend auf Stellenanzeige generieren:")
                          ])),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileSourceData.jobAds, (jobAd) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: jobAd.jobTitle,
                            onClick: ($event: any) => (_ctx.generateProfile(jobAd))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(jobAd.jobTitle), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.availabilityModal,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.availabilityModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _cache[31] || (_cache[31] = _createTextVNode(" Verfügbarkeit / Anonymisierung ")),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closeModals())),
                  icon: "",
                  size: "s",
                  variant: "text",
                  density: "compact"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[30] || (_cache[30] = [
                        _createTextVNode(" fa-solid fa-xmark close-icon ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_combobox, {
                  variant: _ctx.vStyle.input.variant || undefined,
                  rounded: _ctx.vStyle.input.rounded || undefined,
                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                  color: _ctx.vStyle.input.color || undefined,
                  modelValue: _ctx.tempAvailability,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tempAvailability) = $event)),
                  items: _ctx.availabilityOptions,
                  label: "Verfügbarkeit",
                  density: "compact",
                  onKeydown: _withKeys(_ctx.updateAvailability, ["enter"])
                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items", "onKeydown"]),
                _createVNode(_component_v_divider, { class: "mb-5 mt-3" }),
                _createVNode(_component_v_label, null, {
                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                    _createTextVNode("Nachname anonymisieren?")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_switch, {
                  modelValue: _ctx.isLastnameAnonymized,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isLastnameAnonymized) = $event)),
                  class: "ml-1",
                  color: "primary",
                  label: _ctx.isLastnameAnonymized ? 'Vorname N.' : 'Vorname Nachname',
                  density: "compact"
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_v_divider)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  variant: "text",
                  color: "success",
                  onClick: _ctx.updateAvailability
                }, {
                  default: _withCtx(() => [
                    _cache[34] || (_cache[34] = _createTextVNode("Weiter ")),
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "bottom"
                    }, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createTextVNode("auswählen und 'Weiter' oder manuell ins Textfeld eingeben und mit 'Enter' bestätigen")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    (_ctx.profileEditorModal)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          modelValue: _ctx.profileEditorModal,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.profileEditorModal) = $event)),
          persistent: "",
          "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : _ctx.widthDialogProfileGenerator
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "d-flex" }, {
                  default: _withCtx(() => [
                    _cache[36] || (_cache[36] = _createTextVNode(" Profil Editor ")),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.closeModals())),
                      icon: "",
                      size: "s",
                      variant: "text",
                      density: "compact"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[35] || (_cache[35] = [
                            _createTextVNode(" fa-solid fa-xmark close-icon ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "mt-5 mx-3" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          density: "compact",
                          label: "Dateiname Profil",
                          modelValue: _ctx.profileFileName,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.profileFileName) = $event))
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                        _createVNode(_component_v_btn, {
                          icon: "",
                          variant: "text",
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.saveHtmlInCandidate()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[37] || (_cache[37] = [
                                _createTextVNode("fa-solid fa-floppy-disk")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "top"
                            }, {
                              default: _withCtx(() => _cache[38] || (_cache[38] = [
                                _createTextVNode("Profil abspeichern")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          icon: "",
                          variant: "text"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[39] || (_cache[39] = [
                                _createTextVNode("fas fa-arrows-rotate")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_menu, { activator: "parent" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item, {
                                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.generateNewProfil()))
                                    }, {
                                      prepend: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[40] || (_cache[40] = [
                                            _createTextVNode("fas fa-rocket")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => _cache[41] || (_cache[41] = [
                                            _createTextVNode("Neu generieren")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_list_item, {
                                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.promptModal = true))
                                    }, {
                                      prepend: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[42] || (_cache[42] = [
                                            _createTextVNode("fas fa-comment")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => _cache[43] || (_cache[43] = [
                                            _createTextVNode("mit individuellem Prompt")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    (Object.keys(_ctx.generatedProfileJSON).length > 0)
                                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                                          key: 0,
                                          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.toggleAiCareerSteps()))
                                        }, {
                                          prepend: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.useAiCareerSteps
                          ? "fas fa-wand-magic-sparkles"
                          : "fas fa-address-card"), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.useAiCareerSteps
                        ? "Werdegang aus Rohdaten"
                        : "AI zusammengefasster Werdegang"), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "success",
                          onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.saveHtmlAndGenerateEmail()))
                        }, {
                          default: _withCtx(() => [
                            _cache[45] || (_cache[45] = _createTextVNode("übernehmen & senden")),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "top left"
                            }, {
                              default: _withCtx(() => _cache[44] || (_cache[44] = [
                                _createTextVNode("Profil speichern und E-Mail für den Ansprechpartner des Kunden generieren!")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: _ctx.isWidened ? 8 : 12
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ProfileEditor, {
                              ref: "profileEditorComponent",
                              generatedProfile: _ctx.generatedProfileJSON,
                              profileData: _ctx.profileDataJSON,
                              loadedProfile: _ctx.loadedProfile,
                              mandantUuid: _ctx.mandantUuid,
                              availability: _ctx.availability
                            }, null, 8, ["generatedProfile", "profileData", "loadedProfile", "mandantUuid", "availability"])
                          ]),
                          _: 1
                        }, 8, ["cols"]),
                        (_ctx.isWidened)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "4",
                              class: "my-15"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, {
                                  class: "my-15 mx-0 px-0",
                                  color: "primary",
                                  variant: "tonal",
                                  style: {"z-index":"1000"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list, { class: "mx-0" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item, { class: "mx-0" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card_title, null, {
                                                  default: _withCtx(() => _cache[46] || (_cache[46] = [
                                                    _createElementVNode("h2", null, "Werdegang", -1)
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileSourceData.careerSteps, (step, index) => {
                                              return (_openBlock(), _createBlock(_component_v_list_item, {
                                                class: "mx-0",
                                                key: index
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_card_title, null, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(step.title), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_v_card_subtitle, null, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("p", null, "Bei: " + _toDisplayString(step.at), 1),
                                                      _createElementVNode("p", null, " Von: " + _toDisplayString(_ctx.formatDate(step.from)) + " Bis: " + _toDisplayString(_ctx.formatDate(step.until)), 1),
                                                      _createVNode(_component_v_divider, { class: "my-2" })
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createElementVNode("p", _hoisted_6, _toDisplayString(step.details), 1)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            }), 128))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_btn, {
              onClick: _ctx.toggleProfileEditorWidth,
              icon: "",
              size: "s",
              variant: "text",
              density: "compact",
              style: {"position":"absolute","right":"0.8rem","top":"50%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isWidened ? "fa-solid fa-chevron-left" : "fa-solid fa-chevron-right"), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "left"
                }, {
                  default: _withCtx(() => _cache[47] || (_cache[47] = [
                    _createTextVNode("Lebenslauf aus den Kandidatendaten ein- bzw. ausblenden")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["modelValue", "max-width"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.promptModal,
      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.promptModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _cache[49] || (_cache[49] = _createTextVNode(" Prompt: Auf was soll ich achten? ")),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.promptModal = false)),
                  icon: "",
                  size: "s",
                  variant: "text",
                  density: "compact"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode(" fa-solid fa-xmark close-icon ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_textarea, {
                  variant: _ctx.vStyle.input.variant || undefined,
                  rounded: _ctx.vStyle.input.rounded || undefined,
                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                  color: _ctx.vStyle.input.color || undefined,
                  modelValue: _ctx.customPrompt,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.customPrompt) = $event))
                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.promptModal = false)),
                      color: "abort"
                    }, {
                      default: _withCtx(() => _cache[50] || (_cache[50] = [
                        _createTextVNode("Abbrechen")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.generateNewProfil(_ctx.customPrompt))),
                      color: "success"
                    }, {
                      default: _withCtx(() => _cache[51] || (_cache[51] = [
                        _createTextVNode("Neu generieren")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ]))
}