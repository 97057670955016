import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_JobFunnelEdit = _resolveComponent("JobFunnelEdit")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        md: "2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "ml-2 mt-5" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobFunnels, (funnel) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  cols: "12",
                  key: funnel._id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      variant: 
              funnel._id === _ctx.currentFunnel
                ? 'flat'
                : _ctx.vStyle.card.variant || undefined
            ,
                      color: funnel._id === _ctx.currentFunnel ? 'secondary' : undefined,
                      rounded: _ctx.vStyle.card.rounded || undefined,
                      border: _ctx.vStyle.card.border || undefined,
                      onClick: ($event: any) => (_ctx.editJobFunnel(funnel))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "d-flex justify-space-between" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(funnel.companyConfig.title) + " ", 1),
                            _createVNode(_component_v_btn, {
                              icon: "",
                              variant: "text",
                              onClick: ($event: any) => (_ctx.confirmDeleteFunnel(funnel._id || ''))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                                    _createTextVNode("fa-solid fa-trash-can")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_card_subtitle, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(funnel.funnelHeader), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            (funnel.design.funnelHeader)
                              ? (_openBlock(), _createBlock(_component_v_img, {
                                  key: 0,
                                  width: "100%",
                                  "aspect-ratio": "1/1",
                                  cover: "",
                                  src: funnel.design.funnelHeader
                                }, null, 8, ["src"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_v_sheet, {
                              height: "8rem",
                              color: funnel.design.bgQuestionForm,
                              class: "d-flex justify-center flex-wrap"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(funnel.questions, (question, index) => {
                                  return (_openBlock(), _createBlock(_component_v_sheet, {
                                    key: index,
                                    width: "4rem",
                                    height: "2rem",
                                    class: "mt-4 mx-4 mb-0",
                                    color: funnel.design.primary
                                  }, null, 8, ["color"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["color"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["variant", "color", "rounded", "border", "onClick"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    variant: _ctx.getCardVariant(),
                    color: _ctx.getCardColor(),
                    rounded: _ctx.vStyle.card.rounded || undefined,
                    border: "dashed",
                    class: "mt-4",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newJobFunnel()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("fa-solid fa-plus")
                            ])),
                            _: 1
                          }),
                          _cache[4] || (_cache[4] = _createTextVNode(" Funnel "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["variant", "color", "rounded"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        cols: "12",
        md: "10"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_JobFunnelEdit, {
            ref: "jobFunnelEditComponent",
            onLoadJobFunnels: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadJobFunnels()))
          }, null, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}