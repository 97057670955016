<template>
  <ValidateDeletionDialog
    :show="showValidateDeletionDialog"
    :email="deleteUserEmail"
    @validate="deleteMandantByUuid"
    @update:show="updateValidateDeletionDialogVisibility"
  />
  <v-dialog
    v-model="showMandantDialog"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xl)'"
    class="xs12 md6"
  >
    <MandantDialog
      :editMode="editMode"
      :editMandantModel="mandantModel"
      :allMandants="mandants"
      @closeDialog="updateDialogVisibility(false)"
    />
  </v-dialog>
  <AdminMenu />
  <AppHeader v-if="layout !== AppLayout.classic"></AppHeader>
  <v-container :fluid="true">
    <div class="d-flex flex-row-reverse mt-4 mb-4">
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        v-if="getUserRole() <= UserRole.Admin"
        density="comfortable"
        size="large"
        @click="addMandant()"
      >
        <v-icon icon="fa-solid fa-plus" />
      </v-btn>
    </div>
    <v-row v-if="mandants.length > 0">
      <v-col
        v-for="mandant in mandants"
        :key="mandant.uuid"
        :name="mandant.name"
        cols="12"
        md="3"
      >
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          v-if="getUserRole() <= UserRole.BranchManager"
        >
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            v-if="getUserRole() <= UserRole.Admin"
            size="large"
            style="position: absolute; right: 0; padding: 0; min-width: 3rem"
            @click="showDeleteMandantDialog(mandant)"
          >
            <v-icon icon="fa-solid fa-trash-can" />
          </v-btn>
          <v-card-item>
            <div>
              <div class="text-overline mb-1">
                Nr. {{ mandant.branchNumber }} | {{ mandant.uuid }}
              </div>
              <div class="text-h6 mb-1">
                {{ mandant.name }} ({{ mandant.branchInitials }})
              </div>
              <div class="text-caption">
                <div v-html="mandant.contact" />
                <div>
                  <i class="fa-brands fa-whatsapp"></i>
                  {{ mandant.whatsApp }}
                </div>
                <div
                  v-if="$store.state.company.softwareIntegration.zvooveRecruit"
                >
                  <v-divider class="my-3"></v-divider>
                  {{ mandant.zvoovename }}
                </div>
                <div
                  v-if="$store.state.company.softwareIntegration.zvooveRecruit"
                >
                  {{ mandant.EmailEingangskontoId }}
                </div>
              </div>
            </div>
          </v-card-item>
          <v-card-actions>
            <v-btn
              :variant="vStyle.btn.variant || undefined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              v-if="getUserRole() < 11"
              color="success"
              @click="openEditDialog(mandant)"
              class="edit-btn"
            >
              Bearbeiten
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="mandants.length === 0" class="no-entries">
      Keine Eintr&auml;ge vorhanden
    </div>
  </v-container>
</template>

<script lang="ts">
import { Mandant } from "@/models/mandant.model";
import MandantDialog from "@/components/config/mandant/mandant.dialog.vue";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import ValidateDeletionDialog from "@/components/modals/validate-deletion.modal.vue";
import { defineComponent } from "vue";
import { MandantService } from "@/services/api/mandant.service";
import { UserHelperService } from "@/services/user-helper.service";
import AppHeader from "@/components/header/Header.vue";
import { AppLayout } from "@/enums/app-layout.enum";
import { useDisplay } from "vuetify";
import { UserRole } from "../../../enums/user-role.enum";

export default defineComponent({
  name: "ConfigMandant",
  components: {
    AdminMenu,
    MandantDialog,
    AppHeader,
    ValidateDeletionDialog,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      deleteUserEmail: "",
      editMode: false,
      mandantModel: undefined as undefined | Mandant,
      mandants: {} as Mandant[],
      mandantService: {} as MandantService,
      AppLayout: AppLayout,
      layout: this.$store.getters.appLayout,
      showValidateDeletionDialog: false,
      showMandantDialog: false,
      UserRole,
      vStyle: this.$store.state.vStyle,
    };
  },
  created(): void {
    this.mandantService = new MandantService();
    this.loadMandants();
  },
  methods: {
    addPostalcode(mandant: Mandant) {
      console.log("Add postalcode", mandant);
    },

    addMandant() {
      this.mandantModel = undefined;
      this.editMode = false;
      this.showMandantDialog = true;
    },

    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },

    deleteMandantByUuid(uuid: string) {
      this.showValidateDeletionDialog = false;
      this.mandantService.deleteMandant(uuid);
      this.loadMandants();
    },

    showDeleteMandantDialog(mandant: Mandant) {
      this.deleteUserEmail = mandant.uuid;
      this.showValidateDeletionDialog = true;
    },

    loadMandants() {
      this.mandantService
        .getMandants()
        .then((result: any) => (this.mandants = Object.assign(result)));
    },

    openEditDialog(mandant: Mandant) {
      this.editMode = true;
      this.mandantModel = mandant;
      this.showMandantDialog = true;
    },

    updateDialogVisibility(newValue: boolean) {
      this.showMandantDialog = newValue;
      this.editMode = false;
      this.loadMandants();
    },

    updateValidateDeletionDialogVisibility(newValue: boolean) {
      this.showValidateDeletionDialog = newValue;
      this.loadMandants();
    },
  },
});
</script>
<style scoped>
.no-entries {
  font-size: x-large;
  text-align: center;
  width: 100%;
}

.v-card {
  height: 100%; /* Oder einen spezifischen Wert, z.B. 300px */
}
.container {
  padding: 1rem;
  width: calc(100vw - 2rem);
}
.title {
  display: flex;
  justify-content: space-between;
  color: var(--color-primary);
}
.edit-btn {
  position: absolute;
  bottom: 1rem;
  left: calc(50% - 3.75rem);
}
</style>
