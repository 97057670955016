import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-5 d-flex align-end" }
const _hoisted_2 = { class: "ml-1 mt-3" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_4 = { class: "d-flex text-medium-emphasis" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "mr-1"
}
const _hoisted_7 = {
  key: 2,
  class: "mr-1"
}
const _hoisted_8 = {
  key: 3,
  class: "mr-1"
}
const _hoisted_9 = {
  key: 4,
  class: "mr-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_DialogAddEditPayFlow = _resolveComponent("DialogAddEditPayFlow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_img, {
        src: _ctx.logoPayFlow,
        "aspect-ratio": "1",
        cover: "",
        alt: "PayFlow Icon",
        "max-width": "4rem",
        class: "ml-4 mr-6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("= Eintrag in Lohnlaufinfo oder Vorschussliste")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["src"]),
      _createVNode(_component_v_select, {
        variant: "plain",
        density: "compact",
        items: _ctx.payrollMonths,
        modelValue: _ctx.currentPayrollMonth,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPayrollMonth) = $event)),
        label: "Abrechnungsmonat"
      }, null, 8, ["items", "modelValue"]),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_btn, {
        icon: "",
        size: "small",
        color: "primary",
        style: { color: 'var(--color-on-primary)' },
        onClick: _ctx.newPayFlow
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("fas fa-plus")
            ])),
            _: 1
          }),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Neuen PayFlow hinzufügen")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payFlows, (payFlow) => {
      return (_openBlock(), _createBlock(_component_v_list_item, {
        key: payFlow._id
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item_title, { class: "d-flex align-end font-weight-medium" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(payFlow.payrollMonth || "monatlich") + " ", 1),
              _createElementVNode("strong", _hoisted_2, _toDisplayString(payFlow.infoType), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkedByRoles, (role) => {
                return (_openBlock(), _createElementBlock("div", { key: role }, [
                  (_ctx.getBadgeConfig(payFlow, role) && !_ctx.isPayFlowCompleted(payFlow))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_v_badge, {
                          color: _ctx.getBadgeConfig(payFlow, role)?.color,
                          style: { scale: 0.8 },
                          content: _ctx.getBadgeConfig(payFlow, role)?.content,
                          overlap: "",
                          "offset-x": "0",
                          "offset-y": "-5",
                          class: "mx-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { class: "text-medium-emphasis" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getBadgeConfig(payFlow, role)?.icon), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["color", "content"]),
                        _createVNode(_component_v_tooltip, {
                          activator: "parent",
                          location: "end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getBadgeConfig(payFlow, role)?.tooltip), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 2
          }, 1024),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isPayFlowCompleted(payFlow))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_v_badge, {
                    color: "success",
                    style: { scale: 0.8 },
                    content: "✔",
                    overlap: "",
                    "offset-x": "0",
                    "offset-y": "0",
                    class: "mr-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "text-medium-emphasis" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("fas fa-money-bill-wave")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "end"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Abgeschlossen ")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (payFlow.monetaryAmount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[6] || (_cache[6] = _createTextVNode(" Betrag ")),
                  _createElementVNode("strong", null, _toDisplayString(payFlow.monetaryAmount), 1),
                  _cache[7] || (_cache[7] = _createTextVNode("€ "))
                ]))
              : _createCommentVNode("", true),
            (payFlow.hours)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("strong", null, _toDisplayString(payFlow.hours), 1),
                  _cache[8] || (_cache[8] = _createTextVNode(" Stunden "))
                ]))
              : _createCommentVNode("", true),
            (payFlow.dateFrom)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.formatDate(payFlow.dateFrom)), 1))
              : _createCommentVNode("", true),
            (payFlow.dateTo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, " bis " + _toDisplayString(_ctx.formatDate(payFlow.dateTo)), 1))
              : _createCommentVNode("", true),
            (!_ctx.isPayFlowRecordedInErp(payFlow) || !_ctx.isPayFlowCompleted(payFlow))
              ? (_openBlock(), _createBlock(_component_v_list_item_action, {
                  key: 5,
                  class: "text-high-emphasis"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      size: "small",
                      variant: "text",
                      density: "compact",
                      disabled: _ctx.isPayFlowRecordedInErp(payFlow),
                      onClick: ($event: any) => (_ctx.editPayFlow(payFlow))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { size: "xs" }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode("fas fa-pencil")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"]),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      size: "small",
                      variant: "text",
                      density: "compact",
                      disabled: _ctx.isPayFlowRecordedInErp(payFlow),
                      onClick: ($event: any) => (_ctx.removePayFlow(payFlow._id))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { size: "xs" }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode("fas fa-trash-can")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_v_divider),
    _createVNode(_component_DialogAddEditPayFlow, {
      ref: "dialogAddEditPayFlowComponent",
      payFlow: _ctx.currentPayFlow,
      isEdit: _ctx.editCurrentPayFlow,
      onAddPayFlow: _ctx.addPayFlow,
      onUpdatePayFlow: _ctx.updatePayFlow
    }, null, 8, ["payFlow", "isEdit", "onAddPayFlow", "onUpdatePayFlow"])
  ], 64))
}