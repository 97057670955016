<!--src/components/header/AppHeader.vue-->
<template>
  <div
    class="d-flex justify-space-between align-end"
    :class="smAndDown ? 'flex-end' : ''"
    :style="{ height: '5.5rem' }"
  >
    <div class="d-flex">
      <v-img
        v-if="headerLogo && !(smAndDown || isSpinnerVisible)"
        class="mx-5 mb-4 mt-4 top-left-logo"
        :width="mdAndDown ? 50 : 200"
        aspect-ratio="1/1"
        cover
        :src="mdAndDown ? appLogoSmall : appLogo"
        @click="toggleMenu('/dashboard')"
      ></v-img>
      <v-tooltip activator="parent" location="right"
        >zurück zum Dashboard</v-tooltip
      >
      <SpinnerElementHeader
        v-if="headerLogo && isSpinnerVisible"
        class="mt-5"
      />
    </div>
    <div class="d-flex mt-2">
      <div :class="smAndDown ? 'ml-1 d-flex' : 'd-flex'">
        <v-text-field
          width="200"
          :class="smAndDown ? 'mr-2' : 'mr-6'"
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          append-inner-icon="fa-solid fa-magnifying-glass-plus"
          v-if="showDispositionTabs"
          v-model="searchTerm"
          density="compact"
          label="Suchbegriff"
          @click:append-inner="showDetailSearchMenu = true"
          @keydown.enter="askColumnsForMatches()"
          clearable
          class="search-field"
        >
          <v-tooltip activator="parent" location="bottom"
            >Einen oder mehrere Suchbegriffe eingeben und mit Enter
            bestätigen</v-tooltip
          >
        </v-text-field>
        <v-dialog
          :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
          v-model="showDetailSearchMenu"
        >
          <v-card>
            <v-card-title class="d-flex">
              Detailsuche in der Datenbank
              <v-spacer></v-spacer>
              <v-btn
                @click="showDetailSearchMenu = false"
                icon
                size="s"
                variant="text"
                density="compact"
              >
                <v-icon> fa-solid fa-xmark close-icon </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-switch
                @click.stop
                v-model="showCandidateDetailSearch"
                label="Detailsuche für Kandidaten"
              ></v-switch>
              <CandidateDetailSearchForm
                v-if="showCandidateDetailSearch"
                @closeDialog="showDetailSearchMenu = false"
                :searchTerm="searchTerm"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-select
          :class="smAndDown ? 'mr-2' : 'mr-10'"
          width="200"
          height="1rem"
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          v-model="selectedMandants"
          density="compact"
          :items="mandants"
          item-title="branchInitials"
          item-value="uuid"
          label="Eingeloggte Niederlassungen"
          clearable
          multiple
          hide-selected
          closable-chips
          :return-object="false"
          @update:model-value="handleSelectedMandants(selectedMandants)"
          class="single-line-select"
        >
        </v-select>
      </div>
      <ZorstStatus
        v-if="!mdAndDown && $store.state.company.softwareIntegration.zorst"
      ></ZorstStatus>
      <div v-if="!smAndDown">
        <ReportBug></ReportBug>
        <UserProfile></UserProfile>
      </div>
      <GlobalReminder />
      <v-btn class="ml-4" icon density="compact" variant="text">
        <v-icon class="menu-icon"> fa-solid fa-ellipsis-vertical </v-icon>

        <v-menu activator="parent" offset-y>
          <v-list>
            <v-list-item
              v-for="item in filteredItems"
              :key="item.text"
              class="d-flex"
              @click="toggleMenu(item.route)"
            >
              <template v-slot:prepend>
                <v-icon size="xs">{{ item.icon }}</v-icon>
              </template>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </div>
  </div>
  <v-divider
    :thickness="2"
    class="border-opacity-25"
    color="primary"
  ></v-divider>

  <div
    v-if="showRecruitTabs"
    :class="mdAndDown ? 'd-flex justify-space-between' : 'd-flex justify-start'"
  >
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-table-cells"
      @click="selectTab(HeaderTab.JobMatrix)"
      data-tid="recruit-link-jobmatrix"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Jobs Matrix</v-label>
      </template>
      <template v-if="!smAndDown"> Jobanzeigen Matrix </template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-table-list"
      @click="selectTab(HeaderTab.JobList)"
      data-tid="recruit-link-joblist"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Jobs Liste</v-label>
      </template>
      <template v-if="!smAndDown"> Jobanzeigen Liste </template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-brands fa-facebook"
      @click="selectTab(HeaderTab.Funnels)"
      data-tid="recruit-link-joblist"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Funnels</v-label>
      </template>
      <template v-if="!smAndDown"> Job Funnel </template>
    </v-btn>
  </div>
  <div
    v-if="showDispositionTabs"
    :class="mdAndDown ? 'd-flex justify-space-between' : 'd-flex justify-start'"
  >
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-envelope-open-text"
      @click="openZvooveRecruitLink()"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Neu</v-label>
      </template>
      <template v-if="!smAndDown"> Bewerbungen ATS </template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6 '"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-at"
      @click="openZvooveRecruitLink(true)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Mail</v-label>
      </template>
      <template v-if="!smAndDown"> E-Mails ATS </template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-people-group"
      @click="selectTab(HeaderTab.DispatcherBoard)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Dispo</v-label>
      </template>
      <template v-if="!smAndDown">Dispoboard</template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-calendar-days"
      @click="selectTab(HeaderTab.Calendar)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Cal</v-label>
      </template>
      <template v-if="!smAndDown"> Kalender </template>
    </v-btn>
  </div>
  <div v-if="showAdministrationTabs" class="d-flex justify-start">
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-file-lines"
      @click="selectTab(HeaderTab.Documentation)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Doku</v-label>
      </template>
      <template v-if="!smAndDown"> Dokumentation </template>
    </v-btn>
    <v-btn
      v-if="softwareIntegration.payFlow"
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-coins"
      @click="selectTab(HeaderTab.PayFlowOverview)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">PayFlow</v-label>
      </template>
      <template v-if="!smAndDown"> PayFlow Übersicht </template>
    </v-btn>
  </div>
  <!-- <SpinnerElementHeader></SpinnerElementHeader> -->
</template>

<script lang="ts">
//TODO: integrate: import SpinnerElementHeader from "@/components/header/SpinnerElementHeader.vue";
import { defineComponent } from "vue";
import { HeaderTab } from "@/enums/header-tabs.enum";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { ActionType, MutationType } from "@/enums/vuex-types.enum";
import { useDisplay } from "vuetify";
import { UserHelperService } from "@/services/user-helper.service";
import { useRouter } from "vue-router";
import { UserRole } from "@/enums/user-role.enum";
import { UserService } from "@/services/api/user.service";
import debounce from "lodash/debounce";
import ReportBug from "./ReportBug.vue";
import SpinnerElementHeader from "./SpinnerElementHeader.vue";
import ToastService from "@/services/toast.service";
import UserProfile from "@/components/config/user/UserProfile.vue";
import ZorstStatus from "./ZorstStatus.vue";
import { Routes } from "@/enums/routes.enum";
import { CandidateSearchCriteria } from "../../models/candidate.model";
import CandidateDetailSearchForm from "../forms/CandidateDetailSearchForm.vue";
import GlobalReminder from "./GlobalReminder.vue";
import { AppLayout, AppPageAndMenuTitle } from "../../enums/app-layout.enum";

export default defineComponent({
  name: "AppHeader",
  components: {
    CandidateDetailSearchForm,
    GlobalReminder,
    ReportBug,
    SpinnerElementHeader,
    UserProfile,
    ZorstStatus,
  },
  emits: ["tab-selected"],
  setup() {
    const router = useRouter();

    return { router };
  },
  created() {
    this.checkIfStoreHasToInit();
  },

  data() {
    const { smAndDown, mdAndDown } = useDisplay();
    return {
      smAndDown,
      mdAndDown,
      AppLayout: AppLayout,
      appLogo: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/app-layout/${this.$store.getters.appLayout}/header-logo.svg`,
      appLogoSmall: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/app-layout/${this.$store.getters.appLayout}/header-logo-small.svg`,
      buttonTabSize: "250",
      candidateDetailSearchObject: {} as CandidateSearchCriteria,
      currentMandantUuid: this.$store.state.company.loggedInUser.config
        .loggedInMandants
        ? this.$store.state.company.loggedInUser.config.loggedInMandants
        : [],
      layout: this.$store.getters.appLayout,
      menuItems: [
        {
          text: AppPageAndMenuTitle.dashboard,
          route: Routes.dashboard,
          icon: "fa-solid fa-tachometer-alt",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: AppPageAndMenuTitle.company,
          route: Routes.configCompany,
          icon: "fa-solid fa-building",
        },
        {
          accessRoles: [
            UserRole.UberAdmin,
            UserRole.CompanyAdmin,
            UserRole.Admin,
          ],
          text: AppPageAndMenuTitle.user,
          route: Routes.configUser,
          icon: "fa-solid fa-user",
        },
        {
          accessRoles: [
            UserRole.UberAdmin,
            UserRole.CompanyAdmin,
            UserRole.Admin,
            UserRole.Accountant,
            UserRole.PayrollOfficer,
            UserRole.Accountant,
          ],
          text: AppPageAndMenuTitle.mandants,
          route: Routes.configMandant,
          icon: "fa-solid fa-map-marker-alt",
        },
        {
          text: AppPageAndMenuTitle.softwareIntegration,
          route: Routes.configApi,
          icon: "fa-solid fa-plug",
        },
        {
          text: AppPageAndMenuTitle.ai,
          route: Routes.configAi,
          icon: "fa-solid fa-rocket",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: AppPageAndMenuTitle.backendLogs,
          route: Routes.backendLogs,
          icon: "fa-solid fa-boxes-packing",
        },
        {
          accessRoles: [
            UserRole.UberAdmin,
            UserRole.Admin,
            UserRole.CompanyAdmin,
          ],
          text: AppPageAndMenuTitle.dataCleaning,
          route: Routes.dataCleaning,
          icon: "fa-solid fa-broom",
        },
        {
          text: AppPageAndMenuTitle.version,
          route: Routes.version,
          icon: "fa-solid fa-info-circle",
          condition: "showVersion",
        },
        {
          text: AppPageAndMenuTitle.logout,
          route: Routes.logout,
          icon: "fa-solid fa-sign-out-alt",
        },
      ],
      HeaderTab: HeaderTab,
      selectedMandants: [] as string[],
      searchTerm: "",
      showCandidateDetailSearch: true,
      showDetailSearchMenu: false,
      userRole: UserHelperService.getInstance().getUserRole(),
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      softwareIntegration: "softwareIntegration",
      user: "user",
      mandants: "reducedMandants",
      headerLogo: "headerLogo",
      storeHasToInit: "storeHasToInit",
    }),
    ...mapState({
      headerSpinner: (state: any) => state.headerSpinner,
    }),
    isSpinnerVisible(): boolean {
      return !!this.headerSpinner.gif;
    },
    filteredItems() {
      return this.menuItems.filter((item) => {
        if (!item.accessRoles) {
          return true;
        }
        return item.accessRoles.includes(this.userRole);
      });
    },
  },
  watch: {
    searchTerm(newValue) {
      if (newValue === "") this.askColumnsForMatches();
    },
  },
  async mounted() {
    this.checkForLoggedInMandants();
  },
  methods: {
    ...mapMutations({
      updateLoggedInMandants: MutationType.updateLoggedinMandants,
      setICM: MutationType.setICM,
    }),
    ...mapActions([ActionType.loadStoreInitialData]),
    askColumnsForMatches() {
      this.setICM({
        message: InterComponentMessage.matchMeFromGlobalSearch,
        payload: {
          searchTerm: this.searchTerm ?? "",
        },
      });
    },
    async checkForLoggedInMandants() {
      let loggedInUuids =
        this.$store.state.company.loggedInUser.config.loggedInMandants;
      const user = this.$store.getters.user;
      loggedInUuids = user?.config?.loggedInMandants as string[];

      if (loggedInUuids) {
        this.selectedMandants = loggedInUuids;
      }
    },
    async checkIfStoreHasToInit() {
      if (this.storeHasToInit) {
        await this.loadStoreInitialData().then(() => {
          ToastService.show(
            `Sichere Verbindung zu ${this.$store.state.company.name} hergestellt ...`
          );
        });
      }
    },
    handleSelectedMandants: debounce(function (this: any, newValues: string[]) {
      if (this.$store.state.storeHasToInit) return;
      this.updateLoggedInMandants(newValues);
      this.userService.updateUserConfig(
        this.$store.state.company.loggedInUser.config
      );
    }, 1500),
    openZvooveRecruitLink(mail?: boolean) {
      const baseLink = `${this.softwareIntegration.zvooveRecruitLink}/recruiting`;
      const applications = `${baseLink}/inbox/bw`;
      const email = `${baseLink}/inbox/email`;
      if (mail) {
        //TODO: refactor all this Recruit ATS window dimensions:
        window.open(email, "_blank", "width=800,height=800");
      } else {
        window.open(applications, "_blank", "width=800,height=800"); //Opens a small window that the user do not waste his time in another software
      }
    },
    toggleMenu(route: string) {
      if (route) {
        this.router.push(route);
      }
    },
    selectTab(tabName: any) {
      this.$emit("tab-selected", tabName);
    },
  },
  props: {
    showRecruitTabs: Boolean,
    showDispositionTabs: Boolean,
    showAdministrationTabs: Boolean,
  },
});
</script>

<style scoped lang="scss">
@import "./scss/variables.scss";

.top-left-logo {
  cursor: pointer;
  transition: 1s all ease;
}
.top-left-logo:hover {
  scale: 1.3;
}

.single-line-select :deep(.v-select__selection) {
  min-height: 3rem;
  max-height: 3rem;
  height: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.single-line-select :deep(.v-field__input) {
  min-height: unset !important;
  max-height: 3rem !important;
  overflow: hidden !important;
}

.single-line-select :deep(.v-field__input input) {
  display: none;
}

.single-line-select :deep(.v-field__input .v-select__selection) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.search-field :deep(.v-field__input),
.single-line-select :deep(.v-field__input) {
  min-height: 40px !important;
  max-height: 40px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.single-line-select :deep(.v-select__selection) {
  max-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.single-line-select :deep(.v-field__input input) {
  display: none;
}

.single-line-select :deep(.v-field__input .v-select__selection) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
</style>
