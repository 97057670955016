<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)'"
    >
      <v-card style="min-height: var(--d-h-full)" class="pa-0">
        <v-card-title>
          <v-card :rounded="vStyle.card.rounded">
            <v-tabs
              v-model="activeTab"
              bg-color="primary"
              style="color: var(--color-on-primary)"
              slider-color="primary"
            >
              <v-tab v-for="(tab, index) in tabs" :key="index" :value="index">
                {{ tab }}
              </v-tab>
              <v-spacer></v-spacer>
              <v-tab @click="closeModal()"
                ><v-icon size="x-large">fa-solid fa-circle-xmark</v-icon></v-tab
              >
            </v-tabs>
          </v-card>
        </v-card-title>
        <v-card-text>
          <v-tabs-window v-model="activeTab">
            <v-tabs-window-item
              v-for="(tab, index) in tabs"
              :key="index"
              :value="index"
            >
              <!--Tab data + address-->
              <v-row v-if="index === 0">
                <v-card-title>Allgemeine Daten</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="4">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localEmployee.firstName"
                    label="Vorname"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localEmployee.lastName"
                    label="Nachname"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localEmployee.salutation"
                    :items="salutations"
                    label="Anrede"
                  />
                </v-col>
                <v-card-title>Kommunikation</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="4">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="localEmployee.email"
                    label="Email"
                  />
                </v-col>
                <template v-if="localEmployee.address">
                  <v-col cols="12" md="4">
                    <v-text-field
                      :variant="vStyle.input.variant || undefined"
                      :rounded="vStyle.input.rounded || undefined"
                      :base-color="vStyle.input.baseColor || undefined"
                      :color="vStyle.input.color || undefined"
                      v-model="localEmployee.address.phone1"
                      label="Telefon 1"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :variant="vStyle.input.variant || undefined"
                      :rounded="vStyle.input.rounded || undefined"
                      :base-color="vStyle.input.baseColor || undefined"
                      :color="vStyle.input.color || undefined"
                      v-model="localEmployee.address.phone2"
                      label="Telefon 2"
                    />
                  </v-col>
                  <v-card-title>Adresse</v-card-title>
                  <v-divider class="mb-3"></v-divider>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :variant="vStyle.input.variant || undefined"
                      :rounded="vStyle.input.rounded || undefined"
                      :base-color="vStyle.input.baseColor || undefined"
                      :color="vStyle.input.color || undefined"
                      v-model="localEmployee.address.street"
                      label="Straße"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      :variant="vStyle.input.variant || undefined"
                      :rounded="vStyle.input.rounded || undefined"
                      :base-color="vStyle.input.baseColor || undefined"
                      :color="vStyle.input.color || undefined"
                      v-model="localEmployee.address.postalCode"
                      label="PLZ"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      :variant="vStyle.input.variant || undefined"
                      :rounded="vStyle.input.rounded || undefined"
                      :base-color="vStyle.input.baseColor || undefined"
                      :color="vStyle.input.color || undefined"
                      v-model="localEmployee.address.city"
                      label="Ort"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      :variant="vStyle.input.variant || undefined"
                      :rounded="vStyle.input.rounded || undefined"
                      :base-color="vStyle.input.baseColor || undefined"
                      :color="vStyle.input.color || undefined"
                      v-model="localEmployee.address.countryId"
                      label="Staat"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      :variant="vStyle.input.variant || undefined"
                      :rounded="vStyle.input.rounded || undefined"
                      :base-color="vStyle.input.baseColor || undefined"
                      :color="vStyle.input.color || undefined"
                      v-model="localEmployee.address.stateId"
                      label="Bundesland"
                    />
                  </v-col>
                </template>
              </v-row>
              <!--Tab assignments-->
              <v-row v-else-if="index === 1">
                <v-card-title>Einsätze</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-list>
                  <v-list-item
                    v-for="assignment in localEmployee.assignments"
                    :key="assignment.assignmentId"
                  >
                    <div>
                      <v-list-item-title>
                        {{ assignment.clientName }} -
                        {{ assignment.workLocation }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ assignment.jobTitle }} ({{ assignment.from }} -
                        {{ assignment.to }})
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </v-list>
              </v-row>
              <!--Tab DSGVO-->
              <v-row v-else-if="index === 2">
                <v-card-title>Datenschutzeinwilligungen</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-list>
                  <v-list-item
                    v-for="dsgvo in localEmployee.dataPrivacyConsents"
                    :key="dsgvo.id"
                  >
                    <div>
                      <v-list-item-title>
                        {{ dsgvo.id }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Gültig bis: {{ dsgvo.validUntil }} - Löschen:
                        {{ dsgvo.delete }}
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
        <v-card-actions>
          <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="submitModal">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";
import { Salutation } from "@/enums/salutation.model";
import { EmployeeService } from "@/services/api/employee.service";
import { Employee } from "@/models/employee.model";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "EditEmployeeModal",
  emits: ["insertUpdatedEmployee"],
  props: {
    employee: Object as PropType<Employee>,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      activeTab: 0,
      showModal: false,
      tabs: ["Allgemein", "Einsätze", "Datenschutzeinwilligungen"],
      salutations: Object.values(Salutation).filter(
        (value) => typeof value === "string"
      ),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    localEmployee() {
      const localEmployee = { ...this.employee } as Employee;
      return localEmployee;
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
    },
    async submitModal() {
      const apiService = new EmployeeService();
      if (this.localEmployee) {
        apiService.editEmployee(this.localEmployee).then((response) => {
          if (response) {
            this.$emit("insertUpdatedEmployee", this.localEmployee);
            this.closeModal();
          } else {
            ToastService.showError("Fehler beim speichern der Personaldaten");
          }
        });
      }
    },
  },
});
</script>

<style scoped>
.button-icon {
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
