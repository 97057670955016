//src/board-filters.enum.ts
export enum FilterCandidates {
  placementStatus = "Vermittlungstatus",
  lastName = "Nachname",
  applicationDate = "Bewerbungsdatum",
  applicationId = "Bewerbungs-ID",
  appliedAs = "Beworben Als",
  postcode = "Postleitzahl",
  openProfile = "Profiliert & vorgestellt",
  interviewExternal = "Vorstellungsgespräche bei Kunden",
  trailWorkExternal = "Probearbeiten bei Kunden",
}

export enum FilterCustomers {
  name = "Name",
  hasJobAds = "Anzahl ausgeschriebener Stellen",
  hasAssignments = "Anzahl Einsätze",
  customerNumber = "Kundennummer",
  lastEdited = "Letzte Bearbeitung",
  postcode = "Postleitzahl",
  profileDesired = "Möchte Profile erhalten",
}

export enum FilterEmployees {
  name = "Name",
  postcode = "Postleitzahl",
  employeeNumber = "Personalnummer",
  expiringAssignments = "Enddatum Einsätze",
  entryDate = "Eintrittsdatum",
  exitDate = "Austrittsdatum",
}

export enum UserConfigFilterBoard {
  columnCandidate = "columnCandidate",
  columnCustomer = "columnCustomer",
  columnDemand = "columnDemand",
  columnEmployee = "columnEmployee",
}

export enum UserConfigFilterType {
  filterMandants = "filterMandants",
  filterStatus = "filterStatus",
  orderDraggable = "orderDraggable",
}

export enum AllItemUpdate {
  getAssignmentsFromErp,
  checkForFullLoad,
  checkEmployeeStatus,
  getPlacements,
  updateEmployeeFromErp,
  loadCandidateFromAts,
}
