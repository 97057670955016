import { io, Socket } from "socket.io-client";
import store from "../../store/store";

export class WabChatService {
  private socket: Socket | null = null;
  private store = store;

  constructor(wabNumber: string) {
    const accessToken = localStorage.getItem("accessToken");
    const backendWabSocketUri =
      this.store?.state?.env?.VUE_APP_BACKEND_WAB_SOCKET_URI;
    if (accessToken && wabNumber) {
      this.socket = io(backendWabSocketUri, {
        query: {
          "x-wabnumber": wabNumber,
        },
        transports: ["websocket"],
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
          "x-wabnumber": wabNumber,
        },
      });
    }
  }

  onMessage(callback: (data: { sender: string; message: string }) => void) {
    if (this.socket) {
      this.socket.on("message", callback);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      console.log("Disconnected from WebSocket");
    }
  }
}
