import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, {
    modelValue: _ctx.showMenu,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showMenu) = $event)),
    "offset-y": ""
  }, {
    activator: _withCtx(({ props }) => [
      (
          _ctx.zvooveOneCustomerNumber &&
          _ctx.isNumeric(_ctx.zvooveOneCustomerNumber) &&
          _ctx.company.softwareIntegration.zvooveOne
        )
        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 0 }, props, {
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)), ["stop"]))
          }), [
            _createElementVNode("img", {
              src: `${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-zvoove-one.svg`,
              class: "zvoove-one-icon",
              alt: "zvoove One Icon"
            }, null, 8, _hoisted_1),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Unternehmen in ERP One öffnen ")
              ])),
              _: 1
            })
          ], 16))
        : _createCommentVNode("", true),
      (
          _ctx.zvooveOneCustomerNumber &&
          _ctx.isNumeric(_ctx.zvooveOneCustomerNumber) &&
          _ctx.company.softwareIntegration.pdHub
        )
        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 1 }, props, {
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)), ["stop"]))
          }), [
            _createElementVNode("img", {
              src: `${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-pd-hub.svg`,
              class: "pd-hub-icon",
              alt: "PD-Hub Icon"
            }, null, 8, _hoisted_2),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Optionen in PD-Hub ")
              ])),
              _: 1
            })
          ], 16))
        : _createCommentVNode("", true),
      (_ctx.zvooveOneEmployeeNumber && _ctx.company.softwareIntegration.zvooveOne)
        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 2 }, props, {
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)), ["stop"]))
          }), [
            _createElementVNode("img", {
              src: `${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-zvoove-one.svg`,
              class: "zvoove-one-icon",
              alt: "zvoove One Icon"
            }, null, 8, _hoisted_3),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Mitarbeiter in ERP One öffnen ")
              ])),
              _: 1
            })
          ], 16))
        : _createCommentVNode("", true),
      (_ctx.zvooveOneEmployeeNumber && _ctx.company.softwareIntegration.pdHub)
        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 3 }, props, {
            onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)), ["stop"]))
          }), [
            _createElementVNode("img", {
              src: `
            ${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-pd-hub.svg`,
              class: "pd-hub-icon",
              alt: "PD-Hub Icon"
            }, null, 8, _hoisted_4),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Optionen in PD-Hub ")
              ])),
              _: 1
            })
          ], 16))
        : _createCommentVNode("", true),
      (
          _ctx.zvooveRecruitMitarbeiterUuid &&
          _ctx.company.softwareIntegration.zvooveRecruit
        )
        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 4 }, props, {
            onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)), ["stop"]))
          }), [
            _createElementVNode("img", {
              src: `
            ${_ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL}/icons/icon-ats-recruit.svg`,
              class: "zvoove-recruit-icon",
              alt: "zvoove Recruit Icon"
            }, null, 8, _hoisted_5),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" Kandidat in ATS Recruit öffnen ")
              ])),
              _: 1
            })
          ], 16))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: index,
              onClick: item.action
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}