import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/de.es";
import longpress from "./directives/longpress";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { createVuetifyInstance } from "@/plugins/vuetify";
import MaintenancePage from "./components/landingpages/MaintenancePage.vue";
import { ActionType } from "./enums/vuex-types.enum";
import vAutoScroll from "./directives/autoscroll";
import vCollapse from "./directives/collapse";

let app: any = null;
let vuetify: any = null;

// Load configuration from config.json
fetch("/config.json")
  .then((response) => {
    if (!response.ok) {
      throw new Error(`Failed to load configuration: ${response.statusText}`);
    }
    return response.json();
  })
  .then((config) => {
    // Verify required configuration
    const requiredEnvVariables = ["ACTIVE"];
    const missingEnvVariables = requiredEnvVariables.filter(
      (variable) => !config[variable]
    );

    if (
      missingEnvVariables.length > 0 ||
      config[requiredEnvVariables[0]] === false
    ) {
      // Show maintenance page if configuration is incomplete
      app = createApp(MaintenancePage);
      app.mount("#app");
    } else {
      // Load Vue instance with valid configuration
      store.dispatch(ActionType.loadConfig, config).then(() => {
        vuetify = createVuetifyInstance();
        init(app, vuetify);
      });
    }
  })
  .catch((error) => {
    console.error("Error loading configuration:", error);
    // Show maintenance page if an error occurs
    app = createApp(MaintenancePage);
    app.mount("#app");
  });

function init(app: any, vuetify: any) {
  app = createApp(App).use(vuetify).use(store).use(CKEditor).use(router);
  app.directive("longpress", longpress);
  app.directive("autoscroll", vAutoScroll);
  app.directive("collapse", vCollapse);
  app.mount("#app");
}

export function updateVuetify() {
  if (!vuetify) {
    console.error("Vuetify instance is not initialized.");
    return;
  }
  const newVuetifyInstance = createVuetifyInstance();
  if (newVuetifyInstance.theme && vuetify.theme) {
    vuetify.theme.themes.value = newVuetifyInstance.theme.themes.value;
    console.debug("Vuetify theme updated.");
  }
}
