<template>
  <h1>Datenschutzrichtlinie</h1>

  <p>
    Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. Diese
    Datenschutzrichtlinie erläutert, wie Ihre Daten im Rahmen der Nutzung
    unserer Web-App verarbeitet werden.
  </p>
  <br />

  <h2>1. Verantwortlicher</h2>
  <p>
    Verantwortlich für die Datenverarbeitung im Sinne der
    Datenschutz-Grundverordnung (DSGVO) ist:
  </p>
  <p>
    <strong>Softmatix</strong><br />
    Robensstr. 58<br />
    52070 Aachen<br />
    0241-89438639<br />
    info@softmatix.de
  </p>
  <br />

  <h2>2. Zweck der Verarbeitung</h2>
  <p>
    Wir verarbeiten personenbezogene Daten, um die folgenden Dienste anzubieten:
  </p>
  <ul>
    <li>Erstellung von Bewerberprofilen</li>
    <li>Generierung und Veröffentlichung von Job-Anzeigen</li>
    <li>Kommunikation über Meta- und WhatsApp Business Integrationen</li>
  </ul>
  <br />

  <h2>3. Erhobene Daten</h2>
  <p>
    Bei der Nutzung unserer Web-App können folgende personenbezogene Daten
    erhoben werden:
  </p>
  <ul>
    <li>Benutzerdaten (z. B. Name, E-Mail-Adresse, Telefonnummer)</li>
    <li>Recruiting-Daten (z. B. Stellenanzeigen, Bewerberprofile)</li>
    <li>
      Kommunikationsdaten (z. B. Nachrichten, die über WhatsApp Business
      versendet werden)
    </li>
  </ul>
  <br />

  <h2>4. Nutzung von Meta- und WhatsApp Business Integrationen</h2>
  <p>
    Unsere Web-App nutzt die WhatsApp Business API und Meta-Dienste für die
    Kommunikation und Verwaltung von Recruiting-Aktivitäten.
  </p>
  <ul>
    <li>
      <strong>Meta OAuth2-Authentifizierung:</strong> Ihre Daten (z. B.
      Zugriffstokens, Facebook-Seiteninformationen) werden verarbeitet, um Ihre
      Meta- und WhatsApp Business Accounts zu verknüpfen.
    </li>
    <li>
      <strong>WhatsApp Business API:</strong> Nachrichten und Status-Updates
      werden über die API verarbeitet. Diese Daten können mit Meta geteilt
      werden.
    </li>
  </ul>
  <p>
    Bitte beachten Sie die
    <a href="https://www.facebook.com/privacy/explanation" target="_blank"
      >Datenschutzrichtlinie von Meta</a
    >, um mehr darüber zu erfahren, wie Ihre Daten durch Meta verarbeitet
    werden.
  </p>
  <br />

  <h2>5. Weitergabe von Daten</h2>
  <p>
    Wir geben personenbezogene Daten nur dann weiter, wenn dies erforderlich
    ist, um unsere Dienste anzubieten oder gesetzlich vorgeschrieben ist:
  </p>
  <ul>
    <li>Weitergabe an Meta für die Nutzung der WhatsApp Business API</li>
    <li>Weitergabe an Partnerunternehmen für die Schaltung von Job-Anzeigen</li>
    <li>Weitergabe an Behörden, falls gesetzlich vorgeschrieben</li>
  </ul>
  <br />

  <h2>6. Rechtsgrundlage der Verarbeitung</h2>
  <p>
    Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage von:
  </p>
  <ul>
    <li>Art. 6 Abs. 1 lit. b DSGVO (Erfüllung eines Vertrags)</li>
    <li>
      Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse, z. B. für
      Recruiting-Aktivitäten)
    </li>
    <li>
      Art. 6 Abs. 1 lit. a DSGVO (Einwilligung, z. B. für die Kommunikation über
      WhatsApp)
    </li>
  </ul>
  <br />

  <h2>7. Speicherdauer</h2>
  <p>
    Ihre Daten werden nur so lange gespeichert, wie dies für die Erfüllung des
    Zwecks erforderlich ist oder gesetzliche Aufbewahrungsfristen bestehen.
  </p>
  <br />

  <h2>8. Ihre Rechte</h2>
  <p>Sie haben folgende Rechte:</p>
  <ul>
    <li>Auskunft über die Verarbeitung Ihrer Daten</li>
    <li>Berichtigung unrichtiger Daten</li>
    <li>
      Löschung Ihrer Daten (soweit keine gesetzlichen Anforderungen
      entgegenstehen)
    </li>
    <li>Einschränkung der Verarbeitung Ihrer Daten</li>
    <li>Widerspruch gegen die Datenverarbeitung</li>
    <li>Übertragbarkeit Ihrer Daten</li>
    <li>Widerruf Ihrer Einwilligung (Art. 7 Abs. 3 DSGVO)</li>
  </ul>
  <p>
    Zur Ausübung Ihrer Rechte können Sie uns jederzeit unter den oben
    angegebenen Kontaktdaten erreichen.
  </p>
  <br />

  <h2>9. Änderungen der Datenschutzrichtlinie</h2>
  <p>
    Wir behalten uns vor, diese Datenschutzrichtlinie zu ändern, um sie an
    geänderte rechtliche oder technische Rahmenbedingungen anzupassen. Die
    aktuelle Version ist stets auf unserer Website verfügbar.
  </p>
  <br />

  <h2>10. Kontakt</h2>
  <p>
    Bei Fragen oder Anliegen zur Verarbeitung Ihrer personenbezogenen Daten
    können Sie uns wie folgt kontaktieren:
  </p>
  <p>
    <strong>Softmatix</strong><br />
    Robensstr. 58<br />
    52070 Aachen<br />
    0241-89438639<br />
    info@softmatix.de
  </p>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrivacyPolicy",
});
</script>
