import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isRestoreLinkValid)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 0,
          icon: "fa-solid fa-user-lock",
          title: "Fehlerhafter Link",
          text: "Bitte wiederholen Sie den Passwort-Rücksetzungs-Prozess",
          class: "pa-5",
          "max-width": "500",
          style: {"margin":"10vh auto"},
          dismissible: "false",
          variant: "tonal",
          color: "red-darken-3"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, {
      class: "fill-height",
      justify: "center",
      align: "center"
    }, {
      default: _withCtx(() => [
        (_ctx.isRestoreLinkValid)
          ? (_openBlock(), _createBlock(_component_v_card, {
              key: 0,
              variant: "flat",
              class: "pa-5",
              "max-width": "500",
              style: {"margin":"10vh auto"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("img", { src: _ctx.appPasswordRestoreLogo }, null, 8, _hoisted_2)
                ]),
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Neues Passwort setzen")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      ref: "form",
                      modelValue: _ctx.isValid,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isValid) = $event)),
                      "lazy-validation": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          modelValue: _ctx.password,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                          rules: _ctx.passwordRules,
                          label: "Neues Passwort",
                          type: "password",
                          required: "",
                          outlined: ""
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          modelValue: _ctx.confirmPassword,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                          rules: _ctx.confirmPasswordRules,
                          label: "Passwort bestätigen",
                          type: "password",
                          required: "",
                          outlined: ""
                        }, null, 8, ["modelValue", "rules"]),
                        (_ctx.errorMessage)
                          ? (_openBlock(), _createBlock(_component_v_alert, {
                              key: 0,
                              type: "error",
                              dense: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, { class: "d-flex justify-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      disabled: !_ctx.isValid,
                      color: "primary",
                      onClick: _ctx.submitPassword
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Passwort speichern ")
                      ])),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}