<!--src/components/config/ai/AiConfigUserCoaching.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-user-graduate</v-icon>User
      Coaching Bedingungen
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row style="position: sticky; top: 0; z-index: 1000">
        <!-- available actions as chips -->
        <v-col cols="12">
          <v-card
            variant="flat"
            :rounded="vStyle?.card?.rounded"
            :border="vStyle?.card?.border"
            class="mb-4 pa-4"
          >
            <v-card-title>Verfügbare Aktivitäten</v-card-title>
            <v-card-text>
              <v-chip-group column class="d-flex flex-wrap">
                <v-chip
                  v-for="action in userActionTypes"
                  :key="action.value"
                  class="ma-1 pa-2"
                  draggable
                  variant="tonal"
                  density="compact"
                  size="small"
                  @dragstart="handleDragStart($event, action)"
                >
                  {{ action.label }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-col cols="12" class="pa-0">
            <!-- new Trigger Pattern card-->
            <v-card
              :variant="vStyle.card.variant || undefined"
              :rounded="vStyle.card.rounded || undefined"
              :border="vStyle.card.border || undefined"
              class="border-dashed mb-8"
              @dragover.prevent
              @drop="handleDropNew($event)"
            >
              <v-card-title>Neues Trigger Pattern</v-card-title>
              <v-card-subtitle>Aktionen hierher ziehen</v-card-subtitle>
              <v-card-text>
                <v-chip
                  v-if="newPerfEntryPattern.triggerPattern.length === 0"
                  color="error"
                  class="mb-2 pa-3"
                  density="compact"
                  >Bitte zuerst eine oder mehrere Aktivitäten auf die Karte
                  ziehen</v-chip
                >
                <v-chip-group
                  column
                  class="d-flex flex-wrap mb-6"
                  :mandatory="false"
                >
                  <template
                    v-for="(entry, index) in newPerfEntryPattern.triggerPattern"
                    :key="index"
                  >
                    <v-chip
                      closeable
                      class="ma-1 pa-3"
                      density="compact"
                      size="small"
                      style="
                        background-color: var(--color-primary);
                        color: var(--color-on-primary);
                        opacity: 0.8;
                      "
                      @click:close="removeNewEntry(index)"
                    >
                      <div>
                        <v-icon
                          size="small"
                          class="mr-2"
                          @click.stop="toggleNewPhoneCall(index)"
                        >
                          {{
                            entry.hasPhoneCallBefore
                              ? "fa-solid fa-phone"
                              : "fa-solid fa-phone-slash"
                          }}
                        </v-icon>
                        <v-tooltip activator="parent" location="top">
                          {{
                            entry.hasPhoneCallBefore
                              ? "Telefonat vorher"
                              : "ohne Klick auf Telefonbutton vorher"
                          }}
                        </v-tooltip>
                      </div>
                      {{
                        getActionLabel(
                          entry.entryType as TimelineEntryType | LinkingStatus
                        )
                      }}
                      <template v-slot:close>
                        <div>
                          <v-icon>fa-solid fa-xmark</v-icon>
                          <v-tooltip activator="parent" location="top">
                            Aktiviät aus Prozesskette löschen!
                          </v-tooltip>
                        </div>
                      </template>
                    </v-chip>
                    <v-icon
                      v-if="
                        index < newPerfEntryPattern.triggerPattern.length - 1
                      "
                      class="ma-2"
                      color="primary"
                    >
                      fa-solid fa-arrow-right
                    </v-icon>
                  </template>
                </v-chip-group>
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  v-model="newPerfEntryPattern.title"
                  label="Titel"
                  density="compact"
                ></v-text-field>
                <v-textarea
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  v-model="newPerfEntryPattern.coachingPrompt"
                  label="Coaching Prompt"
                  rows="6"
                ></v-textarea>
                <div style="height: 0.49rem"></div>
                <v-btn
                  color="primary"
                  variant="text"
                  @click="addNewPattern"
                  :disabled="!canAddNewPattern"
                >
                  Hinzufügen
                </v-btn>
              </v-card-text>
            </v-card>
            <!-- existing trigger patterns -->
            <v-col
              cols="12"
              class="pa-0"
              v-for="(pattern, index) in localAiData?.prompt?.aiUserCoaching
                ?.perfEntryPatterns"
              :key="index"
            >
              <v-card
                :variant="vStyle.card.variant || undefined"
                :rounded="vStyle.card.rounded || undefined"
                :border="vStyle.card.border || undefined"
                class="mb-4"
                @dragover.prevent
                @drop="handleDrop($event, index)"
              >
                <v-card-title
                  class="mt-4 d-flex justify-space-between align-top"
                >
                  <v-text-field
                    :variant="vStyle?.input?.variant"
                    :rounded="vStyle?.input?.rounded"
                    :base-color="vStyle?.input?.baseColor"
                    :color="vStyle?.input?.color"
                    v-model="pattern.title"
                    density="compact"
                    label="Titel"
                    class="mr-5"
                  ></v-text-field>
                  <v-btn
                    icon
                    density="compact"
                    variant="text"
                    @click="removePattern(index)"
                  >
                    <v-icon>fa-solid fa-trash-can</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-label>Neue Aktionen reinziehen um zu ergänzen:</v-label>
                  <v-divider class="mt-1 mb-3"></v-divider>
                  <v-chip-group
                    column
                    class="d-flex flex-wrap mb-6"
                    :mandatory="false"
                    :selected-class="''"
                  >
                    <template
                      v-for="(entry, entryIndex) in pattern.triggerPattern"
                      :key="entryIndex"
                    >
                      <v-chip
                        closeable
                        class="ma-1 pa-3"
                        density="compact"
                        size="small"
                        style="
                          background-color: var(--color-primary);
                          color: var(--color-on-primary);
                          opacity: 0.8;
                        "
                        @click:close="removeEntry(index, entryIndex)"
                      >
                        <div>
                          <v-icon
                            size="small"
                            class="mr-2"
                            @click.stop="togglePhoneCall(index, entryIndex)"
                          >
                            {{
                              entry.hasPhoneCallBefore
                                ? "fa-solid fa-phone"
                                : "fa-solid fa-phone-slash"
                            }}
                          </v-icon>
                          <v-tooltip activator="parent" location="top">
                            {{
                              entry.hasPhoneCallBefore
                                ? "Telefonat vorher"
                                : "ohne Klick auf Telefonbutton vorher"
                            }}
                          </v-tooltip>
                        </div>
                        {{
                          getActionLabel(
                            entry.entryType as TimelineEntryType | LinkingStatus
                          )
                        }}
                        <template v-slot:close>
                          <div>
                            <v-icon>fa-solid fa-xmark</v-icon>
                            <v-tooltip activator="parent" location="top">
                              Aktiviät aus Prozesskette löschen!
                            </v-tooltip>
                          </div>
                        </template>
                      </v-chip>
                      <v-icon
                        v-if="entryIndex < pattern.triggerPattern.length - 1"
                        class="ma-2"
                        color="primary"
                      >
                        fa-solid fa-arrow-right
                      </v-icon>
                    </template>
                  </v-chip-group>

                  <v-textarea
                    :variant="vStyle?.input?.variant"
                    :rounded="vStyle?.input?.rounded"
                    :base-color="vStyle?.input?.baseColor"
                    :color="vStyle?.input?.color"
                    v-model="pattern.coachingPrompt"
                    label="Coaching Prompt"
                    rows="3"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
        </v-col>
        <v-col cols="12" md="6">
          <!-- new activity card -->
          <v-col cols="12" class="pa-0">
            <v-card
              :variant="vStyle.card.variant || undefined"
              :rounded="vStyle.card.rounded || undefined"
              :border="vStyle.card.border || undefined"
              class="border-dashed mb-8"
              @dragover.prevent
              @drop="handleDropNewActivity"
            >
              <v-card-title>Neue Trigger Aktivität</v-card-title>
              <v-card-subtitle
                >Aktion hierher ziehen um zu erstellen /
                überschreiben</v-card-subtitle
              >
              <v-card-text>
                <v-chip
                  v-if="newActivity.entryType"
                  closeable
                  class="mb-10 pa-3"
                  density="compact"
                  style="
                    background-color: var(--color-secondary);
                    color: var(--color-on-secondary);
                    opacity: 0.8;
                  "
                  @click:close="clearNewActivityType"
                >
                  {{ getActionLabel(newActivity.entryType) }}
                  <v-tooltip activator="parent" location="top">
                    Zum Überschreiben neue Aktivität draufziehen!
                  </v-tooltip>
                </v-chip>
                <v-chip
                  color="error"
                  v-else
                  class="mb-10 pa-3"
                  density="compact"
                  >Bitte zuerst eine Aktivität auf die Karte ziehen</v-chip
                >
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  v-model="newActivity.title"
                  label="Titel"
                  density="compact"
                ></v-text-field>
                <div class="d-flex">
                  <v-text-field
                    :variant="vStyle?.input?.variant"
                    :rounded="vStyle?.input?.rounded"
                    :base-color="vStyle?.input?.baseColor"
                    :color="vStyle?.input?.color"
                    v-model="newActivity.time"
                    label="Zeit"
                    class="mr-3"
                    density="compact"
                    type="time"
                    width="50%"
                  ></v-text-field>
                  <v-btn
                    icon
                    variant="text"
                    @click="toggleNewIsSmallerThanCountForTrigger"
                  >
                    <v-icon>{{
                      newActivity.isSmallerThanCountForTrigger
                        ? "fa-solid fa-angle-left"
                        : "fa-solid fa-angle-right"
                    }}</v-icon>
                    <v-tooltip activator="parent" location="top">
                      {{
                        newActivity.isSmallerThanCountForTrigger
                          ? "wird ausgelöst wenn die Anzahl der Aktivitäten nach der angegebenen Uhrzeit kleiner ist als bei 'Anzahl' angegeben"
                          : "wird ausgelöst wenn die Anzahl der Aktivitäten nach der angegebenen Uhrzeit größer ist als bei 'Anzahl' angegeben"
                      }}
                    </v-tooltip>
                  </v-btn>

                  <v-text-field
                    :variant="vStyle?.input?.variant"
                    :rounded="vStyle?.input?.rounded"
                    :base-color="vStyle?.input?.baseColor"
                    :color="vStyle?.input?.color"
                    v-model.number="newActivity.count"
                    :label="
                      newActivity.isSmallerThanCountForTrigger
                        ? 'Anzahl kleiner als'
                        : 'Anzahl größer als'
                    "
                    type="number"
                    class="ml-3"
                    density="compact"
                    width="50%"
                  ></v-text-field>
                </div>

                <v-textarea
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  v-model="newActivity.coachingPrompt"
                  label="Coaching Prompt"
                  class="mb-4"
                  rows="3"
                ></v-textarea>

                <v-btn
                  color="primary"
                  variant="text"
                  @click="addNewActivity"
                  :disabled="!canAddNewActivity"
                >
                  Hinzufügen
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- existing activity cards -->
          <v-col
            cols="12"
            class="pa-0"
            v-for="(activity, index) in localAiData?.prompt?.aiUserCoaching
              ?.userTriggerSalesActivity"
            :key="index"
          >
            <v-card
              :variant="vStyle.card.variant || undefined"
              :rounded="vStyle.card.rounded || undefined"
              :border="vStyle.card.border || undefined"
              class="mb-4"
              @dragover.prevent
              @drop="handleDropActivity($event, index)"
            >
              <v-card-title class="mt-4 d-flex justify-space-between align-top">
                <v-text-field
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  v-model="activity.title"
                  density="compact"
                  label="Titel"
                  class="mr-5"
                ></v-text-field>
                <v-btn
                  density="compact"
                  icon
                  variant="text"
                  @click="removeActivity(index)"
                >
                  <v-icon>fa-solid fa-trash-can</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-label>Zum Überschreiben neue Aktivität reinziehen:</v-label>
                <v-divider class="mt-1 mb-3"></v-divider>
                <v-chip
                  v-if="activity.entryType"
                  closeable
                  class="mb-8 pa-3"
                  density="compact"
                  @click:close="clearActivityType(index)"
                  style="
                    background-color: var(--color-secondary);
                    color: var(--color-on-secondary);
                    opacity: 0.8;
                  "
                >
                  {{ getActionLabel(activity.entryType) }}
                  <v-tooltip activator="parent" location="top">
                    Zum Überschreiben neue Aktivität draufziehen!
                  </v-tooltip>
                </v-chip>
                <div class="d-flex align-top">
                  <v-text-field
                    :variant="vStyle?.input?.variant"
                    :rounded="vStyle?.input?.rounded"
                    :base-color="vStyle?.input?.baseColor"
                    :color="vStyle?.input?.color"
                    v-model="activity.time"
                    density="compact"
                    width="50%"
                    label="Uhrzeit"
                    class="mr-2"
                    type="time"
                  ></v-text-field>
                  <v-btn
                    icon
                    variant="text"
                    @click="toggleIsSmallerThanCountForTrigger(index)"
                  >
                    <v-icon>{{
                      activity.isSmallerThanCountForTrigger
                        ? "fa-solid fa-angle-left"
                        : "fa-solid fa-angle-right"
                    }}</v-icon>
                    <v-tooltip activator="parent" location="top">
                      {{
                        activity.isSmallerThanCountForTrigger
                          ? "wird ausgelöst wenn die Anzahl der Aktivitäten nach der angegebenen Uhrzeit kleiner ist als bei 'Anzahl' angegeben"
                          : "wird ausgelöst wenn die Anzahl der Aktivitäten nach der angegebenen Uhrzeit größer ist als bei 'Anzahl' angegeben"
                      }}
                    </v-tooltip>
                  </v-btn>

                  <v-text-field
                    :variant="vStyle?.input?.variant"
                    :rounded="vStyle?.input?.rounded"
                    :base-color="vStyle?.input?.baseColor"
                    :color="vStyle?.input?.color"
                    v-model.number="activity.count"
                    density="compact"
                    width="50%"
                    :label="
                      activity.isSmallerThanCountForTrigger
                        ? 'Anzahl kleiner als'
                        : 'Anzahl größer als'
                    "
                    type="number"
                    class="ml-2"
                  ></v-text-field>
                </div>
                <v-textarea
                  :variant="vStyle?.input?.variant"
                  :rounded="vStyle?.input?.rounded"
                  :base-color="vStyle?.input?.baseColor"
                  :color="vStyle?.input?.color"
                  v-model="activity.coachingPrompt"
                  label="Coaching Prompt"
                ></v-textarea>
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>
      </v-row>

      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
      >
        Speichern
      </v-btn>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AiData } from "@/models/company-config.model";
import { TimelineEntryType } from "../../../enums/timeline-entry-types.enum";
import { LinkingStatus } from "../../../enums/dependency.enum";
import {
  PerfEntry,
  PerfEntryPattern,
  UserTriggerSalesActivity,
} from "@/models/perf.model";

export default defineComponent({
  name: "AiConfigUserCoaching",
  props: {
    modelValue: {
      type: Object as PropType<AiData>,
      required: true,
    },
  },
  data() {
    return {
      localAiData: { ...this.modelValue },
      userActionTypes: [
        {
          value: TimelineEntryType.addition,
          label: "Nachtrag",
        },
        {
          value: TimelineEntryType.candidateSuggestion,
          label: "Kandidatenvorschlag (Profil verschickt)",
        },
        {
          value: TimelineEntryType.eMailCandidate,
          label: "E-Mail Kandidat",
        },
        {
          value: TimelineEntryType.eMailCustomer,
          label: "E-Mail Kunden",
        },
        {
          value: TimelineEntryType.eMailEmployee,
          label: "E-Mail Mitarbeiter",
        },
        {
          value: TimelineEntryType.meetingInhouse,
          label: "Termin in der Niederlassung",
        },
        {
          value: TimelineEntryType.meetingOutside,
          label: "Außendiensttermin",
        },
        {
          value: TimelineEntryType.note,
          label: "Notiz",
        },
        {
          value: TimelineEntryType.phoneCallCandidate,
          label: "Telefonat Kandidat",
        },
        {
          value: TimelineEntryType.phoneCallCustomer,
          label: "Telefonat Kunden",
        },
        {
          value: TimelineEntryType.phoneCallEmployee,
          label: "Telefonat Mitarbeiter",
        },
        {
          value: TimelineEntryType.whatsAppCandidate,
          label: "WhatsApp Kandidat",
        },
        {
          value: TimelineEntryType.whatsAppCustomer,
          label: "WhatsApp Kunden",
        },
        {
          value: TimelineEntryType.whatsAppEmployee,
          label: "WhatsApp Mitarbeiter",
        },
        {
          value: LinkingStatus.checklistCreated,
          label: "Checklisteneintrag erstellt",
        },
        {
          value: LinkingStatus.checklistDone,
          label: "Checklisteneintrag erledigt",
        },
        {
          value: LinkingStatus.contractDate,
          label: "Vertragstermin",
        },
        {
          value: LinkingStatus.demand,
          label: "Anfrage",
        },
        {
          value: LinkingStatus.demandCreated,
          label: "Anfrage erstellt",
        },
        {
          value: LinkingStatus.demandLinkedToCustomer,
          label: "Anfrage mit Kunden verlinkt",
        },
        {
          value: LinkingStatus.followUp,
          label: "Wiedervorlage",
        },
        {
          value: LinkingStatus.followUpPrio,
          label: "Wiedervorlage Priorität",
        },
        {
          value: LinkingStatus.generateProfile,
          label: "Profil generieren und verschicken",
        },
        {
          value: LinkingStatus.hired,
          label: "Kandidat eingestellt",
        },
        {
          value: LinkingStatus.inaktive,
          label: "Inaktiv",
        },
        {
          value: LinkingStatus.inboxApplication,
          label: "Bewerbungseingang",
        },
        {
          value: LinkingStatus.interview,
          label: "Vorstellungstermin mit Bewerber",
        },
        {
          value: LinkingStatus.interviewCanceled,
          label: "Vorstellungstermin abgesagt",
        },
        {
          value: LinkingStatus.interviewCompleted,
          label: "Vorstellungstermin durchgeführt",
        },
        {
          value: LinkingStatus.interviewExternal,
          label: "Vorstellungsgespräch beim Kunden",
        },
        {
          value: LinkingStatus.interviewNotShownUp,
          label: "Vorstellungstermin geplatzt",
        },
        {
          value: LinkingStatus.interviewSuggestionExternal,
          label: "Vorschlag für Vorstellungsgespräch bei Kunden",
        },
        {
          value: LinkingStatus.jobAdExtractedFromCustomer,
          label: "Job-Anzeige aus Kunden-Job-Anzeige extrahiert",
        },
        {
          value: LinkingStatus.jobAdExtractedFromDemand,
          label: "Job-Anzeige aus Anfrage extrahiert",
        },
        {
          value: LinkingStatus.jobAdPublishedFromJobsList,
          label: "Job-Anzeige aus Jobs-Liste veröffentlicht",
        },
        {
          value: LinkingStatus.jobAdPublishedFromJobsMatrix,
          label: "Job-Anzeige aus Jobs-Matrix veröffentlicht",
        },
        {
          value: LinkingStatus.jobofferAcceptedExternal,
          label: "Jobangebot beim Kunden angenommen",
        },
        {
          value: LinkingStatus.jobofferExternal,
          label: "Jobangebot von Kunden",
        },
        {
          value: LinkingStatus.landingPageCreated,
          label: "Job Funnel erstellt",
        },
        {
          value: LinkingStatus.linkingRemoved,
          label: "Verlinkung komplett entfernt",
        },
        {
          value: LinkingStatus.linkingSwitchedBack,
          label: "Verlinkung um ein Event zurückgesetzt",
        },
        {
          value: LinkingStatus.noneProfile,
          label: "Absage Einsatz von Kandidat oder Firma",
        },
        {
          value: LinkingStatus.openProfile,
          label: "Profil noch offen",
        },
        {
          value: LinkingStatus.personnelPlacement,
          label: "Vermittlungskandidat",
        },
        {
          value: LinkingStatus.pool,
          label: "Bewerber in Pool",
        },
        {
          value: LinkingStatus.rejected,
          label: "Absage an Bewerber",
        },
        {
          value: LinkingStatus.rejectedCandidate,
          label: "Absage vom Bewerber",
        },
        {
          value: LinkingStatus.rejectedUnattractive,
          label: "Absage an Bewerber Drittstatt",
        },
        {
          value: LinkingStatus.spinnerGamePlayed,
          label: "Affe/Erdnuss Spiel gespielt",
        },
        {
          value: LinkingStatus.tempPlacement,
          label: "Einsatz",
        },
        {
          value: LinkingStatus.trailWorkExternal,
          label: "Probearbeit extern",
        },
        {
          value: LinkingStatus.trailWorkSuggestionExternal,
          label: "Vorschlag Probearbeit beim Kunden",
        },
      ],
      newPerfEntryPattern: {
        triggerPattern: [] as PerfEntry[],
        coachingPrompt: "",
        title: "Neues Trigger Pattern",
      } as PerfEntryPattern,
      vStyle: this.$store.state.vStyle,
      newActivity: {
        isSmallerThanCountForTrigger: true,
        entryType: undefined,
        count: 0,
        time: "15:00",
        coachingPrompt: "",
        title: "Neue Trigger Aktivität",
      } as UserTriggerSalesActivity,
    };
  },
  created() {
    if (!this.localAiData.prompt.aiUserCoaching) {
      this.localAiData.prompt.aiUserCoaching = {
        userTriggerSalesActivity: [],
        perfEntryPatterns: [],
      };
    }
    if (!this.localAiData.prompt.aiUserCoaching.perfEntryPatterns) {
      this.localAiData.prompt.aiUserCoaching.perfEntryPatterns = [];
    }
  },
  watch: {
    modelValue(newValue: AiData) {
      this.localAiData = { ...newValue };
    },
  },
  computed: {
    canAddNewPattern(): boolean {
      return (
        this.newPerfEntryPattern.triggerPattern.length > 0 &&
        !!this.newPerfEntryPattern.coachingPrompt
      );
    },
    canAddNewActivity(): boolean {
      return !!(
        this.newActivity.entryType &&
        this.newActivity.time &&
        this.newActivity.count &&
        this.newActivity.coachingPrompt
      );
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localAiData);
      this.$emit("save");
    },
    getActionLabel(value: TimelineEntryType | LinkingStatus): string {
      const action = this.userActionTypes.find((a) => a.value === value);
      return action ? action.label : value;
    },
    handleDragStart(event: DragEvent, action: any) {
      if (event.dataTransfer) {
        event.dataTransfer.setData("action", JSON.stringify(action));

        // Custom drag image
        const dragImage = document.createElement("div");
        dragImage.textContent = action.label;
        dragImage.style.position = "absolute";
        dragImage.style.top = "-9999px";
        dragImage.style.background = "var(--color-primary)";
        dragImage.style.color = "var(--color-on-primary)";
        dragImage.style.fontSize = "0.8rem";
        dragImage.style.paddingTop = "0.3rem";
        dragImage.style.paddingBottom = "0.3rem";
        dragImage.style.paddingLeft = "0.5rem";
        dragImage.style.paddingRight = "0.5rem";
        dragImage.style.borderRadius = "1.5rem";
        dragImage.style.boxShadow = "0 2px 4px rgba(0,0,0,0.2)";
        document.body.appendChild(dragImage);

        event.dataTransfer.setDragImage(dragImage, 0, 0);

        setTimeout(() => {
          document.body.removeChild(dragImage);
        }, 0);
      }
    },
    handleDrop(event: DragEvent, patternIndex: number) {
      if (event.dataTransfer) {
        const action = JSON.parse(event.dataTransfer.getData("action"));
        this.localAiData.prompt.aiUserCoaching.perfEntryPatterns[
          patternIndex
        ].triggerPattern.push({
          entryType: action.value,
          hasPhoneCallBefore: action.requiresCall || false,
        });
      }
    },
    handleDropNew(event: DragEvent) {
      if (event.dataTransfer) {
        const action = JSON.parse(event.dataTransfer.getData("action"));
        this.newPerfEntryPattern.triggerPattern.push({
          entryType: action.value,
          hasPhoneCallBefore: action.requiresCall || false,
        });
      }
    },
    removeEntry(patternIndex: number, entryIndex: number) {
      this.localAiData.prompt.aiUserCoaching.perfEntryPatterns[
        patternIndex
      ].triggerPattern.splice(entryIndex, 1);
    },
    removeNewEntry(index: number) {
      this.newPerfEntryPattern.triggerPattern.splice(index, 1);
    },
    removePattern(index: number) {
      this.localAiData.prompt.aiUserCoaching.perfEntryPatterns.splice(index, 1);
    },
    addNewPattern() {
      if (this.canAddNewPattern) {
        this.localAiData.prompt.aiUserCoaching.perfEntryPatterns.push({
          ...this.newPerfEntryPattern,
        });
        this.newPerfEntryPattern = {
          title: "Neues Trigger Pattern",
          triggerPattern: [],
          coachingPrompt: "",
        };
      }
    },
    toggleIsSmallerThanCountForTrigger(index: number) {
      this.localAiData.prompt.aiUserCoaching.userTriggerSalesActivity[
        index
      ].isSmallerThanCountForTrigger =
        !this.localAiData.prompt.aiUserCoaching.userTriggerSalesActivity[index]
          .isSmallerThanCountForTrigger;
    },
    toggleNewIsSmallerThanCountForTrigger() {
      this.newActivity.isSmallerThanCountForTrigger =
        !this.newActivity.isSmallerThanCountForTrigger;
    },
    togglePhoneCall(patternIndex: number, entryIndex: number) {
      this.localAiData.prompt.aiUserCoaching.perfEntryPatterns[
        patternIndex
      ].triggerPattern[entryIndex].hasPhoneCallBefore =
        !this.localAiData.prompt.aiUserCoaching.perfEntryPatterns[patternIndex]
          .triggerPattern[entryIndex].hasPhoneCallBefore;
    },
    toggleNewPhoneCall(index: number) {
      this.newPerfEntryPattern.triggerPattern[index].hasPhoneCallBefore =
        !this.newPerfEntryPattern.triggerPattern[index].hasPhoneCallBefore;
    },
    handleDropActivity(event: DragEvent, index: number) {
      if (event.dataTransfer) {
        const action = JSON.parse(event.dataTransfer.getData("action"));
        this.localAiData.prompt.aiUserCoaching.userTriggerSalesActivity[
          index
        ].entryType = action.value;
      }
    },
    handleDropNewActivity(event: DragEvent) {
      if (event.dataTransfer) {
        const action = JSON.parse(event.dataTransfer.getData("action"));
        this.newActivity.entryType = action.value;
      }
    },
    clearActivityType(index: number) {
      this.removeActivity(index);
    },
    clearNewActivityType() {
      this.newActivity.entryType = undefined;
    },
    removeActivity(index: number) {
      this.localAiData.prompt.aiUserCoaching.userTriggerSalesActivity.splice(
        index,
        1
      );
    },
    addNewActivity() {
      if (this.canAddNewActivity && this.newActivity.entryType) {
        const activity: UserTriggerSalesActivity = {
          coachingPrompt: this.newActivity.coachingPrompt,
          count: this.newActivity.count,
          entryType: this.newActivity.entryType,
          isSmallerThanCountForTrigger:
            this.newActivity.isSmallerThanCountForTrigger,
          time: this.newActivity.time,
          title: this.newActivity.title,
        };
        this.localAiData.prompt.aiUserCoaching.userTriggerSalesActivity.push(
          activity
        );
        this.newActivity = {
          coachingPrompt: "",
          count: 0,
          entryType: undefined,
          isSmallerThanCountForTrigger: true,
          time: "",
          title: "Neue Trigger Aktivität",
        };
      }
    },
  },
});
</script>
