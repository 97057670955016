<template>
  <AdminMenu />
  <AppHeader v-if="layout !== AppLayout.classic"></AppHeader>
  <v-container :fluid="true">
    <v-row class="mt-5">
      <v-col cols="6">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
        >
          <v-card-title class="mt-3">Verlinkungen bereinigen</v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item v-for="(linking, index) in linkings" :key="index">
                <div>
                  <v-list-item-title class="d-flex justify-space-between">
                    <div>
                      {{ linking.description?.firstName }}
                      {{ linking.description?.lastName }}
                      <v-icon
                        v-if="
                          linking.description?.lastName &&
                          linking.description?.customerName
                        "
                        class="mx-1"
                        size="small"
                        >fas fa-link</v-icon
                      >
                      {{ linking.description?.customerName }}
                    </div>
                    <v-btn
                      icon
                      density="compact"
                      variant="text"
                      @click="deleteLinking(linking._id)"
                    >
                      <v-icon>fas fa-trash-can</v-icon>
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Letztes Event:
                    {{
                      formatDate(
                        linking.events[linking.events.length - 1].eventDate[0]
                      )
                    }}
                    - {{ linking.events[linking.events.length - 1].eventType }}
                  </v-list-item-subtitle>
                  <v-divider class="mt-3"></v-divider>
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
        >
          <v-card-title> Alle Daten aus ERP System importieren </v-card-title>
          <v-card-subtitle>
            Überschreibt Kunden / Mitarbeiterdaten!
          </v-card-subtitle>
          <v-card-text>
            <v-select
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="initialisieren / aktualisieren"
              v-model="erpOptions"
              :items="ErpFetchOptions"
              item-title="label"
              item-value="label"
              multiple
            >
            </v-select>
            <v-btn
              variant="outlined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              @click="fetchErpInitData(erpOptions)"
              >FETCH</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import { LinkingService } from "@/services/api/linking.service";
import moment from "moment";
import { getEnumOptions } from "@/helper/enum.helper";
import { ErpFetchOption } from "@/enums/init-options.enum";
import { AllErpAdapter } from "@/adapter/all-erp.adapter";
import AppHeader from "@/components/header/Header.vue";
import { AppLayout } from "@/enums/app-layout.enum";
import { ActionType } from "../../../enums/vuex-types.enum";

export default defineComponent({
  name: "DataCleaning",
  components: {
    AdminMenu,
    AppHeader,
  },
  computed: {
    ...mapGetters({
      linkings: "getLinkings",
      softwareIntegration: "softwareIntegration",
      loggedInMandantBranchNumbers: "getLoggedInMandantBranchNumbers",
      allZvooveOneGeschaeftsstelleIds: "allZvooveOneGeschaeftsstelleIds",
    }),
  },
  data() {
    return {
      erpOptions: [] as ErpFetchOption[],
      ErpFetchOptions: getEnumOptions(ErpFetchOption),
      isLoading: false,
      linkingService: new LinkingService(),
      AppLayout: AppLayout,
      layout: this.$store.getters.appLayout,
      vStyle: this.$store.state.vStyle,
    };
  },
  mounted() {
    this.$store.dispatch(ActionType.loadLinkingsAndAddMissingDescriptions);
  },
  methods: {
    deleteLinking(id: string) {
      this.linkingService.deleteLinking(id);
    },
    async fetchErpInitData(erpOptions: ErpFetchOption[]) {
      if (
        (!this.softwareIntegration.pdHub ||
          !this.softwareIntegration.zvooveOne) &&
        this.isLoading
      ) {
        return;
      }

      this.isLoading = true;

      try {
        await AllErpAdapter.getErpInitData(
          erpOptions,
          this.allZvooveOneGeschaeftsstelleIds,
          this.loggedInMandantBranchNumbers
        );
      } catch (error) {
        console.error("Fehler beim Abrufen der Initialdaten", error);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(dateString: string) {
      return moment(dateString).format("DD.MM.YYYY [um] HH:mm");
    },
  },
});
</script>
