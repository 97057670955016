import { Api } from "./api.class";

export class CompanyWebSearchService {
  private api = Api.getInstance();
  private urlPath = "/company-web-search";

  async companyWebSearch(search: string, noMandant?: boolean) {
    const response = await this.api.getBackendApi().post(`${this.urlPath}`, {
      search,
      noMandant,
    });
    return response.data.response;
  }
}
