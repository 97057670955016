import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_Documentation = _resolveComponent("Documentation")!
  const _component_PayFlowOverview = _resolveComponent("PayFlowOverview")!
  const _component_WorkTime = _resolveComponent("WorkTime")!
  const _component_LeftHeader = _resolveComponent("LeftHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_ctx.layout === _ctx.AppLayout.classic)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MainHeader, {
          onTabSelected: _ctx.handleTabSelection,
          showAdministrationTabs: true
        }, null, 8, ["onTabSelected"]),
        (_ctx.currentTab === _ctx.HeaderTab.Documentation)
          ? (_openBlock(), _createBlock(_component_Documentation, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.currentTab === _ctx.HeaderTab.PayFlowOverview)
          ? (_openBlock(), _createBlock(_component_PayFlowOverview, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.currentTab === _ctx.HeaderTab.WorkTime)
          ? (_openBlock(), _createBlock(_component_WorkTime, { key: 2 }))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "auto"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LeftHeader)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "11"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppHeader, {
                onTabSelected: _ctx.handleTabSelection,
                showAdministrationTabs: true
              }, null, 8, ["onTabSelected"]),
              (_ctx.currentTab === _ctx.HeaderTab.Documentation)
                ? (_openBlock(), _createBlock(_component_Documentation, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.currentTab === _ctx.HeaderTab.PayFlowOverview)
                ? (_openBlock(), _createBlock(_component_PayFlowOverview, { key: 1 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}