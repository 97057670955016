<template>
  <div v-if="layout === AppLayout.classic">
    <MainHeader></MainHeader>
    <WorkTime></WorkTime>
  </div>
  <v-row v-else class="d-flex">
    <v-col cols="12" md="auto">
      <LeftHeader></LeftHeader>
    </v-col>
    <v-col cols="12" md="11">
      <AppHeader></AppHeader>
      <WorkTime></WorkTime>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { AppLayout } from "@/enums/app-layout.enum";
import { defineComponent } from "vue";
import LeftHeader from "@/components/header/LeftHeader.vue";
import MainHeader from "@/components/header/ClassicHeader.vue";
import AppHeader from "@/components/header/Header.vue";
import WorkTime from "@/components/worktime/WorkTime.vue";

export default defineComponent({
  name: "WorktimeView",
  components: {
    LeftHeader,
    WorkTime,
    MainHeader,
    AppHeader,
  },
  data() {
    return {
      layout: this.$store.getters.appLayout,
      AppLayout: AppLayout,
    };
  },
});
</script>
