<template>
  <v-file-input
    :variant="vStyle.input.variant || undefined"
    :rounded="vStyle.input.rounded || undefined"
    :base-color="vStyle.input.baseColor || undefined"
    :color="vStyle.input.color || undefined"
    ref="fileInput"
    v-model="file"
    label="PNG / JPEG / PDF"
    prepend-icon="fa-solid fa-cloud-arrow-up"
    show-size
    :rules="[fileRules]"
    accept=".png,.jpg,.pdf"
    @input="onFileChange"
    density="compact"
  ></v-file-input>
  <v-card
    variant="outlined"
    border="primary-darken-1 md dashed"
    class="mb-4 pa-5 d-flex flex-column align-center"
    @drop.prevent="onDrop"
    @dragover.prevent
  >
    <v-card-title class="d-flex align-center"
      >Datei hierhin verschieben</v-card-title
    >
    <v-card-title class="d-flex align-center">oder STRG + V</v-card-title>
    <v-card-text>
      <div v-if="file">
        <p>Dateiname: {{ file.name }}</p>
        <p>Dateigröße: {{ (file.size / 1024).toFixed(2) }} KB</p>
      </div>
    </v-card-text>
  </v-card>

  <v-select
    append-icon="fas fa-plus"
    :prepend-inner-icon="showEditIcon ? 'fas fa-pencil' : undefined"
    :variant="vStyle.input.variant || undefined"
    :rounded="vStyle.input.rounded || undefined"
    :base-color="vStyle.input.baseColor || undefined"
    :color="vStyle.input.color || undefined"
    v-model="selectedPrompt"
    :items="prompts"
    item-title="headline"
    density="compact"
    return-object
    @click:append="openAddOrEditPromptDialog()"
    @click:prepend-inner="editSelectedPrompt()"
    @update:model-value="checkIfEditable()"
  >
  </v-select>
  <v-textarea
    :variant="vStyle.input.variant || undefined"
    :rounded="vStyle.input.rounded || undefined"
    :base-color="vStyle.input.baseColor || undefined"
    :color="vStyle.input.color || undefined"
    v-model="selectedPrompt.prompt"
    @click:append-inner="deleteWorkTimePrompt(selectedPrompt)"
    :append-inner-icon="showEditIcon ? 'fas fa-circle-xmark' : undefined"
    label="Kundenspezifische Besonderheiten"
    rows="4"
    placeholder="Beschreibe genau wie die Arbeitsstunden extahiert werden sollen..."
  ></v-textarea>
  <div class="d-flex">
    <v-btn
      :variant="vStyle.btn.variant || undefined"
      :rounded="vStyle.btn.rounded || undefined"
      :border="vStyle.btn.border || undefined"
      :disabled="!file"
      @click="uploadFile"
      color="secondary"
    >
      Arbeitszeiten extrahieren
    </v-btn>
    <v-btn icon density="compact" variant="text">
      <v-icon>fa-solid fa-ellipsis-vertical</v-icon>

      <v-menu activator="parent">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
        >
          <v-card-text>
            <v-radio-group v-model="selectedAiSpecs.core" label="AI Core">
              <v-radio
                v-for="core in aiCores"
                :key="core.core"
                :label="core.label"
                :value="core.core"
              >
                <template v-slot:label>
                  <div>
                    <div>{{ core.label }}</div>
                    <div
                      class="text-subtitle-1"
                      style="font-size: 0.6rem; color: gray"
                    >
                      {{ core.subtitle }}
                    </div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
            <v-divider class="mb-4"></v-divider>
            <v-radio-group v-model="selectedAiSpecs.model" label="AI Model">
              <v-radio
                v-for="model in aiModels"
                :key="model.core"
                :label="model.label"
                :value="model.core"
              />
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-btn>
  </div>
  <v-dialog
    v-model="showAddOrEditPromptDialog"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'"
  >
    <v-card>
      <v-card-title class="d-flex"
        >{{ editPrompt ? "Details Bearbeiten" : "Neuen Prompt hinzufügen" }}
        <v-spacer></v-spacer>
        <v-btn
          @click="showAddOrEditPromptDialog = false"
          icon
          size="xsmall"
          variant="text"
          density="compact"
        >
          <v-icon> fa-solid fa-xmark close-icon </v-icon>
        </v-btn></v-card-title
      >
      <v-card-text>
        <v-row noGutters>
          <v-col cols="12">
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="currentPrompt.headline"
              density="compact"
              label="Überschrift"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="currentPrompt.prompt"
              density="compact"
              label="Prompt"
            >
            </v-textarea>
          </v-col>
          <v-col cols="4">
            <v-text-field
              class="mr-4"
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="currentPrompt.customerNumber"
              density="compact"
              label="Kundenummer"
              prepend-inner-icon="fa-solid fa-magnifying-glass"
              @click:prepend-inner="searchCustomerMenuOpen()"
            >
            </v-text-field>
            <SearchEmployee
              ref="searchEmployeeComponent"
              @employeeSelected="addSelectedEmployee"
            ></SearchEmployee>
          </v-col>
          <v-col cols="8">
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="currentPrompt.customer"
              density="compact"
              label="Firmenname"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-combobox
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="currentPrompt.employeeNumbers"
              density="compact"
              clearable
              multiple
              label="Mitarbeiter"
              prepend-inner-icon="fa-solid fa-magnifying-glass"
              @click:prepend-inner="searchEmployeeMenuOpen()"
            ></v-combobox>
            <SearchCustomer
              ref="searchCustomerComponent"
              @customerSelected="addSelectedCustomer"
            ></SearchCustomer>
            <v-chip
              v-for="(name, index) in employeeNames"
              :key="index"
              class="ma-2"
              color="primary-darken-1"
              outlined
            >
              {{ name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="abort" @click="closeAddOrEditPromptDialog()"
          >Abbrechen</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="success" @click="addOrEditPrompt()">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AiService } from "@/services/ai.service";
import ToastService from "@/services/toast.service";
import { WorkDay } from "@/models/worktime.model";
import { SpinnerService } from "@/services/spinner.service";
import { WorkTimePrompt } from "../../models/company-config.model";
import { AiCore, AiModel } from "../../enums/ai-options.enum";
import { AiSpecs } from "../../models/ai-specs.model";
import { useDisplay } from "vuetify";
import SearchEmployee from "./SearchEmployee.vue";
import SearchCustomer from "./SearchCustomer.vue";
import { mapGetters } from "vuex";
import { CompanyConfigService } from "../../services/api/company-config.service";
import { MutationType } from "../../enums/vuex-types.enum";

export default defineComponent({
  name: "FileUpload",
  emits: ["addWorkTime"],
  components: {
    SearchCustomer,
    SearchEmployee,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      aiCores: [
        {
          label: "Kern I",
          subtitle: "Trust: Deutschland",
          core: AiCore.GDPRtrust,
        },
        {
          label: "Kern II",
          subtitle: "Save: Europa",
          core: AiCore.GDPRsave,
        },
        {
          label: "Kern III weltweit",
          subtitle: "⚠ keine Personendaten",
          core: AiCore.perform,
        },
      ],
      aiModels: [
        {
          label: "Stundenerkennung",
          core: AiModel.vision,
        },
        {
          label: "Vision Pro",
          core: AiModel.visionHigh,
        },
      ],
      companyConfigService: new CompanyConfigService(),
      currentPrompt: {} as WorkTimePrompt,
      editPrompt: false,
      file: null as File | null,
      selectedAiSpecs: {
        core: AiCore.GDPRtrust,
        model: AiModel.vision,
      } as AiSpecs,
      selectedPrompt: {
        headline: "",
        prompt: "",
      } as WorkTimePrompt,
      showAddOrEditPromptDialog: false,
      showEditIcon: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      prompts: "workTimePrompts",
    }),
    fileRules(): string | boolean {
      return this.validateFile();
    },
    employeeNames(): string[] {
      if (
        !this.currentPrompt.employeeNumbers ||
        this.currentPrompt.employeeNumbers.length === 0
      )
        return [];
      const getEmployeeNameByNumber =
        this.$store.getters.getEmployeeNameByNumberFromEmployeeList;

      return this.currentPrompt.employeeNumbers
        .map((employeeNumber: string) => {
          const employee = getEmployeeNameByNumber(employeeNumber);
          if (employee) {
            return `${employee.firstName} ${employee.lastName}`;
          }
          return null;
        })
        .filter((name: string | null) => name !== null);
    },
  },
  mounted() {
    // Event-Listener for CRTL+V
    window.addEventListener("paste", this.onPaste);
    this.clearCurrentPrompt();
  },
  beforeUnmount() {
    window.removeEventListener("paste", this.onPaste);
  },
  methods: {
    async addOrEditPrompt() {
      if (
        this.editPrompt &&
        this.currentPrompt.headline &&
        this.currentPrompt.prompt
      ) {
        this.$store.commit(MutationType.editWorktimePrompt, this.currentPrompt);
        this.companyConfigService.updateWorkTimePrompts(this.prompts);
      } else if (this.currentPrompt.headline && this.currentPrompt.prompt) {
        const companyConfigPrompts =
          await this.companyConfigService.addWorkTimePrompt(this.currentPrompt);
        this.$store.commit(
          MutationType.setWorktimePrompt,
          companyConfigPrompts
        );
        this.closeAddOrEditPromptDialog();
        this.clearCurrentPrompt();
      }
    },
    addSelectedCustomer(customerNumber: string, customerName: string) {
      this.currentPrompt.customerNumber = customerNumber;
      this.currentPrompt.customer = customerName;
    },
    addSelectedEmployee(employeeNumber: string, employeeName: string) {
      if (!Array.isArray(this.currentPrompt.employeeNumbers)) {
        this.currentPrompt.employeeNumbers = [];
      }
      if (!this.currentPrompt.employeeNumbers.includes(employeeNumber)) {
        this.currentPrompt.employeeNumbers.push(employeeNumber);
      } else {
        ToastService.show(
          `Mitarbeiter ${employeeName} mit Nummer ${employeeNumber} ist bereits ausgewählt.`
        );
      }
    },
    checkIfEditable() {
      if (this.selectedPrompt._id) {
        this.showEditIcon = true;
      } else {
        this.showEditIcon = false;
      }
    },
    clearCurrentPrompt() {
      this.currentPrompt = {
        customerNumber: "",
        customer: "",
        employeeNumbers: [],
        headline: "",
        prompt: "",
      } as WorkTimePrompt;
    },
    clearSelectedPrompt() {
      this.selectedPrompt = {
        customerNumber: "",
        customer: "",
        employeeNumbers: [],
        headline: "",
        prompt: "",
      } as WorkTimePrompt;
    },
    closeAddOrEditPromptDialog() {
      this.showAddOrEditPromptDialog = false;
    },
    editSelectedPrompt() {
      this.currentPrompt = this.selectedPrompt;
      this.editPrompt = true;
      this.openAddOrEditPromptDialog();
    },
    deleteWorkTimePrompt(workTimePrompt: WorkTimePrompt) {
      this.$store.commit(MutationType.deleteWorktimePrompt, workTimePrompt);
      this.companyConfigService.updateWorkTimePrompts(this.prompts);
      this.clearSelectedPrompt();
    },
    onFileChange(file: File | File[]) {
      if (Array.isArray(file)) {
        this.file = file[0];
      } else {
        this.file = file;
      }
    },
    onPaste(event: ClipboardEvent) {
      const items = event.clipboardData?.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item.kind === "file" && item.type.startsWith("image/")) {
            const file = item.getAsFile();
            if (file) {
              this.file = file;
            }
          }
        }
      }
    },
    onDrop(event: DragEvent) {
      const file = event.dataTransfer?.files[0];
      if (file) {
        this.file = file;
      }
    },
    openAddOrEditPromptDialog() {
      this.showAddOrEditPromptDialog = true;
    },
    searchEmployeeMenuOpen() {
      if (this.$refs.searchEmployeeComponent) {
        (
          this.$refs.searchEmployeeComponent as InstanceType<
            typeof SearchEmployee
          >
        ).toggleMenu();
      }
    },
    searchCustomerMenuOpen() {
      if (this.$refs.searchCustomerComponent) {
        (
          this.$refs.searchCustomerComponent as InstanceType<
            typeof SearchCustomer
          >
        ).toggleMenu();
      }
    },
    async uploadFile() {
      if (!this.file) return;
      SpinnerService.showSpinner();
      try {
        const aiService = new AiService();
        const response = (await aiService.extractWorktimes(
          this.file,
          this.selectedPrompt.prompt,
          this.selectedAiSpecs
        )) as WorkDay[];

        if (!response) {
          throw new Error("Es konnten keine Arbeitzeiten extrahiert werden!");
        }
        this.$emit("addWorkTime", response);
        ToastService.showSuccess("Datei und Prompt erfolgreich hochgeladen");
      } catch (error) {
        console.error("Fehler beim Hochladen:", error);
        ToastService.showError(
          "Es konnten keine Arbeitzeiten extrahiert werden!"
        );
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    validateFile() {
      if (!this.file) {
        return "Eine Datei ist erforderlich";
      }
      if (this.file.size > 2 * 1024 * 1024) {
        return "Die Datei darf nicht größer als 2MB sein";
      }
      return true;
    },
  },
});
</script>
