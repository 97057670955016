import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_cal = _resolveComponent("vue-cal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "calendar-header" }, "Kalender", -1)),
    _createVNode(_component_vue_cal, {
      locale: "de",
      "time-from": 6 * 60,
      "time-to": 20 * 60,
      "time-step": 60,
      events: _ctx.appointments,
      view: "week",
      "hide-view-selector": "",
      "hide-weekends": ""
    }, null, 8, ["events"])
  ], 64))
}