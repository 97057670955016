import { Store } from "vuex";
import { MutationType } from "@/enums/vuex-types.enum";
import { RecurionChatbotType } from "@/enums/recurion-chatbot-types.enum";

export class OpenAiCoachingHelper {
  static checkUserForOpenAiCoaching(store: Store<any>, userConfig: any): void {
    if (userConfig.hasOpenAiCoachingPrompt) {
      store.commit(MutationType.setRecurionChat, {
        isActive: true,
        isClosed: false,
        chatbotType: RecurionChatbotType.coach,
        initPrompt: userConfig.hasOpenAiCoachingPrompt,
      });
    }
  }
}
