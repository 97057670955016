<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-link</v-icon>Konfiguration
      für ATS System zvoove Recruit
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="3"></v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="primary"
            v-model="localConfig.softwareIntegration.atsDeterminesStatus"
            label="ATS bestimmt Kandidatenstatus"
            @update:model-value="
              (value) => checkAtsStatusSettings('atsDeterminesStatus', value)
            "
          ></v-switch>
        </v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="primary"
            v-model="localConfig.softwareIntegration.atsStatusIgnored"
            label="Ignoriere ATS Status"
            @update:model-value="
              (value) => checkAtsStatusSettings('atsStatusIgnored', value)
            "
          ></v-switch>
        </v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="primary"
            v-model="localConfig.softwareIntegration.atsAutoDocu"
            label="automatische Dokumentation"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.softwareIntegration.zvooveRecruitLink"
            hint="https://FIRMA-NAME.europersonal.com"
            label="zvoove Recruit Link"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.zvooveNextLevel"
            label="NextLevel API Key"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.zvooveOpenBewerber"
            label="API OpenBewerber"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.zvooveOpenStelle"
            label="API OpenStelle"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-title> Statusnamen im ATS System:</v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="(statusOption, index) in localConfig.statusOptionsRecruit"
          :key="statusOption._id"
        >
          <v-card
            :variant="vStyle.card.variant || undefined"
            :rounded="vStyle.card.rounded || undefined"
            :border="vStyle.card.border || undefined"
            class="mb-2"
          >
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-3"
            >
              {{ statusOption.text }}
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="removeStatusOption(index)">
                <v-icon>fa-solid fa-trash-can</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="statusOption.text"
                label="Anzeige in Dispositioner"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="statusOption.value"
                :rules="[rules.minLength(3)]"
                label="Statusname Recruit"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="statusOption.slider"
                type="number"
                :rules="[rules.validSlider(0, 4)]"
                clearable
                label="Slider Position"
                density="compact"
                class="mb-2"
                @update:model-value="
                  (value) => updateSliderValue(statusOption, value)
                "
              ></v-text-field>
              <v-select
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="statusOption.status"
                :items="linkingStatusOptions"
                label="Status"
                density="compact"
                multiple
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
            :variant="vStyle.card.variant || undefined"
            :rounded="vStyle.card.rounded || undefined"
            :border="vStyle.card.border || undefined"
            class="border-dashed mb-2"
          >
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-3"
            >
              Neuer Status
            </v-card-title>
            <v-card-text>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newStatusOption.text"
                label="Anzeige in Dispositioner"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newStatusOption.value"
                :rules="[rules.minLength(3)]"
                label="Statusname Recruit"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newStatusOption.slider"
                :rules="[rules.validSlider(0, 4)]"
                clearable
                label="Slider Position"
                density="compact"
                class="mb-2"
                @update:model-value="
                  (value) => updateSliderValue(newStatusOption, value)
                "
              ></v-text-field>
              <v-select
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newStatusOption.status"
                :items="linkingStatusOptions"
                label="Status"
                density="compact"
                multiple
              ></v-select>
              <v-btn color="primary" variant="text" @click="addNewStatusOption"
                >Hinzufügen</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import { LinkingStatus } from "@/enums/dependency.enum";
import { formRules } from "@/helper/form-rules.helper";

type SoftwareIntegrationKeys = "atsDeterminesStatus" | "atsStatusIgnored";

export default defineComponent({
  name: "ConfigZvooveRecruit",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue } as CompanyConfig,
      newStatusOption: {
        text: "",
        value: "",
        slider: null as number | null,
        status: [] as LinkingStatus[],
      },
      rules: formRules,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    linkingStatusOptions() {
      return Object.values(LinkingStatus);
    },
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    addNewStatusOption() {
      if (this.newStatusOption.text && this.newStatusOption.value) {
        this.localConfig.statusOptionsRecruit.push({ ...this.newStatusOption });
        this.resetNewStatusOption();
        this.handleSubmit();
      }
    },
    checkAtsStatusSettings(
      key: SoftwareIntegrationKeys,
      value: boolean | null
    ) {
      if (!value) return;

      this.localConfig.softwareIntegration[key] = value;
      if (key === "atsStatusIgnored" && value) {
        this.localConfig.softwareIntegration.atsDeterminesStatus = false;
      }

      if (key === "atsDeterminesStatus" && value) {
        this.localConfig.softwareIntegration.atsStatusIgnored = false;
      }
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
    removeStatusOption(index: number) {
      this.localConfig.statusOptionsRecruit.splice(index, 1);
    },

    resetNewStatusOption() {
      this.newStatusOption = {
        text: "",
        value: "",
        slider: null,
        status: [] as LinkingStatus[],
      };
    },
    updateSliderValue(option: any, value: string | number | null) {
      option.slider = value !== "" ? Number(value) : null;
    },
  },
});
</script>

<style scoped>
/* Add any necessary styling here */
</style>
