import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_v_timeline = _resolveComponent("v-timeline")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_btn, {
        variant: _ctx.vStyle.btn.variant || undefined,
        rounded: _ctx.vStyle.btn.rounded || undefined,
        border: _ctx.vStyle.btn.border || undefined,
        color: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true))
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Historie hinzufügen")
        ])),
        _: 1
      }, 8, ["variant", "rounded", "border"]),
      _createVNode(_component_v_timeline, {
        side: "end",
        density: "compact"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timelines, (entry, index) => {
            return (_openBlock(), _createBlock(_component_v_timeline_item, {
              key: index,
              color: "primary",
              "fill-dot": ""
            }, {
              icon: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("span", { style: {"color":"var(--color-on-primary)"} }, [
                  _createElementVNode("i", { class: "fa-solid fa-comment-dots" })
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "elevation-3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(entry?.entryType), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatEntryDate(entry?.entryDate)), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_text, {
                      innerHTML: _ctx.formatNoteText(entry?.note)
                    }, null, 8, ["innerHTML"]),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_chip, {
                          class: "ma-2",
                          color: "primary",
                          label: "",
                          small: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(entry?.user), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["variant", "rounded", "border"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showModal,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showModal) = $event)),
        persistent: "",
        "max-width": "600px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createElementVNode("span", { class: "text-h5" }, "Eintrag hinzufügen", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.selectedEntryType,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedEntryType) = $event)),
                    items: _ctx.timelineEntryOptions,
                    label: "Eintragstyp"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"]),
                  _createVNode(_component_v_textarea, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.noteText,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.noteText) = $event)),
                    label: "Notiz",
                    rows: "4"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "abort",
                    onClick: _ctx.closeModal
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Abbrechen")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "success",
                    onClick: _ctx.submitEntry
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Dokumentieren")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}