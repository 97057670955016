<template>
  <v-tabs
    v-model="activeTab"
    align-tabs="start"
    bg-color="primary-darken-1"
    slider-color="primary-darken-1"
    height="30"
  >
    <v-tab class="text-none text-subtitle-1"
      ><v-icon class="mr-1">fas fa-gear</v-icon>Einstellungen</v-tab
    >
    <v-tab
      class="text-none text-caption"
      v-for="item in items"
      :key="item"
      :value="item"
      @click="navigateTo(item)"
    >
      {{ item }}
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
import { UserRole } from "@/enums/user-role.enum";
import { UserHelperService } from "@/services/user-helper.service";
import { defineComponent } from "vue";
import { UserRoleHelper } from "@/helper/user-role.helper";
import { Routes } from "@/enums/routes.enum";
import { AppPageAndMenuTitle } from "../../enums/app-layout.enum";
import { MutationType } from "../../enums/vuex-types.enum";

export default defineComponent({
  name: "AdminMenu",
  data() {
    const userRole = UserHelperService.getInstance().getUserRole();
    const items = [
      AppPageAndMenuTitle.softwareIntegration,
      AppPageAndMenuTitle.ai,
    ];
    if (UserRoleHelper.isBranchManager(this.$store.getters.user)) {
      items.unshift(AppPageAndMenuTitle.mandants);
    }
    if (UserRoleHelper.isAdmin(this.$store.getters.user)) {
      items.unshift(AppPageAndMenuTitle.dataCleaning);
    }
    if (userRole === UserRole.UberAdmin) {
      items.unshift(AppPageAndMenuTitle.company);
    }
    if (UserRoleHelper.isAdmin(this.$store.getters.user)) {
      items.unshift(AppPageAndMenuTitle.user);
    }
    if (userRole === UserRole.UberAdmin) {
      items.unshift(AppPageAndMenuTitle.backendLogs);
    }
    return {
      activeTab: AppPageAndMenuTitle.user,
      items,
      userRole,
      vStyle: this.$store.state.vStyle,
    };
  },
  mounted() {
    this.$store.commit(MutationType.setRecurionChatInactive);
  },
  methods: {
    navigateTo(item: string) {
      switch (item) {
        case AppPageAndMenuTitle.company:
          if (this.userRole === UserRole.UberAdmin) {
            this.$router.push(Routes.configCompany);
          }
          break;
        case AppPageAndMenuTitle.backendLogs:
          if (this.userRole === UserRole.UberAdmin) {
            this.$router.push(Routes.backendLogs);
          }
          break;
        case AppPageAndMenuTitle.user:
          if (UserRoleHelper.isAdmin(this.$store.getters.user)) {
            this.$router.push(Routes.configUser);
          }
          break;
        case AppPageAndMenuTitle.mandants:
          if (UserRoleHelper.isBranchManager(this.$store.getters.user)) {
            this.$router.push(Routes.configMandant);
          }
          break;
        case AppPageAndMenuTitle.softwareIntegration:
          this.$router.push(Routes.configApi);
          break;
        case AppPageAndMenuTitle.ai:
          this.$router.push(Routes.configAi);
          break;
        case AppPageAndMenuTitle.dataCleaning:
          if (UserRoleHelper.isAdmin(this.$store.getters.user)) {
            this.$router.push(Routes.dataCleaning);
          }
          break;
        default:
      }
    },
  },
});
</script>
