import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mx-auto" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        style: {"background-color":"#a8a8a8"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { dense: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templates, (template) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  cols: "6",
                  key: template?.name
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["wabTemplate", { selected: template.click }]),
                      onClick: ($event: any) => (_ctx.selectTemplate(template))
                    }, [
                      (template?.imageLink)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("img", {
                              src: template?.imageLink,
                              class: "templateImg"
                            }, null, 8, _hoisted_3)
                          ]))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(template.text), 1)
                    ], 10, _hoisted_1),
                    (template?.params)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(template?.params, (param) => {
                            return (_openBlock(), _createElementBlock("div", { key: param }, [
                              _createVNode(_component_v_text_field, {
                                type: "text",
                                class: "w-100",
                                label: param,
                                "model-value": param === 'name' ? _ctx.receiverName : param,
                                onInput: ($event: any) => (_ctx.onInput(template?.name, param, $event))
                              }, null, 8, ["label", "model-value", "onInput"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}