import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex justify-space-between ml-2 mt-2" }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createTextVNode(" Passwort vergessen ")),
              _createVNode(_component_v_btn, {
                icon: "",
                variant: "text",
                density: "compact",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("fa-solid fa-xmark")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, { class: "ml-2" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Bitte geben Sie Ihre Email Adresse ein")
            ])),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                variant: "outlined",
                modelValue: _ctx.emailInput,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailInput) = $event)),
                label: "Email Adresse",
                type: "email",
                required: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "abort",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancel')))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Abbrechen")
                ])),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "green-darken-4",
                onClick: _ctx.sendPassword
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Senden")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["max-width"]))
}