import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showMatchDialog,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showMatchDialog) = $event)),
    "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)',
    persistent: "",
    class: "fill-height"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex" }, {
            default: _withCtx(() => [
              _cache[8] || (_cache[8] = _createTextVNode(" Umkreissuche ")),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeDialog'))),
                icon: "",
                size: "s",
                variant: "text",
                density: "compact"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" fa-solid fa-xmark close-icon ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                modelValue: _ctx.valid,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.valid) = $event)),
                ref: "form"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    items: _ctx.statusOptionsRecruit,
                    "item-title": "text",
                    "item-value": "value",
                    label: "ein oder mehrere Kandidatenstatus",
                    modelValue: _ctx.matchCandidateRequest.selectedStatuses,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.matchCandidateRequest.selectedStatuses) = $event)),
                    rules: [_ctx.rules.required],
                    hint: "* Erforderlich",
                    "persistent-hint": "",
                    multiple: "",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                  }, null, 8, ["variant", "rounded", "base-color", "color", "items", "modelValue", "rules"]),
                  _createVNode(_component_v_label, { class: "mt-5 mx-2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Suche " + _toDisplayString(_ctx.postcodeRadius) + "km im Umkreis von Postleitzahl " + _toDisplayString(_ctx.postcode), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_slider, {
                    max: 100,
                    min: 0,
                    step: 1,
                    class: "my-2",
                    modelValue: _ctx.postcodeRadius,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.postcodeRadius) = $event)),
                    "thumb-label": ""
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_btn, {
                    block: "",
                    color: "secondary",
                    disabled: !_ctx.valid,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.startBackendSearch()))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Suchen")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}