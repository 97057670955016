import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isUberAdmin)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 0,
          variant: "text",
          icon: "",
          density: "compact"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              class: "bug-icon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = true)),
              size: "small"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" fa-solid fa-bug ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Bug melden")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.dialog,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dialog) = $event)),
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createElementVNode("span", { class: "headline" }, " REPORT-A-BUG: Bugs / Ideen / Features anfragen ", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  modelValue: _ctx.valid,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valid) = $event)),
                  ref: "form"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_select, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.issueType,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.issueType) = $event)),
                      items: _ctx.issueTypes,
                      label: "Select Issue Type",
                      rules: [(v) => !!v || 'Issue type is required'],
                      required: ""
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items", "rules"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.title,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.title) = $event)),
                      label: "Title",
                      rules: [(v) => !!v || 'Title is required'],
                      required: ""
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                    _createVNode(_component_v_textarea, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.description,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.description) = $event)),
                      label: "Description",
                      rules: [(v) => !!v || 'Description is required'],
                      required: ""
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  variant: _ctx.vStyle.btn.variant || undefined,
                  rounded: _ctx.vStyle.btn.rounded || undefined,
                  border: _ctx.vStyle.btn.border || undefined,
                  color: "abort",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.dialog = false))
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Cancel ")
                  ])),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  variant: _ctx.vStyle.btn.variant || undefined,
                  rounded: _ctx.vStyle.btn.rounded || undefined,
                  border: _ctx.vStyle.btn.border || undefined,
                  color: "success",
                  onClick: _ctx.submitBugReport,
                  disabled: !_ctx.valid
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" Submit ")
                  ])),
                  _: 1
                }, 8, ["variant", "rounded", "border", "onClick", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ], 64))
}