//src/services/api/demand.service.ts
import { Demand } from "@/models/demand.model";
import { Api } from "./api.class";
import { TimelineHelperService } from "../timeline-helper.service";
import { LinkingStatus } from "../../enums/dependency.enum";

export class DemandService {
  private api = Api.getInstance();
  private urlPath = "/demand";

  async getAllDemands(mandantUuids?: string[]) {
    try {
      let url = this.urlPath;
      if (mandantUuids && mandantUuids.length > 0) {
        const params = mandantUuids
          .map((uuid) => `mandantUuids=${uuid}`)
          .join("&");
        url += `?${params}`;
      }
      const response = await this.api.getBackendApi().get(url);
      return response.data.response || [];
    } catch (error) {
      console.error("Error fetching demands:", error);
      return { data: null, error };
    }
  }

  async getAllFiltered(
    mandants: string[],
    statuses: string[]
  ): Promise<Demand[]> {
    const response = await this.api
      .getBackendApi()
      .post<{ response: Demand[] }>(`${this.urlPath}/filtered`, {
        mandants,
        statuses,
      });
    return response.data.response;
  }

  async getDemandById(id: string) {
    try {
      const response = await this.api
        .getBackendApi()
        .get(`${this.urlPath}/id/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching demand by id:", error);
      return { data: null, error };
    }
  }

  async addDemand(demand: Demand) {
    try {
      const response = await this.api
        .getBackendApi()
        .post(this.urlPath, demand);
      const timelineHelperService = new TimelineHelperService();
      const customer = {
        name: demand.customer,
        contact: `${demand.demandContactPerson.firstName} ${
          demand.demandContactPerson.lastName
        } ${
          demand.demandContactPerson.position
            ? "(" + demand.demandContactPerson.position + ")"
            : ""
        }`,
        customerId: "",
      };
      timelineHelperService.systemAutoDocu(
        LinkingStatus.demandCreated,
        demand.mandants[0],
        `Anfrage '${demand.demands[0].quantity}x ${demand.demands[0].position}'\nfür ${demand.customer} in ${demand.demands[0].location.postcode} ${demand.demands[0].location.city}\nerfolgreich erstellt`,
        customer
      );
      return response.data;
    } catch (error) {
      console.error("Error adding demand:", error);
      return { data: null, error };
    }
  }

  async updateDemand(demand: Demand) {
    try {
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}/${demand._id}`, demand);
      return response.data;
    } catch (error) {
      console.error("Error updating demand:", error);
      return { data: null, error };
    }
  }

  async removeDemand(id: string) {
    try {
      const response = await this.api
        .getBackendApi()
        .delete(`${this.urlPath}/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error removing demand:", error);
      return { data: null, error };
    }
  }
}
