<template>
  <v-form>
    <v-row noGutters>
      <v-col cols="10">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Server"
          v-model="emailConfigCopy.server"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Port"
          v-model="emailConfigCopy.port"
          type="number"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="10">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="E-Mail Adresse"
          v-model="emailConfigCopy.from"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="SSL"
          v-model="emailConfigCopy.secure"
          density="compact"
          class="ma-1"
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Username"
          v-model="emailConfigCopy.username"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Passwort"
          v-model="emailConfigCopy.password"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MailServer } from "../../models/mailserver.model";

export default defineComponent({
  name: "EmailServerConfigForm",
  props: {
    modelValue: {
      type: Object as () => MailServer,
      required: true,
      default: () => ({
        server: "",
        port: "",
        from: "",
        secure: false,
        username: "",
        password: "",
      }),
    },
  },
  data() {
    return {
      emailConfigCopy: this.modelValue || {
        server: "",
        port: "",
        from: "",
        secure: false,
        username: "",
        password: "",
      },
      vStyle: this.$store.state.vStyle,
    };
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue: {
      handler(model: MailServer) {
        this.updateModel(model);
      },
      deep: true,
    },
    emailConfigCopy: {
      handler(newValue: MailServer) {
        this.$emit("update:modelValue", newValue);
      },
      deep: true,
    },
  },
  methods: {
    updateModel(model: MailServer) {
      this.emailConfigCopy = model || {
        server: "",
        port: "",
        from: "",
        secure: false,
        username: "",
        password: "",
      };
    },
  },
});
</script>
