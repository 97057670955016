import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tab-container"
}
const _hoisted_2 = {
  key: 1,
  class: "tab-container"
}
const _hoisted_3 = {
  key: 2,
  class: "tab-container"
}
const _hoisted_4 = { class: "mt-3 ml-3 icon-container" }
const _hoisted_5 = { class: "pt-5 search-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeaderMenu = _resolveComponent("MainHeaderMenu")!
  const _component_ReportBug = _resolveComponent("ReportBug")!
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_GlobalReminder = _resolveComponent("GlobalReminder")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_SpinnerElementHeader = _resolveComponent("SpinnerElementHeader")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    elevation: "4",
    color: "primary",
    class: "pt-2 pr-4 d-flex justify-space-beetwen align-end rounded-b-lg"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MainHeaderMenu),
      (_ctx.showRecruitTabs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "tab",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.JobMatrix))),
              "data-tid": "recruit-link-jobmatrix"
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("i", { class: "fa-solid fa-table-cells tab-icon" }, null, -1),
              _createElementVNode("p", null, "Jobs Matrix", -1)
            ])),
            _createElementVNode("div", {
              class: "tab",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.JobList))),
              "data-tid": "recruit-link-joblist"
            }, _cache[14] || (_cache[14] = [
              _createElementVNode("i", { class: "fa-solid fa-table-list tab-icon" }, null, -1),
              _createElementVNode("p", null, "Jobs Liste", -1)
            ])),
            _createElementVNode("div", {
              class: "tab",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.Funnels))),
              "data-tid": "recruit-link-funnel"
            }, _cache[15] || (_cache[15] = [
              _createElementVNode("i", { class: "fa-brands fa-facebook tab-icon" }, null, -1),
              _createElementVNode("p", null, "unnels", -1)
            ]))
          ]))
        : _createCommentVNode("", true),
      (_ctx.showDispositionTabs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "tab",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openZvooveRecruitLink()))
            }, _cache[16] || (_cache[16] = [
              _createElementVNode("i", { class: "fa-solid fa-envelope-open-text tab-icon" }, null, -1),
              _createElementVNode("p", null, "Bewerbungen ATS", -1)
            ])),
            _createElementVNode("div", {
              class: "tab",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openZvooveRecruitLink(true)))
            }, _cache[17] || (_cache[17] = [
              _createElementVNode("i", { class: "fa-solid fa-at tab-icon" }, null, -1),
              _createElementVNode("p", null, "E-Mails ATS", -1)
            ])),
            _createElementVNode("div", {
              class: "tab",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.DispatcherBoard)))
            }, _cache[18] || (_cache[18] = [
              _createElementVNode("i", { class: "fa-solid fa-people-group tab-icon" }, null, -1),
              _createElementVNode("p", null, "Dispoboard", -1)
            ])),
            _createElementVNode("div", {
              class: "tab",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.Calendar)))
            }, _cache[19] || (_cache[19] = [
              _createElementVNode("i", { class: "fa-solid fa-calendar-days tab-icon" }, null, -1),
              _createElementVNode("p", null, "Kalender", -1)
            ]))
          ]))
        : _createCommentVNode("", true),
      (_ctx.showAdministrationTabs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "tab",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.Documentation)))
            }, _cache[20] || (_cache[20] = [
              _createElementVNode("i", { class: "fa-solid fa-file-lines tab-icon" }, null, -1),
              _createElementVNode("p", null, "Dokumentation", -1)
            ])),
            (_ctx.softwareIntegration.payFlow)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "tab",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.PayFlowOverview)))
                }, _cache[21] || (_cache[21] = [
                  _createElementVNode("i", { class: "fa-solid fa-coins tab-icon" }, null, -1),
                  _createElementVNode("p", null, "PayFlow Übersicht", -1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.softwareIntegration.workTime)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "tab",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.WorkTime)))
                }, _cache[22] || (_cache[22] = [
                  _createElementVNode("i", { class: "fa-solid fa-clock tab-icon" }, null, -1),
                  _createElementVNode("p", null, "WorkTime", -1)
                ])))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_spacer, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ReportBug),
            _createVNode(_component_UserProfile),
            _createVNode(_component_GlobalReminder)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_SpinnerElementHeader),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.showDispositionTabs)
          ? (_openBlock(), _createBlock(_component_v_text_field, {
              key: 0,
              variant: "solo",
              rounded: _ctx.vStyle.input.rounded || undefined,
              "base-color": _ctx.vStyle.input.baseColor || undefined,
              color: _ctx.vStyle.input.color || undefined,
              modelValue: _ctx.searchTerm,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.searchTerm) = $event)),
              class: "ma-0 pa-0",
              density: "compact",
              label: "Suchbegriff",
              onKeydown: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.askColumnsForMatches()), ["enter"])),
              clearable: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "bottom"
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("Einen oder mehrere Suchbegriffe eingeben und mit Enter bestätigen")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["rounded", "base-color", "color", "modelValue"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_select, {
          variant: "solo",
          rounded: _ctx.vStyle.input.rounded || undefined,
          "base-color": _ctx.vStyle.input.baseColor || undefined,
          color: _ctx.vStyle.input.color || undefined,
          modelValue: _ctx.selectedMandants,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedMandants) = $event)),
          density: "compact",
          items: _ctx.mandants,
          "item-title": "branchInitials",
          "item-value": "uuid",
          label: "Eingeloggte Niederlassungen",
          clearable: "",
          multiple: ""
        }, null, 8, ["rounded", "base-color", "color", "modelValue", "items"])
      ])
    ]),
    _: 1
  }))
}