import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_range_slider = _resolveComponent("v-range-slider")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showModal) = $event)),
    persistent: "",
    "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)',
    class: "fill-height"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createElementVNode("h1", null, [
                _createElementVNode("i", { class: "fa-solid fa-users-viewfinder" }),
                _createTextVNode(" JOB Leads")
              ], -1),
              _createTextVNode(" manuelle Suche in Anzeigendaten")
            ])),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode("Nach Schlagwörtern im Anzeigentext suchen")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_chip_group, {
                        modelValue: _ctx.localDataForQuery.skills,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localDataForQuery.skills) = $event)),
                        multiple: "",
                        column: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localDataForQuery.skills, (skill, index) => {
                            return (_openBlock(), _createBlock(_component_v_chip, {
                              class: "mb-2",
                              key: index,
                              color: "primary",
                              closable: "",
                              "onClick:close": ($event: any) => (_ctx.removeSkill(index))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(skill), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick:close"]))
                          }), 128)),
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            rounded: "pill",
                            class: "ml-3",
                            style: {"max-width":"12rem"},
                            density: "compact",
                            modelValue: _ctx.newSkill,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newSkill) = $event)),
                            onKeyup: _withKeys(_ctx.addSkill, ["enter"])
                          }, null, 8, ["variant", "base-color", "color", "modelValue", "onKeyup"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("Nach Berufen suchen")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_chip_group, {
                        modelValue: _ctx.localDataForQuery.tags,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localDataForQuery.tags) = $event)),
                        multiple: "",
                        column: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localDataForQuery.tags, (tag, index) => {
                            return (_openBlock(), _createBlock(_component_v_chip, {
                              key: index,
                              color: "primary",
                              closable: "",
                              "onClick:close": ($event: any) => (_ctx.removeTag(index))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(tag), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick:close"]))
                          }), 128)),
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            rounded: "pill",
                            class: "ml-3",
                            style: {"max-width":"12rem"},
                            density: "compact",
                            modelValue: _ctx.newTag,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newTag) = $event)),
                            onKeyup: _withKeys(_ctx.addTag, ["enter"])
                          }, null, 8, ["variant", "base-color", "color", "modelValue", "onKeyup"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("Nach Karriere Level suchen")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_container, { class: "d-flex" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            class: "mx-3",
                            icon: "fa-solid fa-person-arrow-down-to-line"
                          }),
                          _createVNode(_component_v_range_slider, {
                            class: "mx-2",
                            max: 12,
                            min: 0,
                            step: 1,
                            modelValue: _ctx.careerLevel,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.careerLevel) = $event))
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_icon, {
                            class: "mx-1",
                            icon: "fa-solid fa-user-tie"
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "8"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode("Umkreissuche")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_slider, {
                        class: "mx-4",
                        max: 100,
                        min: 0,
                        step: 1,
                        modelValue: _ctx.localDataForQuery.radius,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localDataForQuery.radius) = $event)),
                        "thumb-label": ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.localDataForQuery.radius) + " km um", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_text_field, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        label: "Postleitzahl",
                        density: "compact",
                        modelValue: _ctx.localDataForQuery.postcode,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localDataForQuery.postcode) = $event))
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "8"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode("Suche im Zeitraum")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_slider, {
                        class: "mx-4",
                        max: 360,
                        min: 0,
                        step: 1,
                        modelValue: _ctx.localDataForQuery.period,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localDataForQuery.period) = $event)),
                        "thumb-label": ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("Zeitraum")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_text_field, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        label: "Tage",
                        density: "compact",
                        modelValue: _ctx.localDataForQuery.period,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localDataForQuery.period) = $event))
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "abort",
                onClick: _ctx.closeModal
              }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode("Abbrechen")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.replaceJobAds,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.replaceJobAds) = $event)),
                label: "bestehende Job Leads überschreiben?"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_btn, {
                color: "success",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.submitQuery(_ctx.replaceJobAds)))
              }, {
                default: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createTextVNode("Ausführen")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}