const vCollapse = {
  mounted(el: HTMLElement, binding: any) {
    // Set the initial state based on the binding value
    if (binding.value) {
      el.style.height = "auto"; // Fully expanded
      el.style.overflow = ""; // Ensure content is visible
    } else {
      el.style.height = "0"; // Collapsed
      el.style.overflow = "hidden"; // Prevent content visibility
    }
  },
  updated(el: HTMLElement, binding: any) {
    const isExpanded = binding.value;

    if (isExpanded) {
      // Expand the element
      el.style.height = el.scrollHeight + "px"; // Set height to content size
      el.style.overflow = "hidden"; // Prevent overflow during animation

      el.addEventListener(
        "transitionend",
        () => {
          el.style.height = "auto"; // Reset to auto after animation
          el.style.overflow = ""; // Make content fully visible
        },
        { once: true }
      );
    } else {
      // Collapse the element
      el.style.height = el.scrollHeight + "px"; // Start at the current height
      requestAnimationFrame(() => {
        el.style.transition = "height 0.5s ease"; // Apply transition
        el.style.height = "0"; // Collapse to zero height
      });

      // Ensure overflow stays hidden after collapsing
      el.addEventListener(
        "transitionend",
        () => {
          el.style.height = "0"; // Keep height at zero after animation
          el.style.overflow = "hidden"; // Prevent content visibility
        },
        { once: true }
      );
    }
  },
};

export default vCollapse;
