export enum ContactStates {
  aktiv = 1,
  inaktiv = 2,
}

export enum CustomerStates {
  prospect = 1,
  customer = 2,
  formerCustomer = 3,
  completedProspect = 6,
  unknown = 10,
}

export enum CustomerStateLabel {
  prospect = "Interessent",
  customer = "Aktiver Kunde",
  formerCustomer = "Ehemaliger Kunde",
  completedProspect = "Erledigter Interessent",
  unknown = "Unbekannt",
}

const customerStateLabels: { [key in CustomerStates]: string } = {
  [CustomerStates.prospect]: CustomerStateLabel.prospect,
  [CustomerStates.customer]: CustomerStateLabel.customer,
  [CustomerStates.formerCustomer]: CustomerStateLabel.formerCustomer,
  [CustomerStates.completedProspect]: CustomerStateLabel.completedProspect,
  [CustomerStates.unknown]: CustomerStateLabel.unknown,
};

export const getCustomerStates = (enumObj: {
  [key: number]: string | number;
}): { [key: number]: string } => {
  const entries = Object.entries(enumObj);
  const filteredEntries = entries.filter(([key]) => !isNaN(Number(key)));
  return filteredEntries.reduce<{ [key: number]: string }>(
    (obj, [key, value]) => {
      const enumKey = Number(key) as CustomerStates;
      if (typeof value === "string") {
        obj[enumKey] = customerStateLabels[enumKey] || value;
      }
      return obj;
    },
    {}
  );
};

export const getCustomerStateValue = (
  stateLabel: string
): number | undefined => {
  const labelToEnum: { [label: string]: number } = Object.keys(CustomerStates)
    .filter((key) => isNaN(Number(key)))
    .reduce((obj, key) => {
      const enumKey = CustomerStates[key as keyof typeof CustomerStates];
      const label = customerStateLabels[enumKey];
      if (label) {
        obj[label] = enumKey;
      }
      return obj;
    }, {} as { [key: string]: number });

  return labelToEnum[stateLabel];
};
