<template>
  <div>
    <div class="main-container">
      <div
        class="editor-container editor-container_classic-editor editor-container_include-style"
        ref="editorContainerElement"
      >
        <div class="editor-container__editor">
          <div ref="editorElement">
            <ckeditor
              v-if="isLayoutReady"
              v-model="config.initialData"
              :editor="editor"
              :config="config"
              @ready="onReady"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  FullPage,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  HtmlComment,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
} from "ckeditor5";

import { defineComponent } from "vue";
import { buildProfileTemplate } from "@/services/profile-template.service";
import {
  ProfileData,
  ProfileAiData,
  ProfileNonAiHtmlCvData,
} from "@/models/profile.model";
import { ApiResponse } from "@/models/api-response.model";
import { MandantService } from "@/services/api/mandant.service";
import { PdfService } from "@/services/api/pdf.service";
import "ckeditor5/ckeditor5.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import translations from "ckeditor5/translations/de.js"; //FIXME
import { CandidateService } from "@/services/api/candidate.service";
import DialogService from "@/services/dialog.service";
import ToastService from "@/services/toast.service";
import { mapGetters } from "vuex";
import { SpinnerService } from "../../../services/spinner.service";

export default defineComponent({
  name: "ProfileEditor",
  props: {
    generatedProfile: Object as () => ProfileAiData,
    loadedProfile: String,
    profileData: Object as () => ProfileData,
    mandantUuid: String,
    availability: String,
  },
  computed: {
    ...mapGetters(["getEnv"]),
  },
  data() {
    return {
      isLayoutReady: false,
      config: {} as any, // CKEditor needs the DOM tree before calculating the configuration.
      editor: ClassicEditor,
      editorInstance: null as any,
      editorData: "",
      mandantService: new MandantService(),
      pdfService: new PdfService(),
    };
  },
  mounted() {
    this.setEditorContent();
    this.config = {
      toolbar: {
        items: [
          "undo",
          "redo",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "alignment",
          "bold",
          "italic",
          "underline",
          "|",
          "bulletedList",
          "numberedList",
          "todoList",
          "indent",
          "outdent",
          "|",
          "insertImage",
          "link",
          "insertTable",
          "|",
          "sourceEditing",
          "|",
          "blockQuote",
          "|",
          "heading",
          "style",
          "|",
          "highlight",
        ],
        shouldNotGroupWhenFull: true,
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        Autoformat,
        AutoImage,
        AutoLink,
        Autosave,
        BalloonToolbar,
        Base64UploadAdapter,
        BlockQuote,
        Bold,
        Essentials,
        FindAndReplace,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        FullPage,
        GeneralHtmlSupport,
        Heading,
        Highlight,
        HorizontalLine,
        HtmlComment,
        HtmlEmbed,
        ImageBlock,
        ImageCaption,
        ImageInline,
        ImageInsert,
        ImageInsertViaUrl,
        ImageResize,
        ImageStyle,
        ImageTextAlternative,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        ListProperties,
        MediaEmbed,
        PageBreak,
        Paragraph,
        PasteFromOffice,
        RemoveFormat,
        SelectAll,
        SourceEditing,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Strikethrough,
        Style,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TextTransformation,
        TodoList,
        Underline,
        Undo,
      ],
      balloonToolbar: [
        "bold",
        "italic",
        "fontColor",
        "|",
        "link",
        "insertImage",
        "|",
        "bulletedList",
        "numberedList",
      ],
      fontFamily: {
        supportAllValues: true,
      },
      fontSize: {
        options: [10, 12, 14, "default", 18, 20, 22],
        supportAllValues: true,
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: "h3",
            title: "Heading 3",
            class: "ck-heading_heading3",
          },
          {
            model: "heading4",
            view: "h4",
            title: "Heading 4",
            class: "ck-heading_heading4",
          },
          {
            model: "heading5",
            view: "h5",
            title: "Heading 5",
            class: "ck-heading_heading5",
          },
          {
            model: "heading6",
            view: "h6",
            title: "Heading 6",
            class: "ck-heading_heading6",
          },
        ],
      },
      htmlSupport: {
        allow: [
          {
            name: /^.*$/,
            styles: true,
            attributes: true,
            classes: true,
          },
        ],
      },
      image: {
        toolbar: [
          "toggleImageCaption",
          "imageTextAlternative",
          "|",
          "imageStyle:inline",
          "imageStyle:wrapText",
          "imageStyle:breakText",
          "|",
          "resizeImage",
        ],
      },
      initialData: this.editorData,
      language: "de",
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: "https://",
        decorators: {
          toggleDownloadable: {
            mode: "manual",
            label: "Downloadable",
            attributes: {
              download: "file",
            },
          },
        },
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
      menuBar: {
        isVisible: true,
      },
      placeholder: "Type or paste your content here!",
      style: {
        definitions: [
          {
            name: "Article category",
            element: "h3",
            classes: ["category"],
          },
          {
            name: "Title",
            element: "h2",
            classes: ["document-title"],
          },
          {
            name: "Subtitle",
            element: "h3",
            classes: ["document-subtitle"],
          },
          {
            name: "Info box",
            element: "p",
            classes: ["info-box"],
          },
          {
            name: "Side quote",
            element: "blockquote",
            classes: ["side-quote"],
          },
          {
            name: "Marker",
            element: "span",
            classes: ["marker"],
          },
          {
            name: "Spoiler",
            element: "span",
            classes: ["spoiler"],
          },
          {
            name: "Code (dark)",
            element: "pre",
            classes: ["fancy-code", "fancy-code-dark"],
          },
          {
            name: "Code (bright)",
            element: "pre",
            classes: ["fancy-code", "fancy-code-bright"],
          },
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      translations: [translations],
    };

    this.isLayoutReady = true;
  },
  watch: {
    profileData: {
      handler() {
        this.updateEditorContent();
      },
      deep: true,
    },
    generatedProfile: {
      handler() {
        this.updateEditorContent();
      },
      deep: true,
    },
    mandantUuid(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateEditorContent();
      }
    },
  },
  methods: {
    onReady(editor: any) {
      this.editorInstance = editor;
      this.addCustomButtonToToolbar();
      editor.model.document.on("change:data", () => {
        this.editorData = editor.getData();
      });
    },
    addCustomButtonToToolbar() {
      const assetsUrl = this.$store.getters.getEnv.VUE_APP_ASSETS_URL;
      const button = document.createElement("button");
      button.innerHTML = `<img src="${assetsUrl}/icons/pdf-icon.svg" style="cursor: pointer; width: 1.5rem; height: auto;" alt="PDF anzeigen" title="PDF anzeigen" />`;
      button.className = "pdf-button";
      button.addEventListener("click", () => {
        this.customButtonAction();
      });

      const toolbarItems = document.querySelector(".ck .ck-toolbar__items");
      if (toolbarItems) {
        if (toolbarItems.firstChild) {
          toolbarItems.insertBefore(button, toolbarItems.firstChild);
        } else {
          toolbarItems.appendChild(button);
        }
      } else {
        console.error("Toolbar items container not found");
      }
    },
    async customButtonAction() {
      /*this.pdfService
        .getPdf(this.editorData)
        .then((apiResponse: ApiResponse) => {
          this.openPdfInTab(apiResponse.response.pdfData);
        });*/
      SpinnerService.showSpinner();
      try {
        await this.pdfService
          .getPdf2(this.editorData)
          .then((apiResponse: ApiResponse) => {
            this.openPdfInTab(apiResponse.response.pdfData);
          });
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    openPdfInTab(pdfData: string) {
      const blob = this.base64ToBlob(pdfData, "application/pdf");
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    },
    base64ToBlob(base64: string, mimeType: string) {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    },
    updateEditorContent(profileNonAiHtmlCvData?: ProfileNonAiHtmlCvData) {
      if (this.editorInstance) {
        this.setEditorContent(profileNonAiHtmlCvData);
        // Ensure that the editor instance exists before calling setData
        this.editorInstance.setData(this.editorData);
      }
    },
    setEditorContent(profileNonAiHtmlCvData?: ProfileNonAiHtmlCvData) {
      if (
        this.generatedProfile &&
        Object.keys(this.generatedProfile).length !== 0 &&
        !profileNonAiHtmlCvData
      ) {
        this.editorData = buildProfileTemplate(
          this.generatedProfile,
          this.profileData,
          this.mandantUuid,
          this.availability
        );
      } else if (this.loadedProfile) {
        this.editorData = this.loadedProfile;
      } else if (profileNonAiHtmlCvData) {
        const generatedProfile = JSON.parse(
          JSON.stringify(this.generatedProfile)
        );
        generatedProfile.curiculumVitae = profileNonAiHtmlCvData.curiculumVitae;
        generatedProfile.education = profileNonAiHtmlCvData.education;
        this.editorData = buildProfileTemplate(
          generatedProfile,
          this.profileData,
          this.mandantUuid,
          this.availability,
          true
        );
      } else {
        this.editorData = buildProfileTemplate();
      }
    },
    exportHtml(candidateId?: string) {
      if (
        this.mandantUuid &&
        this.editorData &&
        this.profileData?.profileNumber
      ) {
        return this.mandantService.addPdf(
          this.mandantUuid,
          candidateId ?? this.profileData.candidateId,
          this.profileData?.profileNumber,
          this.editorData
        );
      }
    },
    async saveHtmlInCandidate(
      candidateId: string,
      filename: string
    ): Promise<boolean> {
      const candidateService = new CandidateService();
      const candidate = await candidateService.getCandidateById(candidateId);
      if (!candidate) return false;
      const existingProfile = candidate.profiles.find(
        (profile) => profile.description === filename
      );

      if (existingProfile) {
        const confirmed = await DialogService.confirm(
          "Das Profil so abspeichern / überschreiben?",
          "Abbrechen",
          "Ja"
        );
        if (!confirmed) {
          return true;
        }
        existingProfile.html = this.editorData;
      } else {
        const newProfile = {
          description: filename,
          uuid: "",
          html: this.editorData,
        };
        candidate.profiles.push(newProfile);
      }
      try {
        candidateService
          .updateCandidateByUuid(candidate.uuid, candidate)
          .then(() => {
            ToastService.showSuccess("Profil abgespeichert");
          });
        return true;
      } catch (error) {
        ToastService.showError("Fehler beim speichern des Profils");
        return false;
      }
    },
  },
});
</script>

<style scoped>
.pdf-button {
  background-color: var(--color-primary) !important;
  color: var(--color-on-primary) !important;
  padding: 0.5rem !important;
}

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  font-family: var(--font-profile-editor);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.ck-content {
  font-family: var(--font-profile-editor);
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  min-width: 60rem;
  max-width: 60rem;
}

.ck-content h3.category {
  font-family: var(--font-profile-editor);
  font-size: 20px;
  font-weight: bold;
  color: #555;
  letter-spacing: 10px;
  margin: 0;
  padding: 0;
}

.ck-content h2.document-title {
  font-family: var(--font-profile-editor);
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  border: 0;
}

.ck-content h3.document-subtitle {
  font-family: var(--font-profile-editor);
  font-size: 20px;
  color: #555;
  margin: 0 0 1em;
  font-weight: bold;
  padding: 0;
}

.ck-content p.info-box {
  --background-size: 30px;
  --background-color: #e91e63;
  padding: 1.2em 2em;
  border: 1px solid var(--background-color);
  background: linear-gradient(
      135deg,
      var(--background-color) 0%,
      var(--background-color) var(--background-size),
      transparent var(--background-size)
    ),
    linear-gradient(
      135deg,
      transparent calc(100% - var(--background-size)),
      var(--background-color) calc(100% - var(--background-size)),
      var(--background-color)
    );
  border-radius: 10px;
  margin: 1.5em 2em;
  box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
  font-family: var(--font-profile-editor);
  font-style: normal;
  float: right;
  width: 35%;
  position: relative;
  border: 0;
  overflow: visible;
  z-index: 1;
  margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
  content: "“";
  position: absolute;
  top: -37px;
  left: -10px;
  display: block;
  font-size: 200px;
  color: #e7e7e7;
  z-index: -1;
  line-height: 1;
}

.ck-content blockquote.side-quote p {
  font-size: 2em;
  line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
  font-size: 1.3em;
  text-align: right;
  color: #555;
}

.ck-content span.marker {
  background: yellow;
}

.ck-content span.spoiler {
  background: #000;
  color: #000;
}

.ck-content span.spoiler:hover {
  background: #000;
  color: #fff;
}

.ck-content pre.fancy-code {
  border: 0;
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 10px;
}

.ck-content pre.fancy-code::before {
  content: "";
  display: block;
  height: 13px;
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
  margin-bottom: 8px;
  background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
  background: #272822;
  color: #fff;
  box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
  background: #dddfe0;
  color: #000;
  box-shadow: 5px 5px 0 #b3b3b3;
}
</style>
