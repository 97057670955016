<template>
  <v-container>
    <div class="text-center">
      <img :src="appMaintenanceLogo" />
    </div>
    <div style="text-align: center">
      <h1>
        <span style="color: #1c365e">Wartungs</span>
        <span style="color: #008b8b"> Modus</span>
      </h1>
      <p>Versuchen Sie es später noch einmal</p>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MaintenancePage",
  data() {
    return {
      appMaintenanceLogo: "https://assets.dispositioner.de/maintenance.svg",
    };
  },
});
</script>
