import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "pa-0 text-body-2" }
const _hoisted_2 = { class: "font-weight-light text-caption text-medium-emphasis" }
const _hoisted_3 = { class: "mx-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex ma-0"
}
const _hoisted_5 = { class: "item-collapsed" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 3,
  class: "hide-on-inactive-column"
}
const _hoisted_8 = { class: "customer-mandant" }
const _hoisted_9 = { class: "customer-status" }
const _hoisted_10 = { class: "status-communication-container" }
const _hoisted_11 = {
  key: 0,
  class: "communication-icons-container"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["src"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["src"]
const _hoisted_16 = { key: 2 }
const _hoisted_17 = ["src"]
const _hoisted_18 = { key: 3 }
const _hoisted_19 = ["src"]
const _hoisted_20 = { key: 4 }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "communication-icons-container" }
const _hoisted_23 = {
  key: 4,
  class: "tags-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_TopRightButton = _resolveComponent("TopRightButton")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_ErpLinkMenu = _resolveComponent("ErpLinkMenu")!
  const _component_PhoneClient = _resolveComponent("PhoneClient")!
  const _component_MailClient = _resolveComponent("MailClient")!
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_Dependencies = _resolveComponent("Dependencies")!
  const _component_Assignments = _resolveComponent("Assignments")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_DialogUpdateCustomer = _resolveComponent("DialogUpdateCustomer")!
  const _component_ProfileGenerator = _resolveComponent("ProfileGenerator")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_JobAdListCustomer = _resolveComponent("JobAdListCustomer")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "ma-0 pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_hover, null, {
        default: _withCtx(({ isHovering, props }) => [
          (_ctx.isFullyCollapsed)
            ? (_openBlock(), _createBlock(_component_v_container, {
                key: 0,
                onDrop: _ctx.handleDrop,
                onDragstart: _ctx.handleDragStart,
                onDragover: _ctx.handleDragover,
                class: "ma-0 pa-0 item",
                draggable: "true"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_sheet, {
                    class: "ma-0 pt-1 px-1",
                    color: _ctx.item.colorList
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "d-flex justify-space-between pa-0" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_1, [
                            (_ctx.candidatesTagsMatching)
                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 0,
                                  class: "mx-1 match-icon"
                                }, {
                                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                                    _createTextVNode("fa-solid fa-heart")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createTextVNode(_toDisplayString(_ctx.customer?.generalData?.name) + " ", 1),
                            _createElementVNode("span", _hoisted_2, "(" + _toDisplayString(_ctx.customer?.customerNumber) + ") ", 1)
                          ]),
                          _createElementVNode("span", _hoisted_3, [
                            (_ctx.customer?.jobAds && _ctx.customer.jobAds.length > 0)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
                                  onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scheduleJobAdListClose && _ctx.scheduleJobAdListClose(...args)))
                                }, _cache[23] || (_cache[23] = [
                                  _createElementVNode("i", { class: "fa-solid fa-rectangle-ad" }, null, -1)
                                ]), 32))
                              : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(_ctx.customer?.addressAndCommunication?.city), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_divider, { class: "mt-1" })
                    ]),
                    _: 1
                  }, 8, ["color"])
                ]),
                _: 1
              }, 8, ["onDrop", "onDragstart", "onDragover"]))
            : _createCommentVNode("", true),
          (!_ctx.isFullyCollapsed)
            ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({
                key: 1,
                variant: _ctx.vStyle.boardItem.variant || undefined,
                rounded: _ctx.vStyle.boardItem.rounded || undefined,
                border: _ctx.item.border,
                elevation: _ctx.vStyle.boardItem.elevation || undefined
              }, props, {
                color: isHovering ? _ctx.item.hoverColor : _ctx.item.color,
                class: {
            expanded: _ctx.isExpanded,
            'fully-expanded': _ctx.isFullyExpanded,
            'item pl-5 pr-2': _ctx.isOpenedAsDialog,
            'item pa-2 mb-2': !_ctx.isOpenedAsDialog,
          },
                "max-height": _ctx.getItemHeight(),
                onDblclick: _ctx.handleDoubleClickOnItem,
                onDrop: _ctx.handleDrop,
                onDragstart: _ctx.handleDragStart,
                onDragover: _ctx.handleDragover,
                draggable: "true"
              }), {
                default: _withCtx(() => [
                  (_ctx.isOpenedAsDialog)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _cache[25] || (_cache[25] = _createElementVNode("p", { class: "mt-2 mb-5 text-h6 text-medium-emphasis" }, " Unternehmenskarte ", -1)),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeDialog'))),
                          icon: "",
                          size: "s",
                          variant: "text",
                          density: "compact"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[24] || (_cache[24] = [
                                _createTextVNode(" fa-solid fa-xmark close-icon ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_v_card_title, { class: "pa-0 text-body-2" }, {
                      default: _withCtx(() => [
                        (_ctx.candidatesTagsMatching)
                          ? (_openBlock(), _createBlock(_component_v_icon, {
                              key: 0,
                              class: "mx-1 match-icon"
                            }, {
                              default: _withCtx(() => _cache[26] || (_cache[26] = [
                                _createTextVNode("fa-solid fa-heart")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (
                  _ctx.customer?.additionalInfo &&
                  _ctx.customer?.additionalInfo.logoLink &&
                  !_ctx.candidatesTagsMatching
                )
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              alt: "Logo",
                              src: _ctx.customer?.additionalInfo.logoLink,
                              class: "logo-img mr-1"
                            }, null, 8, _hoisted_6))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(_ctx.customer?.generalData?.name), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle, { class: "pl-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.customer?.addressAndCommunication?.postalCode) + " " + _toDisplayString(_ctx.customer?.addressAndCommunication?.city) + " (" + _toDisplayString(_ctx.customer?.customerNumber) + ") ", 1)
                      ]),
                      _: 1
                    })
                  ]),
                  (!_ctx.isOpenedAsDialog)
                    ? (_openBlock(), _createBlock(_component_TopRightButton, {
                        key: 1,
                        isExpanded: _ctx.isExpanded,
                        isFullyExpanded: _ctx.isFullyExpanded,
                        parentRole: _ctx.Role.customer,
                        onReceiveCandidate: _ctx.handleReceiveCandidate,
                        onToggleExpansion: _ctx.toggleExpansion,
                        onToggleFullExpansion: _ctx.toggleFullExpansion,
                        onOpenContextMenu: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('openContextMenu', $event)))
                      }, null, 8, ["isExpanded", "isFullyExpanded", "parentRole", "onReceiveCandidate", "onToggleExpansion", "onToggleFullExpansion"]))
                    : _createCommentVNode("", true),
                  (_ctx.showJobAdsIcon())
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: "job-ad-hover",
                        onMouseenter: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
                        onMouseleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.scheduleJobAdListClose && _ctx.scheduleJobAdListClose(...args)))
                      }, _cache[27] || (_cache[27] = [
                        _createElementVNode("i", { class: "fa-solid fa-rectangle-ad" }, null, -1)
                      ]), 32))
                    : _createCommentVNode("", true),
                  (_ctx.isActive)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createTextVNode(_toDisplayString(_ctx.mandantName()) + " ", 1),
                          _createVNode(_component_v_menu, { activator: "parent" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (item, index) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      onClick: ($event: any) => (_ctx.saveMandantSelection(item.uuid)),
                                      key: index,
                                      value: index
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createTextVNode(_toDisplayString(_ctx.customer?.generalData?.status) + " ", 1),
                          _createVNode(_component_v_menu, { activator: "parent" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerStates, (item, index) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: index,
                                      value: index,
                                      onClick: ($event: any) => (_ctx.saveStatusSelection(index.toString()))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_divider),
                  _createElementVNode("div", _hoisted_10, [
                    (_ctx.isExpanded)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          (
                  (_ctx.softwareIntegration as SoftwareIntegration).indexAnzeigendaten &&
                  (!_ctx.customer?.additionalInfo?.indexLink ||
                    _ctx.customer?.additionalInfo?.indexLink === '')
                )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _createElementVNode("img", {
                                  src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-index-disconnect.png'
                  ,
                                  alt: "Index Anzeigendaten disconnected",
                                  class: "index-erp-ats-icon",
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openUpdateCustomerModal(_ctx.ModalMode.indexAnzeigendaten)))
                                }, null, 8, _hoisted_13),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                                    _createTextVNode("mit Anzeigendaten verknüpfen")
                                  ])),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (
                  (_ctx.softwareIntegration as SoftwareIntegration).zvooveOne &&
                  (!_ctx.isNumeric(_ctx.customer?.customerNumber) ||
                    _ctx.customer?.generalData?.costCenter == null)
                )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createElementVNode("img", {
                                  src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-zvoove-one-disconnect.png'
                  ,
                                  alt: "ERP One disconnected",
                                  class: "index-erp-ats-icon",
                                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openUpdateCustomerModal(_ctx.ModalMode.zvooveOne)))
                                }, null, 8, _hoisted_15),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                                    _createTextVNode("mit bestehendem Kunden in ERP One verknüpfen")
                                  ])),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.isNotZvooveCustomerNumber())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createElementVNode("img", {
                                  src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-zvoove-one-upload.svg'
                  ,
                                  alt: "ERP One upload",
                                  class: "index-erp-ats-icon",
                                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('createInErp', _ctx.customer)))
                                }, null, 8, _hoisted_17),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                                    _createTextVNode("in ERP One anlegen")
                                  ])),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (
                  (_ctx.softwareIntegration as SoftwareIntegration).pdHub &&
                  (!_ctx.isNumeric(_ctx.customer?.customerNumber) ||
                    _ctx.customer?.generalData?.costCenter == null)
                )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createElementVNode("img", {
                                  src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-pd-hub-disconnect.svg'
                  ,
                                  alt: "PD-Hub disconnected",
                                  class: "index-erp-ats-icon",
                                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openUpdateCustomerModal(_ctx.ModalMode.pdHub)))
                                }, null, 8, _hoisted_19),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                                    _createTextVNode("mit bestehendem Kunden in PD-Hub verknüpfen")
                                  ])),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.isNotPdHubCustomerNumber())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createElementVNode("img", {
                                  src: 
                    _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                    '/icons/icon-pd-hub-upload.svg'
                  ,
                                  alt: "pd hub upload",
                                  class: "index-erp-ats-icon",
                                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('createInErp', _ctx.customer)))
                                }, null, 8, _hoisted_21),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                                    _createTextVNode("in PD-Hub anlegen")
                                  ])),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _createElementVNode("i", {
                              class: "fa-regular fa-address-book get-contacts-icon",
                              onClick: _cache[11] || (_cache[11] = ($event: any) => (
                    _ctx.openUpdateCustomerModal(_ctx.ModalMode.scrapeContactsFromSite)
                  ))
                            }, [
                              _createVNode(_component_v_tooltip, {
                                activator: "parent",
                                location: "bottom"
                              }, {
                                default: _withCtx(() => _cache[33] || (_cache[33] = [
                                  _createTextVNode("Ansprechpartner von Webseite ergänzen")
                                ])),
                                _: 1
                              })
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_22, [
                      (_ctx.isExpanded && _ctx.customer?.generalData?.costCenter != null)
                        ? (_openBlock(), _createBlock(_component_ErpLinkMenu, {
                            key: 0,
                            zvooveOneCustomerNumber: _ctx.customer?.customerNumber,
                            onGetErpData: _cache[12] || (_cache[12] = ($event: any) => (_ctx.updateCustomerFromErp())),
                            onPutErpData: _cache[13] || (_cache[13] = ($event: any) => (_ctx.updateErpFromCustomer()))
                          }, null, 8, ["zvooveOneCustomerNumber"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_btn, {
                        icon: "",
                        variant: "text",
                        density: "compact",
                        class: "mr-3 text-medium-emphasis",
                        onClick: _ctx.toggleProfileDesired
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            color: _ctx.getProfileDesiredStatus().color
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getProfileDesiredStatus().icon), 1)
                            ]),
                            _: 1
                          }, 8, ["color"]),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getProfileDesiredStatus().tooltip), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      (_ctx.isExpanded)
                        ? (_openBlock(), _createBlock(_component_PhoneClient, {
                            key: 1,
                            ref: "phoneClientComponent",
                            customer: _ctx.customer,
                            phoneNumbers: _ctx.filteredPhoneNumbers,
                            onAddFollowUpEvent: _ctx.addFollowUpOnDependencies,
                            onCollapseParentItem: _ctx.collapseItem,
                            onIsDialingNumber: _ctx.phoneClientIsDialing,
                            onSetAppointmentEvent: _ctx.setAppointmentOnDependencies
                          }, null, 8, ["customer", "phoneNumbers", "onAddFollowUpEvent", "onCollapseParentItem", "onIsDialingNumber", "onSetAppointmentEvent"]))
                        : _createCommentVNode("", true),
                      (_ctx.isExpanded || _ctx.isMailClientActive)
                        ? (_openBlock(), _createBlock(_component_MailClient, {
                            key: 2,
                            ref: "mailClientComponent",
                            AiMessageType: _ctx.AiMessageType.mailCustomer,
                            customer: _ctx.customer,
                            emailAddresses: _ctx.filteredEmail,
                            emailObject: _ctx.email,
                            signature: _ctx.signatureText,
                            onCollapseParentItem: _ctx.collapseItem
                          }, null, 8, ["AiMessageType", "customer", "emailAddresses", "emailObject", "signature", "onCollapseParentItem"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_Checklist, {
                    checklist: _ctx.customer?.checklist || [],
                    customer: _ctx.customer,
                    onUpdateChecklist: _ctx.updateChecklist
                  }, null, 8, ["checklist", "customer", "onUpdateChecklist"]),
                  _createVNode(_component_v_divider),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer?.tags, (tag, index) => {
                          return (_openBlock(), _createBlock(_component_v_chip, {
                            key: index,
                            class: "dispatcher-board-icon-tag",
                            color: "primary",
                            "onClick:close": ($event: any) => (_ctx.removeTag(index))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(tag) + " ", 1),
                              _createVNode(_component_v_icon, {
                                small: "",
                                onClick: _withModifiers(($event: any) => (_ctx.removeTag(index)), ["stop"]),
                                class: "fa fa-times"
                              }, null, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["onClick:close"]))
                        }), 128)),
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          class: "mt-2",
                          label: "Neuer Tag",
                          style: {"max-width":"10rem"},
                          rounded: "lg",
                          density: "compact",
                          modelValue: _ctx.newTag,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newTag) = $event)),
                          onKeyup: _withKeys(_ctx.addTag, ["enter"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[34] || (_cache[34] = [
                                _createTextVNode("mit \"Enter\" neuen Tag hinzufügen")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onKeyup"]),
                        _createVNode(_component_v_btn, {
                          icon: "",
                          class: "mt-3",
                          variant: "text",
                          density: "compact",
                          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.generateAndSetCustomerTags()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { size: "small" }, {
                              default: _withCtx(() => _cache[35] || (_cache[35] = [
                                _createTextVNode("fas fa-arrows-rotate")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[36] || (_cache[36] = [
                                _createTextVNode("Tags anhand historischer Einsätze und ausgeschriebener Stellen neu generieren")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_divider),
                  (
              _ctx.customer && (_ctx.hasLinking(_ctx.customer?._id) || _ctx.isDependenciesActive)
            )
                    ? (_openBlock(), _createBlock(_component_Dependencies, {
                        key: 5,
                        mandant: [_ctx.customer?.mandants[0] || ''],
                        customer_id: _ctx.customer?._id,
                        linkingDescription: _ctx.linkingDescription,
                        interComponentMessage: _ctx.interComponentMessage,
                        ref: "dependenciesComponent",
                        onGenerateProfile: _cache[16] || (_cache[16] = ($event: any) => (_ctx.generateProfileFromDependencies())),
                        onOpenPhoneClient: _cache[17] || (_cache[17] = ($event: any) => (_ctx.openPhoneClient()))
                      }, null, 8, ["mandant", "customer_id", "linkingDescription", "interComponentMessage"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_divider),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createBlock(_component_Assignments, {
                        key: 6,
                        isCustomer: true,
                        assignments: _ctx.customer?.assignments,
                        onUpdateAssignments: _cache[18] || (_cache[18] = ($event: any) => (
              _ctx.getAssignmentsFromErp(_ctx.customer?.customerNumber ?? '')
            ))
                      }, null, 8, ["assignments"]))
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createBlock(_component_Timeline, {
                        key: 7,
                        contact: "Test Ansprechpartner",
                        customer: _ctx.customer
                      }, null, 8, ["customer"]))
                    : _createCommentVNode("", true),
                  (_ctx.showModal || _ctx.resultsModal)
                    ? (_openBlock(), _createBlock(_component_DialogUpdateCustomer, {
                        key: 8,
                        showModal: _ctx.showModal,
                        showSearchResultsFromErp: _ctx.showSearchResultsFromErp,
                        "onUpdate:showModal": _cache[19] || (_cache[19] = ($event: any) => (_ctx.showModal = $event)),
                        results: _ctx.results,
                        resultsModal: _ctx.resultsModal,
                        "onUpdate:resultsModal": _cache[20] || (_cache[20] = ($event: any) => (_ctx.resultsModal = $event)),
                        modalTitle: _ctx.modalTitle,
                        modalType: _ctx.modalType,
                        addCustomerModalInput1: _ctx.addCustomerModalInput1,
                        addCustomerModalInput2: _ctx.addCustomerModalInput2,
                        addCustomerModalLabel1: _ctx.addCustomerModalLabel1,
                        addCustomerModalLabel2: _ctx.addCustomerModalLabel2,
                        modalActionButtonText: _ctx.modalActionButtonText,
                        onSubmit: _ctx.handleModalSubmit,
                        onSelectedResult: _ctx.handleSelectedResult
                      }, null, 8, ["showModal", "showSearchResultsFromErp", "results", "resultsModal", "modalTitle", "modalType", "addCustomerModalInput1", "addCustomerModalInput2", "addCustomerModalLabel1", "addCustomerModalLabel2", "modalActionButtonText", "onSubmit", "onSelectedResult"]))
                    : _createCommentVNode("", true),
                  (_ctx.isProfileGeneratorActive)
                    ? (_openBlock(), _createBlock(_component_ProfileGenerator, {
                        key: 9,
                        ref: "profileGeneratorComponent",
                        profileSourceData: _ctx.profileSourceData,
                        onOpenMailClient: _ctx.openMailClientFromProfileGenerator
                      }, null, 8, ["profileSourceData", "onOpenMailClient"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1040, ["variant", "rounded", "border", "elevation", "color", "class", "max-height", "onDblclick", "onDrop", "onDragstart", "onDragover"]))
            : _createCommentVNode("", true),
          (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
            (!_ctx.isExpanded && _ctx.customer?.jobAds)
              ? (_openBlock(), _createBlock(_component_JobAdListCustomer, {
                  key: 0,
                  ref: "jobAdListRef",
                  onFetchIndexAnzeigendaten: _cache[21] || (_cache[21] = ($event: any) => (_ctx.autoFetchJobAds(true))),
                  jobAds: _ctx.customer?.jobAds || [],
                  mandant: _ctx.customer?.mandants[0] || '',
                  positionX: _ctx.jobAdListPosition.x,
                  positionY: _ctx.jobAdListPosition.y
                }, null, 8, ["jobAds", "mandant", "positionX", "positionY"]))
              : _createCommentVNode("", true)
          ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}