import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "job-ad" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["href"]
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_range_slider = _resolveComponent("v-range-slider")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "slide-right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            class: "job-ad-list",
            style: _normalizeStyle({ top: 0, right: 0 + 'px', width: _ctx.listWidth + 'px' })
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: "fa-solid fa-circle-xmark close-icon",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleVisibility && _ctx.toggleVisibility(...args)))
              }),
              _createElementVNode("div", {
                class: "resize-handle",
                onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startResize && _ctx.startResize(...args)))
              }, "||", 32),
              _createVNode(_component_v_row, {
                justify: "space-between",
                class: "pa-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "d-flex" }, {
                    default: _withCtx(() => [
                      _cache[8] || (_cache[8] = _createElementVNode("h1", null, [
                        _createElementVNode("i", { class: "fa-solid fa-users-viewfinder" }),
                        _createTextVNode(" JOB Leads")
                      ], -1)),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_spacer)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    class: "d-flex",
                    cols: "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        size: "small",
                        icon: "",
                        variant: "text",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('generateAiSearchterm')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode("AI Suche starten")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode("fa-solid fa-rocket job-ad-icon")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("Job Leads mit AI suchen")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        size: "small",
                        icon: "",
                        variant: "text",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.initComponent()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode("Neu laden und angelegte Kunden neu prüfen!")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode("fa-solid fa-arrows-rotate job-ad-icon")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        size: "small",
                        icon: "",
                        variant: "text",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('manualAnzeigendatenSearch')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode("manuelle Suche")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[15] || (_cache[15] = [
                              _createTextVNode("fa-solid fa-magnifying-glass job-ad-icon")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_spacer)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        icon: "",
                        variant: "text",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filterCustomers(_ctx.customerNumbersForFilter)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                              _createTextVNode("nur diese Firmen anzeigen")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[17] || (_cache[17] = [
                              _createTextVNode("fa-solid fa-eye filter-customers-icon")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, {
                justify: "space-between",
                class: "mt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "d-flex" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        class: "mx-3",
                        icon: "fa-solid fa-person-arrow-down-to-line"
                      }),
                      _createVNode(_component_v_range_slider, {
                        class: "mx-2",
                        max: 12,
                        min: 0,
                        step: 1,
                        modelValue: _ctx.careerLevel,
                        "onUpdate:modelValue": [
                          _cache[6] || (_cache[6] = ($event: any) => ((_ctx.careerLevel) = $event)),
                          _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateCareerLevelIndexNumbers()))
                        ]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_icon, {
                        class: "mx-1",
                        icon: "fa-solid fa-user-tie"
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_list, { dense: "" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueAndLatestJobAds, (jobAd) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: jobAd.adId
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, [
                                _createElementVNode("a", {
                                  class: "job-ad-title",
                                  href: jobAd.link,
                                  target: "_blank"
                                }, _toDisplayString(jobAd.jobTitle), 9, _hoisted_3),
                                _createVNode(_component_v_spacer)
                              ]),
                              _createElementVNode("div", _hoisted_4, [
                                _createTextVNode(_toDisplayString(jobAd.company?.name) + " ( ", 1),
                                (_ctx.customerMatches.has(jobAd.adId))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      _createTextVNode(_toDisplayString(_ctx.customerMatches.get(jobAd.adId).number) + " ", 1),
                                      (_ctx.customerMatches.get(jobAd.adId).active)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                                            _createElementVNode("i", {
                                              class: "fa-solid fa-eye add-customer-icon",
                                              onClick: ($event: any) => (
                        _ctx.filterCustomers([
                          _ctx.customerMatches.get(jobAd.adId).number,
                        ])
                      )
                                            }, [
                                              _createVNode(_component_v_tooltip, {
                                                activator: "parent",
                                                location: "bottom"
                                              }, {
                                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                  _createTextVNode("in der Spalte \"Unternehmen\" anzeigen")
                                                ])),
                                                _: 1
                                              })
                                            ], 8, _hoisted_7)
                                          ]))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                            _createElementVNode("i", {
                                              class: "fa-solid fa-cloud-arrow-down add-customer-icon",
                                              onClick: ($event: any) => (
                        _ctx.addCustomerFromZvoove(
                          _ctx.customerMatches.get(jobAd.adId).number
                        )
                      )
                                            }, [
                                              _createVNode(_component_v_tooltip, {
                                                activator: "parent",
                                                location: "bottom"
                                              }, {
                                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                  _createTextVNode("Unternehmen aus ERP übernehmen!")
                                                ])),
                                                _: 1
                                              })
                                            ], 8, _hoisted_9)
                                          ]))
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                      _createElementVNode("div", {
                                        class: "add-customer-icon",
                                        onClick: ($event: any) => (
                      _ctx.addCustomerFromIndex(
                        jobAd.company?.name || '',
                        jobAd.company?.postalCode || ''
                      )
                    )
                                      }, [
                                        _cache[21] || (_cache[21] = _createElementVNode("i", { class: "fa-solid fa-plus" }, null, -1)),
                                        _cache[22] || (_cache[22] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                        _createVNode(_component_v_tooltip, {
                                          activator: "parent",
                                          location: "bottom"
                                        }, {
                                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                                            _createTextVNode("Unternehmen im System anlegen")
                                          ])),
                                          _: 1
                                        })
                                      ], 8, _hoisted_11)
                                    ])),
                                _cache[23] || (_cache[23] = _createTextVNode(" ) "))
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_list_item_subtitle, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(jobAd.date) + ": " + _toDisplayString(jobAd.location) + " " + _toDisplayString(jobAd.source) + " ", 1),
                              _createElementVNode("span", {
                                innerHTML: jobAd.listPrice
                              }, null, 8, _hoisted_12),
                              (_ctx.company.softwareIntegration.indexAnzeigendaten)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: jobAd.internLinkPdf,
                                    target: "_blank"
                                  }, [
                                    _cache[25] || (_cache[25] = _createElementVNode("i", { class: "fa-solid fa-file-pdf job-ad-icon" }, null, -1)),
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                        _createTextVNode("PDF Datei aus Anzeigendaten öffnen")
                                      ])),
                                      _: 1
                                    })
                                  ], 8, _hoisted_13))
                                : _createCommentVNode("", true),
                              (_ctx.company.softwareIntegration.indexAnzeigendaten)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    href: jobAd.internLinkTxt,
                                    target: "_blank"
                                  }, [
                                    _cache[27] || (_cache[27] = _createElementVNode("i", { class: "fa-solid fa-file-lines job-ad-icon" }, null, -1)),
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                                        _createTextVNode("Textdatei aus Anzeigendaten öffnen")
                                      ])),
                                      _: 1
                                    })
                                  ], 8, _hoisted_14))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_icon, { small: "" }, {
                                default: _withCtx(() => _cache[28] || (_cache[28] = [
                                  _createTextVNode("mdi-plus-box")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_icon, { small: "" }, {
                                default: _withCtx(() => _cache[29] || (_cache[29] = [
                                  _createTextVNode("mdi-advertisements")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["style"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}