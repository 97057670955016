// Proactive Engagement and Results Factor PERF
export enum PerfScore {
  // For LinkingStatus
  checklistCreated = 5,
  checklistDone = 5,
  contractDate = 15,
  demand = 10,
  demandCreated = 10,
  demandLinkedToCustomer = 10,
  followUp = 1,
  followUpPrio = 2,
  hired = 25,
  inaktive = 5,
  inboxApplication = 1,
  interview = 10,
  interviewCanceled = 5,
  interviewCompleted = 15,
  interviewExternal = 10,
  interviewNotShownUp = 10,
  interviewSuggestionExternal = 5,
  jobAdExtractedFromCustomer = 5,
  jobAdExtractedFromDemand = 10,
  jobAdPublishedFromJobsList = 1,
  jobAdPublishedFromJobsMatrix = 1,
  jobofferAcceptedExternal = 25,
  jobofferExternal = 15,
  landingPageCreated = 25,
  linkingRemoved = 0,
  linkingSwitchedBack = 0,
  noneProfile = 10,
  openProfile = 1,
  personnelPlacement = 15,
  pool = 10,
  rejected = 5,
  rejectedCandidate = 10,
  rejectedUnattractive = 5,
  tempPlacement = 15,
  trailWorkExternal = 15,
  trailWorkSuggestionExternal = 10,
  // For TimelineEntryType
  addition = 5,
  candidateSuggestion = 15,
  emailCandidate = 2,
  eMailCustomer = 2,
  eMailEmployee = 1,
  meetingInhouse = 10,
  meetingOutside = 50,
  note = 5,
  phoneCallCandidate = 5,
  phoneCallCustomer = 5,
  phoneCallEmployee = 1,
  whatsAppCandidate = 2,
  whatsAppCustomer = 1,
  whatsAppEmployee = 1,
}
