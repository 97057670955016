import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "mr-1" }
const _hoisted_6 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "mr-1" }
const _hoisted_9 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_10 = { class: "d-flex mt-2" }
const _hoisted_11 = { class: "mr-1" }
const _hoisted_12 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_13 = { class: "d-flex" }
const _hoisted_14 = { class: "mr-1" }
const _hoisted_15 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_16 = { class: "mr-1" }
const _hoisted_17 = { class: "d-flex" }
const _hoisted_18 = { class: "mr-1" }
const _hoisted_19 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_20 = { class: "mr-1" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "d-flex" }
const _hoisted_23 = { class: "mr-1" }
const _hoisted_24 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_25 = { class: "mr-1" }
const _hoisted_26 = { class: "d-flex" }
const _hoisted_27 = { class: "mr-1" }
const _hoisted_28 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_29 = { class: "mr-1" }
const _hoisted_30 = { class: "d-flex" }
const _hoisted_31 = { class: "mr-1" }
const _hoisted_32 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_33 = { class: "mr-1" }
const _hoisted_34 = { class: "d-flex" }
const _hoisted_35 = { class: "mr-1" }
const _hoisted_36 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_37 = { class: "mr-1" }
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { class: "d-flex" }
const _hoisted_40 = { class: "mr-1" }
const _hoisted_41 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_42 = { class: "d-flex" }
const _hoisted_43 = { class: "mr-1" }
const _hoisted_44 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_45 = { class: "d-flex mt-2" }
const _hoisted_46 = { class: "mr-1" }
const _hoisted_47 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_48 = { class: "d-flex" }
const _hoisted_49 = { class: "mr-1" }
const _hoisted_50 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_51 = { class: "mr-1" }
const _hoisted_52 = { class: "d-flex" }
const _hoisted_53 = { class: "mr-1" }
const _hoisted_54 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_55 = { class: "mr-1" }
const _hoisted_56 = { key: 0 }
const _hoisted_57 = { class: "d-flex" }
const _hoisted_58 = { class: "mr-1" }
const _hoisted_59 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_60 = { class: "mr-1" }
const _hoisted_61 = { class: "d-flex" }
const _hoisted_62 = { class: "mr-1" }
const _hoisted_63 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_64 = { class: "mr-1" }
const _hoisted_65 = { class: "d-flex" }
const _hoisted_66 = { class: "mr-1" }
const _hoisted_67 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_68 = { class: "mr-1" }
const _hoisted_69 = { class: "d-flex" }
const _hoisted_70 = { class: "mr-1" }
const _hoisted_71 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_72 = { class: "mr-1" }
const _hoisted_73 = { key: 0 }
const _hoisted_74 = { class: "d-flex" }
const _hoisted_75 = { class: "mr-1" }
const _hoisted_76 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_77 = { class: "d-flex" }
const _hoisted_78 = { class: "mr-1" }
const _hoisted_79 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_80 = { class: "d-flex mt-2" }
const _hoisted_81 = { class: "mr-1" }
const _hoisted_82 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_83 = { class: "d-flex" }
const _hoisted_84 = { class: "mr-1" }
const _hoisted_85 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_86 = { class: "mr-1" }
const _hoisted_87 = { class: "d-flex" }
const _hoisted_88 = { class: "mr-1" }
const _hoisted_89 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_90 = { class: "mr-1" }
const _hoisted_91 = { key: 0 }
const _hoisted_92 = { class: "d-flex" }
const _hoisted_93 = { class: "mr-1" }
const _hoisted_94 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_95 = { class: "mr-1" }
const _hoisted_96 = { class: "d-flex" }
const _hoisted_97 = { class: "mr-1" }
const _hoisted_98 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_99 = { class: "mr-1" }
const _hoisted_100 = { class: "d-flex" }
const _hoisted_101 = { class: "mr-1" }
const _hoisted_102 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_103 = { class: "mr-1" }
const _hoisted_104 = { class: "d-flex" }
const _hoisted_105 = { class: "mr-1" }
const _hoisted_106 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_107 = { class: "mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, { "no-gutters": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          class: "ma-5",
          cols: "12"
        }, {
          default: _withCtx(() => [
            (Object.keys(_ctx.aiText).length === 0)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_1, " Hallo " + _toDisplayString(_ctx.user.forename) + " " + _toDisplayString(_ctx.user.lastname) + ", Willkommen auf dem Dashboard! ", 1))
              : (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.aiText.salutation) + " " + _toDisplayString(_ctx.aiText.motivation), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "auto",
          class: "ml-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_sheet, { class: "sheet2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  title: "30 Tage Vertriebskennzahlen",
                  subtitle: "Telefonate Unternehmen"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.User
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                        (_ctx.hasLoggedInMandants)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                              _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.ThirtyDays,
                  _ctx.KpiCategory.Mandant
                ) -
                _ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.ThirtyDays,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.ThirtyDays,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.Global
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_divider, { class: "my-1" }),
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )) + " | ", 1),
                            _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Firmenbesuche", -1))
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Kandidaten bei Kunden"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            _cache[9] || (_cache[9] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _cache[10] || (_cache[10] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[11] || (_cache[11] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )) + " | ", 1),
                            _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Profile verschickt", -1))
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _cache[13] || (_cache[13] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[15] || (_cache[15] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )) + " | Vorstellungsgespräche ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _cache[16] || (_cache[16] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                _cache[17] || (_cache[17] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_16, [
                            _cache[18] || (_cache[18] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )) + " | Probearbeiten ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _cache[19] || (_cache[19] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _cache[20] || (_cache[20] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_20, [
                            _cache[21] || (_cache[21] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )) + " | Zusagen ", 1)
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Telefonate Kandidaten"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _cache[23] || (_cache[23] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.User
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
                        (_ctx.hasLoggedInMandants)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_21, [
                              _cache[22] || (_cache[22] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                              _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.ThirtyDays,
                  _ctx.KpiCategory.Mandant
                ) -
                _ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.ThirtyDays,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.ThirtyDays,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _cache[25] || (_cache[25] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.Global
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.ThirtyDays,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Vorstellungstermine"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _cache[27] || (_cache[27] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                _cache[28] || (_cache[28] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_25, [
                            _cache[29] || (_cache[29] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )) + " | vereinbart ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _cache[30] || (_cache[30] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                _cache[31] || (_cache[31] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_29, [
                            _cache[32] || (_cache[32] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )) + " | abgesagt ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", _hoisted_31, [
                            _cache[33] || (_cache[33] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                _cache[34] || (_cache[34] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_33, [
                            _cache[35] || (_cache[35] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )) + " | geplatzt ", 1)
                          ])
                        ]),
                        _cache[39] || (_cache[39] = _createElementVNode("h4", { class: "mt-2" }, "stattgefunden:", -1)),
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _cache[36] || (_cache[36] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                _cache[37] || (_cache[37] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_37, [
                            _cache[38] || (_cache[38] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.ThirtyDays,
                    _ctx.KpiCategory.Global
                  )), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "auto",
          class: "ml-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_sheet, { class: "sheet2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  title: "7 Tage Vertriebskennzahlen",
                  subtitle: "Telefonate Unternehmen"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _cache[45] || (_cache[45] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.User
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[46] || (_cache[46] = _createElementVNode("br", null, null, -1)),
                        (_ctx.hasLoggedInMandants)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_38, [
                              _cache[40] || (_cache[40] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                              _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.SevenDays,
                  _ctx.KpiCategory.Mandant
                ) -
                _ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.SevenDays,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.SevenDays,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _cache[47] || (_cache[47] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.Global
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[48] || (_cache[48] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_divider, { class: "my-1" }),
                        _createElementVNode("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _cache[41] || (_cache[41] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                _cache[42] || (_cache[42] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[43] || (_cache[43] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )) + " | ", 1),
                            _cache[44] || (_cache[44] = _createElementVNode("strong", null, "Firmenbesuche", -1))
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Kandidaten bei Kunden"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_42, [
                          _createElementVNode("div", _hoisted_43, [
                            _cache[49] || (_cache[49] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                _cache[50] || (_cache[50] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[51] || (_cache[51] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )) + " | ", 1),
                            _cache[52] || (_cache[52] = _createElementVNode("strong", null, "Profile verschickt", -1))
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_45, [
                          _createElementVNode("div", _hoisted_46, [
                            _cache[53] || (_cache[53] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                _cache[54] || (_cache[54] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[55] || (_cache[55] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )) + " | Vorstellungsgespräche ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_48, [
                          _createElementVNode("div", _hoisted_49, [
                            _cache[56] || (_cache[56] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                                _cache[57] || (_cache[57] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_51, [
                            _cache[58] || (_cache[58] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )) + " | Probearbeiten ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_52, [
                          _createElementVNode("div", _hoisted_53, [
                            _cache[59] || (_cache[59] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                                _cache[60] || (_cache[60] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_55, [
                            _cache[61] || (_cache[61] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )) + " | Zusagen ", 1)
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Telefonate Kandidaten"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _cache[63] || (_cache[63] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.User
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[64] || (_cache[64] = _createElementVNode("br", null, null, -1)),
                        (_ctx.hasLoggedInMandants)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_56, [
                              _cache[62] || (_cache[62] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                              _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.SevenDays,
                  _ctx.KpiCategory.Mandant
                ) -
                _ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.SevenDays,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.SevenDays,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _cache[65] || (_cache[65] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.Global
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.SevenDays,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[66] || (_cache[66] = _createElementVNode("br", null, null, -1))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Vorstellungstermine"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_57, [
                          _createElementVNode("div", _hoisted_58, [
                            _cache[67] || (_cache[67] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                                _cache[68] || (_cache[68] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_60, [
                            _cache[69] || (_cache[69] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )) + " | vereinbart ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_61, [
                          _createElementVNode("div", _hoisted_62, [
                            _cache[70] || (_cache[70] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                                _cache[71] || (_cache[71] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_64, [
                            _cache[72] || (_cache[72] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )) + " | abgesagt ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_65, [
                          _createElementVNode("div", _hoisted_66, [
                            _cache[73] || (_cache[73] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                                _cache[74] || (_cache[74] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_68, [
                            _cache[75] || (_cache[75] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )) + " | geplatzt ", 1)
                          ])
                        ]),
                        _cache[79] || (_cache[79] = _createElementVNode("h4", { class: "mt-2" }, "stattgefunden:", -1)),
                        _createElementVNode("div", _hoisted_69, [
                          _createElementVNode("div", _hoisted_70, [
                            _cache[76] || (_cache[76] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
                                _cache[77] || (_cache[77] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_72, [
                            _cache[78] || (_cache[78] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.SevenDays,
                    _ctx.KpiCategory.Global
                  )), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "auto",
          class: "ml-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_sheet, { class: "sheet2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  title: "24 Stunden Vertriebskennzahlen",
                  subtitle: "Telefonate Unternehmen"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _cache[85] || (_cache[85] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.User
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[86] || (_cache[86] = _createElementVNode("br", null, null, -1)),
                        (_ctx.hasLoggedInMandants)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_73, [
                              _cache[80] || (_cache[80] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                              _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.TwentyFourHours,
                  _ctx.KpiCategory.Mandant
                ) -
                _ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.TwentyFourHours,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCustomer,
                  _ctx.TimePeriod.TwentyFourHours,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _cache[87] || (_cache[87] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.Global
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCustomer,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[88] || (_cache[88] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_divider, { class: "my-1" }),
                        _createElementVNode("div", _hoisted_74, [
                          _createElementVNode("div", _hoisted_75, [
                            _cache[81] || (_cache[81] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                                _cache[82] || (_cache[82] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[83] || (_cache[83] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.meetingOutside,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )) + " | ", 1),
                            _cache[84] || (_cache[84] = _createElementVNode("strong", null, "Firmenbesuche", -1))
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Kandidaten bei Kunden"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_77, [
                          _createElementVNode("div", _hoisted_78, [
                            _cache[89] || (_cache[89] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                                _cache[90] || (_cache[90] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[91] || (_cache[91] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.TimelineEntryType.candidateSuggestion,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )) + " | ", 1),
                            _cache[92] || (_cache[92] = _createElementVNode("strong", null, "Profile verschickt", -1))
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_80, [
                          _createElementVNode("div", _hoisted_81, [
                            _cache[93] || (_cache[93] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                                _cache[94] || (_cache[94] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[95] || (_cache[95] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )) + " | Vorstellungsgespräche ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_83, [
                          _createElementVNode("div", _hoisted_84, [
                            _cache[96] || (_cache[96] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_85, [
                                _cache[97] || (_cache[97] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_86, [
                            _cache[98] || (_cache[98] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.trailWorkExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )) + " | Probearbeiten ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_87, [
                          _createElementVNode("div", _hoisted_88, [
                            _cache[99] || (_cache[99] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                                _cache[100] || (_cache[100] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_90, [
                            _cache[101] || (_cache[101] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.jobofferExternal,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )) + " | Zusagen ", 1)
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Telefonate Kandidaten"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _cache[103] || (_cache[103] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.User
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.User,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[104] || (_cache[104] = _createElementVNode("br", null, null, -1)),
                        (_ctx.hasLoggedInMandants)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_91, [
                              _cache[102] || (_cache[102] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                              _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.TwentyFourHours,
                  _ctx.KpiCategory.Mandant
                ) -
                _ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.TwentyFourHours,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                  _ctx.TimelineEntryType.phoneCallCandidate,
                  _ctx.TimePeriod.TwentyFourHours,
                  _ctx.KpiCategory.Mandant,
                  _ctx.PhoneCallOption.notReached
                )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _cache[105] || (_cache[105] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                        _createTextVNode(": erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.Global
              ) -
              _ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )) + " | nicht erreicht: " + _toDisplayString(_ctx.getDynamicKPI(
                _ctx.TimelineEntryType.phoneCallCandidate,
                _ctx.TimePeriod.TwentyFourHours,
                _ctx.KpiCategory.Global,
                _ctx.PhoneCallOption.notReached
              )), 1),
                        _cache[106] || (_cache[106] = _createElementVNode("br", null, null, -1))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined,
                  class: "sheet2__card",
                  subtitle: "Vorstellungstermine"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_92, [
                          _createElementVNode("div", _hoisted_93, [
                            _cache[107] || (_cache[107] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                                _cache[108] || (_cache[108] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_95, [
                            _cache[109] || (_cache[109] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interview,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )) + " | vereinbart ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_96, [
                          _createElementVNode("div", _hoisted_97, [
                            _cache[110] || (_cache[110] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_98, [
                                _cache[111] || (_cache[111] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_99, [
                            _cache[112] || (_cache[112] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCanceled,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )) + " | abgesagt ", 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_100, [
                          _createElementVNode("div", _hoisted_101, [
                            _cache[113] || (_cache[113] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_102, [
                                _cache[114] || (_cache[114] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_103, [
                            _cache[115] || (_cache[115] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewNotShownUp,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )) + " | geplatzt ", 1)
                          ])
                        ]),
                        _cache[119] || (_cache[119] = _createElementVNode("h4", { class: "mt-2" }, "stattgefunden:", -1)),
                        _createElementVNode("div", _hoisted_104, [
                          _createElementVNode("div", _hoisted_105, [
                            _cache[116] || (_cache[116] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.User
                  )), 1)
                          ]),
                          (_ctx.hasLoggedInMandants)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_106, [
                                _cache[117] || (_cache[117] = _createElementVNode("i", { class: "fa-solid fa-building" }, null, -1)),
                                _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Mandant
                  )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_107, [
                            _cache[118] || (_cache[118] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1)),
                            _createTextVNode(": " + _toDisplayString(_ctx.getDynamicKPI(
                    _ctx.LinkingStatus.interviewCompleted,
                    _ctx.TimePeriod.TwentyFourHours,
                    _ctx.KpiCategory.Global
                  )), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "4",
          class: "ml-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_sheet, { class: "sheet1" }, {
              default: _withCtx(() => [
                (
            _ctx.softwareIntegration.dashboardAiKpiAnalysis && _ctx.aiText.analysisText
          )
                  ? (_openBlock(), _createBlock(_component_v_card, {
                      key: 0,
                      variant: _ctx.vStyle.card.variant || undefined,
                      rounded: _ctx.vStyle.card.rounded || undefined,
                      border: _ctx.vStyle.card.border || undefined,
                      class: "sheet1__card"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, {
                              size: "x-small",
                              class: "mr-1"
                            }, {
                              default: _withCtx(() => _cache[120] || (_cache[120] = [
                                _createTextVNode("fa-solid fa-rocket")
                              ])),
                              _: 1
                            }),
                            _cache[121] || (_cache[121] = _createTextVNode("Analyse"))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_card_subtitle, null, {
                          default: _withCtx(() => _cache[122] || (_cache[122] = [
                            _createTextVNode("Recurion.ai")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_card_text, {
                          innerHTML: _ctx.aiText.analysisText
                        }, null, 8, ["innerHTML"]),
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[123] || (_cache[123] = [
                            _createTextVNode("💡Ideen:")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_card_text, {
                          class: "ml-5",
                          innerHTML: _ctx.aiText.tips
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }, 8, ["variant", "rounded", "border"]))
                  : _createCommentVNode("", true),
                (_ctx.softwareIntegration.zvooveOne)
                  ? (_openBlock(), _createBlock(_component_v_card, {
                      key: 1,
                      variant: _ctx.vStyle.card.variant || undefined,
                      rounded: _ctx.vStyle.card.rounded || undefined,
                      border: _ctx.vStyle.card.border || undefined,
                      class: "sheet1__card",
                      title: "Mitarbeiter Übersicht"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.zvooveDashboardData.mitarbeiter), 1),
                            _cache[124] || (_cache[124] = _createTextVNode(" Mitarbeiter insgesamt")),
                            _cache[125] || (_cache[125] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" Eintritte: " + _toDisplayString(_ctx.zvooveDashboardData.eintritte) + " | Austritte: " + _toDisplayString(_ctx.zvooveDashboardData.austritte) + " | im Einsatz: " + _toDisplayString(_ctx.zvooveDashboardData.mitarbeiterMitEinsatz), 1),
                            _cache[126] || (_cache[126] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("strong", null, "ohne Einsatz: " + _toDisplayString(_ctx.zvooveDashboardData.mitarbeiterOhneEinsatz), 1),
                            _createVNode(_component_v_divider, { class: "my-1" }),
                            _createTextVNode(" " + _toDisplayString(_ctx.zvooveDashboardData.baldBeginnend) + " bald beginnend", 1),
                            _cache[127] || (_cache[127] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.zvooveDashboardData.mitarbeiterInFehlzeit) + " Mitarbeiter in Fehlzeit (" + _toDisplayString(_ctx.zvooveDashboardData.mitarbeiterInFehlzeitOhneBeleg) + " ohne Beleg)", 1),
                            _cache[128] || (_cache[128] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.zvooveDashboardData.baldEndend) + " beenden Einsatz bald ", 1),
                            _cache[129] || (_cache[129] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.zvooveDashboardData.mitarbeiterErreichenHUeD) + " erreichen Höchstüberlassungsdauer", 1),
                            _cache[130] || (_cache[130] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.zvooveDashboardData.mitarbeiterInEqualPay) + " sind in EqualPay", 1),
                            _cache[131] || (_cache[131] = _createElementVNode("br", null, null, -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["variant", "rounded", "border"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, { "no-gutters": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_sheet, { class: "pa-2 ma-2" })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}