const vAutoScroll = {
  mounted(el: any, binding: any) {
    el.scrollTop = el.scrollHeight;
    if (binding && binding.value) {
      const targetElement = el.querySelector(binding.value);
      el = targetElement;
    }
    if (el) el.scrollTop = el.scrollHeight;
  },
  updated(el: any, binding: any) {
    el.scrollTop = el.scrollHeight;
    if (binding && binding.value) {
      const targetElement = el.querySelector(binding.value);
      el = targetElement;
    }
    if (el) el.scrollTop = el.scrollHeight;
  },
};

export default vAutoScroll;
