<template>
  <v-container>
    <v-card variant="flat">
      <v-card-text id="chat-container" v-autoscroll class="chat-container">
        <div
          v-for="(message, index) in messages"
          :key="index"
          :class="['d-flex', message.isUser ? 'justify-end' : 'justify-start']"
        >
          <v-card
            :color="message.isUser ? 'secondary' : 'surface-bright'"
            :class="['ma-2', 'pa-3', { 'white--text': message.isUser }]"
            max-width="80%"
            rounded="lg"
          >
            <div v-if="message.isUser">
              {{ message.text }}
            </div>
            <div
              v-else
              class="markdown-body"
              v-html="renderMarkdown(message.text)"
            ></div>
          </v-card>
        </div>
        <div v-if="isLoading" class="d-flex justify-start">
          <v-card variant="flat" class="ma-2 pa-3" max-width="80%" rounded="lg">
            <v-img
              :src="generateSpinner"
              alt="Generiere..."
              height="16"
              width="32"
            ></v-img>
          </v-card>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-text-field
          :variant="vStyle?.input?.variant"
          :rounded="vStyle?.input?.rounded"
          :base-color="vStyle?.input?.baseColor"
          :color="vStyle?.input?.color"
          v-model="newMessage"
          placeholder="Frag mich etwas..."
          @keyup.enter="sendMessage"
          :disabled="isLoading"
          hide-details
          class="mr-2"
        ></v-text-field>
        <v-btn
          color="primary"
          @click="sendMessage"
          :loading="isLoading"
          :disabled="!newMessage.trim()"
        >
          Senden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AiService } from "@/services/ai.service";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { RecurionChatbotType } from "../../enums/recurion-chatbot-types.enum";

//TODO: if Altan has the same for whatsApp make a generic model
interface ChatMessage {
  text: string;
  isUser: boolean;
}

export default defineComponent({
  name: "RecurionChat",
  emits: ["isGenerating"],
  computed: {
    chatbotType(): RecurionChatbotType {
      return this.$store?.state?.recurionState?.chatbotType;
    },
  },

  watch: {
    "$store.state.recurionState.chatbotType": {
      handler(newVal) {
        // reset Thread if chatbot type changes
        this.threadId = undefined;
        if (newVal === RecurionChatbotType.coach) {
          this.messages = [];
          this.$nextTick(() => {
            this.sendMessage(this.$store?.state?.recurionState?.initPrompt);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      messages: [] as ChatMessage[],
      newMessage: "",
      isLoading: false,
      threadId: undefined as string | undefined,
      marked: marked,
      generateSpinner: `${this.$store?.getters?.getEnv?.VUE_APP_ASSETS_URL}/spinner/generate.gif`,
      vStyle: this.$store?.state?.vStyle,
    };
  },

  methods: {
    renderMarkdown(text: string): string {
      const rawHtml = this.marked.parse(text) as string;
      return DOMPurify.sanitize(rawHtml);
    },

    async sendMessage(initPrompt?: string | Event) {
      if (initPrompt instanceof Event) {
        initPrompt = undefined;
      }

      if ((!this.newMessage.trim() && !initPrompt) || this.isLoading) return;

      this.$emit("isGenerating", true);
      try {
        let userMessage = initPrompt || this.newMessage.trim();
        if (!initPrompt) {
          this.messages.push({ text: userMessage, isUser: true });
        }
        this.newMessage = "";
        this.isLoading = true;

        const aiService = new AiService();
        let response, threadId;

        switch (this.chatbotType) {
          case RecurionChatbotType.handbook:
            ({ response, threadId } = await aiService.chatWithHandbook(
              userMessage,
              this.threadId
            ));
            break;
          case RecurionChatbotType.coach:
            ({ response, threadId } = await aiService.chatWithCoach(
              userMessage,
              this.threadId
            ));
            break;
          default:
            throw new Error("Ungültiger Chatbot-Typ");
        }

        this.threadId = threadId;
        this.messages.push({ text: response, isUser: false });
      } catch (error) {
        console.error("Fehler beim Chat:", error);
        this.messages.push({
          text: "Entschuldigung, es gab einen Fehler bei der Verarbeitung deiner Nachricht.",
          isUser: false,
        });
      } finally {
        this.isLoading = false;
        this.$emit("isGenerating", false);
      }
    },
  },
});
</script>

<style>
.chat-container {
  height: 50vh;
  overflow-y: auto;
}

.markdown-body {
  line-height: 1.6;

  ul,
  ol {
    padding-left: 2.5rem;
    margin: 0.5rem 0;
    list-style-position: outside;
  }

  li {
    margin: 0.25rem 0;
    padding-left: 0.5rem;
  }

  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin: 0.25rem 0;
    padding-left: 1.5rem;
  }

  blockquote {
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    color: var(--color-primary);
    border-left: 0.25em solid var(--color-secondary);
  }

  a {
    color: var(--color-secondary);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.markdown-body pre {
  background-color: var(--color-surface-variant);
  color: var(--color-on-surface-variant);
  border-radius: 6px;
  padding: 16px;
  overflow-x: auto;
}

.markdown-body code {
  font-family: monospace;
  background-color: var(--color-surface-variant);
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
}

.markdown-body pre code {
  padding: 0;
  background-color: transparent;
}

.markdown-body blockquote > :first-child {
  margin-top: 0;
}

.markdown-body blockquote > :last-child {
  margin-bottom: 0;
}
</style>
