import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys, mergeProps as _mergeProps, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "pa-0 text-body-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  style: {"color":"black"}
}
const _hoisted_4 = { class: "mx-1 text-caption font-weight-light text-medium-emphasis" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex ma-0"
}
const _hoisted_6 = { class: "status-title" }
const _hoisted_7 = { class: "item-collapsed" }
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = {
  key: 2,
  class: "mx-1"
}
const _hoisted_10 = {
  key: 3,
  class: "mx-1 text-caption text-medium-emphasis"
}
const _hoisted_11 = {
  key: 0,
  style: {"color":"black"}
}
const _hoisted_12 = {
  key: 1,
  class: "hide-on-inactive-column"
}
const _hoisted_13 = { style: {"font-size":"0.7em"} }
const _hoisted_14 = { style: {"font-size":"0.8em"} }
const _hoisted_15 = {
  key: 2,
  class: "status-communication-container"
}
const _hoisted_16 = { class: "communication-icons-container" }
const _hoisted_17 = { class: "fa-solid fa-building" }
const _hoisted_18 = { class: "communication-icons-container" }
const _hoisted_19 = {
  key: 4,
  class: "tags-container"
}
const _hoisted_20 = { key: 6 }
const _hoisted_21 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_TopRightButton = _resolveComponent("TopRightButton")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_zvooveRecruitLinkMenu = _resolveComponent("zvooveRecruitLinkMenu")!
  const _component_PhoneClient = _resolveComponent("PhoneClient")!
  const _component_WhatsAppClient = _resolveComponent("WhatsAppClient")!
  const _component_MailClient = _resolveComponent("MailClient")!
  const _component_DialogApplicationForm = _resolveComponent("DialogApplicationForm")!
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_Dependencies = _resolveComponent("Dependencies")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_SendCandidateToWp = _resolveComponent("SendCandidateToWp")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_JobAdLeadsCandidate = _resolveComponent("JobAdLeadsCandidate")!
  const _component_DialogInterviewCompleted = _resolveComponent("DialogInterviewCompleted")!
  const _component_DialogManualAnzeigendatenSearch = _resolveComponent("DialogManualAnzeigendatenSearch")!
  const _component_DialogEditCandidate = _resolveComponent("DialogEditCandidate")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (!_ctx.isHided)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        class: "ma-0 pa-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_hover, null, {
            default: _withCtx(({ isHovering, props }) => [
              (_ctx.isFullyCollapsed)
                ? (_openBlock(), _createBlock(_component_v_container, {
                    key: 0,
                    onContextmenu: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.openContextMenu($event)), ["prevent"])),
                    onDragstart: _ctx.handleDragStart,
                    onLongpress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openContextMenu($event))),
                    draggable: "true",
                    class: "ma-0 pa-0 item longpress",
                    color: isHovering ? 'card' : 'cardHover'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "d-flex justify-space-between pa-0" }, {
                        default: _withCtx(() => [
                          (
                !_ctx.candidate.firstName &&
                !_ctx.candidate.lastName &&
                !_ctx.candidateData?.firstName &&
                !_ctx.candidateData?.lastName
              )
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                rounded: _ctx.vStyle.btn.rounded || undefined,
                                border: _ctx.vStyle.btn.border || undefined,
                                loading: _ctx.isLoadingFromAts,
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadCandidate(_ctx.ExternalSoftware.atsRecruit))),
                                variant: "tonal",
                                density: "compact",
                                size: "small"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, {
                                    class: "mr-2",
                                    size: "small"
                                  }, {
                                    default: _withCtx(() => _cache[38] || (_cache[38] = [
                                      _createTextVNode("fa-solid fa-cloud-arrow-down")
                                    ])),
                                    _: 1
                                  }),
                                  _createTextVNode(" #" + _toDisplayString(_ctx.candidate.applicationId) + " ", 1),
                                  _createVNode(_component_v_tooltip, {
                                    activator: "parent",
                                    location: "top left"
                                  }, {
                                    default: _withCtx(() => _cache[39] || (_cache[39] = [
                                      _createTextVNode("Kandidatendaten vollständig aus dem ATS laden ...")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["rounded", "border", "loading"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_1, [
                            _createTextVNode(_toDisplayString(_ctx.candidateData?.firstName
                  ? _ctx.candidateData.firstName
                  : _ctx.candidate.firstName) + " " + _toDisplayString(_ctx.candidateData?.lastName
                  ? _ctx.candidateData.lastName
                  : _ctx.candidate.lastName) + " ", 1),
                            (_ctx.candidateAge > 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString(_ctx.candidateAge) + ")", 1))
                              : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(_ctx.candidateData?.shiftIcon
                  ? _ctx.candidateData.shiftIcon
                  : _ctx.candidate.shiftIcon) + " ", 1),
                            (
                  (_ctx.candidateData && _ctx.candidateData.mobilityRadius > 0) ||
                  (_ctx.candidate &&
                    _ctx.candidate?.mobilityRadius &&
                    _ctx.candidate?.mobilityRadius > 0)
                )
                              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                                  _createTextVNode(" (" + _toDisplayString(_ctx.candidateData?.mobilityRadius
                    ? _ctx.candidateData.mobilityRadius
                    : _ctx.candidate.mobilityRadius) + " km ", 1),
                                  _createVNode(_component_v_icon, { size: "xsmall" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.candidateData?.mobilityIcon
                    ? _ctx.candidateData.mobilityIcon
                    : _ctx.candidate.mobilityIcon), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _cache[40] || (_cache[40] = _createTextVNode(" ) "))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.candidateData?.addressCity
                  ? _ctx.candidateData.addressCity
                  : _ctx.candidate.addressCity), 1),
                          _createElementVNode("div", {
                            class: _normalizeClass(["status-list", { blinking: _ctx.needToContact || _ctx.prioNeedToContact }]),
                            style: _normalizeStyle({ backgroundColor: _ctx.statusColor })
                          }, null, 6)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_divider, { class: "my-1" })
                    ]),
                    _: 2
                  }, 1032, ["onDragstart", "color"]))
                : _createCommentVNode("", true),
              (!_ctx.isFullyCollapsed)
                ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({
                    key: 1,
                    variant: _ctx.vStyle.boardItem.variant || undefined,
                    rounded: _ctx.vStyle.boardItem.rounded || undefined,
                    border: _ctx.vStyle.boardItem.border || undefined,
                    elevation: _ctx.vStyle.boardItem.elevation || undefined,
                    color: isHovering ? _ctx.item.hoverColor : _ctx.item.color
                  }, props, {
                    class: {
            expanded: _ctx.isExpanded,
            'fully-expanded': _ctx.isFullyExpanded,
            'item pl-5 pr-2': _ctx.isOpenedAsDialog(),
            'item pa-2 mb-2': !_ctx.isOpenedAsDialog(),
          },
                    "max-height": _ctx.getItemHeight(),
                    onDblclick: _ctx.handleDoubleClickOnItem,
                    onDragstart: _ctx.handleDragStart,
                    onContextmenu: _cache[20] || (_cache[20] = _withModifiers(($event: any) => (_ctx.openContextMenu($event)), ["prevent"])),
                    onLongpress: _cache[21] || (_cache[21] = ($event: any) => (_ctx.openContextMenu($event))),
                    draggable: "true"
                  }), {
                    default: _withCtx(() => [
                      (_ctx.isOpenedAsDialog())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _cache[42] || (_cache[42] = _createElementVNode("p", { class: "mt-2 mb-5 text-h6 text-medium-emphasis" }, " Kandidatenkarte ", -1)),
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit(_ctx.CandidateItemEmits.closeDialog))),
                              icon: "",
                              size: "s",
                              variant: "text",
                              density: "compact"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[41] || (_cache[41] = [
                                    _createTextVNode(" fa-solid fa-xmark close-icon ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: _normalizeClass(["status", { blinking: _ctx.needToContact || _ctx.prioNeedToContact }]),
                        style: _normalizeStyle({ backgroundColor: _ctx.statusColor })
                      }, null, 6),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            (_ctx.avatar)
                              ? (_openBlock(), _createBlock(_component_v_avatar, {
                                  key: 0,
                                  size: "2.5rem",
                                  class: "mr-1"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_img, {
                                      alt: "Avatar",
                                      src: _ctx.avatar
                                    }, null, 8, ["src"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", null, [
                              _createVNode(_component_v_card_title, { class: "d-flex pa-0 text-body-2" }, {
                                default: _withCtx(() => [
                                  (
                        !_ctx.candidate.firstName &&
                        !_ctx.candidate.lastName &&
                        !_ctx.candidateData?.firstName &&
                        !_ctx.candidateData?.lastName
                      )
                                    ? (_openBlock(), _createBlock(_component_v_btn, {
                                        key: 0,
                                        rounded: _ctx.vStyle.btn.rounded || undefined,
                                        border: _ctx.vStyle.btn.border || undefined,
                                        loading: _ctx.isLoadingFromAts,
                                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadCandidate(_ctx.ExternalSoftware.atsRecruit))),
                                        variant: "tonal"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, {
                                            class: "mr-2",
                                            size: "small"
                                          }, {
                                            default: _withCtx(() => _cache[43] || (_cache[43] = [
                                              _createTextVNode("fa-solid fa-cloud-arrow-down")
                                            ])),
                                            _: 1
                                          }),
                                          _createTextVNode(" #" + _toDisplayString(_ctx.candidate.applicationId) + " ", 1),
                                          _createVNode(_component_v_tooltip, {
                                            activator: "parent",
                                            location: "top left"
                                          }, {
                                            default: _withCtx(() => _cache[44] || (_cache[44] = [
                                              _createTextVNode("Kandidatendaten vollständig aus dem ATS laden ...")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["rounded", "border", "loading"]))
                                    : _createCommentVNode("", true),
                                  (
                        _ctx.isAtsStatusUnknown && !_ctx.isAtsStatusChangedFromUnknown
                      )
                                    ? (_openBlock(), _createBlock(_component_v_btn, {
                                        key: 1,
                                        icon: "",
                                        variant: "text",
                                        density: "compact",
                                        onClick: _ctx.updateComponent
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, { size: "small" }, {
                                            default: _withCtx(() => _cache[45] || (_cache[45] = [
                                              _createTextVNode("fa-regular fa-question-circle")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_tooltip, {
                                            activator: "parent",
                                            location: "bottom"
                                          }, {
                                            default: _withCtx(() => _cache[46] || (_cache[46] = [
                                              _createTextVNode("ATS Status ist unbekannt! Kandidat aktualisieren und neuen Status abrufen?")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"]))
                                    : _createCommentVNode("", true),
                                  _createTextVNode(" " + _toDisplayString(_ctx.candidateData?.firstName
                        ? _ctx.candidateData.firstName
                        : _ctx.candidate.firstName) + " " + _toDisplayString(_ctx.candidateData?.lastName
                        ? _ctx.candidateData.lastName
                        : _ctx.candidate.lastName) + " ", 1),
                                  (_ctx.candidateAge > 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, " (" + _toDisplayString(_ctx.candidateAge) + ")", 1))
                                    : _createCommentVNode("", true),
                                  _createTextVNode(" " + _toDisplayString(_ctx.candidateData?.shiftIcon
                        ? _ctx.candidateData.shiftIcon
                        : _ctx.candidate.shiftIcon) + " ", 1),
                                  (_ctx.isAtsStatusChangedFromUnknown)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, " (verschoben nach " + _toDisplayString(_ctx.matchedApplication.ats?.status) + ") ", 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_card_subtitle, { class: "pl-0" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.candidateData?.addressPostalCode
                        ? _ctx.candidateData.addressPostalCode
                        : _ctx.candidate.addressPostalCode) + " " + _toDisplayString(_ctx.candidateData?.addressCity
                        ? _ctx.candidateData.addressCity
                        : _ctx.candidate.addressCity) + " ", 1),
                                  (
                        (_ctx.candidateData && _ctx.candidateData.mobilityRadius > 0) ||
                        (_ctx.candidate &&
                          _ctx.candidate?.mobilityRadius &&
                          _ctx.candidate?.mobilityRadius > 0)
                      )
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                        _createTextVNode(" (" + _toDisplayString(_ctx.candidateData?.mobilityRadius
                          ? _ctx.candidateData.mobilityRadius
                          : _ctx.candidate.mobilityRadius) + " km ", 1),
                                        _createVNode(_component_v_icon, { size: "xsmall" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.candidateData?.mobilityIcon
                          ? _ctx.candidateData.mobilityIcon
                          : _ctx.candidate.mobilityIcon), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _cache[47] || (_cache[47] = _createTextVNode(" ) "))
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ])
                          ])
                        ]),
                        (!_ctx.isOpenedAsDialog())
                          ? (_openBlock(), _createBlock(_component_TopRightButton, {
                              key: 0,
                              isExpanded: _ctx.isExpanded,
                              isFullyExpanded: _ctx.isFullyExpanded,
                              parentRole: _ctx.Role.candidate,
                              onSendCandidate: _ctx.handleSendCandidateToGenerateProfile,
                              onToggleExpansion: _ctx.toggleExpansion,
                              onToggleFullExpansion: _ctx.toggleFullExpansion,
                              onOpenContextMenu: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openContextMenu($event)))
                            }, null, 8, ["isExpanded", "isFullyExpanded", "parentRole", "onSendCandidate", "onToggleExpansion", "onToggleFullExpansion"]))
                          : _createCommentVNode("", true)
                      ]),
                      (_ctx.isActive)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("span", _hoisted_13, [
                              _createElementVNode("b", null, "#" + _toDisplayString(_ctx.candidate?.applicationId) + " " + _toDisplayString(_ctx.matchedApplication?.appliedAs
                    ? _ctx.matchedApplication.appliedAs
                    : _ctx.candidate.appliedAs), 1)
                            ]),
                            _cache[48] || (_cache[48] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.matchedMandantName), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_divider),
                      (_ctx.isExpanded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              (_ctx.candidateData && _ctx.candidateData._id)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: "appointment-icon",
                                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.createNewMandantAppointmentOnDependencies()))
                                  }, [
                                    _createElementVNode("i", _hoisted_17, [
                                      _createVNode(_component_v_tooltip, {
                                        activator: "parent",
                                        location: "bottom"
                                      }, {
                                        default: _withCtx(() => _cache[49] || (_cache[49] = [
                                          _createTextVNode("Termine mit Niederlassung")
                                        ])),
                                        _: 1
                                      })
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_select, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                items: _ctx.statusOptionsRecruit,
                                label: "Status (manuell)",
                                "item-title": "text",
                                "item-value": "value",
                                density: "compact",
                                modelValue: _ctx.selectedStatus,
                                "onUpdate:modelValue": [
                                  _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedStatus) = $event)),
                                  _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeStatus(_ctx.selectedStatus)))
                                ]
                              }, null, 8, ["variant", "rounded", "base-color", "color", "items", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_zvooveRecruitLinkMenu, {
                                zvooveRecruitMitarbeiterUuid: _ctx.candidate?.candidateUuid,
                                zvooveRecruitCurrentApplicationUuid: 
                  _ctx.candidate?.applicationUuid
                ,
                                onGetRecruitData: _cache[9] || (_cache[9] = ($event: any) => (_ctx.loadCandidate(_ctx.ExternalSoftware.atsRecruit)))
                              }, null, 8, ["zvooveRecruitMitarbeiterUuid", "zvooveRecruitCurrentApplicationUuid"]),
                              (!_ctx.isStatusInterview())
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 0,
                                    icon: "",
                                    class: "application-form-icon",
                                    variant: "text",
                                    density: "compact",
                                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openApplicationFormDispatcherMode()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, { size: "xsmall" }, {
                                        default: _withCtx(() => _cache[50] || (_cache[50] = [
                                          _createTextVNode("fa-solid fa-id-card")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_tooltip, {
                                        activator: "parent",
                                        location: "bottom"
                                      }, {
                                        default: _withCtx(() => _cache[51] || (_cache[51] = [
                                          _createTextVNode("Zum Bewerberbogen")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_PhoneClient, {
                                ref: "phoneClientComponent",
                                candidate: _ctx.candidate,
                                candidateName: `${_ctx.candidateData?.firstName} ${_ctx.candidateData?.lastName}`,
                                phoneNumbers: _ctx.filteredPhoneNumbers,
                                onAddFollowUpEvent: _ctx.addFollowUpOnDependencies,
                                onCollapseParentItem: _ctx.collapseItem,
                                onIsDialingNumber: _ctx.phoneClientIsDialing,
                                onSetAppointmentEvent: _ctx.setAppointmentOnDependencies
                              }, null, 8, ["candidate", "candidateName", "phoneNumbers", "onAddFollowUpEvent", "onCollapseParentItem", "onIsDialingNumber", "onSetAppointmentEvent"]),
                              _createVNode(_component_WhatsAppClient, {
                                AiMessageType: _ctx.AiMessageType.whatsAppCandidate,
                                candidate: _ctx.candidate,
                                linkingStatuses: _ctx.linkingStatuses,
                                message: _ctx.message.body,
                                phoneNumbers: _ctx.filteredPhoneNumbers,
                                receiverName: `${_ctx.candidateData?.firstName} ${_ctx.candidateData?.lastName}`,
                                salutation: _ctx.salutationText,
                                signature: _ctx.signatureWhatsApp,
                                onCollapseParentItem: _ctx.collapseItem
                              }, null, 8, ["AiMessageType", "candidate", "linkingStatuses", "message", "phoneNumbers", "receiverName", "salutation", "signature", "onCollapseParentItem"]),
                              _createVNode(_component_MailClient, {
                                ref: "mailClientComponent",
                                AiMessageType: _ctx.AiMessageType.mailCandidate,
                                candidate: _ctx.candidate,
                                candidateName: `${_ctx.candidateData?.firstName} ${_ctx.candidateData?.lastName}`,
                                emailAddresses: _ctx.filteredEmail,
                                emailObject: _ctx.message,
                                linkingStatuses: _ctx.linkingStatuses,
                                signature: _ctx.signatureMail,
                                onCollapseParentItem: _ctx.collapseItem
                              }, null, 8, ["AiMessageType", "candidate", "candidateName", "emailAddresses", "emailObject", "linkingStatuses", "signature", "onCollapseParentItem"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isExpanded)
                        ? (_openBlock(), _createBlock(_component_DialogApplicationForm, {
                            key: 3,
                            ref: "dialogApplicationFormComponent",
                            candidate: _ctx.candidateData,
                            isStatusInterview: _ctx.isStatusInterview(),
                            onSubmit: _cache[11] || (_cache[11] = ($event: any) => (_ctx.loadCandidate()))
                          }, null, 8, ["candidate", "isStatusInterview"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_divider),
                      _createVNode(_component_Checklist, {
                        checklist: _ctx.candidateData?.checklist || [],
                        candidate: _ctx.candidate,
                        onUpdateChecklist: _ctx.updateChecklist
                      }, null, 8, ["checklist", "candidate", "onUpdateChecklist"]),
                      _createVNode(_component_v_divider),
                      (_ctx.isExpanded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.candidateData?.tags, (tag, index) => {
                              return (_openBlock(), _createBlock(_component_v_chip, {
                                key: index,
                                class: "dispatcher-board-icon-tag",
                                color: "primary",
                                "onClick:close": ($event: any) => (_ctx.removeTag(index))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(tag) + " ", 1),
                                  _createVNode(_component_v_icon, {
                                    small: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.removeTag(index)), ["stop"]),
                                    class: "fa fa-times"
                                  }, null, 8, ["onClick"])
                                ]),
                                _: 2
                              }, 1032, ["onClick:close"]))
                            }), 128)),
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              class: "mt-2",
                              label: "Neuer Tag",
                              style: {"max-width":"10rem"},
                              density: "compact",
                              modelValue: _ctx.newTag,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newTag) = $event)),
                              onKeyup: _withKeys(_ctx.addTag, ["enter"])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[52] || (_cache[52] = [
                                    _createTextVNode("mit \"Enter\" neuen Tag hinzufügen")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onKeyup"])
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_divider),
                      (
              _ctx.candidate &&
              (_ctx.hasLinking(_ctx.candidate.parentObjectid) || _ctx.isDependenciesActive)
            )
                        ? (_openBlock(), _createBlock(_component_Dependencies, {
                            key: 5,
                            ref: "dependenciesComponent",
                            mandant: [_ctx.matchedMandantUuid || ''],
                            candidate_id: _ctx.candidate.parentObjectid,
                            linkingDescription: _ctx.linkingDescription,
                            onUpdateCandidateStatus: _ctx.handleCandidateStatusUpdate,
                            onCandidateToMandantLinked: _ctx.setCandidateToMandantFromDependencies,
                            onNeedToContact: _cache[13] || (_cache[13] = ($event: any) => (_ctx.prioNeedToContact = true)),
                            onNotNeedToContact: _cache[14] || (_cache[14] = ($event: any) => (_ctx.prioNeedToContact = false)),
                            onOpenPhoneClient: _cache[15] || (_cache[15] = ($event: any) => (_ctx.openPhoneClient()))
                          }, null, 8, ["mandant", "candidate_id", "linkingDescription", "onUpdateCandidateStatus", "onCandidateToMandantLinked"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_divider),
                      (_ctx.isExpanded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createVNode(_component_v_textarea, {
                              class: "mt-2 pr-1",
                              modelValue: _ctx.interviewResultEdit,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.interviewResultEdit) = $event)),
                              label: "Zusammenfassung Vorstellungsgespräch:",
                              variant: "solo",
                              rows: "4"
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_container, { class: "ma-0 pa-0 d-flex" }, {
                              default: _withCtx(() => [
                                (
                  _ctx.candidateData &&
                  _ctx.softwareIntegration.wordPressPlugin &&
                  _ctx.isCandidateActiveForPlacement()
                )
                                  ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                      key: 0,
                                      density: "compact",
                                      modelValue: _ctx.candidateData.isPublishedOnWebsite,
                                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.candidateData.isPublishedOnWebsite) = $event)),
                                      label: `Kandidat auf die Webseite stellen (WordPress Plugin)`,
                                      onChange: _ctx.handlePublishedOnWebseiteChange
                                    }, null, 8, ["modelValue", "onChange"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_icon, {
                                  class: "save-interview-result",
                                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.saveInterviewResult(_ctx.interviewResultEdit)))
                                }, {
                                  default: _withCtx(() => _cache[53] || (_cache[53] = [
                                    _createTextVNode("fa-solid fa-cloud-arrow-up")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "top left"
                                }, {
                                  default: _withCtx(() => _cache[54] || (_cache[54] = [
                                    _createTextVNode("Zusammenfassung in den Kandidatendaten abspeichern")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            (_ctx.getCareerLevelDescription())
                              ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.getCareerLevelDescription()), 1))
                              : _createCommentVNode("", true),
                            _createVNode(_component_v_divider),
                            (_ctx.softwareIntegration.wordPressPlugin)
                              ? (_openBlock(), _createBlock(_component_SendCandidateToWp, {
                                  key: 1,
                                  ref: "sendCandidateToWpComponent",
                                  onAborted: _cache[19] || (_cache[19] = ($event: any) => (_ctx.sendCandidateToWpAborted()))
                                }, null, 512))
                              : _createCommentVNode("", true),
                            _createVNode(_component_Timeline, {
                              candidate: _ctx.candidate,
                              candidateName: `${_ctx.candidateData?.firstName} ${_ctx.candidateData?.lastName}`,
                              mandant: _ctx.matchedMandantUuid || ''
                            }, null, 8, ["candidate", "candidateName", "mandant"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1040, ["variant", "rounded", "border", "elevation", "color", "class", "max-height", "onDblclick", "onDragstart"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_menu, {
                modelValue: _ctx.showContextMenu,
                "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.showContextMenu) = $event)),
                style: _normalizeStyle({
            top: _ctx.contextMenuPosition.y + 'px',
            left: _ctx.contextMenuPosition.x + 'px',
          })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list, { dense: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.openEditCandidateModal()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            class: "text-medium-emphasis ml-1 mr-2",
                            size: "xs"
                          }, {
                            default: _withCtx(() => _cache[55] || (_cache[55] = [
                              _createTextVNode(" fa-solid fa-pen-to-square ")
                            ])),
                            _: 1
                          }),
                          _cache[56] || (_cache[56] = _createTextVNode("bearbeiten"))
                        ]),
                        _: 1
                      }),
                      (!_ctx.isOpenedAsDialog())
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 0,
                            onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.askCustomerItemsForMatch()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                class: "text-medium-emphasis ml-1 mr-2",
                                size: "xs"
                              }, {
                                default: _withCtx(() => _cache[57] || (_cache[57] = [
                                  _createTextVNode(" fa-solid fa-heart ")
                                ])),
                                _: 1
                              }),
                              _cache[58] || (_cache[58] = _createTextVNode("Matching"))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.$store.state.isActiveCandidateMatching)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 1,
                            onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.clearMatchingOject()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                class: "text-medium-emphasis ml-1 mr-2",
                                size: "xs"
                              }, {
                                default: _withCtx(() => _cache[59] || (_cache[59] = [
                                  _createTextVNode(" fa-solid fa-heart-circle-xmark ")
                                ])),
                                _: 1
                              }),
                              _cache[60] || (_cache[60] = _createTextVNode("Matches entfernen"))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_list_item, {
                        onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.deleteCandidate()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            class: "text-medium-emphasis ml-1 mr-2",
                            size: "xs"
                          }, {
                            default: _withCtx(() => _cache[61] || (_cache[61] = [
                              _createTextVNode(" fa-solid fa-trash-can ")
                            ])),
                            _: 1
                          }),
                          _cache[62] || (_cache[62] = _createTextVNode("Kandidat vom Dispoboard löschen"))
                        ]),
                        _: 1
                      }),
                      (_ctx.isAdmin)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 2,
                            onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.deleteCandidate(_ctx.candidate.candidateUuid)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                class: "text-medium-emphasis ml-1 mr-2",
                                size: "xs"
                              }, {
                                default: _withCtx(() => _cache[63] || (_cache[63] = [
                                  _createTextVNode(" fa-solid fa-user-xmark ")
                                ])),
                                _: 1
                              }),
                              _cache[64] || (_cache[64] = _createTextVNode("ADMIN: Kandidat anhand uuid löschen"))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.isOpenedAsDialog())
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 3,
                            onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.emitLoadCandidatesFull()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                class: "text-medium-emphasis ml-1 mr-2",
                                size: "xs"
                              }, {
                                default: _withCtx(() => _cache[65] || (_cache[65] = [
                                  _createTextVNode(" fa-regular fa-id-card ")
                                ])),
                                _: 1
                              }),
                              _cache[66] || (_cache[66] = _createTextVNode("alle vollständig laden"))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.isOpenedAsDialog())
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 4,
                            onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.emitLoadCandidatesFromAts()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                class: "text-medium-emphasis ml-1 mr-2",
                                size: "xs"
                              }, {
                                default: _withCtx(() => _cache[67] || (_cache[67] = [
                                  _createTextVNode(" fa-solid fa-cloud-arrow-down ")
                                ])),
                                _: 1
                              }),
                              _cache[68] || (_cache[68] = _createTextVNode("alle aus ATS überschreiben"))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.isOpenedAsDialog())
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 5,
                            onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.emitToggleItemsMinimized()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                class: "text-medium-emphasis ml-1 mr-1",
                                size: "xs"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.isFullyCollapsed
                    ? "fa-solid fa-chevron-up"
                    : "fa-solid fa-chevron-down"), 1)
                                ]),
                                _: 1
                              }),
                              _createTextVNode(" " + _toDisplayString(_ctx.isFullyCollapsed ? "Karten normal" : "Karten minimieren"), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "style"]),
              (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
                (
              _ctx.isExpanded &&
              _ctx.candidateData?.jobAdLeads &&
              _ctx.isCandidateActiveForPlacement() &&
              !_ctx.isOpenedAsDialog()
            )
                  ? (_openBlock(), _createBlock(_component_JobAdLeadsCandidate, {
                      key: 0,
                      ref: "jobAdListComponent",
                      jobAds: _ctx.candidateData?.jobAdLeads as JobAdLead[] || [],
                      indexCareerLevel: _ctx.candidateData?.careerLevel,
                      mandant: _ctx.matchedMandantUuid || '',
                      onGenerateAiSearchterm: _cache[31] || (_cache[31] = ($event: any) => (_ctx.fetchJobAdLeads(true))),
                      onManualAnzeigendatenSearch: _cache[32] || (_cache[32] = ($event: any) => (_ctx.openAnzeigendatenManualSearch())),
                      onUpdateCareerLevels: _ctx.updateCareerLevels
                    }, null, 8, ["jobAds", "indexCareerLevel", "mandant", "onUpdateCareerLevels"]))
                  : _createCommentVNode("", true),
                (_ctx.isDialogInterviewCompletedActive)
                  ? (_openBlock(), _createBlock(_component_DialogInterviewCompleted, {
                      key: 1,
                      ref: "dialogInterviewCompletedComponent",
                      zvooveRecruitMitarbeiterUuid: _ctx.candidate.candidateUuid,
                      onSaveResumeInterview: _ctx.handleSaveResumeInterview,
                      onFetchRecruitData: _cache[33] || (_cache[33] = ($event: any) => (_ctx.loadCandidate(_ctx.ExternalSoftware.atsRecruit))),
                      onOpenEditCandidateDialog: _ctx.openEditCandidateModal
                    }, null, 8, ["zvooveRecruitMitarbeiterUuid", "onSaveResumeInterview", "onOpenEditCandidateDialog"]))
                  : _createCommentVNode("", true),
                (_ctx.isDialogManualAnzeigendatenSearchActive)
                  ? (_openBlock(), _createBlock(_component_DialogManualAnzeigendatenSearch, {
                      key: 2,
                      dataForQuery: _ctx.prepareDataForJobAdLeadsQuery(),
                      ref: "dialogManualAnzeigendatenSearchComponent",
                      onSearchAndReplace: _ctx.searchAnzeigendatenAndReplace,
                      onSearchAndAdd: _ctx.searchAnzeigendatenAndAdd
                    }, null, 8, ["dataForQuery", "onSearchAndReplace", "onSearchAndAdd"]))
                  : _createCommentVNode("", true),
                (_ctx.isDialogEditCandidateActive)
                  ? (_openBlock(), _createBlock(_component_DialogEditCandidate, {
                      key: 3,
                      ref: "dialogEditCandidateComponent",
                      candidate: _ctx.candidateData,
                      onSubmit: _cache[34] || (_cache[34] = ($event: any) => (_ctx.loadCandidate())),
                      onFetchJobAdLeads: _cache[35] || (_cache[35] = ($event: any) => (_ctx.fetchJobAdLeads())),
                      onGenerateAiSearchterm: _cache[36] || (_cache[36] = ($event: any) => (_ctx.fetchJobAdLeads(true))),
                      onManualAnzeigendatenSearch: _cache[37] || (_cache[37] = ($event: any) => (_ctx.openAnzeigendatenManualSearch()))
                    }, null, 8, ["candidate"]))
                  : _createCommentVNode("", true)
              ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}