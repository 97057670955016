<template>
  <v-btn
    v-if="!mdAndDown"
    icon
    density="compact"
    variant="text"
    class="toggle-button"
    @click="toggleExpansion"
  >
    <v-icon>{{
      isExpanded ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
    }}</v-icon>
  </v-btn>
  <v-btn
    v-if="mdAndDown"
    icon
    density="compact"
    variant="text"
    class="toggle-button"
  >
    <v-icon>fa-solid fa-ellipsis-vertical</v-icon>
    <v-menu activator="parent">
      <v-list>
        <v-list-item @click="toggleExpansion">
          <v-icon>{{
            isExpanded ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
          }}</v-icon>
        </v-list-item>
        <v-list-item v-if="showMaximize()" @click="toggleFullExpansion">
          <v-icon>fa-solid fa-maximize</v-icon>
        </v-list-item>
        <v-list-item v-if="showSendCandidate()" @click="sendCandidate">
          <v-icon class="mx-1">fa-solid fa-user-plus</v-icon>
        </v-list-item>
        <v-list-item v-if="showReceiveCandidate()" @click="receiveCandidate">
          <v-icon class="mx-1">fa-solid fa-building-user</v-icon>
        </v-list-item>
        <v-list-item @click="openContextMenu($event)">
          <v-icon>fa-solid fa-bars</v-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";
import { Role } from "../../../enums/dependency.enum";

export default defineComponent({
  name: "DemandItem",
  emits: [
    "openContextMenu",
    "sendCandidate",
    "receiveCandidate",
    "toggleExpansion",
    "toggleFullExpansion",
  ],
  props: {
    isExpanded: {
      type: Boolean,
      required: true,
    },
    isFullyExpanded: {
      type: Boolean,
      required: true,
    },
    parentRole: {
      type: String as PropType<Role>,
    },
  },
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
    };
  },
  methods: {
    openContextMenu(event: any) {
      this.$emit("openContextMenu", event);
    },
    showMaximize() {
      return this.isExpanded && !this.isFullyExpanded;
    },
    showSendCandidate(): boolean {
      return this.parentRole === Role.candidate;
    },
    showReceiveCandidate(): boolean {
      return (
        this.parentRole === Role.customer &&
        !!this.$store.state.isDraggingItem.candidate.candidateData
      );
    },
    sendCandidate() {
      this.$emit("sendCandidate");
    },
    receiveCandidate() {
      this.$emit("receiveCandidate");
    },
    toggleExpansion() {
      this.$emit("toggleExpansion");
    },
    toggleFullExpansion() {
      this.$emit("toggleFullExpansion");
    },
  },
});
</script>
