//src/enums/inter-component-messagin.enum.ts

export enum InterComponentMessage {
  addCustomerFromIndex = "addCustomerFromIndex",
  addCustomerFromZvoove = "addCustomerFromZvoove",
  addDemandFromPhoneClient = "addDemandFromPhoneClient",
  deepSearchCandidate = "deepSearchCandidate",
  filterCustomers = "filterCustomers",
  generateProfile = "generateProfile",
  matchMeFromCandidate = "matchMeCandidate",
  matchMeFromCustomerOrDemand = "matchMeCustomerOrDemand",
  matchMeFromGlobalSearch = "matchMeFromGlobalSearch",
  openCustomer = "openCustomer",
  profileSend = "profileSend",
  searchCandidate = "searchCandidate",
  sendDemandDetailsBackToPhoneClient = "sendDemandDetailsBackToPhoneClient",
}
