<template>
  <div v-if="layout === AppLayout.classic">
    <MainHeader
      @tab-selected="handleTabSelection"
      :showAdministrationTabs="true"
    ></MainHeader>
    <Documentation
      v-if="currentTab === HeaderTab.Documentation"
    ></Documentation>
    <PayFlowOverview
      v-if="currentTab === HeaderTab.PayFlowOverview"
    ></PayFlowOverview>
    <WorkTime v-if="currentTab === HeaderTab.WorkTime"></WorkTime>
  </div>
  <v-row v-else>
    <v-col cols="12" md="auto">
      <LeftHeader></LeftHeader>
    </v-col>
    <v-col cols="12" md="11">
      <AppHeader
        @tab-selected="handleTabSelection"
        :showAdministrationTabs="true"
      ></AppHeader>
      <Documentation
        v-if="currentTab === HeaderTab.Documentation"
      ></Documentation>
      <PayFlowOverview
        v-if="currentTab === HeaderTab.PayFlowOverview"
      ></PayFlowOverview>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Documentation from "@/components/documentation/Doku.vue";
import PayFlowOverview from "@/components/payflow/PayFlowOverview.vue";
import MainHeader from "@/components/header/ClassicHeader.vue";
import { HeaderTab } from "@/enums/header-tabs.enum";
import LeftHeader from "@/components/header/LeftHeader.vue";
import AppHeader from "@/components/header/Header.vue";
import { AppLayout } from "@/enums/app-layout.enum";
import WorkTime from "../components/worktime/WorkTime.vue";

export default defineComponent({
  name: "DocumentationView",
  components: {
    MainHeader,
    Documentation,
    PayFlowOverview,
    LeftHeader,
    AppHeader,
    WorkTime,
  },
  data() {
    return {
      currentTab: HeaderTab.Documentation as string,
      HeaderTab: HeaderTab,
      layout: this.$store.getters.appLayout,
      AppLayout: AppLayout,
    };
  },
  methods: {
    handleTabSelection(selectedTab: string) {
      this.currentTab = selectedTab;
    },
  },
});
</script>
