import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_CandidateItem = _resolveComponent("CandidateItem")!
  const _component_CustomerItem = _resolveComponent("CustomerItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "reminder-bell",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDialog && _ctx.openDialog(...args)))
    }, [
      (_ctx.globalExpiredReminders.length > 0)
        ? (_openBlock(), _createBlock(_component_v_badge, {
            key: 0,
            color: "abort",
            content: _ctx.globalExpiredReminders.length,
            overlap: "",
            "offset-x": "0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_v_icon, {
                  size: "small",
                  onMouseover: _ctx.showTooltip,
                  onMouseleave: _ctx.hideTooltip
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" fa-solid fa-bell ")
                  ])),
                  _: 1
                }, 8, ["onMouseover", "onMouseleave"])
              ])
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true),
      (_ctx.globalExpiredReminders.length === 0)
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 1,
            size: "small"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" fa-solid fa-bell ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { class: "text-medium-emphasis mr-2" }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("fa-solid fa-bell")
                  ])),
                  _: 1
                }),
                _cache[9] || (_cache[9] = _createTextVNode("Offene Ereignisse ")),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false)),
                  icon: "",
                  size: "s",
                  variant: "text",
                  density: "compact"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" fa-solid fa-xmark close-icon ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_divider, { class: "mx-4 mt-10" }),
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.globalExpiredReminders, (reminder, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, { class: "d-flex justify-space-between" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createTextVNode(_toDisplayString(_ctx.getReminderListTitle(reminder)) + " ", 1),
                            _createElementVNode("strong", null, "(" + _toDisplayString(reminder.message) + ")", 1)
                          ]),
                          _createElementVNode("div", null, [
                            (reminder.linkingCore?.customer)
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 0,
                                  icon: "",
                                  density: "compact",
                                  variant: "text",
                                  onClick: ($event: any) => (_ctx.openCustomer(reminder.linkingCore?.customer))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                        _createTextVNode("fa-solid fa-building")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                                        _createTextVNode("Kundenkarte öffnen")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            (reminder.linkingCore?.candidate)
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 1,
                                  icon: "",
                                  density: "compact",
                                  variant: "text",
                                  onClick: ($event: any) => (_ctx.openCandidate(reminder.linkingCore?.candidate))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode("fa-solid fa-id-badge")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode("Kandidatenkarte öffnen")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_list_item_subtitle, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(reminder.event.eventType) + " " + _toDisplayString(_ctx.formatDate(reminder.event.eventDate[0])) + " abgelaufen " + _toDisplayString(reminder.expiredSince), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_divider, { class: "mt-2" })
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)',
      modelValue: _ctx.showCandidateItem,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showCandidateItem) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CandidateItem, {
          candidate: _ctx.clickedCandidate,
          candidateDataInput: _ctx.clickedCandidateData,
          interComponentMessage: _ctx.interComponentMessage,
          isActive: false,
          isFullyCollapsed: false,
          softwareIntegration: _ctx.softwareIntegration,
          lastUpdateTimeline: _ctx.lastUpdateTimeline,
          user: _ctx.user,
          onCloseDialog: _ctx.closeCandidateOrCustomerItem
        }, null, 8, ["candidate", "candidateDataInput", "interComponentMessage", "softwareIntegration", "lastUpdateTimeline", "user", "onCloseDialog"])
      ]),
      _: 1
    }, 8, ["max-width", "modelValue"]),
    _createVNode(_component_v_dialog, {
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)',
      modelValue: _ctx.showCustomerItem,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showCustomerItem) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CustomerItem, {
          customer: _ctx.clickedCustomer,
          interComponentMessage: _ctx.interComponentMessage,
          isActive: false,
          isFullyCollapsed: false,
          isOpenedAsDialog: true,
          lastUpdateTimeline: _ctx.lastUpdateTimeline,
          loggedInMandantUuids: _ctx.loggedInMandantUuids,
          mandants: _ctx.mandants,
          softwareIntegration: _ctx.softwareIntegration,
          user: _ctx.user,
          onCloseDialog: _ctx.closeCandidateOrCustomerItem
        }, null, 8, ["customer", "interComponentMessage", "lastUpdateTimeline", "loggedInMandantUuids", "mandants", "softwareIntegration", "user", "onCloseDialog"])
      ]),
      _: 1
    }, 8, ["max-width", "modelValue"])
  ], 64))
}