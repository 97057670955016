import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ErrorChip = _resolveComponent("ErrorChip")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "ma-3" }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" Passwort ändern ")
            ])),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                modelValue: _ctx.passwordValid,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.passwordValid) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.password.oldPassword,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password.oldPassword) = $event)),
                      _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('dirty')))
                    ],
                    label: "Altes Passwort",
                    type: "password",
                    rules: [_ctx.rules.required]
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.password.newPassword,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password.newPassword) = $event)),
                      _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('dirty')))
                    ],
                    label: "Neues Passwort",
                    type: "password",
                    rules: [_ctx.rules.required, _ctx.rules.min(8)]
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.password.confirmPassword,
                    "onUpdate:modelValue": [
                      _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password.confirmPassword) = $event)),
                      _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('dirty')))
                    ],
                    label: "Passwort bestätigen",
                    type: "password",
                    rules: [
              _ctx.rules.required,
              () =>
                _ctx.password.newPassword === _ctx.password.confirmPassword ||
                'Passwörter stimmen nicht überein',
            ]
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                  _createVNode(_component_ErrorChip, { label: _ctx.requestError }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "abort",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('cancel')))
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Abbrechen")
                ])),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "green-darken-4",
                onClick: _ctx.save
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Speichern")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["max-width"]))
}