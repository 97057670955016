import { MutationType } from "../enums/vuex-types.enum";
import store from "../store/store";

export class RequestLimiter {
  private static instance: RequestLimiter;
  private maxConcurrentRequests: number;
  private queue: (() => Promise<void>)[];

  private constructor(maxConcurrentRequests: number) {
    this.maxConcurrentRequests = maxConcurrentRequests;
    this.queue = [];
  }

  static getInstance(maxConcurrentRequests = 10): RequestLimiter {
    if (!RequestLimiter.instance) {
      RequestLimiter.instance = new RequestLimiter(maxConcurrentRequests);
    }
    return RequestLimiter.instance;
  }

  async makeRequest<T>(requestFn: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.queue.push(async () => {
        try {
          this.incrementActiveRequests();
          const result = await requestFn();
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          this.decrementActiveRequests();
          setTimeout(() => {
            this.processQueue();
          }, 500);
        }
      });

      this.processQueue();
    });
  }

  private processQueue() {
    const activeRequests = this.getActiveRequests();
    if (activeRequests < this.maxConcurrentRequests && this.queue.length > 0) {
      const nextRequest = this.queue.shift();
      if (nextRequest) nextRequest();
    }
  }

  private incrementActiveRequests() {
    const current = this.getActiveRequests();
    this.setActiveRequests(current + 1);
  }

  private decrementActiveRequests() {
    const current = this.getActiveRequests();
    this.setActiveRequests(Math.max(current - 1, 0)); // negative values are not allowed
  }

  private getActiveRequests(): number {
    return store.state.activeRequestsForLimiter || 0;
  }

  private setActiveRequests(value: number) {
    store.commit(MutationType.setActiveRequests, value);
  }
}
