// src/services/api-integration-one.adapter.ts

import {
  Customer,
  CustomerContact,
  CustomerList,
} from "../models/customer.model";
import { Mandant } from "../models/mandant.model";
import store from "../store/store";
import {
  ContactStates,
  CustomerStates,
  getCustomerStateValue,
  getCustomerStates,
} from "../enums/customer-states.enum";
import {
  ZvooveAddress,
  ZvooveAssignmentCustomer,
  ZvooveAssignmentEmployee,
  ZvooveCustomer,
  ZvooveEmployee,
  ZvoovePayrollDetails,
} from "@/models/external/zvoove-one.model";
import {
  Employee,
  EmployeeList,
  Address,
  DSGVO,
  FormerEmployeeNumbers,
} from "@/models/employee.model";
import { ZvooveCandidate } from "@/models/external/zvoove-candidate.model";
import {
  Application,
  Avatar,
  Candidate,
  CandidateAddress,
  CareerStep,
  CommunicationMeans,
  File,
  Profile,
  Skill,
} from "@/models/candidate.model";
import { CandidateList } from "@/models/candidate-list.model";
import { Salutation } from "@/enums/salutation.enum";
import moment from "moment";
import { CommunicationType } from "@/enums/communication-types.enum";
import { MaritalStatus } from "@/enums/marital-status.enum";
import { Mobility, MobilityIcon } from "@/enums/mobilities.enum";
import {
  ShiftPreferences,
  ShiftPreferencesIcon,
} from "@/enums/shift-preferences.enum";
import { ZvooveRecruitSkillGroupName } from "@/enums/external/zvoove-recruit.enum";
import { BankAccount } from "@/models/payflow.model";
import { PayrollDeduction } from "@/enums/payflow.enum";
import { Assignment } from "@/models/assignment.model";
import { ZvooveCandidateList } from "@/models/external/zvoove-candidate-list.model";

export function zvooveCandidateToCandidate(
  apiData: ZvooveCandidate,
  BewerbungID: string,
  BewerbungUuid: string
): Candidate {
  const candidate: Candidate = {
    uuid: apiData.ObjectUuid,
    addressHouseNumber: apiData.AdresseHausnummer,
    addressCountry: apiData.AdresseLand?.Bezeichnung,
    additionalAddresses: apiData.Adressen.map(
      (address: any) =>
        ({
          description: address.Bezeichnung,
          houseNumber: address.Hausnummer,
          country: address.Land?.Bezeichnung,
          city: address.Ort,
          postalCode: address.Plz,
          street: address.Strasse,
        } as CandidateAddress)
    ),
    addressCity: apiData.AdresseOrt,
    addressPostalCode: apiData.AdressePlz,
    addressStreet: apiData.AdresseStrasse,
    workPermitUntil: apiData.ArbeitserlaubnisBis,
    residencePermitUntil: apiData.AufenthaltserlaubnisBis,
    avatar: {
      uuid: apiData.Avatar?.ObjectUuid,
      mimeType: apiData.Avatar?.MimeType,
    } as Avatar,
    applications: apiData.Bewerbungen.map(
      (application: any) =>
        ({
          applicationId:
            application.ObjectUuid === BewerbungUuid ? BewerbungID : undefined,
          uuid: application.ObjectUuid,
          workingHours: application.Arbeitsstunden,
          workingHoursUntil: application.ArbeitsstundenBis,
          workingHoursPeriod: application.ArbeitsstundenZeitraum?.Bezeichnung,
          lastEditorName: application.Bearbeiter?.[0]?.Bezeichnung,
          applicationDate: application.Bewerbungsdatum,
          appliedAs: application.BeworbenAls,
          dataPrivacyDeadline: application.Datenschutzfaelligkeitsdatum,
          dataStorageDeclined: application.DatenspeicherungAbgelehnt,
          dataStorageGrantedBy: application.DatenspeicherungErteiltVon,
          inputMedium: application.Eingangsmedium?.Bezeichnung,
          operationalRadius: application.Einsatzradius,
          entryFrom: application.EintrittAb,
          questions: application.Fragen.map((question: any) => ({
            answer: question.Antwort,
            question: question.Frage,
            sortOrder: question.SortOrder,
          })),
          salary: application.Gehalt,
          salaryCurrency: application.GehaltWaehrung?.Bezeichnung,
          salaryPeriod: application.GehaltZeitraum?.Bezeichnung,
          mandantUuid: application.MandantUuid,
          source: application.Quelle?.Bezeichnung,
          ats: {
            statusUuid: application.Status?.ObjectUuid,
            status: application.Status?.Bezeichnung,
            internalStatusSystemName:
              application.Status?.InternalStatusSystemName,
          },
          status: application.Status?.Bezeichnung,
          documentsReturnShipping: application.UnterlagenRueckversand,
          availableUntil: application.VerfuegbarBis,
        } as Application)
    ),
    files: apiData.Dateien.map(
      (file: any) =>
        ({
          uuid: file.ObjectUuid,
          fileSize: file.Dateigroesse,
          fileName: file.Dateiname,
          mimeType: file.MimeType,
        } as File)
    ),
    maritalStatus: apiData.Familienstand?.Bezeichnung,
    birthDate: apiData.Geburtsdatum,
    birthName: apiData.Geburtsname,
    birthPlace: apiData.Geburtsort,
    isEmbargoChecked: apiData.IsEmbargoChecked,
    communicationMeans: apiData.Kommunikationsmittel.map(
      (means: any) =>
        ({
          description: means.Beschreibung,
          type: means.Typ,
          value: means.Wert,
        } as CommunicationMeans)
    ),
    employeeStatus: apiData.MitarbeiterStatus,
    lastName: apiData.Nachname,
    namePrefix: apiData.Namensvorsatz,
    nameSuffix: apiData.Namenszusatz,
    nationality: apiData.Nationalitaet?.Bezeichnung,
    profiles: apiData.Profile.map(
      (profile: any) =>
        ({
          description: profile.Bezeichnung,
          uuid: profile.ObjectUuid,
        } as Profile)
    ),
    isPublishedOnWebsite: apiData.IsPublishedOnWebseite,
    salutationCatalogId: apiData.SalutationCatalogId,
    skills: Array.from(
      new Map(
        apiData.Skills.map((skill: any) => [
          skill.Skill?.Bezeichnung?.trim().toLowerCase(),
          {
            type: skill.Art,
            level: skill.Auspraegung,
            description: skill.Skill?.Bezeichnung,
            groupDescription: skill.Skill?.Grp?.Bezeichnung,
          } as Skill,
        ])
      ).values() // Extract only the unique values
    ),

    title: apiData.Titel,
    firstName: apiData.Vorname,
    otherFirstNames: apiData.WeitereVornamen,
    careerSteps: apiData.Werdegaenge.map(
      (careerStep: any) =>
        ({
          degree: careerStep.Abschluss,
          at: careerStep.Bei,
          until: careerStep.Bis,
          details: careerStep.Details,
          title: careerStep.Titel,
          type: {
            description: careerStep.Typ?.Bezeichnung,
            systemName: careerStep.Typ?.SystemName,
            sortOrder: careerStep.Typ?.SortOrder,
          },
          from: careerStep.Von,
        } as CareerStep)
    ),
    zvooveEmployeeObjectId: apiData.ObjectUuid,
    mobility: Mobility.PublicTransport,
    mobilityIcon: MobilityIcon.Train,
    mobilityRadius: 0,
    shiftIcon: ShiftPreferencesIcon.None,
  } as any;

  // Extract mobility, mobilityIcon, mobilityRadius and shiftIcon from ATS Skills
  extractMobilityDetails(candidate);
  extractShiftPreferences(candidate);

  return candidate;
}

function extractMobilityDetails(candidate: Candidate) {
  candidate.skills.forEach((skill) => {
    if (skill.groupDescription === ZvooveRecruitSkillGroupName.Mobilities) {
      if (
        skill.description.includes(Mobility.Car) ||
        skill.description.includes(Mobility.OwnCar)
      ) {
        candidate.mobilityIcon = MobilityIcon.Car;
        candidate.mobility = Mobility.Car;
      }
      if (skill.description.includes("Mobil bis")) {
        const radius = skill.description.match(/\d+/);
        if (radius) {
          candidate.mobilityRadius = parseInt(radius[0], 10);
        }
      }
    }
  });
}

function extractShiftPreferences(candidate: Candidate) {
  candidate.skills.forEach((skill) => {
    if (
      skill.groupDescription === ZvooveRecruitSkillGroupName.ShiftPreference
    ) {
      const bezeichnung = skill.description;
      if (bezeichnung.includes(ShiftPreferences.TwoShift)) {
        candidate.shiftIcon = ShiftPreferencesIcon.TwoShift;
        candidate.shiftPreference = ShiftPreferences.TwoShift;
      } else if (bezeichnung.includes(ShiftPreferences.ThreeShift)) {
        candidate.shiftIcon = ShiftPreferencesIcon.ThreeShift;
        candidate.shiftPreference = ShiftPreferences.ThreeShift;
      } else if (bezeichnung.includes(ShiftPreferences.FourShift)) {
        candidate.shiftIcon = ShiftPreferencesIcon.FourShift;
        candidate.shiftPreference = ShiftPreferences.FourShift;
      } else if (bezeichnung.includes(ShiftPreferences.FiveShift)) {
        candidate.shiftIcon = ShiftPreferencesIcon.FiveShift;
        candidate.shiftPreference = ShiftPreferences.FiveShift;
      } else if (bezeichnung.includes(ShiftPreferences.Morning)) {
        candidate.shiftIcon = ShiftPreferencesIcon.Morning;
        candidate.shiftPreference = ShiftPreferences.Morning;
      } else if (bezeichnung.includes(ShiftPreferences.Afternoon)) {
        candidate.shiftIcon = ShiftPreferencesIcon.Afternoon;
        candidate.shiftPreference = ShiftPreferences.Afternoon;
      } else if (bezeichnung.includes(ShiftPreferences.DayShift)) {
        candidate.shiftIcon = ShiftPreferencesIcon.DayShift;
        candidate.shiftPreference = ShiftPreferences.DayShift;
      } else if (bezeichnung.includes(ShiftPreferences.PermanentNightShift)) {
        candidate.shiftIcon = ShiftPreferencesIcon.PermanentNightShift;
        candidate.shiftPreference = ShiftPreferences.PermanentNightShift;
      }
    }
  });
}

export function extractCandidatePhoneNumbers(data: CommunicationMeans[]) {
  const filteredPhoneNumbers =
    data
      ?.filter(
        (c: CommunicationMeans) =>
          c.type === CommunicationType.Phone ||
          c.type === CommunicationType.Mobile ||
          c.type === CommunicationType.WorkMobile ||
          c.type === CommunicationType.WhatsApp
      )
      .map((c: CommunicationMeans) => c.value.replace(/ /g, "")) || [];

  const phone1 = filteredPhoneNumbers[0] || "";
  const phone2 = filteredPhoneNumbers[1] || "";

  return {
    phone1,
    phone2,
  };
}

export function extractCandidateEmail(data: CommunicationMeans[]) {
  const filteredEmails =
    data
      ?.filter((c: CommunicationMeans) => c.type === CommunicationType.Email)
      .map((c: CommunicationMeans) => c.value) || [];
  return filteredEmails[0] || "";
}

function getMaritalStatusValue(status: MaritalStatus) {
  const statusValue =
    MaritalStatus[status as unknown as keyof typeof MaritalStatus];
  return typeof statusValue === "number" ? statusValue : undefined;
}

export function zvooveCandidateToNewZvooveOneEmployee(
  candidateData: Candidate,
  mandantUuid: string
) {
  const branchNumber = store.getters.getMandantBranchNumberByUuid(mandantUuid);

  const salutation = Salutation[candidateData.salutationCatalogId];
  let gender = 1;
  if (
    candidateData.salutationCatalogId === 2 ||
    candidateData.salutationCatalogId === 3 ||
    candidateData.salutationCatalogId === 4
  )
    gender = candidateData.salutationCatalogId;
  let formatedBirthdate = "";

  const { phone1, phone2 } = extractCandidatePhoneNumbers(
    candidateData.communicationMeans
  );

  const email = extractCandidateEmail(candidateData.communicationMeans);
  const maritalStatus = getMaritalStatusValue(candidateData.maritalStatus);

  if (candidateData.birthDate)
    formatedBirthdate = moment(candidateData.birthDate).format("YYYY-MM-DD");
  let letterSalutation = `Guten Tag ${candidateData.firstName} ${candidateData.lastName}`;
  if (gender === 1)
    letterSalutation = `Sehr geehrter Herr ${candidateData.lastName}`;
  if (gender === 2)
    letterSalutation = `Sehr geehrte Frau ${candidateData.lastName}`;

  const newEmployee = {
    vorname: candidateData.firstName,
    nachname: candidateData.lastName,
    anrede: salutation,
    briefanrede: letterSalutation,
    geschaeftsstelleId: branchNumber,
    geburtsdatum: formatedBirthdate,
    geburtsort: candidateData.birthPlace ?? "",
    geburtsname: candidateData.birthName ?? "",
    geschlecht: gender,
    familienstand: maritalStatus,
    adresse: {
      strasse: `${candidateData.addressStreet ?? ""} ${
        candidateData.addressHouseNumber ?? ""
      }`,
      plz: candidateData.addressPostalCode ?? "",
      ort: candidateData.addressCity ?? "",
      telefon1: phone1,
      telefon2: phone2,
    },
    email: email,
    kandidatGuid: candidateData.uuid,
  };
  return newEmployee;
}
export function zvooveCustomerToCustomer(data: ZvooveCustomer): Customer {
  const mandants = store.state.company.mandants;
  const geschaeftsstelleId = data.geschaeftsstelleId.toString();
  const matchedMandant = mandants.find(
    (mandant: Mandant) => mandant.branchNumber === geschaeftsstelleId
  );
  const customerStates = getCustomerStates(CustomerStates);
  const statusFromEnum =
    customerStates[data.status] || customerStates[CustomerStates.unknown];

  return {
    customerNumber: data.id,
    mandants: matchedMandant ? [matchedMandant.uuid] : [""],
    generalData: {
      name: data.name,
      name2: data.name2,
      name3: data.name3,
      searchTerm: data.suchbegriff,
      costCenter: data.kostenstelle ? data.kostenstelle.toString() : "",
      customerSince: data.anlagedatum,
      accountsReceivable: data.debitorkonto ? data.debitorkonto.toString() : "",
      createdOn: data.anlagedatum,
      status: statusFromEnum,
    },
    addressAndCommunication: {
      street: data.adresse?.strasse ?? "",
      postalCode: data.adresse?.plz ?? "",
      city: data.adresse?.ort ?? "",
      phone1: data.adresse?.telefon1 ?? "",
      phone2: data.adresse?.telefon2 ?? "",
      email: data.adresse?.email ?? "",
      website: data.adresse?.homepage ?? "",
      country: data.adresse?.staatId ?? "",
      state: data.adresse?.bundeslandId ?? "",
    },
    furtherInformation: {
      dispatcher: data.disponentId,
      industries: data.branchenIds,
    },
    additionalInfo: {
      info1: data.info ?? "",
      info2: data.info2 ?? "",
      info3: data.info3 ?? "",
    },
    tags: [],
    reporting: [],
    contacts: [],
    jobAds: [],
  };
}
export function getBewerbungenFilteredToCandidateList(
  apiData: ZvooveCandidateList[],
  selectedMandants?: string[]
) {
  let mandants = store.state.company.loggedInUser.config.mandants;
  if (selectedMandants && selectedMandants.length > 0)
    mandants = selectedMandants;
  return apiData
    .map((data: ZvooveCandidateList) => {
      const mandantUuid = store.getters.getMandantUuidByZvoovename(data.Firma);
      if (mandantUuid && mandants.includes(mandantUuid)) {
        return {
          applicationId: data.BewerbungID,
          applicationUuid: data.BewerbungUuid,
          applicationDate: data.Bewerbungsdatum,
          mandants: [mandantUuid],
          status: data.Status,
          candidateUuid: data.MitarbeiterUuid,
        } as CandidateList;
      } else {
        return undefined;
      }
    })
    .filter((item) => item !== undefined);
}
export function transformToCustomerList(data: any): CustomerList {
  return {
    customerNumber: data.id,
    name: data.name,
    address: `${data.adresse.strasse} ${data.adresse.plz} ${data.adresse.ort}`,
  };
}
export function transformContact(data: any): CustomerContact {
  const statusFromEnum = ContactStates[data.status] || "Unbekannt";
  return {
    zvooveId: data.id,
    salutation: data.anrede,
    firstName: data.vorname,
    lastName: data.nachname,
    phone: data.telefon1,
    email: data.email,
    status: statusFromEnum,
    title: data.titel,
    role: data.position,
  };
}
export function customerToZvooveCustomer(customer: Customer): ZvooveCustomer {
  let branchId = store.getters.getLoggedInMandantBranchNumbers[0].toString();
  if (customer.mandants.length > 0)
    branchId = store.getters.getBranchNumberByUuid(customer.mandants[0]); //customer has one mandant
  const {
    generalData,
    addressAndCommunication,
    furtherInformation,
    additionalInfo,
  } = customer;

  if (
    addressAndCommunication.street &&
    addressAndCommunication.postalCode &&
    addressAndCommunication.city
  ) {
    const adresse: ZvooveAddress = {
      strasse: addressAndCommunication.street,
      plz: addressAndCommunication.postalCode,
      ort: addressAndCommunication.city,
      telefon1: addressAndCommunication.phone1,
      telefon2: addressAndCommunication.phone2,
      email: addressAndCommunication.email,
    };

    const zvooveCustomer: ZvooveCustomer = {
      name: generalData.name.substring(0, 25),
      suchbegriff: generalData.searchTerm.substring(0, 25),
      geschaeftsstelleId: branchId,
      status: getCustomerStateValue(generalData.status) || 1,
      adresse,
      id: "",
      debitorkonto: 0,
      feiertagskalenderId: "",
      kundeSeit: "",
      anlagedatum: "",
      rechnungsintervall: 0,
      fremdId: "",
      handelsregisterNummer: "",
      kreditLimit: 0,
      kreditDatum: "",
    };

    if (generalData.name2) zvooveCustomer.name2 = generalData.name2;
    if (generalData.name3) zvooveCustomer.name3 = generalData.name3;
    if (generalData.costCenter)
      zvooveCustomer.kostenstelle = parseInt(generalData.costCenter);
    if (furtherInformation.dispatcher)
      zvooveCustomer.disponentId = furtherInformation.dispatcher;
    if (additionalInfo.info1) zvooveCustomer.info = additionalInfo.info1;
    if (additionalInfo.info2) zvooveCustomer.info2 = additionalInfo.info2;
    if (additionalInfo.info3) zvooveCustomer.info3 = additionalInfo.info3;

    return zvooveCustomer;
  } else {
    throw new Error("Required address fields are missing");
  }
}
export function zvooveOneEmployeeToEmployee(apiData: ZvooveEmployee) {
  return {
    address: {
      city: apiData.adresse.ort ?? "",
      countryId: apiData.adresse.staatId ?? "",
      phone1: apiData.adresse.telefon1 ?? "",
      phone2: apiData.adresse.telefon2 ?? "",
      postalCode: apiData.adresse.plz ?? "",
      stateId: apiData.adresse.bundeslandId ?? "",
      street: apiData.adresse.strasse ?? "",
    } as Address,
    appActivation: apiData.appfreischaltung,
    birthDate: apiData.geburtsdatum,
    birthName: apiData.geburtsname,
    birthPlace: apiData.geburtsort,
    branchOfficeId: apiData.geschaeftsstelleId,
    candidateUuid: apiData.kandidatGuid,
    creationDate: apiData.anlagedatum,
    costCenter: apiData.kostenstelle,
    dataPrivacyConsents: apiData.datenschutzeinwilligungen.map(
      (consent: any) => ({
        id: consent.id,
        validUntil: consent.validUntil,
      })
    ) as DSGVO[],
    disponentId: apiData.disponentId,
    employedAsJobId: apiData.eingestelltAlsBerufId,
    employeeNumber: apiData.id,
    employeeStatus: apiData.personalstatus,
    email: apiData.email,
    entryDate: apiData.eintrittsdatum,
    exitDate: apiData.austrittsdatum,
    firstName: apiData.vorname,
    foreignPayrollNumber: apiData.fremdlohnnummer,
    formerEmployeeNumbers: apiData.ehemaligePersonalnummern.map(
      (number: any) => ({
        personalId: number.personalId,
        entryDate: number.eintrittsdatum,
        exitDate: number.austrittsdatum,
      })
    ) as FormerEmployeeNumbers[],
    gender: apiData.geschlecht,
    lastName: apiData.nachname,
    letterSalutation: apiData.briefanrede,
    mandants: [
      store.getters.getMandantByBranchNumber(apiData.geschaeftsstelleId),
    ],
    maritalStatus: apiData.familienstand,
    nationalityId: apiData.staatsangehoerigkeitId,
    probationEnd: apiData.probezeitEnde,
    salary: apiData.lohnGehalt,
    salutation: apiData.anrede,
    technicalEntryDate: apiData.technischesEintrittsdatum,
    title: apiData.titel,
    unlimitedResidencePermit: apiData.unbeschraenkteAufenthaltserlaubnis,
    residencePermitExpiration: apiData.ablaufAufenthaltserlaubnis,
    workPermitExpiration: apiData.ablaufErwerbstaetigkeitserlaubnis,
  } as Employee;
}
export function zvooveOnePayrollDetailsToBankAccount(
  apiData: ZvoovePayrollDetails
) {
  return {
    differentAccountHolder: apiData.bankverbindung.abweichenderKontoinhaber,
    iban: apiData.bankverbindung.iban,
    bank: {
      bic: apiData.bankverbindung.bank.bic,
      name: apiData.bankverbindung.bank.name,
    },
  } as BankAccount;
}
export function employeeToZvooveOneEmployee(employee: Employee) {
  return {
    ablaufAufenthaltserlaubnis: employee.residencePermitExpiration,
    ablaufErwerbstaetigkeitserlaubnis: employee.workPermitExpiration,
    adresse: {
      bundeslandId: employee.address.stateId,
      ort: employee.address.city,
      plz: employee.address.postalCode,
      staatId: employee.address.countryId,
      strasse: employee.address.street,
      telefon1: employee.address.phone1,
      telefon2: employee.address.phone2,
    },
    anrede: employee.salutation,
    anlagedatum: employee.creationDate,
    appfreischaltung: employee.appActivation,
    austrittsdatum: employee.exitDate,
    briefanrede: employee.letterSalutation,
    disponentId: employee.disponentId,
    eingestelltAlsBerufId: employee.employedAsJobId,
    eintrittsdatum: employee.entryDate,
    email: employee.email,
    familienstand: employee.maritalStatus,
    fremdlohnnummer: employee.foreignPayrollNumber,
    geburtsdatum: employee.birthDate,
    geburtsname: employee.birthName,
    geburtsort: employee.birthPlace,
    geschaeftsstelleId: employee.branchOfficeId,
    geschlecht: employee.gender,
    id: employee.employeeNumber,
    kandidatGuid: employee.candidateUuid,
    kostenstelle: employee.costCenter,
    lohnGehalt: employee.salary,
    nachname: employee.lastName,
    personalstatus: employee.employeeStatus,
    probezeitEnde: employee.probationEnd,
    staatsangehoerigkeitId: employee.nationalityId,
    technischesEintrittsdatum: employee.technicalEntryDate,
    titel: employee.title,
    unbeschraenkteAufenthaltserlaubnis: employee.unlimitedResidencePermit,
    datenschutzeinwilligungen: employee.dataPrivacyConsents.map(
      (consent: DSGVO) => ({
        id: consent.id,
        validUntil: consent.validUntil,
      })
    ),
    ehemaligePersonalnummern: employee.formerEmployeeNumbers.map(
      (number: FormerEmployeeNumbers) => ({
        personalId: number.personalId,
        eintrittsdatum: number.entryDate,
        austrittsdatum: number.exitDate,
      })
    ),
  };
}
export function zvooveEmployeeAssingmentsToAssignments(
  apiResponse: ZvooveAssignmentEmployee[]
) {
  return apiResponse.map(
    (placement: any) =>
      ({
        assignmentId: placement.auftragId,
        positionId: placement.positionId,
        leaseId: placement.ueberlassungId,
        clientName: placement.kundenname,
        workLocation: placement.einsatzort,
        from: placement.von,
        to: placement.bis,
        jobTitle: placement.berufsbezeichnung,
        billingRate: placement.verrechnungssatz,
        tariffWage: placement.tariflohn,
        aboveTariffAllowance: placement.uebertariflicheZulage,
      } as Assignment)
  );
}
export function zvooveCustomerAssingmentsToAssignments(
  apiResponse: ZvooveAssignmentCustomer[],
  isNoTimeFilter?: boolean
): Assignment[] {
  return apiResponse
    .filter((placement) => {
      if (!isNoTimeFilter) {
        //only present assigments
        const maxMonthsAgo = moment().subtract(3, "months");
        return (
          !placement.bis || moment(placement.bis).isSameOrAfter(maxMonthsAgo)
        );
      }
      return true;
    })
    .map(
      (placement: any) =>
        ({
          aboveTariffAllowance: placement.uebertariflicheZulage,
          assignmentId: placement.auftragId,
          billingRate: placement.verrechnungssatz,
          from: placement.von,
          jobTitle: placement.berufsbezeichnung,
          leaseId: placement.ueberlassungId,
          tariffWage: placement.tariflohn,
          to: placement.bis,
          workLocation: placement.einsatzort,
          firstName: placement.vorname,
          lastName: placement.nachname,
          positionId: placement.positionId,
          presenceStatus: placement.anwesenheitsstatus,
        } as Assignment)
    );
}
export function payrollDeductionToZvoovePayrollDeduction(
  payload: PayrollDeduction
) {
  return {
    abzugartId: payload.adjustmentTypeId.toString(),
    art: payload.type,
    abrechnungszeitraumVon: payload.payrollPeriodFrom,
    abrechnungszeitraumBis: payload.payrollPeriodTo,
    intervall: "P1M",
    betrag: payload.amount,
    sachkonto: payload.accountNumber,
    info: payload.info,
  };
}
export function transformToEmployeeList(data: any): EmployeeList {
  return {
    employeeNumber: data.id,
    name: `${data.vorname} ${data.nachname}`,
    address: `${data.adresse.strasse} ${data.adresse.plz} ${data.adresse.ort}`,
    status: data.personalstatus,
  } as EmployeeList;
}
