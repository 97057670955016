import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_Recurion = _resolveComponent("Recurion")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slide-menu-container ma-0 pa-0", { 'is-hidden': !_ctx.isClosed }]),
    style: _normalizeStyle({ width: _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)' })
  }, [
    (!_ctx.mdAndDown || !_ctx.isClosed)
      ? (_openBlock(), _createBlock(_component_v_sheet, {
          key: 0,
          onClick: _ctx.toggleDrawer,
          color: _ctx.isClosed ? 'primary-darken-1' : 'primary',
          style: _normalizeStyle({
        top: _ctx.getOpenButtonTopPosition().top,
        height: _ctx.getOpenButtonTopPosition().height,
      }),
          class: _normalizeClass(["d-flex justify-center align-center slide-menu-open-button", {
        'slide-menu-open-button-open': !_ctx.isClosed,
        'slide-menu-open-button-closed': _ctx.isClosed,
      }])
        }, {
          default: _withCtx(() => [
            (_ctx.isGenerating)
              ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                  key: 0,
                  indeterminate: "",
                  color: "white",
                  size: "24"
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  _createVNode(_component_v_icon, {
                    size: _ctx.getOpenButtonTopPosition().iconSize,
                    class: "open-close-arrow"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getIconConfig().icon), 1)
                    ]),
                    _: 1
                  }, 8, ["size"]),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "right"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getIconConfig().tooltip), 1)
                    ]),
                    _: 1
                  })
                ]))
          ]),
          _: 1
        }, 8, ["onClick", "color", "style", "class"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_card, {
      color: "primary",
      class: "ma-0 pl-10 rounded-e-lg"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { class: "mr-2" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("fa-solid fa-robot")
                  ])),
                  _: 1
                }),
                _cache[1] || (_cache[1] = _createTextVNode(" Recurion "))
              ]),
              _: 1
            }),
            _createVNode(_component_Recurion, { onIsGenerating: _ctx.updateGeneratingStatus }, null, 8, ["onIsGenerating"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 6))
}