import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.mdAndDown)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 0,
          icon: "",
          density: "compact",
          variant: "text",
          class: "toggle-button",
          onClick: _ctx.toggleExpansion
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isExpanded ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.mdAndDown)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 1,
          icon: "",
          density: "compact",
          variant: "text",
          class: "toggle-button"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("fa-solid fa-ellipsis-vertical")
              ])),
              _: 1
            }),
            _createVNode(_component_v_menu, { activator: "parent" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, { onClick: _ctx.toggleExpansion }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.isExpanded ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    (_ctx.showMaximize())
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 0,
                          onClick: _ctx.toggleFullExpansion
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode("fa-solid fa-maximize")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.showSendCandidate())
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 1,
                          onClick: _ctx.sendCandidate
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { class: "mx-1" }, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode("fa-solid fa-user-plus")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.showReceiveCandidate())
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 2,
                          onClick: _ctx.receiveCandidate
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { class: "mx-1" }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode("fa-solid fa-building-user")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_list_item, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openContextMenu($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("fa-solid fa-bars")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}