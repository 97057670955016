export enum TimelineEntryType {
  addition = "addition",
  candidateSuggestion = "candidateSuggestion",
  eMailCandidate = "emailCandidate",
  eMailCustomer = "eMailCustomer",
  eMailEmployee = "eMailEmployee",
  meetingInhouse = "meetingInhouse",
  meetingOutside = "meetingOutside",
  note = "note",
  phoneCallCandidate = "phoneCallCandidate",
  phoneCallCustomer = "phoneCallCustomer",
  phoneCallEmployee = "phoneCallEmployee",
  whatsAppCandidate = "whatsAppCandidate",
  whatsAppCustomer = "whatsAppCustomer",
  whatsAppEmployee = "whatsAppEmployee",
}

export enum PhoneCallOption {
  notReached = "nicht erreicht",
}
