// src/services/ai.service.ts
import store from "../store/store";
import ToastService from "./toast.service";
import { CareerStep } from "@/models/candidate.model";
import { AiCluster } from "./api/ai-cluster.service";
import { AiRequestObject, AiSpecs } from "../models/ai-specs.model";
import { AiMessageType, AiOrder } from "../enums/ai-options.enum";
import { SalesKPIs } from "../models/sales-kpi.model";
import { TimePeriod } from "../enums/time-period.enum";
import { Customer } from "../models/customer.model";
import { UserService } from "./api/user.service";
import { TimelineHelperService } from "./timeline-helper.service";
import {
  RecurionAnswerHandles,
  RecurionChatbotType,
  RecurionCoachingTimelineEntry,
} from "../enums/recurion-chatbot-types.enum";
import { MutationType } from "../enums/vuex-types.enum";
import { GitLabService } from "./api/gitlab.service";
import { GitLabLabels } from "../enums/gitlab-labels.enum";
import { IssueType } from "../enums/issue-type.enum";

export class AiService {
  private aiCluster: AiCluster;
  promptGlobal: any;

  constructor() {
    this.aiCluster = new AiCluster();
    this.promptGlobal = {
      anonymizedUserFirstName: "Horst",
      anonymizedUserLastName: "Lokal-Maier",
    };
  }

  async extractDemand(file?: File, text?: string) {
    const request: AiRequestObject = {
      aiOrder: AiOrder.extractDemand,
      parts: {
        prompt: text,
      },
    };

    try {
      const response = await this.aiCluster.clusterRequest(request, file);
      const responseText = response.data.response.trim();

      const jsonArrayMatch = responseText.match(/\[(.*)\]/s);
      if (jsonArrayMatch) {
        const jsonString = jsonArrayMatch[0];

        try {
          const parsedResponse = JSON.parse(jsonString);

          if (Array.isArray(parsedResponse)) {
            return parsedResponse;
          } else {
            throw new Error("Gefundener JSON-Inhalt ist kein gültiges Array.");
          }
        } catch (error) {
          console.error("Fehler beim Parsen des extrahierten JSON:", error);
          throw new Error("Extrahiertes JSON ist ungültig.");
        }
      }

      throw new Error("No valid JSON array found.");
    } catch (error) {
      console.error(
        "Fehler bei der Verarbeitung der Datei und des Prompts:",
        error
      );
      throw error;
    }
  }

  async extractWorktimes(file: File, customPrompt: string, specs: AiSpecs) {
    const request: AiRequestObject = {
      aiOrder: AiOrder.extractWorktimes,
      parts: {
        customPrompt: customPrompt,
      },
      aiCore: specs.core,
      aiModel: specs.model,
    };

    try {
      const response = await this.aiCluster.clusterRequest(request, file);
      const matches = response.data.response.match(/\[(.*)\]/s);
      if (matches && matches[0]) {
        const parsedResponse = JSON.parse(matches[0]);
        if (Array.isArray(parsedResponse) && parsedResponse.length > 0) {
          return parsedResponse;
        } else {
          throw new Error("AI-Antwort ist kein gültiges JSON-Array.");
        }
      }
    } catch (error) {
      console.error(
        "Fehler bei der Verarbeitung der Datei und des Prompts:",
        error
      );
      throw error;
    }
  }

  async generateDashboardText(salesKPIs: SalesKPIs, branchKPIs: any) {
    if (salesKPIs.error) return;
    const salesKPIstring = this.generateKPIText(salesKPIs);
    const request: AiRequestObject = {
      aiOrder: AiOrder.generateDashboardText,
      parts: {
        salesKPIs: salesKPIstring,
        branchKPIs: branchKPIs,
      },
    };

    try {
      const response = await this.aiCluster.clusterRequest(request);
      const matches = response.data.response.match(/{(.*?)}/s);
      if (matches && matches[1]) {
        let dashboardText = `{${matches[1]}}`;

        const firstNameRegex = new RegExp(
          this.promptGlobal.anonymizedUserFirstName,
          "g"
        );
        const lastNameRegex = new RegExp(
          this.promptGlobal.anonymizedUserLastName,
          "g"
        );

        dashboardText = dashboardText.replace(
          firstNameRegex,
          store.state.company.loggedInUser.forename
        );
        dashboardText = dashboardText.replace(
          lastNameRegex,
          store.state.company.loggedInUser.lastname
        );

        const dashboardData = JSON.parse(dashboardText);
        return dashboardData;
      } else {
        return null;
      }
    } catch (error) {
      console.warn("Fehler bei der Textgenerierung fürs Dashboard", error);
      throw error;
    }
  }

  generateKPIText(salesKPIs: SalesKPIs) {
    let result = "### Aktivitäten:\n\n";

    for (const [activity, periods] of Object.entries(salesKPIs)) {
      result += `1. **${activity}**:\n`;
      result += "   - Vom **User**:\n";
      result += `     - 24h: ${periods[TimePeriod.TwentyFourHours].user}\n`;
      result += `     - 7 Tage: ${periods[TimePeriod.SevenDays].user}\n`;
      result += `     - 30 Tage: ${periods[TimePeriod.ThirtyDays].user}\n`;
      result += "   - Von der **Niederlassung des Users**:\n";
      result += `     - 24h: ${periods[TimePeriod.TwentyFourHours].mandant}\n`;
      result += `     - 7 Tage: ${periods[TimePeriod.SevenDays].mandant}\n`;
      result += `     - 30 Tage: ${periods[TimePeriod.ThirtyDays].mandant}\n`;
      result += "   - Vom **gesamten Unternehmen**:\n";
      result += `     - 24h: ${periods[TimePeriod.TwentyFourHours].global}\n`;
      result += `     - 7 Tage: ${periods[TimePeriod.SevenDays].global}\n`;
      result += `     - 30 Tage: ${periods[TimePeriod.ThirtyDays].global}\n\n`;
    }

    return result;
  }

  async generateMessage(
    message: string,
    customPrompt: string,
    messageType: AiMessageType,
    GDPRsave: boolean
  ) {
    const request = {
      aiOrder: AiOrder.generateMessage,
      parts: {
        message: message,
        messageType: messageType,
        customPrompt: customPrompt,
      },
    } as AiRequestObject;

    if (GDPRsave) request.aiOrder = AiOrder.generateMessageGDPRsave;

    try {
      const response = await this.aiCluster.clusterRequest(request);
      return response.data.response;
    } catch (error) {
      console.error("Fehler bei der Generierung der Nachricht", error);
      throw error;
    }
  }

  async generateEmail(
    message: string,
    customPrompt: string,
    messageType: string,
    GDPRsave: boolean
  ) {
    const request = {
      aiOrder: AiOrder.generateEmail,
      parts: {
        message: message,
        messageType: messageType,
        customPrompt: customPrompt,
      },
    } as AiRequestObject;

    if (GDPRsave) request.aiOrder = AiOrder.generateEmailGDPRsave;

    try {
      const response = await this.aiCluster.clusterRequest(request);

      const matches = response.data.response.match(/{(.*?)}/s);
      if (matches && matches[1]) {
        const aiResponseText = `{${matches[1]}}`;

        const aiResponseData = JSON.parse(aiResponseText);
        return aiResponseData;
      } else {
        throw new Error(
          "Kein gültiges JSON-Format in der AI-Antwort gefunden."
        );
      }
    } catch (error) {
      console.error("Fehler beim generieren der E-mail:", error);
      throw error;
    }
  }

  async translateMessage(
    message: string,
    targetLanguage: string,
    GDPRsave: boolean
  ) {
    const request = {
      aiOrder: AiOrder.translateMessage,
      parts: {
        message: message,
        targetLanguage: targetLanguage,
      },
    } as AiRequestObject;

    if (GDPRsave) request.aiOrder = AiOrder.translateMessageGDPRsave;

    try {
      const response = await this.aiCluster.clusterRequest(request);

      return { text: response.data.response };
    } catch (error) {
      console.error("Fehler bei der Übersetzung:", error);
      throw error;
    }
  }

  async translateBugReport(
    reportTitle: string,
    reportText: string,
    reportTyp: string
  ) {
    const request = {
      aiOrder: AiOrder.translateBugReport,
      parts: {
        title: reportTitle,
        message: reportText,
        messageType: reportTyp,
      },
    } as AiRequestObject;

    try {
      const response = await this.aiCluster.clusterRequest(request);
      const jsonMatch = response.data.response.match(/{.*}/s);
      if (!jsonMatch) {
        throw new Error("no JSON format in ai anwer");
      }
      const jsonString = jsonMatch[0];
      const parsedResponse = JSON.parse(jsonString);

      if (
        typeof parsedResponse === "object" &&
        parsedResponse !== null &&
        "title" in parsedResponse
      ) {
        return parsedResponse;
      } else {
        throw new Error("ai answer in wrong format");
      }
    } catch (error) {
      console.error("Fehler bei der Übersetzung:", error);
      throw error;
    }
  }

  async generateCandidateTags(anonymizedResume: string) {
    const request = {
      aiOrder: AiOrder.generateCandidateTags,
      parts: {
        anonymizedResume: anonymizedResume,
      },
    } as AiRequestObject;

    try {
      const response = await this.aiCluster.clusterRequest(request);

      // Extract the response part that is expected to be in the exact array format
      const rawResponse = response.data.response;
      const startIndex = rawResponse.indexOf("[");
      const endIndex = rawResponse.lastIndexOf("]") + 1;
      const arrayResponse = rawResponse.substring(startIndex, endIndex);

      try {
        const parsedArray = JSON.parse(arrayResponse);
        if (Array.isArray(parsedArray)) {
          // Ensure that all elements are strings
          return parsedArray.filter((tag) => typeof tag === "string");
        }
        throw new Error("Parsed content is not an array.");
      } catch (parseError) {
        console.error("Error parsing the array response:", parseError);
        return [];
      }
    } catch (error) {
      console.error("Error generating candidate tags:", error);
      throw error;
    }
  }

  async generateCustomerTags(customerTagsData: string) {
    const request = {
      aiOrder: AiOrder.generateCustomerTags,
      parts: {
        data: customerTagsData,
      },
    } as AiRequestObject;

    try {
      const response = await this.aiCluster.clusterRequest(request);

      const rawResponse = response.data.response;
      const startIndex = rawResponse.indexOf("[");
      const endIndex = rawResponse.lastIndexOf("]") + 1;
      const arrayResponse = rawResponse.substring(startIndex, endIndex);

      try {
        const parsedArray = JSON.parse(arrayResponse);
        if (Array.isArray(parsedArray)) {
          return parsedArray.filter((tag) => typeof tag === "string");
        }
        throw new Error("Parsed content is not an array.");
      } catch (parseError) {
        console.error("Error parsing the array response:", parseError);
        return [];
      }
    } catch (error) {
      console.error("Error generating customer tags:", error);
      throw error;
    }
  }

  async extractCustomerData(dataInput: string) {
    const request = {
      aiOrder: AiOrder.extractCustomerData,
      parts: {
        data: dataInput,
      },
    } as AiRequestObject;

    try {
      const response = await this.aiCluster.clusterRequest(request);

      const jsonMatch = response.data.response.match(/{.*}/s);
      if (!jsonMatch) {
        throw new Error("no JSON format in ai answer");
      }
      const jsonString = jsonMatch[0];
      const parsedResponse = JSON.parse(jsonString);

      if (
        typeof parsedResponse === "object" &&
        parsedResponse !== null &&
        "customerNumber" in parsedResponse
      ) {
        return parsedResponse;
      } else {
        throw new Error("ai answer in wrong format");
      }
    } catch (error) {
      console.error("extracting Data Error:", error);
      throw error;
    }
  }

  extractCustomerContacts = async (dataInput: string) => {
    const request = {
      aiOrder: AiOrder.extractCustomerContacts,
      parts: {
        data: dataInput,
      },
    } as AiRequestObject;

    try {
      const response = await this.aiCluster.clusterRequest(request);

      const matches = response.data.response.match(/\[(.*)\]/s);
      if (matches && matches[0]) {
        const parsedResponse = JSON.parse(matches[0]);
        if (Array.isArray(parsedResponse) && parsedResponse.length > 0) {
          return parsedResponse;
        } else {
          throw new Error("AI-Antwort ist kein gültiges JSON-Array.");
        }
      } else {
        throw new Error(
          "Kein gültiges JSON-Format in der AI-Antwort gefunden."
        );
      }
    } catch (error) {
      console.error("Fehler beim Extrahieren der Daten:", error);
      throw error;
    }
  };

  async extractJobAdData(jobAdText: string) {
    const request = {
      aiOrder: AiOrder.extractJobAdData,
      parts: {
        data: jobAdText,
      },
    } as AiRequestObject;

    try {
      const response = await this.aiCluster.clusterRequest(request);
      const matches = response.data.response.match(/{(.*?)}/s);
      if (matches && matches[1]) {
        const parsedResponse = JSON.parse(`{${matches[1]}}`);
        return parsedResponse;
      } else {
        throw new Error(
          "Kein gültiges JSON-Format in der AI-Antwort gefunden."
        );
      }
    } catch (error) {
      console.error("Fehler beim Extrahieren der Daten:", error);
      throw error;
    }
  }

  async generateProfile(
    anonymizedResume: string,
    anonymizedNote: string,
    jobAdOrTags: string,
    basedOnTags?: boolean,
    customPrompt?: string
  ) {
    const request = {
      aiOrder: AiOrder.generateProfile,
      parts: {
        anonymizedResume: anonymizedResume,
        anonymizedNote: anonymizedNote,
        jobAdOrTags: jobAdOrTags,
        basedOnTags: basedOnTags,
        customPrompt: customPrompt,
      },
    } as AiRequestObject;

    const getAIResponse = async (): Promise<string> => {
      const response = await this.aiCluster.clusterRequest(request);
      return response.data.response;
    };

    const parseJSON = (responseContent: string): any => {
      const jsonStartIndex = responseContent.indexOf("{");
      const jsonEndIndex = responseContent.lastIndexOf("}");
      if (jsonStartIndex === -1 || jsonEndIndex === -1) {
        throw new Error(
          "Kein gültiges JSON-Format in der AI-Antwort gefunden."
        );
      }
      const jsonString = responseContent.substring(
        jsonStartIndex,
        jsonEndIndex + 1
      );
      return JSON.parse(jsonString);
    };

    const extractJSONWithRegex = (responseContent: string): any => {
      const jsonMatch = responseContent.match(/{.*}/s);
      if (!jsonMatch) {
        throw new Error(
          "Kein gültiges JSON-Format in der AI-Antwort gefunden."
        );
      }
      return JSON.parse(jsonMatch[0]);
    };

    for (let attempt = 0; attempt < 2; attempt++) {
      try {
        ToastService.showReminder(
          "die Profilgenierung wird noch ein wenig mehr Zeit in Anspruch nehmen ..."
        );
        const responseContent = await getAIResponse();
        try {
          return parseJSON(responseContent);
        } catch {
          return extractJSONWithRegex(responseContent);
        }
      } catch (error) {
        console.error(`Fehler beim Versuch ${attempt + 1}:`, error);
      }
    }

    try {
      const responseContent = await getAIResponse();
      for (let attempt = 2; attempt < 4; attempt++) {
        try {
          return parseJSON(responseContent);
        } catch {
          return extractJSONWithRegex(responseContent);
        }
      }
    } catch (error) {
      console.error("Fehler bei der erneuten AI-Abfrage:", error);
    }

    throw new Error("Fehler bei der Profilerstellung nach mehreren Versuchen.");
  }

  async generateEmailforProfile(
    salutation: string,
    anonymizedResume: string,
    anonymizedNote: string
  ) {
    const request = {
      aiOrder: AiOrder.generateEmailforProfile,
      parts: {
        salutation: salutation,
        anonymizedResume: anonymizedResume,
        anonymizedNote: anonymizedNote,
      },
    } as AiRequestObject;
    try {
      const response = await this.aiCluster.clusterRequest(request);

      const matches = response.data.response.match(/{(.*?)}/s);
      if (matches && matches[1]) {
        const profileMailData = JSON.parse(`{${matches[1]}}`);
        return profileMailData;
      }
    } catch (error) {
      console.error("Fehler bei der Mailgenerierung", error);
      throw error;
    }
  }

  async generateJobAdLeadsQuery(anonymizedResume: string) {
    const request = {
      aiOrder: AiOrder.generateJobAdLeadsQuery,
      parts: {
        anonymizedResume: anonymizedResume,
      },
    } as AiRequestObject;
    try {
      const response = await this.aiCluster.clusterRequest(request);

      const matches = response.data.response.match(/{(.*?)}/s);
      if (matches && matches[1]) {
        const jobLeadQuery = JSON.parse(`{${matches[1]}}`);
        return jobLeadQuery;
      }
    } catch (error) {
      console.error(
        "Fehler bei der Generierung des Index Anzeigedaten Suchauftrags",
        error
      );
      throw error;
    }
  }

  async generateWpPluginProfile(anonymizedResume: string) {
    const request = {
      aiOrder: AiOrder.generateWpPluginProfile,
      parts: {
        anonymizedResume: anonymizedResume,
      },
    } as AiRequestObject;
    try {
      const response = await this.aiCluster.clusterRequest(request);

      const matches = response.data.response.match(/{(.*?)}/s);
      if (matches && matches[1]) {
        const jobLeadQuery = JSON.parse(`{${matches[1]}}`);
        return jobLeadQuery;
      }
    } catch (error) {
      console.error(
        "Fehler bei der generierung des Kurzprofils für die Webseite",
        error
      );
      throw error;
    }
  }

  async formatCv(birthdate: string, cvData: CareerStep[]) {
    const cvDataText = JSON.stringify(cvData, null, 2);
    const request = {
      aiOrder: AiOrder.formatCv,
      parts: {
        data: birthdate,
        anonymizedResume: cvDataText,
      },
    } as AiRequestObject;
    try {
      const response = await this.aiCluster.clusterRequest(request);

      const matches = response.data.response.match(/\[.*\]/s);
      if (matches && matches[0]) {
        const parsedResponse = JSON.parse(matches[0]);
        return parsedResponse;
      } else {
        throw new Error(
          "Kein gültiges JSON-Format in der AI-Antwort gefunden."
        );
      }
    } catch (error) {
      console.error("Fehler beim Extrahieren der Daten:", error);
      throw error;
    }
  }

  async generateFunnelQuestions(title: string, customPrompt: string) {
    const request = {
      aiOrder: AiOrder.generateFunnelQuestions,
      parts: {
        title: title,
        customPrompt: customPrompt,
      },
    } as AiRequestObject;
    try {
      const response = await this.aiCluster.clusterRequest(request);

      const matches = response.data.response.match(/{.*}/s);
      if (matches && matches[0]) {
        const funnelQuestions = JSON.parse(matches[0]);
        return funnelQuestions;
      } else {
        throw new Error(
          "Kein gültiges JSON-Format in der AI-Antwort gefunden."
        );
      }
    } catch (error) {
      console.error("Fehler beim Extrahieren der Daten:", error);
      throw error;
    }
  }

  async generateMandantFormContent(branchCustomer: Customer) {
    const promptObject = {
      name: branchCustomer.generalData.name,
      address: branchCustomer.addressAndCommunication,
    };
    const prompt = JSON.stringify(promptObject);
    const request: AiRequestObject = {
      aiOrder: AiOrder.generateMandantFormContent,
      parts: {
        prompt: prompt,
      },
    };

    try {
      const response = await this.aiCluster.clusterRequest(request);
      const matches = response.data.response.match(/{(.*?)}/s);
      if (matches && matches[1]) {
        const mandantForm = `{${matches[1]}}`;

        const mandantFormData = JSON.parse(mandantForm) as {
          contact: string;
          name: string;
          branchInitials: string;
          whatsApp: string;
        };
        return mandantFormData;
      } else {
        return null;
      }
    } catch (error) {
      console.warn(
        "Fehler bei Datengenerierung für die Niederlassungsdaten",
        error
      );
      throw error;
    }
  }
  async chatWithHandbook(
    message: string,
    threadId?: string
  ): Promise<{ response: string; threadId: string }> {
    const request: AiRequestObject = {
      aiOrder: AiOrder.chatWithHandbook,
      parts: {
        message: message,
        threadId: threadId,
      },
    };

    try {
      const response = await this.aiCluster.clusterRequest(request);
      let responseText = response?.data?.response;

      const createTicketKey = RecurionAnswerHandles.createTicket;

      if (responseText.includes(createTicketKey)) {
        let systemStart = responseText.indexOf(createTicketKey);

        systemStart = responseText.lastIndexOf("```json", systemStart);
        if (systemStart === -1) systemStart = 0;

        const jsonStartIndex = responseText.indexOf("{", systemStart);
        const jsonEndIndex = responseText.indexOf("}", jsonStartIndex) + 1;

        if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
          const jsonString = responseText.substring(
            jsonStartIndex,
            jsonEndIndex
          );

          try {
            const jsonObject = JSON.parse(jsonString);

            const gitLabService = new GitLabService();
            const ticketTitle = `[${store.state.company.name}] ${jsonObject.title}`;
            const ticketDescription = `${jsonObject.resume}\n\nUser: ${store.getters.user.forename} ${store.getters.user.lastname}`;

            const label = jsonObject.label;
            const labelArray: string[] = [
              GitLabLabels.recurionTicket,
              store.state.company.name,
            ];

            switch (label) {
              case RecurionAnswerHandles.bugReport:
                labelArray.push(GitLabLabels.bug);
                break;
              case RecurionAnswerHandles.featureRequest:
                labelArray.push(GitLabLabels.featureRequest);
                break;
              case RecurionAnswerHandles.requestToAddChatbotKnowledge:
                labelArray.push(GitLabLabels.knowledgeRequest);
                break;
              default:
                console.warn(`Unknown label for Ticket: ${label}`);
                labelArray.push(label);
                break;
            }

            await gitLabService.createBugReport(
              ticketTitle,
              ticketDescription,
              labelArray,
              IssueType.issue
            );

            responseText = responseText.substring(0, systemStart).trim();
          } catch (error) {
            console.error("Fehler beim Parsen des JSON-Strings:", error);
          }
        }
      }

      return {
        response: responseText,
        threadId: response?.data?.threadId,
      };
    } catch (error) {
      console.error("Fehler beim Chat mit dem Handbook Assistant:", error);
      throw error;
    }
  }

  async chatWithCoach(
    message: string,
    threadId?: string
  ): Promise<{ response: string; threadId: string }> {
    const request: AiRequestObject = {
      aiOrder: AiOrder.chatWithDispatcherCoach,
      parts: {
        message: message,
        threadId: threadId,
      },
    };

    try {
      const response = await this.aiCluster.clusterRequest(request);
      let responseText = response?.data?.response;
      const unlockKey = RecurionAnswerHandles.appUnlockKey;
      const unlockKeyIndex = responseText.indexOf(unlockKey);

      if (unlockKeyIndex !== -1) {
        let systemStart = responseText.lastIndexOf("```json", unlockKeyIndex);
        if (systemStart === -1) systemStart = unlockKeyIndex;

        // reset the recurion chat
        store.commit(MutationType.setRecurionChat, {
          isActive: true,
          isClosed: false,
          chatbotType: RecurionChatbotType.handbook,
          initPrompt: "",
        });

        // clear open coachings in user config
        const user = store.getters.user;
        const userConfig = user.config;
        userConfig.hasOpenAiCoachingPrompt = undefined;
        const userService = new UserService();
        await userService.updateUserConfig(userConfig);

        // extract the json part for processing
        const jsonStartIndex = responseText.indexOf("{", unlockKeyIndex);
        const jsonEndIndex = responseText.indexOf("}", jsonStartIndex) + 1;

        if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
          const jsonString = responseText.substring(
            jsonStartIndex,
            jsonEndIndex
          );
          try {
            const jsonObject = JSON.parse(
              jsonString
            ) as RecurionCoachingTimelineEntry;
            const timelineHelper = new TimelineHelperService();
            await timelineHelper.timelineAutoDocu({
              entryType: RecurionAnswerHandles.timelineEntryType,
              mandant: RecurionAnswerHandles.timelineEntryPseudoMandant,
              note: `<strong>${jsonObject.title}</strong>:<br>${jsonObject.resume}`,
            });

            // remove the system block from the response
            responseText = responseText.substring(0, systemStart).trim();
          } catch (error) {
            console.error("Fehler beim Parsen des JSON-Strings:", error);
          }
        }
      }

      return {
        response: responseText,
        threadId: response?.data?.threadId,
      };
    } catch (error) {
      console.error("Fehler beim Chat mit dem Handbook Assistant:", error);
      throw error;
    }
  }
}
