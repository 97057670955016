import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa-svg";
import { mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { ThemeDefinition } from "vuetify";
import store from "../store/store";
import "../../scss/styles.scss";
import { AppLayout } from "../enums/app-layout.enum";
import { watch } from "vue";

const themes = {
  dispositionerLight: {
    dark: false,
    colors: {
      background: "#F5F5F5",
      surface: "#FFFFFF",
      "surface-bright": "#F9F9F9",
      "surface-light": "#E0E0E0",
      "surface-variant": "#757575",
      "on-surface-variant": "#FFFFFF",
      border: "#494949",
      "border-light": "#E0E0E0",
      "border-medium": "#757575",
      primary: "#1C365E",
      "on-primary": "#FFFFFF",
      "primary-darken-1": "#15243E",
      secondary: "#008B8B",
      "on-secondary": "#FFFFFF",
      "secondary-darken-1": "#005F5F",
      "on-secondary-darken-1": "#FFFFFF",
      tertiary: "#EB8C2F",
      "tertiary-darken-1": "#B56623",
      "on-tertiary": "#FFFFFF",
      "on-tertiary-darken-1": "#FFFFFF",
      tableHeader: "#C7E0DC",
      "on-tableHeader": "#1C365E",
      tableHover: "#1C365E09",
      tableFirstColumn: "#E6EEF2",
      "on-tableFirstColumn": "#1C365E",
      card: "#F4F7FA",
      "card-darken-1": "#E8EDF3",
      "on-card": "#15243E",
      cardHover: "#F0F4F8",
      "on-cardHover": "#000",
      cardMatch: "#C2D8DF",
      "on-cardMatch": "#1C365E",
      matchHover: "#D2E1DA",
      "on-matchHover": "#1C365E",
      cardPerfectMatch: "#A8CBAF",
      "on-cardPerfectMatch": "#1C365E",
      perfectMatchHover: "#BDD4C5",
      "on-perfectMatchHover": "#1C365E",
      error: "#D32F2F",
      info: "#1976D2",
      abort: "#C62828",
      success: "#388E3C",
      warning: "#FFA000",
    },
    variables: {
      "border-color": "#008B8B",
      "border-opacity": 0.05,
      "high-emphasis-opacity": 0.87,
      "medium-emphasis-opacity": 0.6,
      "disabled-opacity": 0.38,
      "idle-opacity": 0.04,
      "hover-opacity": 0.3,
      "focus-opacity": 0.12,
      "selected-opacity": 0.08,
      "activated-opacity": 0.12,
      "pressed-opacity": 0.12,
      "dragged-opacity": 0.08,
      "theme-kbd": "#212529",
      "theme-on-kbd": "#FFFFFF",
      "theme-code": "#F5F5F5",
      "theme-on-code": "#000000",
    },
  },
  dispositionerDark: {
    dark: true,
    colors: {
      background: "#121212",
      surface: "#1E1E1E",
      "surface-bright": "#2A2A2A",
      "surface-light": "#333333",
      "surface-variant": "#444E56",
      "on-surface-variant": "#F5F5F5",
      border: "#D0D0D0",
      "border-light": "#FFFFFF",
      "border-medium": "#A0A0A0",
      primary: "#D0E2F9",
      "on-primary": "#FFFFFF",
      "primary-darken-1": "#2C486A",
      secondary: "#008B8B",
      "on-secondary": "#FFFFFF",
      "secondary-darken-1": "#005F5F",
      "on-secondary-darken-1": "#FFFFFF",
      tertiary: "#EB8C2F",
      "tertiary-darken-1": "#B56623",
      "on-tertiary": "#FFFFFF",
      "on-tertiary-darken-1": "#FFFFFF",
      tableHeader: "#335E55",
      "on-tableHeader": "#E6EEF2",
      tableHover: "#1C365E09",
      tableFirstColumn: "#2F3E47",
      "on-tableFirstColumn": "#F5F5F5",
      card: "#2A2A2A",
      "card-darken-1": "#212121",
      "on-card": "#E0E0E0",
      cardHover: "#3A3A3A",
      "on-cardHover": "#FFFFFF",
      cardMatch: "#4C5A62",
      "on-cardMatch": "#F5F5F5",
      matchHover: "#58666F",
      "on-matchHover": "#F5F5F5",
      cardPerfectMatch: "#41594B",
      "on-cardPerfectMatch": "#FFFFFF",
      perfectMatchHover: "#4A6052",
      "on-perfectMatchHover": "#FFFFFF",
      error: "#D32F2F",
      info: "#1976D2",
      abort: "#C62828",
      success: "#388E3C",
      warning: "#FFA000",
    },
    variables: {
      "border-color": "#2196F3",
      "border-opacity": 0.12,
      "high-emphasis-opacity": 0.87,
      "medium-emphasis-opacity": 0.6,
      "disabled-opacity": 0.38,
      "idle-opacity": 0.04,
      "hover-opacity": 0.3,
      "focus-opacity": 0.12,
      "selected-opacity": 0.08,
      "activated-opacity": 0.12,
      "pressed-opacity": 0.12,
      "dragged-opacity": 0.08,
      "theme-kbd": "#F5F5F5",
      "theme-on-kbd": "#212529",
      "theme-code": "#1E1E1E",
      "theme-on-code": "#F5F5F5",
    },
  },

  pdMasterLight: {
    dark: false,
    colors: {
      background: "#F5F5F5",
      surface: "#FFFFFF",
      "surface-bright": "#F9F9F9",
      "surface-light": "#E0E0E0",
      "surface-variant": "#757575",
      "on-surface-variant": "#FFFFFF",
      border: "#494949",
      "border-light": "#E0E0E0",
      "border-medium": "#757575",
      primary: "#002f5d",
      "on-primary": "#FFFFFF",
      "primary-darken-1": "#1a3553",
      secondary: "#85bd86",
      "on-secondary": "#FFFFFF",
      "secondary-darken-1": "#577a58",
      "on-secondary-darken-1": "#FFFFFF",
      tertiary: "#f18700",
      "tertiary-darken-1": "#c06d01",
      "on-tertiary": "#FFFFFF",
      "on-tertiary-darken-1": "#FFFFFF",
      tableHeader: "#85bd86",
      "on-tableHeader": "#1a3553",
      tableHover: "#1a355309",
      tableFirstColumn: "#E0E0E0",
      "on-tableFirstColumn": "#1a3553",
      card: "#F4F7FA",
      "card-darken-1": "#E8EDF3",
      "on-card": "#000000",
      cardHover: "#FBFCFD",
      "on-cardHover": "#000000",
      cardMatch: "#C6CCD4",
      "on-cardMatch": "#000000",
      matchHover: "#E4E9F0",
      "on-matchHover": "#000000",
      cardPerfectMatch: "#bcddb5",
      "on-cardPerfectMatch": "#000000",
      perfectMatchHover: "#D0D8DE",
      "on-perfectMatchHover": "#000000",
      error: "#B71C1C",
      info: "#2196F3",
      abort: "#B71C1C",
      success: "#2E7D32",
      warning: "#FB8C00",
    },
    variables: {
      "border-color": "#2196F3",
      "border-opacity": 0.12,
      "high-emphasis-opacity": 0.87,
      "medium-emphasis-opacity": 0.6,
      "disabled-opacity": 0.38,
      "idle-opacity": 0.04,
      "hover-opacity": 0.2,
      "focus-opacity": 0.12,
      "selected-opacity": 0.08,
      "activated-opacity": 0.12,
      "pressed-opacity": 0.12,
      "dragged-opacity": 0.08,
      "theme-kbd": "#212529",
      "theme-on-kbd": "#FFFFFF",
      "theme-code": "#F5F5F5",
      "theme-on-code": "#000000",
    },
  },
  pdMasterDark: {
    dark: true,
    colors: {
      background: "#121212",
      surface: "#1E1E1E",
      "surface-bright": "#2C2C2C",
      "surface-light": "#2E2E2E",
      "surface-variant": "#424242",
      "on-surface-variant": "#E0E0E0",
      border: "#D0D0D0",
      "border-light": "#FFFFFF",
      "border-medium": "#A0A0A0",
      primary: "#4A90E2",
      "on-primary": "#FFFFFF",
      "primary-darken-1": "#357ABD",
      secondary: "#A5D6A7",
      "on-secondary": "#1E4620",
      "secondary-darken-1": "#81C784",
      "on-secondary-darken-1": "#1E4620",
      tertiary: "#FFA726",
      "tertiary-darken-1": "#FB8C00",
      "on-tertiary": "#4A2900",
      "on-tertiary-darken-1": "#4A2900",
      tableHeader: "#A5D6A7",
      "on-tableHeader": "#000000",
      tableHover: "#A5D6A709",
      tableFirstColumn: "#2C2C2C",
      "on-tableFirstColumn": "#F9F9F9",
      card: "#2A2A2A",
      "card-darken-1": "#202020",
      "on-card": "#FFFFFF",
      cardHover: "#333333",
      "on-cardHover": "#FFFFFF",
      cardMatch: "#002346",
      "on-cardMatch": "#FFFFFF",
      matchHover: "#001931",
      "on-matchHover": "#FFFFFF",
      cardPerfectMatch: "#56774f",
      "on-cardPerfectMatch": "#FFFFFF",
      perfectMatchHover: "#4A5E55",
      "on-perfectMatchHover": "#FFFFFF",
      error: "#EF5350",
      info: "#42A5F5",
      abort: "#EF5350",
      success: "#66BB6A",
      warning: "#FFA726",
    },
    variables: {
      "border-color": "#42A5F5",
      "border-opacity": 0.12,
      "high-emphasis-opacity": 0.87,
      "medium-emphasis-opacity": 0.6,
      "disabled-opacity": 0.38,
      "idle-opacity": 0.04,
      "hover-opacity": 0.3,
      "focus-opacity": 0.12,
      "selected-opacity": 0.08,
      "activated-opacity": 0.12,
      "pressed-opacity": 0.12,
      "dragged-opacity": 0.08,
      "theme-kbd": "#E0E0E0",
      "theme-on-kbd": "#000000",
      "theme-code": "#2E2E2E",
      "theme-on-code": "#FFFFFF",
    },
  },
};
function updateCssVariablesFromVuetify(theme: any) {
  const root = document.documentElement;

  // Update color variables
  Object.entries(theme.colors).forEach(([key, value]) => {
    if (typeof value === "string") {
      root.style.setProperty(`--color-${key}`, value);
    }
  });

  // Update variables (if defined)
  if (theme.variables) {
    Object.entries(theme.variables).forEach(([key, value]) => {
      if (typeof value === "string") {
        root.style.setProperty(`--${key}`, value);
      }
    });
  }
}

export function createVuetifyInstance() {
  const appLayout = store.getters.appLayout || "defaultLayout";

  let lightTheme = {};
  let darkTheme = {};

  if (
    appLayout === AppLayout.dispositioner ||
    appLayout === AppLayout.classic
  ) {
    lightTheme = themes.dispositionerLight;
    darkTheme = themes.dispositionerDark;
  } else if (appLayout === AppLayout.pdmaster) {
    lightTheme = themes.pdMasterLight;
    darkTheme = themes.pdMasterDark;
  }

  const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        fa,
        mdi,
      },
    },
    theme: {
      defaultTheme: "light",
      themes: {
        light: lightTheme,
        dark: darkTheme,
      },
    },
  });

  updateCssVariablesFromVuetify(
    vuetify.theme.global.name.value === "dark" ? darkTheme : lightTheme
  );

  watch(vuetify.theme.global.name, (newTheme) => {
    const theme = (
      vuetify.theme.themes as unknown as Record<string, ThemeDefinition>
    )[newTheme];
    updateCssVariablesFromVuetify(theme);
  });

  return vuetify;
}
