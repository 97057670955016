import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.showModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)',
      class: "fill-height"
    }, {
      default: _withCtx(() => [
        _cache[71] || (_cache[71] = _createTextVNode(" > ")),
        _createVNode(_component_v_card, {
          style: {"min-height":"var(--d-h-full)"},
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  rounded: _ctx.vStyle.card.rounded
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tabs, {
                      modelValue: _ctx.activeTab,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
                      "bg-color": "primary",
                      style: {"color":"var(--color-on-primary)"},
                      "slider-color": "primary"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                          return (_openBlock(), _createBlock(_component_v_tab, {
                            key: index,
                            value: index
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(tab), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128)),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_tab, {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { size: "x-large" }, {
                              default: _withCtx(() => _cache[46] || (_cache[46] = [
                                _createTextVNode("fa-solid fa-circle-xmark")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["rounded"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_tabs_window, {
                  modelValue: _ctx.activeTab,
                  "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.activeTab) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                      return (_openBlock(), _createBlock(_component_v_tabs_window_item, {
                        key: index,
                        value: index
                      }, {
                        default: _withCtx(() => [
                          (index === 0)
                            ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card_title, null, {
                                    default: _withCtx(() => _cache[47] || (_cache[47] = [
                                      _createTextVNode("Allgemeine Daten")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_divider, { class: "mb-3" }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.generalData.name,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localCustomer.generalData.name) = $event)),
                                        label: "Name"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.generalData.name2,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localCustomer.generalData.name2) = $event)),
                                        label: "Name 2"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.generalData.name3,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localCustomer.generalData.name3) = $event)),
                                        label: "Name 3"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.generalData.searchTerm,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localCustomer.generalData.searchTerm) = $event)),
                                        label: "Suchbegriff"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.generalData.customerSince,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localCustomer.generalData.customerSince) = $event)),
                                        label: "Kunde seit"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.generalData.costCenter,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localCustomer.generalData.costCenter) = $event)),
                                        label: "Kostenstelle"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.generalData.accountsReceivable,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localCustomer.generalData.accountsReceivable) = $event)),
                                        label: "Debitorenkonto"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.generalData.status,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localCustomer.generalData.status) = $event)),
                                        items: _ctx.customerStatesArray,
                                        title: "text",
                                        label: "Status"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_title, null, {
                                    default: _withCtx(() => _cache[48] || (_cache[48] = [
                                      _createTextVNode("Adresse")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_divider, { class: "mb-3" }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.street,
                                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.street) = $event)),
                                        label: "Straße"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.postalCode,
                                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.postalCode) = $event)),
                                        label: "PLZ"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.city,
                                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.city) = $event)),
                                        label: "Ort"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.country,
                                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.country) = $event)),
                                        label: "Staat"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.state,
                                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.state) = $event)),
                                        label: "Bundesland"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_title, null, {
                                    default: _withCtx(() => _cache[49] || (_cache[49] = [
                                      _createTextVNode("Kontakt")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_divider, { class: "mb-3" }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.phone1,
                                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.phone1) = $event)),
                                        label: "Telefon 1"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.phone2,
                                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.phone2) = $event)),
                                        label: "Telefon 2"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.email,
                                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.email) = $event)),
                                        label: "Email"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: _ctx.vStyle.input.variant || undefined,
                                        rounded: _ctx.vStyle.input.rounded || undefined,
                                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                                        color: _ctx.vStyle.input.color || undefined,
                                        modelValue: _ctx.localCustomer.addressAndCommunication.website,
                                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localCustomer.addressAndCommunication.website) = $event)),
                                        label: "Website"
                                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : (index === 1)
                              ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        (_ctx.softwareIntegration.indexAnzeigendaten)
                                          ? (_openBlock(), _createBlock(_component_v_card, {
                                              key: 0,
                                              variant: _ctx.vStyle.card.variant || undefined,
                                              rounded: _ctx.vStyle.card.rounded || undefined,
                                              border: _ctx.vStyle.card.border || undefined,
                                              class: "job-ad-card"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card_title, { class: "d-flex" }, {
                                                  default: _withCtx(() => [
                                                    (_ctx.localCustomer.additionalInfo.indexCompanyId)
                                                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Anzeigendaten"))
                                                      : (_openBlock(), _createElementBlock("span", _hoisted_2, "Anzeigendaten nicht verlinkt")),
                                                    _createVNode(_component_v_spacer),
                                                    (_ctx.localCustomer.additionalInfo.indexCompanyId)
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 2,
                                                          variant: "text",
                                                          onClick: _ctx.removeLinkingData,
                                                          icon: "fa-solid fa-trash-can"
                                                        }, null, 8, ["onClick"]))
                                                      : _createCommentVNode("", true)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_card_text, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_row, null, {
                                                      default: _withCtx(() => [
                                                        (_ctx.localCustomer.additionalInfo.indexCompanyId)
                                                          ? (_openBlock(), _createBlock(_component_v_col, {
                                                              key: 0,
                                                              cols: "12",
                                                              md: "3"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_card_subtitle, null, {
                                                                  default: _withCtx(() => _cache[50] || (_cache[50] = [
                                                                    _createTextVNode("Company ID")
                                                                  ])),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_v_chip, { class: "ml-3" }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.localCustomer.additionalInfo.indexCompanyId), 1)
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true),
                                                        (_ctx.localCustomer.furtherInformation.WZ08)
                                                          ? (_openBlock(), _createBlock(_component_v_col, {
                                                              key: 1,
                                                              cols: "12",
                                                              md: "3"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_card_subtitle, null, {
                                                                  default: _withCtx(() => _cache[51] || (_cache[51] = [
                                                                    _createTextVNode("WZ08")
                                                                  ])),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_v_chip, { class: "ml-3" }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.localCustomer.furtherInformation.WZ08), 1)
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true),
                                                        (_ctx.localCustomer.furtherInformation.Siret)
                                                          ? (_openBlock(), _createBlock(_component_v_col, {
                                                              key: 2,
                                                              cols: "12",
                                                              md: "3"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_card_subtitle, null, {
                                                                  default: _withCtx(() => _cache[52] || (_cache[52] = [
                                                                    _createTextVNode("Siret")
                                                                  ])),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_v_chip, { class: "ml-3" }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.localCustomer.furtherInformation.Siret), 1)
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true),
                                                        (_ctx.localCustomer.additionalInfo.indexLink)
                                                          ? (_openBlock(), _createBlock(_component_v_col, {
                                                              key: 3,
                                                              cols: "12",
                                                              md: "3"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_card_subtitle, null, {
                                                                  default: _withCtx(() => _cache[53] || (_cache[53] = [
                                                                    _createTextVNode("in Anzeigendaten")
                                                                  ])),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_v_btn, {
                                                                  variant: "outlined",
                                                                  rounded: _ctx.vStyle.btn.rounded || undefined,
                                                                  border: _ctx.vStyle.btn.border || undefined,
                                                                  class: "ml-5",
                                                                  onClick: _cache[19] || (_cache[19] = ($event: any) => (
                              _ctx.openLink(_ctx.localCustomer.additionalInfo.indexLink)
                            ))
                                                                }, {
                                                                  default: _withCtx(() => _cache[54] || (_cache[54] = [
                                                                    _createTextVNode(" öffnen ")
                                                                  ])),
                                                                  _: 1
                                                                }, 8, ["rounded", "border"])
                                                              ]),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }, 8, ["variant", "rounded", "border"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card, {
                                          variant: _ctx.vStyle.card.variant || undefined,
                                          rounded: _ctx.vStyle.card.rounded || undefined,
                                          border: _ctx.vStyle.card.border || undefined,
                                          class: "job-ad-card"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                  color: _ctx.vStyle.input.color || undefined,
                                                  modelValue: _ctx.localCustomer.additionalInfo.careerSiteLink,
                                                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.localCustomer.additionalInfo.careerSiteLink) = $event)),
                                                  label: "Karriereseite"
                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                                                _createVNode(_component_v_btn, {
                                                  variant: "outlined",
                                                  rounded: _ctx.vStyle.btn.rounded || undefined,
                                                  block: "",
                                                  disabled: !_ctx.localCustomer.additionalInfo.careerSiteLink,
                                                  onClick: _cache[21] || (_cache[21] = ($event: any) => (
                          _ctx.openLink(_ctx.localCustomer.additionalInfo.careerSiteLink)
                        ))
                                                }, {
                                                  default: _withCtx(() => _cache[55] || (_cache[55] = [
                                                    _createTextVNode(" öffnen ")
                                                  ])),
                                                  _: 1
                                                }, 8, ["rounded", "disabled"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["variant", "rounded", "border"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_divider),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "12"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          variant: _ctx.vStyle.btn.variant || undefined,
                                          rounded: _ctx.vStyle.btn.rounded || undefined,
                                          border: _ctx.vStyle.btn.border || undefined,
                                          onClick: _ctx.fetchIndexAnzeigendaten
                                        }, {
                                          default: _withCtx(() => [
                                            _cache[57] || (_cache[57] = _createTextVNode(" Anzeigedaten neu laden ")),
                                            _createElementVNode("img", {
                                              src: 
                        _ctx.$store.getters.getEnv.VUE_APP_ASSETS_URL +
                        '/icons/icon-index-anzeigendaten.png'
                      ,
                                              class: "button-icon"
                                            }, null, 8, _hoisted_3),
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[56] || (_cache[56] = [
                                                _createTextVNode("fas fa-arrows-rotate")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["variant", "rounded", "border", "onClick"]),
                                        _createVNode(_component_v_list, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_container, null, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCustomer.jobAds, (jobAd) => {
                                                  return (_openBlock(), _createBlock(_component_v_list_item, {
                                                    key: jobAd.adId,
                                                    onClick: ($event: any) => (_ctx.openLink(jobAd.link)),
                                                    class: "job-ad ma-0"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_container, null, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_list_item_title, null, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(jobAd.jobTitle), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1024),
                                                          _createVNode(_component_v_list_item_subtitle, null, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(jobAd.date) + ": " + _toDisplayString(jobAd.location) + " - " + _toDisplayString(jobAd.sourceText) + " ", 1),
                                                              _createElementVNode("span", {
                                                                innerHTML: jobAd.listPrice
                                                              }, null, 8, _hoisted_4)
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["onClick"]))
                                                }), 128))
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : (index === 2)
                                ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "12"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            variant: _ctx.vStyle.input.variant || undefined,
                                            rounded: _ctx.vStyle.input.rounded || undefined,
                                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                                            color: _ctx.vStyle.input.color || undefined,
                                            modelValue: _ctx.localCustomer.additionalInfo.info1,
                                            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.localCustomer.additionalInfo.info1) = $event)),
                                            label: "Info 1"
                                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "12"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            variant: _ctx.vStyle.input.variant || undefined,
                                            rounded: _ctx.vStyle.input.rounded || undefined,
                                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                                            color: _ctx.vStyle.input.color || undefined,
                                            modelValue: _ctx.localCustomer.additionalInfo.info2,
                                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.localCustomer.additionalInfo.info2) = $event)),
                                            label: "Info 2"
                                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "12"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            variant: _ctx.vStyle.input.variant || undefined,
                                            rounded: _ctx.vStyle.input.rounded || undefined,
                                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                                            color: _ctx.vStyle.input.color || undefined,
                                            modelValue: _ctx.localCustomer.additionalInfo.info3,
                                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.localCustomer.additionalInfo.info3) = $event)),
                                            label: "Info 3"
                                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            variant: _ctx.vStyle.input.variant || undefined,
                                            rounded: _ctx.vStyle.input.rounded || undefined,
                                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                                            color: _ctx.vStyle.input.color || undefined,
                                            modelValue: _ctx.localCustomer.furtherInformation.dispatcher,
                                            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.localCustomer.furtherInformation.dispatcher) = $event)),
                                            label: "Disponent"
                                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            variant: _ctx.vStyle.input.variant || undefined,
                                            rounded: _ctx.vStyle.input.rounded || undefined,
                                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                                            color: _ctx.vStyle.input.color || undefined,
                                            modelValue: _ctx.localCustomer.additionalInfo.logoLink,
                                            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.localCustomer.additionalInfo.logoLink) = $event)),
                                            label: "Link zu Logo"
                                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_switch, {
                                            modelValue: _ctx.localCustomer.additionalInfo.dataProtectionConsent,
                                            "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.localCustomer.additionalInfo.dataProtectionConsent) = $event)),
                                            color: "primary",
                                            label: "Datenschutzerklärung"
                                          }, null, 8, ["modelValue"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : (index === 3)
                                  ? (_openBlock(), _createBlock(_component_v_row, { key: 3 }, {
                                      default: _withCtx(() => [
                                        (
                    _ctx.localCustomer.reporting &&
                    _ctx.localCustomer.reporting[0] &&
                    _ctx.localCustomer.reporting[0].jobs
                  )
                                          ? (_openBlock(), _createBlock(_component_v_col, {
                                              key: 0,
                                              cols: "12",
                                              md: "6"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card, {
                                                  variant: _ctx.vStyle.card.variant || undefined,
                                                  rounded: _ctx.vStyle.card.rounded || undefined,
                                                  border: _ctx.vStyle.card.border || undefined,
                                                  class: "pa-3"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_btn, {
                                                      block: "",
                                                      variant: "text",
                                                      onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.openLink(_ctx.localCustomer.reporting[0].jobs)))
                                                    }, {
                                                      default: _withCtx(() => _cache[58] || (_cache[58] = [
                                                        _createTextVNode(" Jobs ")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    (
                        _ctx.localCustomer.reporting &&
                        _ctx.localCustomer.reporting[0] &&
                        _ctx.localCustomer.reporting[0].company
                      )
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 0,
                                                          block: "",
                                                          variant: "text",
                                                          onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.openLink(_ctx.localCustomer.reporting[0].company)))
                                                        }, {
                                                          default: _withCtx(() => _cache[59] || (_cache[59] = [
                                                            _createTextVNode(" Company ")
                                                          ])),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (
                        _ctx.localCustomer.reporting &&
                        _ctx.localCustomer.reporting[0] &&
                        _ctx.localCustomer.reporting[0].groupMedia
                      )
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 1,
                                                          block: "",
                                                          variant: "text",
                                                          onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.openLink(_ctx.localCustomer.reporting[0].groupMedia)))
                                                        }, {
                                                          default: _withCtx(() => _cache[60] || (_cache[60] = [
                                                            _createTextVNode(" Group Media ")
                                                          ])),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (
                        _ctx.localCustomer.reporting &&
                        _ctx.localCustomer.reporting[0] &&
                        _ctx.localCustomer.reporting[0].groupFlexible
                      )
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 2,
                                                          block: "",
                                                          variant: "text",
                                                          onClick: _cache[31] || (_cache[31] = ($event: any) => (
                        _ctx.openLink(_ctx.localCustomer.reporting[0].groupFlexible)
                      ))
                                                        }, {
                                                          default: _withCtx(() => _cache[61] || (_cache[61] = [
                                                            _createTextVNode(" Group Flexible ")
                                                          ])),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (
                        _ctx.localCustomer.reporting &&
                        _ctx.localCustomer.reporting[0] &&
                        _ctx.localCustomer.reporting[0].group
                      )
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 3,
                                                          block: "",
                                                          variant: "text",
                                                          onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.openLink(_ctx.localCustomer.reporting[0].group)))
                                                        }, {
                                                          default: _withCtx(() => _cache[62] || (_cache[62] = [
                                                            _createTextVNode(" Group ")
                                                          ])),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (
                        _ctx.localCustomer.reporting &&
                        _ctx.localCustomer.reporting[0] &&
                        _ctx.localCustomer.reporting[0].media
                      )
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 4,
                                                          block: "",
                                                          variant: "text",
                                                          onClick: _cache[33] || (_cache[33] = ($event: any) => (_ctx.openLink(_ctx.localCustomer.reporting[0].media)))
                                                        }, {
                                                          default: _withCtx(() => _cache[63] || (_cache[63] = [
                                                            _createTextVNode(" Media ")
                                                          ])),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (
                        _ctx.localCustomer.reporting &&
                        _ctx.localCustomer.reporting[0] &&
                        _ctx.localCustomer.reporting[0].flexible
                      )
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 5,
                                                          block: "",
                                                          variant: "text",
                                                          onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.openLink(_ctx.localCustomer.reporting[0].flexible)))
                                                        }, {
                                                          default: _withCtx(() => _cache[64] || (_cache[64] = [
                                                            _createTextVNode(" Flexible ")
                                                          ])),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true)
                                                  ]),
                                                  _: 1
                                                }, 8, ["variant", "rounded", "border"])
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }))
                                  : (index === 4)
                                    ? (_openBlock(), _createBlock(_component_v_row, { key: 4 }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCustomer.contacts, (contact, contactIndex) => {
                                            return (_openBlock(), _createBlock(_component_v_col, {
                                              cols: "12",
                                              md: "6",
                                              key: contactIndex
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card, {
                                                  variant: _ctx.vStyle.card.variant || undefined,
                                                  rounded: _ctx.vStyle.card.rounded || undefined,
                                                  border: _ctx.vStyle.card.border || undefined,
                                                  class: "pa-3 mb-3"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_card_title, { class: "d-flex align-center justify-space-between mb-0" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(contact.firstName) + " " + _toDisplayString(contact.lastName) + " ", 1),
                                                        _createVNode(_component_v_btn, {
                                                          icon: "",
                                                          variant: "text",
                                                          onClick: ($event: any) => (_ctx.deleteContact(contactIndex))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_icon, null, {
                                                              default: _withCtx(() => _cache[65] || (_cache[65] = [
                                                                _createTextVNode("fa-solid fa-trash-can")
                                                              ])),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["onClick"])
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_v_card_subtitle, { class: "mt-0" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(contact.role), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_v_divider, { class: "my-3" }),
                                                    _createVNode(_component_v_card_text, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_row, null, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "4"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_select, {
                                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                  color: _ctx.vStyle.input.color || undefined,
                                                                  modelValue: contact.status,
                                                                  "onUpdate:modelValue": ($event: any) => ((contact.status) = $event),
                                                                  items: _ctx.contactStatuses,
                                                                  density: "compact",
                                                                  class: "ma-0",
                                                                  label: "Status"
                                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue", "items"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "4"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_select, {
                                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                  color: _ctx.vStyle.input.color || undefined,
                                                                  modelValue: contact.salutation,
                                                                  "onUpdate:modelValue": ($event: any) => ((contact.salutation) = $event),
                                                                  items: _ctx.salutations,
                                                                  density: "compact",
                                                                  class: "ma-0",
                                                                  label: "Anrede"
                                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue", "items"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "4"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                  color: _ctx.vStyle.input.color || undefined,
                                                                  modelValue: contact.title,
                                                                  "onUpdate:modelValue": ($event: any) => ((contact.title) = $event),
                                                                  density: "compact",
                                                                  class: "ma-0",
                                                                  label: "Titel"
                                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "6"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                  color: _ctx.vStyle.input.color || undefined,
                                                                  modelValue: contact.firstName,
                                                                  "onUpdate:modelValue": ($event: any) => ((contact.firstName) = $event),
                                                                  density: "compact",
                                                                  class: "ma-0",
                                                                  label: "Vorname"
                                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "6"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                  color: _ctx.vStyle.input.color || undefined,
                                                                  modelValue: contact.lastName,
                                                                  "onUpdate:modelValue": ($event: any) => ((contact.lastName) = $event),
                                                                  density: "compact",
                                                                  class: "ma-0",
                                                                  label: "Nachname"
                                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "6"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                  color: _ctx.vStyle.input.color || undefined,
                                                                  modelValue: contact.phone,
                                                                  "onUpdate:modelValue": ($event: any) => ((contact.phone) = $event),
                                                                  density: "compact",
                                                                  class: "ma-0",
                                                                  label: "Telefon"
                                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "6"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                  color: _ctx.vStyle.input.color || undefined,
                                                                  modelValue: contact.email,
                                                                  "onUpdate:modelValue": ($event: any) => ((contact.email) = $event),
                                                                  density: "compact",
                                                                  class: "ma-0",
                                                                  label: "Email"
                                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "12"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: _ctx.vStyle.input.variant || undefined,
                                                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                  color: _ctx.vStyle.input.color || undefined,
                                                                  modelValue: contact.role,
                                                                  "onUpdate:modelValue": ($event: any) => ((contact.role) = $event),
                                                                  density: "compact",
                                                                  class: "ma-0",
                                                                  label: "Position"
                                                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                                                              ]),
                                                              _: 2
                                                            }, 1024)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_v_card_actions, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_spacer),
                                                        _createVNode(_component_v_btn, {
                                                          color: "success",
                                                          onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.submitModal()))
                                                        }, {
                                                          default: _withCtx(() => _cache[66] || (_cache[66] = [
                                                            _createTextVNode("Speichern")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 2
                                                }, 1032, ["variant", "rounded", "border"])
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128)),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_card, {
                                                variant: _ctx.vStyle.card.variant || undefined,
                                                rounded: _ctx.vStyle.card.rounded || undefined,
                                                border: "dashed",
                                                class: "pa-3 mb-3"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_card_title, { class: "d-flex align-center justify-space-between mb-0" }, {
                                                    default: _withCtx(() => _cache[67] || (_cache[67] = [
                                                      _createTextVNode(" Neuer Kontakt ")
                                                    ])),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_divider, { class: "my-3" }),
                                                  _createVNode(_component_v_card_text, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_row, null, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_col, {
                                                            cols: "12",
                                                            md: "4"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_select, {
                                                                variant: _ctx.vStyle.input.variant || undefined,
                                                                rounded: _ctx.vStyle.input.rounded || undefined,
                                                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                color: _ctx.vStyle.input.color || undefined,
                                                                modelValue: _ctx.newContact.status,
                                                                "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.newContact.status) = $event)),
                                                                items: _ctx.contactStatuses,
                                                                density: "compact",
                                                                class: "ma-0",
                                                                label: "Status"
                                                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            cols: "12",
                                                            md: "4"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_select, {
                                                                variant: _ctx.vStyle.input.variant || undefined,
                                                                rounded: _ctx.vStyle.input.rounded || undefined,
                                                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                color: _ctx.vStyle.input.color || undefined,
                                                                modelValue: _ctx.newContact.salutation,
                                                                "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.newContact.salutation) = $event)),
                                                                items: _ctx.salutations,
                                                                density: "compact",
                                                                class: "ma-0",
                                                                label: "Anrede"
                                                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            cols: "12",
                                                            md: "4"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                variant: _ctx.vStyle.input.variant || undefined,
                                                                rounded: _ctx.vStyle.input.rounded || undefined,
                                                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                color: _ctx.vStyle.input.color || undefined,
                                                                modelValue: _ctx.newContact.title,
                                                                "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.newContact.title) = $event)),
                                                                density: "compact",
                                                                class: "ma-0",
                                                                label: "Titel"
                                                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            cols: "12",
                                                            md: "6"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                variant: _ctx.vStyle.input.variant || undefined,
                                                                rounded: _ctx.vStyle.input.rounded || undefined,
                                                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                color: _ctx.vStyle.input.color || undefined,
                                                                modelValue: _ctx.newContact.firstName,
                                                                "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.newContact.firstName) = $event)),
                                                                density: "compact",
                                                                class: "ma-0",
                                                                label: "Vorname"
                                                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            cols: "12",
                                                            md: "6"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                variant: _ctx.vStyle.input.variant || undefined,
                                                                rounded: _ctx.vStyle.input.rounded || undefined,
                                                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                color: _ctx.vStyle.input.color || undefined,
                                                                modelValue: _ctx.newContact.lastName,
                                                                "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.newContact.lastName) = $event)),
                                                                density: "compact",
                                                                class: "ma-0",
                                                                label: "Nachname"
                                                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            cols: "12",
                                                            md: "6"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                variant: _ctx.vStyle.input.variant || undefined,
                                                                rounded: _ctx.vStyle.input.rounded || undefined,
                                                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                color: _ctx.vStyle.input.color || undefined,
                                                                modelValue: _ctx.newContact.phone,
                                                                "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.newContact.phone) = $event)),
                                                                density: "compact",
                                                                class: "ma-0",
                                                                label: "Telefon"
                                                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            cols: "12",
                                                            md: "6"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                variant: _ctx.vStyle.input.variant || undefined,
                                                                rounded: _ctx.vStyle.input.rounded || undefined,
                                                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                color: _ctx.vStyle.input.color || undefined,
                                                                modelValue: _ctx.newContact.email,
                                                                "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.newContact.email) = $event)),
                                                                density: "compact",
                                                                class: "ma-0",
                                                                label: "Email"
                                                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            cols: "12",
                                                            md: "12"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                variant: _ctx.vStyle.input.variant || undefined,
                                                                rounded: _ctx.vStyle.input.rounded || undefined,
                                                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                                                color: _ctx.vStyle.input.color || undefined,
                                                                modelValue: _ctx.newContact.role,
                                                                "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.newContact.role) = $event)),
                                                                density: "compact",
                                                                class: "ma-0",
                                                                label: "Position"
                                                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_card_actions, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_spacer),
                                                      _createVNode(_component_v_btn, {
                                                        color: "success",
                                                        onClick: _ctx.addContact
                                                      }, {
                                                        default: _withCtx(() => _cache[68] || (_cache[68] = [
                                                          _createTextVNode("Hinzufügen")
                                                        ])),
                                                        _: 1
                                                      }, 8, ["onClick"])
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["variant", "rounded"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "abort",
                  onClick: _ctx.closeModal
                }, {
                  default: _withCtx(() => _cache[69] || (_cache[69] = [
                    _createTextVNode("Abbrechen")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "success",
                  onClick: _ctx.submitModal
                }, {
                  default: _withCtx(() => _cache[70] || (_cache[70] = [
                    _createTextVNode("Speichern")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ]))
}