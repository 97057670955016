<template>
  <v-row no-gutters>
    <v-col class="ma-5" cols="12">
      <h2 v-if="Object.keys(aiText).length === 0">
        Hallo {{ user.forename }} {{ user.lastname }}, Willkommen auf dem
        Dashboard!
      </h2>
      <h2 v-else>{{ aiText.salutation }} {{ aiText.motivation }}</h2>
    </v-col>
    <v-col cols="12" md="auto" class="ml-1">
      <v-sheet class="sheet2">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          title="30 Tage Vertriebskennzahlen"
          subtitle="Telefonate Unternehmen"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.ThirtyDays,
                KpiCategory.User
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.ThirtyDays,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.ThirtyDays,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.ThirtyDays,
                  KpiCategory.Mandant
                ) -
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.ThirtyDays,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.ThirtyDays,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.ThirtyDays,
                KpiCategory.Global
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.ThirtyDays,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.ThirtyDays,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}<br />
            <v-divider class="my-1"></v-divider>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
                |
                <strong>Firmenbesuche</strong>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Kandidaten bei Kunden"
        >
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
                | <strong>Profile verschickt</strong>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
                | Vorstellungsgespräche
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
                | Probearbeiten
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
                | Zusagen
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Telefonate Kandidaten"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.ThirtyDays,
                KpiCategory.User
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.ThirtyDays,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.ThirtyDays,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.ThirtyDays,
                  KpiCategory.Mandant
                ) -
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.ThirtyDays,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.ThirtyDays,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.ThirtyDays,
                KpiCategory.Global
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.ThirtyDays,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.ThirtyDays,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}<br />
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Vorstellungstermine"
        >
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
                | vereinbart
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
                | abgesagt
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
                | geplatzt
              </div>
            </div>
            <h4 class="mt-2">stattgefunden:</h4>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.ThirtyDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.ThirtyDays,
                    KpiCategory.Global
                  )
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="auto" class="ml-1">
      <v-sheet class="sheet2">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          title="7 Tage Vertriebskennzahlen"
          subtitle="Telefonate Unternehmen"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.SevenDays,
                KpiCategory.User
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.SevenDays,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.SevenDays,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.SevenDays,
                  KpiCategory.Mandant
                ) -
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.SevenDays,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.SevenDays,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.SevenDays,
                KpiCategory.Global
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.SevenDays,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.SevenDays,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}<br />
            <v-divider class="my-1"></v-divider>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
                |
                <strong>Firmenbesuche</strong>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Kandidaten bei Kunden"
        >
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
                | <strong>Profile verschickt</strong>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
                | Vorstellungsgespräche
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
                | Probearbeiten
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
                | Zusagen
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Telefonate Kandidaten"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.SevenDays,
                KpiCategory.User
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.SevenDays,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.SevenDays,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.SevenDays,
                  KpiCategory.Mandant
                ) -
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.SevenDays,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.SevenDays,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.SevenDays,
                KpiCategory.Global
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.SevenDays,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.SevenDays,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}<br />
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Vorstellungstermine"
        >
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
                | vereinbart
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
                | abgesagt
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
                | geplatzt
              </div>
            </div>
            <h4 class="mt-2">stattgefunden:</h4>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.SevenDays,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.SevenDays,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.SevenDays,
                    KpiCategory.Global
                  )
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="auto" class="ml-1">
      <v-sheet class="sheet2">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          title="24 Stunden Vertriebskennzahlen"
          subtitle="Telefonate Unternehmen"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.TwentyFourHours,
                KpiCategory.User
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.TwentyFourHours,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.TwentyFourHours,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.TwentyFourHours,
                  KpiCategory.Mandant
                ) -
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.TwentyFourHours,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCustomer,
                  TimePeriod.TwentyFourHours,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.TwentyFourHours,
                KpiCategory.Global
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.TwentyFourHours,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCustomer,
                TimePeriod.TwentyFourHours,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}<br />
            <v-divider class="my-1"></v-divider>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.meetingOutside,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
                |
                <strong>Firmenbesuche</strong>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Kandidaten bei Kunden"
        >
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    TimelineEntryType.candidateSuggestion,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
                | <strong>Profile verschickt</strong>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
                | Vorstellungsgespräche
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.trailWorkExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
                | Probearbeiten
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.jobofferExternal,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
                | Zusagen
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Telefonate Kandidaten"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.TwentyFourHours,
                KpiCategory.User
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.TwentyFourHours,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.TwentyFourHours,
                KpiCategory.User,
                PhoneCallOption.notReached
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.TwentyFourHours,
                  KpiCategory.Mandant
                ) -
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.TwentyFourHours,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  TimelineEntryType.phoneCallCandidate,
                  TimePeriod.TwentyFourHours,
                  KpiCategory.Mandant,
                  PhoneCallOption.notReached
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.TwentyFourHours,
                KpiCategory.Global
              ) -
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.TwentyFourHours,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                TimelineEntryType.phoneCallCandidate,
                TimePeriod.TwentyFourHours,
                KpiCategory.Global,
                PhoneCallOption.notReached
              )
            }}<br />
          </v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="sheet2__card"
          subtitle="Vorstellungstermine"
        >
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interview,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
                | vereinbart
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCanceled,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
                | abgesagt
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewNotShownUp,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
                | geplatzt
              </div>
            </div>
            <h4 class="mt-2">stattgefunden:</h4>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.User
                  )
                }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Mandant
                  )
                }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{
                  getDynamicKPI(
                    LinkingStatus.interviewCompleted,
                    TimePeriod.TwentyFourHours,
                    KpiCategory.Global
                  )
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="4" class="ml-1">
      <v-sheet class="sheet1">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          v-if="
            softwareIntegration.dashboardAiKpiAnalysis && aiText.analysisText
          "
          class="sheet1__card"
        >
          <v-card-title
            ><v-icon size="x-small" class="mr-1">fa-solid fa-rocket</v-icon
            >Analyse</v-card-title
          >
          <v-card-subtitle>Recurion.ai</v-card-subtitle>
          <v-card-text v-html="aiText.analysisText"></v-card-text>
          <v-card-title>💡Ideen:</v-card-title>

          <v-card-text class="ml-5" v-html="aiText.tips"></v-card-text>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          v-if="softwareIntegration.zvooveOne"
          class="sheet1__card"
          title="Mitarbeiter Übersicht"
        >
          <v-card-text>
            <strong>{{ zvooveDashboardData.mitarbeiter }}</strong> Mitarbeiter
            insgesamt<br />
            Eintritte: {{ zvooveDashboardData.eintritte }} | Austritte:
            {{ zvooveDashboardData.austritte }}
            | im Einsatz: {{ zvooveDashboardData.mitarbeiterMitEinsatz }}<br />
            <strong
              >ohne Einsatz:
              {{ zvooveDashboardData.mitarbeiterOhneEinsatz }}</strong
            >
            <v-divider class="my-1"></v-divider>
            {{ zvooveDashboardData.baldBeginnend }} bald beginnend<br />
            {{ zvooveDashboardData.mitarbeiterInFehlzeit }} Mitarbeiter in
            Fehlzeit ({{
              zvooveDashboardData.mitarbeiterInFehlzeitOhneBeleg
            }}
            ohne Beleg)<br />
            {{ zvooveDashboardData.baldEndend }} beenden Einsatz bald <br />
            {{ zvooveDashboardData.mitarbeiterErreichenHUeD }} erreichen
            Höchstüberlassungsdauer<br />
            {{ zvooveDashboardData.mitarbeiterInEqualPay }} sind in EqualPay<br />
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col>
      <v-sheet class="pa-2 ma-2"> </v-sheet>
    </v-col>
  </v-row>
  <!--<ProfileEditor></ProfileEditor>-->
  <!--<WhatsAppMessenger></WhatsAppMessenger>-->
</template>

<script lang="ts">
import { ActionType, MutationType } from "../../enums/vuex-types.enum";
import { AiService } from "@/services/ai.service";
import { AllErpAdapter } from "@/adapter/all-erp.adapter";
import { CompanyConfigService } from "../../services/api/company-config.service";
import { defineComponent } from "vue";
import { ErpFetchOption } from "../../enums/init-options.enum";
import { KpiCategory } from "../../enums/sales-kpi.enum";
import { LinkingStatus } from "@/enums/dependency.enum";
import { mapGetters } from "vuex";
import { SalesKPIs } from "../../models/sales-kpi.model";
import { TimelineService } from "@/services/api/timeline.service";
import { TimePeriod } from "../../enums/time-period.enum";
import { UserRoleHelper } from "@/helper/user-role.helper";
import {
  PhoneCallOption,
  TimelineEntryType,
} from "../../enums/timeline-entry-types.enum";
import DialogService from "@/services/dialog.service";
import moment from "moment";
import ToastService from "../../services/toast.service";
import { DashboardAiText } from "../../models/dashboard-ai-text.model";
import { UserService } from "../../services/api/user.service";
import { User } from "../../models/user.model";

export default defineComponent({
  name: "MainDashboard",
  components: {},
  data() {
    return {
      aiText: {} as DashboardAiText,
      imagename: "",
      isLoading: false,
      KpiCategory,
      TimePeriod,
      linkAnimation142x120: "",
      linkAnimation296x120: "",
      LinkingStatus,
      PhoneCallOption,
      salesKPIs: {} as SalesKPIs,
      showEditor: true,
      size: 0,
      TimelineEntryType,
      timelineService: new TimelineService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters([
      "candidateToCustomerEvents",
      "candidateToMandantEvents",
      "user",
      "timelineEntryTypes",
      "softwareIntegration",
      "getLoggedInMandantBranchNumbers",
      "allZvooveOneGeschaeftsstelleIds",
      "zvooveDashboardData",
    ]),
    hasLoggedInMandants() {
      if (
        this.user &&
        this.user.config &&
        Array.isArray(this.user.config.loggedInMandants)
      ) {
        return this.user.config.loggedInMandants.length > 0;
      }
      return false;
    },
    isAdmin(): boolean {
      return UserRoleHelper.isAdmin(this.user);
    },
  },
  mounted() {
    this.fetchActivitiesKPIs().then(() => {
      this.fetchZvooveOneDashboard().then(() => {
        this.fetchAiText();
        this.checkIfHaveToFetchErpInitData();
        this.$store.commit(MutationType.setScoreboardActive);
      });
    });
  },
  watch: {
    getLoggedInMandantBranchNumbers(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchActivitiesKPIs().then(() => {
          this.fetchZvooveOneDashboard().then(() => {
            this.fetchAiText();
          });
        });
      }
    },
  },
  methods: {
    async checkIfHaveToFetchErpInitData() {
      if (!this.isAdmin) return;
      const lastFetched =
        this.$store.state.company.softwareIntegration.lastAutoUpdateFromErp;
      const erpOptions = [
        ErpFetchOption.CustomerList,
        ErpFetchOption.EmployeeList,
      ];

      if (!lastFetched || moment().diff(moment(lastFetched), "days") > 1) {
        const confirmed = await DialogService.confirm(
          "Gesamte Kunden- bzw. Personaldaten aus der ERP sind älter als 24 Stunden! Mit dem ERP synchronisieren?",
          "jetzt nicht",
          "Daten von ERP abrufen"
        );

        if (confirmed) {
          ToastService.showReminder(
            "ERP Daten von Unternehmen und Personalien werden im Hintergrund abgerufen!"
          );
          await this.fetchErpInitData(erpOptions);

          const currentTime = moment().toISOString();

          this.$store.commit(MutationType.setLastUpdateErpData, currentTime);

          const companyConfigService = new CompanyConfigService();
          await companyConfigService.putLastAutoUpdateFromErp(currentTime);
        }
      }
    },
    async fetchActivitiesKPIs() {
      this.$store.dispatch(ActionType.loadLinkingsAndAddMissingDescriptions);
      const notes = {} as any;
      notes[this.timelineEntryTypes.phoneCallCandidate.description] =
        PhoneCallOption.notReached;
      notes[this.timelineEntryTypes.phoneCallCustomer.description] =
        PhoneCallOption.notReached;
      notes[this.timelineEntryTypes.phoneCallEmployee.description] =
        PhoneCallOption.notReached;

      const kpiRequest = {
        mandantUuids: this.user.config ? this.user.config.loggedInMandants : [],
        entryTypes: [
          this.timelineEntryTypes.phoneCallCandidate.description,
          this.timelineEntryTypes.phoneCallCustomer.description,
          this.timelineEntryTypes.phoneCallEmployee.description,
          this.timelineEntryTypes.candidateSuggestion.description,
          this.timelineEntryTypes.meetingOutside.description,
          this.timelineEntryTypes.meetingInhouse.description,
          this.timelineEntryTypes.emailCandidate.description,
          this.timelineEntryTypes.eMailCustomer.description,
          this.timelineEntryTypes.whatsAppCandidate.description,
          this.timelineEntryTypes.whatsAppEmployee.description,
          this.getEventNameByCandidateStatus(
            this.candidateToMandantEvents,
            LinkingStatus.interview
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToMandantEvents,
            LinkingStatus.interviewCanceled
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToMandantEvents,
            LinkingStatus.interviewNotShownUp
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToMandantEvents,
            LinkingStatus.interviewCompleted
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToCustomerEvents,
            LinkingStatus.interviewExternal
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToCustomerEvents,
            LinkingStatus.trailWorkExternal
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToCustomerEvents,
            LinkingStatus.jobofferExternal
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToCustomerEvents,
            LinkingStatus.jobofferAcceptedExternal
          ),
        ],
        notes: notes,
      };
      try {
        this.salesKPIs = await this.timelineService.getKPIs(kpiRequest);
      } catch (error) {
        console.error("Failed to fetch KPIs:", error);
      }
    },
    async fetchErpInitData(erpOptions: ErpFetchOption[]) {
      if (
        (!this.softwareIntegration.pdHub ||
          !this.softwareIntegration.zvooveOne) &&
        this.isLoading
      ) {
        return;
      }

      this.isLoading = true;

      try {
        await AllErpAdapter.getErpInitData(
          erpOptions,
          this.allZvooveOneGeschaeftsstelleIds
        );
      } catch (error) {
        console.error("Fehler beim Abrufen der Initialdaten", error);
      } finally {
        this.isLoading = false;
      }
    },
    getEventNameByCandidateStatus(eventsArray: any, status: any) {
      const event = eventsArray.find(
        (event: any) => event.candidateStatus === status
      );
      return event ? event.eventName : null;
    },

    getDynamicKPI(
      typeName: string,
      period: TimePeriod,
      category: KpiCategory,
      noteSuffix = ""
    ) {
      let eventName = this.resolveEventName(typeName);

      eventName += noteSuffix ? ` ${noteSuffix}` : "";

      const kpiData = this.salesKPIs[eventName]
        ? this.salesKPIs[eventName][period]
        : null;

      return kpiData ? kpiData[category] : 0;
    },

    resolveEventName(typeName: string) {
      if (this.timelineEntryTypes[typeName]) {
        return this.timelineEntryTypes[typeName].description;
      }
      const event = [
        ...this.candidateToMandantEvents,
        ...this.candidateToCustomerEvents,
      ].find((event) => event.candidateStatus === typeName);
      return event ? event.eventName : typeName;
    },
    checkIfHasToFetchAiText() {
      if (
        this.user?.insightsBundle?.dashboardText?.date ===
        moment().format("YYYY-MM-DD")
      ) {
        this.aiText =
          this.user?.insightsBundle?.dashboardText?.dashboardAnalysis;
        return false;
      }
      return true;
    },
    async fetchAiText() {
      if (
        this.softwareIntegration.dashboardAiKpiAnalysis &&
        this.checkIfHasToFetchAiText()
      ) {
        const aiService = new AiService();
        const response = await aiService.generateDashboardText(
          this.salesKPIs,
          this.zvooveDashboardData
        );
        if (response) {
          const userService = new UserService();
          this.aiText = response;
          const updatedUser = (await userService.updateDashboardText(
            this.user._id,
            this.aiText
          )) as unknown as User;

          this.$store.commit(
            MutationType.setUserDashboardAnalysis,
            updatedUser?.insightsBundle?.dashboardText
          );
        }
      }
    },
    async fetchZvooveOneDashboard() {
      if (this.isLoading || this.getLoggedInMandantBranchNumbers.length === 0) {
        return;
      }
      this.isLoading = true;

      try {
        let retries = 3;
        while (!this.$store.state.zorstAlive && retries > 0) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          retries--;
        }

        if (!this.$store.state.zorstAlive) {
          console.warn("Zorst service is not alive after 3 retries.");
          return;
        }

        await AllErpAdapter.getErpDashboard(
          this.getLoggedInMandantBranchNumbers
        );
      } catch (error) {
        console.error("Error fetching ERP Dashboard Data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.sheet1 {
  margin-right: 1rem;

  &__card {
    background-color: var(--color-item);
    margin-bottom: 1rem;
  }
}

.sheet2 {
  margin-right: 1rem;

  &__card {
    background-color: var(--color-item);
    margin-bottom: 1rem;
  }
}
</style>
