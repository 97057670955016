import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "name-title d-flex justify-space-between font-weight-bold" }
const _hoisted_2 = { class: "d-flex justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ErrorChip = _resolveComponent("ErrorChip")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_PasswordDialog = _resolveComponent("PasswordDialog")!
  const _component_PasswordForgottenDialog = _resolveComponent("PasswordForgottenDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_btn, {
      variant: "text",
      icon: "",
      density: "compact",
      class: "mr-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          size: "small",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mainModal = true)),
          class: "mx-3 clickable"
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode(" fa-solid fa-user ")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "bottom"
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode("User Profil ändern")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.mainModal,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.mainModal) = $event)),
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "title d-flex" }, {
              default: _withCtx(() => [
                _cache[16] || (_cache[16] = _createTextVNode(" Profil bearbeiten ")),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_icon, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mainModal = false)),
                  class: "close-icon"
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createElementVNode("i", { class: "fa-solid fa-xmark" }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", null, _toDisplayString(_ctx.user?.forename) + " " + _toDisplayString(_ctx.user?.lastname), 1),
              _createElementVNode("div", null, "Firma " + _toDisplayString(_ctx.$store.state.company.name), 1)
            ]),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  ref: "form",
                  modelValue: _ctx.valid,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.valid) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.user.tel,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.tel) = $event)),
                      label: "Telefonnummer"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.user.mobilePhone,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.mobilePhone) = $event)),
                      label: "Handynummer"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.user.email,
                      "onUpdate:modelValue": [
                        _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.email) = $event)),
                        _ctx.resetError
                      ],
                      label: "E-Mail",
                      rules: [_ctx.rules.required, _ctx.rules.email]
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules", "onUpdate:modelValue"]),
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_v_btn, {
                          variant: _ctx.vStyle.btn.variant || undefined,
                          rounded: _ctx.vStyle.btn.rounded || undefined,
                          border: _ctx.vStyle.btn.border || undefined,
                          color: "primary",
                          onClick: _ctx.showPasswordModal,
                          "onUpdate:modelValue": _ctx.resetError
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createTextVNode("Passwort ändern")
                          ])),
                          _: 1
                        }, 8, ["variant", "rounded", "border", "onClick", "onUpdate:modelValue"]),
                        _createVNode(_component_ErrorChip, { label: _ctx.requestError }, null, 8, ["label"])
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_v_btn, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.btn.rounded || undefined,
                          border: _ctx.vStyle.btn.border || undefined,
                          color: "abort",
                          onClick: _ctx.showPasswordForgottenModal
                        }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode("Passwort vergessen")
                          ])),
                          _: 1
                        }, 8, ["variant", "rounded", "border", "onClick"])
                      ])
                    ]),
                    _createVNode(_component_v_divider, { class: "my-5" }),
                    (_ctx.softwareIntegration.pdHub)
                      ? (_openBlock(), _createBlock(_component_v_divider, {
                          key: 0,
                          class: "my-5"
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.user.config.hideTip && _ctx.user.config.hideTip?.onDispatcherBoard)
                      ? (_openBlock(), _createBlock(_component_v_checkbox, {
                          key: 1,
                          modelValue: _ctx.user.config.hideTip.onDispatcherBoard,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.config.hideTip.onDispatcherBoard) = $event)),
                          label: "Spaltenüberschriften auf Dispoboard verbergen"
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_v_label, { class: "mr-1" }, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode("Theme umstellen:")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  density: "compact",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setTheme()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.themeLight ? "Light" : "Dark"), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "red-darken-3",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.mainModal = false))
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("Abbrechen")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "green-darken-4",
                  onClick: _ctx.save
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode("Speichern")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    (_ctx.passwordModal)
      ? (_openBlock(), _createBlock(_component_PasswordDialog, {
          key: 0,
          "model-value": "",
          requestError: _ctx.passwordRequestError,
          onCancel: _cache[10] || (_cache[10] = ($event: any) => (_ctx.passwordModal = false)),
          onChangePassword: _ctx.changePassword,
          "onClick:outside": _ctx.handlePasswordBackgroundClick,
          onDirty: _ctx.resetPasswordError
        }, null, 8, ["requestError", "onChangePassword", "onClick:outside", "onDirty"]))
      : _createCommentVNode("", true),
    (_ctx.passwordForgottenModal)
      ? (_openBlock(), _createBlock(_component_PasswordForgottenDialog, {
          key: 1,
          "model-value": "",
          requestError: _ctx.passwordRequestError,
          onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.passwordForgottenModal = false)),
          onSent: _cache[12] || (_cache[12] = ($event: any) => (_ctx.passwordForgottenModal = false)),
          "onClick:outside": _ctx.handlePasswordBackgroundClick
        }, null, 8, ["requestError", "onClick:outside"]))
      : _createCommentVNode("", true)
  ], 64))
}