import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", { src: _ctx.appMaintenanceLogo }, null, 8, _hoisted_2)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"text-align":"center"} }, [
        _createElementVNode("h1", null, [
          _createElementVNode("span", { style: {"color":"#1c365e"} }, "Wartungs"),
          _createElementVNode("span", { style: {"color":"#008b8b"} }, " Modus")
        ]),
        _createElementVNode("p", null, "Versuchen Sie es später noch einmal")
      ], -1))
    ]),
    _: 1
  }))
}