import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "mr-2"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("fa-brands fa-whatsapp")
          ])),
          _: 1
        }),
        _cache[8] || (_cache[8] = _createTextVNode("WhatsApp Integration "))
      ]),
      (_ctx.wabNumber)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", null, "Firmen-WhatsApp-Nummer: " + _toDisplayString(_ctx.wabNumber), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("a", { href: _ctx.metaLink }, " Mit Meta verbinden ", 8, _hoisted_3)
      ]),
      false
        ? (_openBlock(), _createBlock(_component_v_form, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Accounts")
                ])),
                _: 1
              }),
              _createVNode(_component_v_divider, { class: "mb-5" }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.whatsAppConfig.accounts, (account, index) => {
                    return (_openBlock(), _createBlock(_component_v_col, {
                      cols: "12",
                      md: "4",
                      key: account._id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, {
                          variant: _ctx.vStyle.card.variant || undefined,
                          rounded: _ctx.vStyle.card.rounded || undefined,
                          border: _ctx.vStyle.card.border || undefined,
                          class: "mb-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, { class: "d-flex justify-content-between align-items-center mb-3" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(account.description) + " ", 1),
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                  variant: "text",
                                  onClick: ($event: any) => (_ctx.removeAccount(index))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                        _createTextVNode("fa-solid fa-trash-can")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: account.description,
                                  "onUpdate:modelValue": ($event: any) => ((account.description) = $event),
                                  label: "Name",
                                  density: "compact",
                                  class: "mb-2"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                                _createVNode(_component_v_select, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: account.mandants,
                                  "onUpdate:modelValue": ($event: any) => ((account.mandants) = $event),
                                  items: _ctx.mandants,
                                  "item-title": "name",
                                  "item-value": "uuid",
                                  label: "Niederlassungen",
                                  density: "compact",
                                  class: "mb-2",
                                  multiple: ""
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue", "items"]),
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: account.phoneNumber,
                                  "onUpdate:modelValue": ($event: any) => ((account.phoneNumber) = $event),
                                  label: "WhatsApp Nummer",
                                  density: "compact",
                                  class: "mb-2"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: account.accountId,
                                  "onUpdate:modelValue": ($event: any) => ((account.accountId) = $event),
                                  label: "WA Business Account ID",
                                  density: "compact",
                                  class: "mb-2"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: account.apiToken,
                                  "onUpdate:modelValue": ($event: any) => ((account.apiToken) = $event),
                                  label: "WA Business API Token",
                                  density: "compact",
                                  class: "mb-2"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: account.phoneId,
                                  "onUpdate:modelValue": ($event: any) => ((account.phoneId) = $event),
                                  label: "WA Business Phone ID",
                                  density: "compact",
                                  class: "mb-2"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                                _createVNode(_component_v_select, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: account.bot,
                                  "onUpdate:modelValue": ($event: any) => ((account.bot) = $event),
                                  items: _ctx.botOptions,
                                  label: "Ai Bot",
                                  "item-value": "value",
                                  "item-title": "label",
                                  density: "compact"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue", "items"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["variant", "rounded", "border"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: _ctx.vStyle.card.variant || undefined,
                        rounded: _ctx.vStyle.card.rounded || undefined,
                        border: _ctx.vStyle.card.border || undefined,
                        class: "border-dashed mb-2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "d-flex justify-content-between align-items-center mb-3" }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode(" Neuer Status ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.newAccount.description,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAccount.description) = $event)),
                                label: "Name",
                                density: "compact",
                                class: "mb-2"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_select, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.newAccount.mandants,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newAccount.mandants) = $event)),
                                items: _ctx.mandants,
                                "item-title": "name",
                                "item-value": "uuid",
                                label: "Niederlassungen",
                                density: "compact",
                                class: "mb-2",
                                multiple: ""
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.newAccount.phoneNumber,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newAccount.phoneNumber) = $event)),
                                label: "WhatsApp Nummer",
                                density: "compact",
                                class: "mb-2"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.newAccount.accountId,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newAccount.accountId) = $event)),
                                label: "WA Business Account ID",
                                density: "compact",
                                class: "mb-2"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.newAccount.apiToken,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newAccount.apiToken) = $event)),
                                label: "WA Business API Token",
                                density: "compact",
                                class: "mb-2"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.newAccount.phoneId,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newAccount.phoneId) = $event)),
                                label: "WA Business Phone ID",
                                density: "compact",
                                class: "mb-2"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_select, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.newAccount.bot,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newAccount.bot) = $event)),
                                items: _ctx.botOptions,
                                label: "Ai Bot",
                                "item-value": "value",
                                "item-title": "label",
                                density: "compact"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"]),
                              _createVNode(_component_v_btn, {
                                color: "primary",
                                variant: "text",
                                onClick: _ctx.addNewAccount
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode("Hinzufügen")
                                ])),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                variant: _ctx.vStyle.btn.variant || undefined,
                rounded: _ctx.vStyle.btn.rounded || undefined,
                border: _ctx.vStyle.btn.border || undefined,
                class: "mt-10",
                type: "submit",
                color: "success"
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Speichern")
                ])),
                _: 1
              }, 8, ["variant", "rounded", "border"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}